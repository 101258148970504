import React, { Fragment } from "react";
import Navbar from "../../components/Shared/Navbar";
import { Link } from "react-router-dom";
import ListaToursEdit from "./ListaToursEdit";
import MenuCliente from "../../components/Shared/MenuCliente";
import { Container, Row, Col } from "react-bootstrap";

export default function Dashboard({ uid, permisos, verificado, rol }) {
    // console.log("propsdash",props)
    return (
        <Container className="mt-4">
            <Row className="mobile mb-3">
                <Col md={12}>
                    <MenuCliente
                        dashboard={true}
                        anfitrion={true}
                        operador={permisos === "cliente-operador"}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="2" className="desktop">
                    <MenuCliente
                        dashboard={true}
                        vertical={true}
                        anfitrion={true}
                        operador={permisos === "cliente-operador"}
                    />
                </Col>
                <Col lg="10">
                    <div className="container mt-4">
                        {verificado ? (
                            <div className="d-flex justify-content-between">
                                <h2 className="text-itoour">Lista de Tours</h2>

                                <Link
                                    className="btn btn-itoour btn-lg"
                                    to="/creartour"
                                    style={{ height: "45px" }}
                                >
                                    <i className="fas fa-folder-plus mr-2"></i>
                                    Crear Tour
                                </Link>
                            </div>
                        ) : null}
                        <ListaToursEdit uid={uid} rol={rol} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
