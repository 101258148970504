import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Link, useHistory } from 'react-router-dom';
import TourService from '../../services/Tour';
import { EnumStatusTours } from '../../helpers/enumTours';
import Swal from 'sweetalert2';
import fire from '../../config/Firebase';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: '1000px',
        maxHeight: '720px',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '16px 32px 24px',
        overflow: 'scroll',
        overflowX: 'hidden',
    },
}));

export default function TransitionsModal({ resultGestionTour, idTour, uiId, tourNombre }) {
    console.log({ resultGestionTour, idTour, uiId, tourNombre });
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [idNotificacion, setIdNotificacion] = useState([]);
    let history = useHistory();
    const [notificationObs, setNotificationObs] = useState({});
    const notificationArray = [];

    useEffect(() => {
        if (!!resultGestionTour?.length) {
            const obj = {};

            resultGestionTour.forEach((field) => {
                obj[field.campo] = '';
            });

            setNotificationObs(obj);
        }
    }, [resultGestionTour]);

    let db = fire.firestore();
    const handleOpen = () => {
        const docRef = db.collection('notificaciones');
        docRef
            .where('idTour', '==', idTour)
            .get()
            .then((idTourNotif) => {
                // console.log({ idTourNotif });
                let arrayNotif = [];
                idTourNotif.forEach((idNotif) => {
                    let objNotif = {
                        id: idNotif.id,
                        nombreTour: idNotif.data().tourNombre,
                        idTour: idNotif.data().idTour,
                    };
                    arrayNotif.push(objNotif);
                });
                setIdNotificacion(arrayNotif);
            });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    let aprobarTour = () => {
        Swal.fire({
            title: '¿Desea aprobar el Tour?',
            icon: 'info',
            text: 'Al aprobar el Tour será publicado y no se podrá modificar',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, regresar',
            confirmButtonText: 'Sí, Activar',
            allowOutsideClick: false,
            customClass: {
                container: 'my-swal',
            },
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour.activarTour(idTour);
                if (idNotificacion.length === 0) {
                    sTour.notificarClienteTour(uiId, idTour, null, tourNombre, EnumStatusTours.PUBLICADO, '', 0);
                } else {
                    sTour.notificarClienteTour(
                        uiId,
                        idTour,
                        null,
                        tourNombre,
                        EnumStatusTours.PUBLICADO,
                        idNotificacion[0].id,
                        idNotificacion.length
                    );
                }
                Swal.fire({
                    title: 'El Tour fue aprobado con éxito',
                    icon: 'success',
                    customClass: {
                        container: 'my-swal',
                    },
                    showConfirmButton: false,
                    timer: 2000,
                });
                history.push('/dashboardadmin');
            } else {
                notificationArray.length = 0;
            }
        });
    };
    let desaprobarTour = (camposObservados) => {
        Swal.fire({
            title: '¿Desea observar el Tour?',
            icon: 'warning',
            text: 'Esto hará que los usuarios no puedan interactuar con el tour',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, regresar',
            confirmButtonText: 'Sí, Observar',
            allowOutsideClick: false,
            customClass: {
                container: 'my-swal',
            },
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour.desactivarTour(idTour);
                if (idNotificacion.length === 0) {
                    sTour.notificarClienteTour(
                        uiId,
                        idTour,
                        camposObservados,
                        tourNombre,
                        EnumStatusTours.OBSERVADO,
                        idNotificacion[0].id,
                        0
                    );
                } else {
                    sTour.notificarClienteTour(
                        uiId,
                        idTour,
                        camposObservados,
                        tourNombre,
                        EnumStatusTours.OBSERVADO,
                        idNotificacion[0].id,
                        idNotificacion.length
                    );
                }

                Swal.fire({
                    icon: 'success',
                    title: `El Tour ha sido observado`,
                    customClass: {
                        container: 'my-swal',
                    },
                    showConfirmButton: false,
                    timer: 2000,
                });
                history.push('/dashboardadmin');
            } else {
                notificationArray.length = 0;
            }
        });
    };
    let inhabilitarTour = (camposObservados) => {
        Swal.fire({
            title: '¿Desea INHABILITAR el Tour?',
            icon: 'warning',
            text: 'Esto hará que el tour pase a una lista negra por incumplir politicas de la página',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, regresar',
            confirmButtonText: 'Sí, Inhabilitar',
            allowOutsideClick: false,
            customClass: {
                container: 'my-swal',
            },
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour.inhabilitarTour(idTour);
                if (idNotificacion.length === 0) {
                    sTour.notificarClienteTour(
                        uiId,
                        idTour,
                        camposObservados,
                        tourNombre,
                        EnumStatusTours.INHABILITADO,
                        null,
                        0
                    );
                } else {
                    sTour.notificarClienteTour(
                        uiId,
                        idTour,
                        camposObservados,
                        tourNombre,
                        EnumStatusTours.INHABILITADO,
                        idNotificacion[0].id,
                        idNotificacion.length
                    );
                }

                Swal.fire({
                    icon: 'success',
                    title: `El Tour ha sido desactivado`,
                    customClass: {
                        container: 'my-swal',
                    },
                    showConfirmButton: false,
                    timer: 2000,
                });
                history.push('/dashboardadmin');
            } else {
                notificationArray.length = 0;
            }
        });
    };
    return (
        <div className='card shadow mb-3'>
            <button className='btn btn-itoour' type='button' onClick={handleOpen}>
                Gestionar Tour
            </button>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div class='modal-content'>
                            <form method='POST'>
                                <div class='modal-header'>
                                    <h5 class='modal-title' id='modal__title'>
                                        Gestionando Tour
                                    </h5>
                                </div>
                                <div class='modal-body'>
                                    <div className='row'>
                                        <div class='col-md-12'>
                                            <div class='form-row'>
                                                {Object.keys(notificationObs).map((campo, index) => {
                                                    return (
                                                        <div className='col-sm-6' key={index}>
                                                            <label class='col-form-label'>{campo}</label>
                                                            <textarea
                                                                class='form-control textArea__content'
                                                                //id={aux + 'OBS'}
                                                                //data-value={campo}
                                                                value={notificationObs[campo]}
                                                                onChange={(e) => {
                                                                    setNotificationObs({
                                                                        ...notificationObs,
                                                                        [campo]: e.target.value,
                                                                    });
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='modal-footer'>
                                    <button type='button' class='btn btn-secondary' onClick={handleClose}>
                                        Cerrar
                                    </button>
                                    {resultGestionTour.length !== 0 ? (
                                        <div className='d-flex justify-content-end flex-wrap'>
                                            <input
                                                type='button'
                                                class='btn btn-danger mr-2'
                                                onClick={() => {
                                                    /*resultGestionTour.forEach((element) => {
                                                        let tempCampo = document
                                                            .getElementById(element.aux + 'OBS')
                                                            .getAttribute('data-value');
                                                        let tempValor = document.getElementById(
                                                            element.aux + 'OBS'
                                                        ).value;
                                                        notificationArray.push({
                                                            campoObser: tempCampo,
                                                            valorCampo: tempValor,
                                                        });
                                                    });*/
                                                    inhabilitarTour(
                                                        Object.keys(notificationObs).map((campo) => {
                                                            return {
                                                                campoObser: campo,
                                                                valorCampo: notificationObs[campo],
                                                            };
                                                        })
                                                    );
                                                }}
                                                value='Inhabilitar'
                                            />
                                            <input
                                                type='button'
                                                class='btn btn-warning'
                                                onClick={() => {
                                                    /*resultGestionTour.forEach((element) => {
                                                        let tempCampo = document
                                                            .getElementById(element.aux + 'OBS')
                                                            .getAttribute('data-value');
                                                        let tempValor = document.getElementById(
                                                            element.aux + 'OBS'
                                                        ).value;
                                                        notificationArray.push({
                                                            campoObser: tempCampo,
                                                            valorCampo: tempValor,
                                                        });
                                                    });*/
                                                    desaprobarTour(
                                                        Object.keys(notificationObs).map((campo) => {
                                                            return {
                                                                campoObser: campo,
                                                                valorCampo: notificationObs[campo],
                                                            };
                                                        })
                                                    );
                                                }}
                                                value='Observar'
                                            />
                                        </div>
                                    ) : (
                                        <Link type='button' class='btn btn-success' onClick={aprobarTour}>
                                            Aprobar
                                        </Link>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
