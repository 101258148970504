import React, { Fragment } from 'react';

export default function CrearTourPrimerPaso({ value, actualizarInput, formState, errors, handleChange, handleBlur }) {
    return (
        <Fragment>
            <div className='form-group'>
                <label>Nombre del Tour</label>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Ingrese el nombre'
                    name='nombre'
                    value={formState.nombre}
                    onChange={(e) => {
                        actualizarInput(e);
                        handleChange(e);
                        handleBlur(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors.nombre && <small className='text-danger'>{errors.nombre}</small>}
            </div>

            <div className='form-group'>
                <label>Descripción del Tour</label>
                <textarea
                    className='form-control'
                    placeholder='Ingrese una descripción'
                    rows='6'
                    name='descripcion'
                    value={formState.descripcion}
                    onChange={(e) => {
                        actualizarInput(e);
                        handleChange(e);
                        handleBlur(e);
                    }}
                    onBlur={handleBlur}
                ></textarea>
                {errors.descripcion && <small className='text-danger'>{errors.descripcion}</small>}
            </div>
            <div className='form-group'>
                <label>Link de Youtube (Opcional)</label>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Ingrese el Link Ej. https://www.youtube.com/watch?v=UVCP4bKy9Iw'
                    name='youtube'
                    value={formState.youtube}
                    onChange={(e) => {
                        actualizarInput(e);
                        handleChange(e);
                        handleBlur(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors.youtube && <small className='text-danger'>{errors.youtube}</small>}
            </div>
        </Fragment>
    );
}
