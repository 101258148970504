import React from "react";
import { useSelector } from "react-redux";
import "./css/ProfilePic.css";

const ProfilePic = ({ permisos }) => {
    const avatar = useSelector((state) => state.auth.avatar);
    const operatorAvatar = useSelector((state) => state.auth.operatorAvatar);

    return (
        <div className="profile-pic">
            {/*<img
                src={
                    permisos === "cliente-operador" ||
                    permisos === "cliente-anfitrion"
                        ? operatorAvatar
                        : avatar
                }
                alt="profile pic"
            />*/}
            <img src={avatar} alt="profile pic" />
        </div>
    );
};

export default ProfilePic;
