import React from 'react';
import './css/WhatsAppButton.css';

export default function WhatsAppButton() {
    return (
        <a
            className='fab fa-whatsapp fab-wsp fa-wsp'
            href='https://api.whatsapp.com/send?phone=5117439064'
            target='_blank'
        ></a>
    );
}
