import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import Registro from './pages/Registro';
import Home from './pages/Home';
import CrearTour2 from './pages/Cliente/CrearTour2';
import VerTour from './pages/Usuario/VerTour';
import Tours from './pages/Usuario/Tours';
import ActivarUsuario from './pages/ActivarUsuario';
// import PrivateRouteCliente from "./pages/Cliente/PrivateRouteCliente";
import RegistroCliente from './pages/RegistroCliente';
import Dashboard from './pages/Cliente/Dashboard';
import EditarTour from './pages/Cliente/EditarTour';
//import Cuenta from "./pages/Usuario/Cuenta";
import Reservas from './pages/Reservas';
// import PrivateRouteUsuario from "./pages/Usuario/PrivateRouteUsuario";
//import PerfilCliente from "./pages/Cliente/PerfilCliente";
import DashboardAdmin from './pages/Admin/DashboardAdmin';
import ListaTours from './pages/Admin/ListaTours';
import VerTourAdmin from './pages/Admin/VerTourAdmin';
// import PrivateRouteAdmin from "./pages/Admin/PrivateRouteAdmin";
import NavBar from './components/Shared/Navbar';
import fire from './config/Firebase';
import Swal from 'sweetalert2';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import RestaurarContrasena from './pages/RestaurarContrasena';

import InfoPersonal from './pages/InfoPersonal';
import InfoEmpresa from './pages/Cliente/InfoEmpresa';
import InfoComercial from './pages/Cliente/InfoComercial';
import ActionsPage from './pages/ActionsPage';
import VerificationEmail from './pages/VerificationEmail';
import ResetPassword from './pages/ResetPassword';
import CompleteRegistration from './pages/CompleteRegistration';
// import defaultPic from './assets/defaultPic.jpg';
import SuccessPage from './pages/SuccessPage';
import PendingPage from './pages/PendingPage';
import FailurePage from './pages/FailurePage';
import { connect } from 'react-redux';
import Reservaciones from './pages/Cliente/Reservaciones';
// import { TransferWithinAStationSharp } from '@material-ui/icons';
// import ViewPDF from './components/Shared/ViewPDF';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            permisos: 'sinlogin',
            uid: 0,
            verificado: false,
            email: '',
            rol: '',
        };
        this.obtenerUsuario();
    }

    selectPic = (tipo) => {
        switch (tipo) {
            case 'Operador':
                return 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FOperador.png?alt=media&token=438591cb-4101-4cdf-b948-f31d59f3f5ec';
            case 'Hombre':
                return 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FProfilePicMale.png?alt=media&token=c6bc6d8c-9ce2-4d89-90d6-462d5ae86620';
            case 'Mujer':
                return 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FProfilePicFemale.png?alt=media&token=352b9239-9879-446b-977f-638c65da6eae';
            default:
                return 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FProfilePicNoGender.png?alt=media&token=62b982ba-3017-40f9-a430-7c2ede2b26e9';
        }
    };

    obtenerUsuario = () => {
        fire.auth().onAuthStateChanged((user) => {
            let sessionTimeout = null;

            if (user) {
                user.getIdTokenResult(true)
                    .then((token) => {
                        const authTime = token.claims.auth_time * 1000;
                        const sessionDuration = 12 * 60 * 60 * 1000;
                        let millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
                        millisecondsUntilExpiration = millisecondsUntilExpiration < 0 ? 0 : millisecondsUntilExpiration;

                        sessionTimeout = setTimeout(() => this.logoutInTimeout(), millisecondsUntilExpiration);

                        if (token.claims.admin && millisecondsUntilExpiration) return 'admin';

                        if (millisecondsUntilExpiration) return true;

                        return false;
                    })
                    .then((permisos) => {
                        if (permisos !== 'admin') {
                            if (permisos === true) {
                                const db = fire.firestore();

                                db.collection('usuario')
                                    .doc(user.uid)
                                    .get()
                                    .then((u) => {
                                        if (!u.exists) {
                                            return db
                                                .collection('cliente')
                                                .doc(user.uid)
                                                .get()
                                                .then((c) => c.data());
                                        }

                                        return u.data();
                                    })
                                    .then((data) => {
                                        let tipo, foto, operador_foto;
                                        let rol = data?.cliente_tipo;

                                        if (data?.cliente_tipo === 'TOUR OPERADOR') {
                                            tipo = 'cliente-operador';
                                            foto = '';
                                            operador_foto = data?.cliente_operadorfoto || this.selectPic('Operador');
                                        } else if (
                                            data?.cliente_tipo === 'ANFITRION' ||
                                            data?.cliente_tipo === 'GUIA'
                                        ) {
                                            tipo = 'cliente-usuario';
                                            foto = data?.cliente_foto || this.selectPic(data?.cliente_sexo);
                                            operador_foto =
                                                data?.cliente_operadorfoto || this.selectPic(data?.cliente_sexo);
                                        } else {
                                            tipo = 'usuario';
                                            foto = data?.usuario_foto || this.selectPic(data?.usuario_sexo);
                                            operador_foto = '';
                                            rol = '';
                                        }

                                        this.setState({
                                            logged: true,
                                            permisos: tipo,
                                            uid: user.uid,
                                            email: user.email,
                                            verificado: user.emailVerified,
                                            rol,
                                        });

                                        this.props.setAvatar(foto || operador_foto);
                                        this.props.setOperatorAvatar(operador_foto || foto);
                                    });
                            } else {
                                this.setState({
                                    logged: false,
                                    permisos: 'sinlogin',
                                    uid: 0,
                                    verificado: false,
                                    email: '',
                                    rol: '',
                                });
                            }
                        } else {
                            this.setState({
                                logged: true,
                                permisos: 'admin',
                                uid: user.uid,
                                verificado: true,
                                rol: '',
                            });
                        }
                    });
            } else {
                sessionTimeout && clearTimeout(sessionTimeout);
                sessionTimeout = null;

                this.setState({
                    logged: false,
                    permisos: 'sinlogin',
                    uid: 0,
                    verificado: false,
                    foto: '',
                    operador_foto: '',
                    email: '',
                    rol: '',
                });
            }
        });
    };

    logout = () => {
        fire.auth()
            .signOut()
            .then(() => {
                Swal.fire({
                    icon: 'info',
                    title: 'Ha cerrado sesión con éxito',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    this.setState({
                        logged: false,
                        permisos: 'sinlogin',
                    });
                });
            });
    };

    silenceLogout = () => {
        this.setState({
            logged: false,
            permisos: 'sinlogin',
        });

        return fire.auth().signOut();
    };

    logoutInTimeout = () => {
        fire.auth()
            .signOut()
            .then(() => {
                this.setState({
                    logged: false,
                    permisos: 'sinlogin',
                });
            });
    };

    cambiarModo = () => {
        if (this.state.permisos === 'cliente-usuario') {
            this.setState({
                ...this.state,
                permisos: 'cliente-anfitrion',
            });
        } else {
            this.setState({
                ...this.state,
                permisos: 'cliente-usuario',
            });
        }
    };

    sendEmailVerification = (e) => {
        e.preventDefault();

        const user = fire.auth().currentUser;

        if (user) {
            Swal.fire({
                title: 'Enviando correo de verificación',
                text: 'Espere un momento',
                allowOutsideClick: false,
                icon: 'info',
                showConfirmButton: false,
            });
            Swal.showLoading();
            user.sendEmailVerification()
                .then(() => {
                    Swal.fire({
                        title: 'Correo de verificación enviado',
                        text: 'Revise su bandeja de entrada',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Ocurrió un error al enviar el correo. Es posible que el correo ya haya sido enviado.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 3000,
                    });
                });
        }
    };

    render() {
        return (
            <div>
                <Router>
                    {this.state.permisos !== 'admin' && this.state.permisos !== 'sinlogin' && !this.state.verificado ? (
                        <div className='msg-verificacion-container'>
                            <span className='msg-verificacion'>
                                <span>¡Su cuenta no está verificada!</span> Por favor revise su correo con el que se
                                registró y de click en el enlace de verificación.
                            </span>
                            <div>
                                <span className='msg-verificacion'>No me llegó el enlace. </span>{' '}
                                <span onClick={this.sendEmailVerification} className='verificar'>
                                    Volver a enviar
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <NavBar
                        permisos={this.state.permisos}
                        logout={this.logout}
                        cambiarModo={this.cambiarModo}
                        email={this.state.email}
                        uid={this.state.uid}
                    />
                    <Switch>
                        <Route path={'/'} exact component={Home} />
                        <Route path={'/restaurar'} exact component={RestaurarContrasena} />
                        <Route
                            path={'/login'}
                            exact
                            render={() => {
                                if (this.state.logged) {
                                    return <Redirect to={{ pathname: '/' }} />;
                                } else {
                                    return <Login />;
                                }
                            }}
                        />
                        <Route
                            path={'/registro'}
                            exact
                            render={(props) => {
                                return <Registro logout={this.silenceLogout} {...props} />;
                            }}
                        />
                        <Route
                            path={'/registrocliente'}
                            exact
                            render={(props) => {
                                return <RegistroCliente logout={this.silenceLogout} {...props} />;
                            }}
                        />
                        <Route
                            path={'/creartour'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged === true &&
                                    (this.state.permisos === 'cliente-operador' ||
                                        this.state.permisos === 'cliente-anfitrion')
                                ) {
                                    return <CrearTour2 history={this.props.history} rol={this.state.rol} />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/dashboard'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged === true &&
                                    (this.state.permisos === 'cliente-operador' ||
                                        this.state.permisos === 'cliente-anfitrion')
                                ) {
                                    return (
                                        <Dashboard
                                            uid={this.state.uid}
                                            permisos={this.state.permisos}
                                            verificado={this.state.verificado}
                                            rol={this.state.rol}
                                        />
                                    );
                                } else {
                                    if (this.state.logged === true && this.state.permisos === 'admin') {
                                        return (
                                            <Redirect
                                                to={{
                                                    pathname: '/dashboardadmin',
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Redirect to={{ pathname: '/login' }} />;
                                    }
                                }
                            }}
                        />
                        <Route
                            path={'/editartour/:id'}
                            exact
                            render={(props) => {
                                if (
                                    this.state.logged === true &&
                                    (this.state.permisos === 'cliente-operador' ||
                                        this.state.permisos === 'cliente-anfitrion' ||
                                        this.state.permisos === 'admin')
                                ) {
                                    return (
                                        <EditarTour
                                            {...props}
                                            rol={this.state.rol}
                                            admin={this.state.permisos === 'admin'}
                                        />
                                    );
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/reservas'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged === true &&
                                    (this.state.permisos === 'usuario' || this.state.permisos === 'cliente-usuario')
                                ) {
                                    return <Reservas uid={this.state.uid} permisos={this.state.permisos} />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/reservaciones'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged === true &&
                                    (this.state.permisos === 'cliente-anfitrion' ||
                                        this.state.permisos === 'cliente-operador')
                                ) {
                                    return <Reservaciones uid={this.state.uid} permisos={this.state.permisos} />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/dashboardadmin'}
                            exact
                            render={() => {
                                if (this.state.logged === true && this.state.permisos === 'admin') {
                                    return <DashboardAdmin />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/listatoursadmin/:id'}
                            exact
                            render={(props) => {
                                if (this.state.logged === true && this.state.permisos === 'admin') {
                                    return <ListaTours {...props} />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/vertouradmin/:id'}
                            exact
                            render={(props) => {
                                if (this.state.logged === true && this.state.permisos === 'admin') {
                                    return <VerTourAdmin {...props} />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route
                            path={'/vertour/:id'}
                            exact
                            render={(props) => {
                                return (
                                    <VerTour
                                        userId={this.state.uid}
                                        permisos={this.state.permisos}
                                        email={this.state.email}
                                        {...props}
                                    />
                                );
                            }}
                        />
                        <Route path={'/vertours/:departamento?'} exact component={Tours} />
                        <Route path={'/activar/:id'} exact component={ActivarUsuario} />
                        <Route path={'/contacto'} exact component={Contact} />
                        <Route path={'/nosotros'} exact component={AboutUs} />
                        <Route
                            path={'/perfil'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged &&
                                    (this.state.permisos === 'usuario' || this.state.permisos === 'cliente-usuario')
                                ) {
                                    return <InfoPersonal permisos={this.state.permisos} />;
                                } else {
                                    if (
                                        this.state.logged &&
                                        (this.state.permisos === 'cliente-operador' ||
                                            this.state.permisos === 'cliente-anfitrion')
                                    ) {
                                        return (
                                            <Redirect
                                                to={{
                                                    pathname: '/perfilempresa',
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Redirect to={{ pathname: '/login' }} />;
                                    }
                                }
                            }}
                        />
                        <Route
                            path={'/perfilempresa'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged &&
                                    (this.state.permisos === 'cliente-operador' ||
                                        this.state.permisos === 'cliente-anfitrion')
                                ) {
                                    return <InfoEmpresa permisos={this.state.permisos} />;
                                } else {
                                    if (this.state.logged && this.state.permisos === 'cliente-usuario') {
                                        return (
                                            <Redirect
                                                to={{
                                                    pathname: '/perfil',
                                                }}
                                            />
                                        );
                                    } else {
                                        return <Redirect to={{ pathname: '/login' }} />;
                                    }
                                }
                            }}
                        />
                        <Route
                            path={'/perfilcomercial'}
                            exact
                            render={() => {
                                if (
                                    this.state.logged &&
                                    (this.state.permisos === 'cliente-operador' ||
                                        this.state.permisos === 'cliente-anfitrion')
                                ) {
                                    return <InfoComercial />;
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route path='/action' render={() => <ActionsPage />} />
                        <Route
                            path='/verification'
                            render={() => (
                                <VerificationEmail
                                    logged={this.state.logged}
                                    setVerificado={() => {
                                        this.setState({
                                            ...this.state,
                                            verificado: true,
                                        });
                                    }}
                                />
                            )}
                        />
                        <Route path='/reset' render={() => <ResetPassword />} />
                        <Route
                            path='/completeregistration'
                            render={() => {
                                if (this.state.logged) {
                                    return (
                                        <CompleteRegistration
                                            permisos={this.state.permisos}
                                            uid={this.state.uid}
                                            setOperador={() =>
                                                this.setState({
                                                    ...this.state,
                                                    permisos: 'cliente-operador',
                                                })
                                            }
                                            email={this.state.email}
                                        />
                                    );
                                } else {
                                    return <Redirect to={{ pathname: '/login' }} />;
                                }
                            }}
                        />
                        <Route path='/success' render={() => <SuccessPage />} />
                        <Route path='/pending' render={() => <PendingPage />} />
                        <Route path='/failure' render={() => <FailurePage />} />
                        {/* <Route path='/viewpdf' render={() => <ViewPDF />} /> */}
                        <Route path='/*' render={() => <Redirect to={{ pathname: '/' }} />} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setAvatar: (avatar) => dispatch({ type: 'SET_AVATAR', payload: avatar }),
        setOperatorAvatar: (avatar) => dispatch({ type: 'SET_OPERATOR_AVATAR', payload: avatar }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
