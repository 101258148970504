import React, { Fragment, useState, useEffect } from 'react';
import fire from '../../config/Firebase';
import SharedHeader from '../../components/Shared/SharedHeader';
import { Link, useLocation } from 'react-router-dom';
import Search from '../../components/Shared/Search';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Footer from '../../components/Shared/Footer';
import WhatsAppButton from '../../components/Shared/WhatsAppButton';
import LeerMasLeerMenos from '../../components/Shared/LeerMasLeerMenos';
import './css/Tours.css';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default function Tours(props) {
    const query = useQuery();
    let [tours, setTours] = useState([]);
    let [vista, setVista] = useState(true);
    let [pagination, setPagination] = useState({
        offset: 0,
        perPage: 20,
        currentPage: 0,
        pageCount: 0,
    });
    const departamento = query.get('departamento');
    const provincia = query.get('provincia');
    const ciudad = query.get('ciudad');
    const tipo = query.get('tipo');
    const pMin = query.get('pMin');
    const pMax = query.get('pMax');
    const m = query.get('m');
    const s = query.get('s');

    let db = fire.firestore();

    let getTour = () => {
        const date = new Date(new Date().getTime() + 86400000);
        date.setHours(0, 0, 0, 0);

        db.collection('tours')
            .where('status', '==', 'Publicado')
            .get()
            .then((querySnapshot) => {
                let ToursTmp = [];
                querySnapshot.forEach((tour) => {
                    if (tour.data()?.status) {
                        const objTour = {
                            id: tour.id,
                            tour_nom: tour.data().tour_nom,
                            tour_activo: tour.data().tour_activo,
                            tour_status: tour.data().status,
                            tour_precio: tour.data().tour_precioAdulto,
                            tour_desc: tour.data().tour_desc,
                            tour_moneda: tour.data().tour_moneda,
                            tour_fecha: tour.data().tour_fecha,
                            tour_ciudad: tour.data().tour_ciudad,
                            tour_provincia: tour.data().tour_provincia,
                            tour_departamento: tour.data().tour_departamento,
                            tour_imagen: tour.data().tour_imagenes[tour.data().tour_miniatura],
                            tour_duracion: tour.data().fullDay
                                ? `Full Day`
                                : +(tour.data().horasTour ?? 0) + +(tour.data().minTour ?? 0) > 0
                                ? `${tour.data().horasTour ?? 0} hora${+(tour.data().horasTour ?? 0) > 1 ? 's' : ''} ${
                                      +(tour.data().minTour ?? 0) !== 0 ? `y ${tour.data().minTour} minutos` : ''
                                  }`
                                : `${tour.data().tour_dias} día${+tour.data().tour_dias > 1 ? 's' : ''} ${
                                      +tour.data().tour_noches !== 0
                                          ? `y ${tour.data().tour_noches} noche${
                                                +tour.data().tour_noches > 1 ? 's' : ''
                                            }`
                                          : ''
                                  }`,
                            cliente_tipo: tour.data().cliente_tipo,
                            tour_disponibilidad: tour.data().tour_disponibilidad || [],
                            tour_minimoanticipacion: tour.data().tour_minimoanticipacion,
                            tour_tipoanticipacion: tour.data().tour_tipoanticipacion,
                        };

                        ToursTmp.push(objTour);
                    }
                });

                // const lugar = !props.match.params.departamento ? null : props.match.params.departamento.toLowerCase();

                //if (lugar) {
                if (departamento) {
                    ToursTmp = ToursTmp.filter(
                        (tour) => tour.tour_departamento.toLowerCase() === departamento.toLowerCase()
                    );
                }

                if (provincia) {
                    ToursTmp = ToursTmp.filter((tour) => tour.tour_provincia.toLowerCase() === provincia.toLowerCase());
                }

                if (ciudad) {
                    ToursTmp = ToursTmp.filter((tour) => tour.tour_ciudad.toLowerCase() === ciudad.toLowerCase());
                }

                if (tipo) {
                    ToursTmp = ToursTmp.filter((tour) => tour.cliente_tipo.toLowerCase() === tipo.toLowerCase());
                }

                ToursTmp = ToursTmp.filter((tour) => {
                    const tiempo_anticipacion = new Date(
                        date.getTime() +
                            +tour.tour_minimoanticipacion *
                                (tour.tour_tipoanticipacion === 'dias' ? 1 : 30) *
                                24 *
                                60 *
                                60 *
                                1000
                    );

                    return !!tour?.tour_disponibilidad?.find((disponibilidad) => {
                        const diaDisponibilidad = new Date(disponibilidad?.dia.seconds * 1000);

                        diaDisponibilidad.setHours(0, 0, 0, 0);

                        return (
                            +disponibilidad.capacidad > 0 &&
                            diaDisponibilidad.getTime() >= tiempo_anticipacion.getTime()
                        );
                    });
                });

                if (pMin) {
                    ToursTmp = ToursTmp.filter((tour) => Math.ceil(+tour.tour_precio / 0.85) >= +pMin);
                }

                if (pMax) {
                    ToursTmp = ToursTmp.filter((tour) => Math.ceil(+tour.tour_precio / 0.85) <= +pMax);
                }

                if (m) {
                    ToursTmp = ToursTmp.filter((tour) => tour.tour_moneda.toLowerCase() === m.toLowerCase());
                }

                if (s) {
                    ToursTmp = ToursTmp.filter(
                        (tour) =>
                            tour.tour_departamento.toLowerCase().includes(s) ||
                            tour.tour_ciudad.toLowerCase().includes(s) ||
                            tour.tour_provincia.toLowerCase().includes(s) ||
                            tour.tour_nom.toLowerCase().includes(s)
                    );
                }

                setPagination({
                    ...pagination,
                    pageCount: Math.ceil(ToursTmp.length / pagination.perPage),
                });

                setTours(ToursTmp);
            });
    };

    useEffect(() => {
        getTour();
    }, []);

    let handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * pagination.perPage;

        setPagination({
            ...pagination,
            currentPage: selectedPage,
            offset: offset,
        });
    };

    const getTiempoAnticipacion = (tipo, cantidad) => {
        const tiempoDia = 24 * 60 * 60 * 1000;

        switch (tipo) {
            case 'dias':
                return +cantidad * tiempoDia;
            case 'meses':
                return +cantidad * 30 * tiempoDia;
            default:
                return 0;
        }
    };

    let busquedaTours = async ({ departamento, provincia, ciudad, tipo, fecha, rangoPrecios, moneda }) => {
        const date = moment(fecha).toDate();
        const dia = new Date(date);

        dia.setHours(0, 0, 0, 0);

        let Tours = await db
            .collection('tours')
            //.where('tour_departamento', '==', departamento)
            //.where("tour_fecha", ">=", date)
            .where('tour_moneda', '==', moneda)
            .get()
            .then((snapshot) => {
                const ToursTmp = [];

                snapshot.forEach(async (tour) => {
                    if (tour.data().status === 'Publicado') {
                        const objTour = {
                            id: tour.id,
                            tour_activo: tour.data().tour_activo,
                            tour_status: tour.data().status,
                            tour_nom: tour.data().tour_nom,
                            tour_precio: tour.data().tour_precioAdulto,
                            tour_desc: tour.data().tour_desc,
                            tour_moneda: tour.data().tour_moneda,
                            tour_ciudad: tour.data().tour_ciudad,
                            tour_provincia: tour.data().tour_provincia,
                            tour_departamento: tour.data().tour_departamento,
                            tour_imagen: tour.data().tour_imagenes[tour.data().tour_miniatura],
                            tour_duracion: tour.data().fullDay
                                ? `Full Day`
                                : +(tour.data().horasTour ?? 0) + +(tour.data().minTour ?? 0) > 0
                                ? `${tour.data().horasTour ?? 0} hora${+(tour.data().horasTour ?? 0) > 1 ? 's' : ''} ${
                                      +(tour.data().minTour ?? 0) !== 0 ? `y ${tour.data().minTour} minutos` : ''
                                  }`
                                : `${tour.data().tour_dias} día${+tour.data().tour_dias > 1 ? 's' : ''} ${
                                      +tour.data().tour_noches !== 0
                                          ? `y ${tour.data().tour_noches} noche${
                                                +tour.data().tour_noches > 1 ? 's' : ''
                                            }`
                                          : ''
                                  }`,
                            tour_disponibilidad: tour.data().tour_disponibilidad,
                            cliente_tipo: tour.data().cliente_tipo,
                            tour_minimoanticipacion: tour.data().tour_minimoanticipacion,
                            tour_tipoanticipacion: tour.data().tour_tipoanticipacion,
                        };

                        ToursTmp.push(objTour);
                    }
                });

                return ToursTmp;
            });

        const now = new Date();

        const tdy = new Date();
        tdy.setHours(0, 0, 0, 0);

        const date_turno = new Date(fecha);
        date_turno.setHours(0, 0, 0, 0);

        Tours = Tours.filter((t) => {
            //const mult = t.tour_tipoanticipacion === 'mes' ? 30 : 1;
            const disponibilidad = t.tour_disponibilidad
                .map((turno) => {
                    return {
                        dia: new Date(turno.dia.seconds * 1000),
                        capacidad: +turno.capacidad,
                    };
                })
                .filter((turno) => {
                    const date = new Date(
                        tdy.getTime() +
                            +t.tour_minimoanticipacion *
                                (t.tour_tipoanticipacion === 'dias' ? 1 : 30) *
                                24 *
                                60 *
                                60 *
                                1000
                    );

                    return turno.dia.getTime() > date.getTime() && turno.capacidad > 0;
                });

            return !!disponibilidad.length;
        });

        let toursFiltrados = Tours.filter((tour) => {
            const tiempo_anticipacion = new Date(
                tdy.getTime() +
                    +tour.tour_minimoanticipacion *
                        (tour.tour_tipoanticipacion === 'dias' ? 1 : 30) *
                        24 *
                        60 *
                        60 *
                        1000
            );

            return (
                Math.ceil(+tour.tour_precio / 0.85) >= rangoPrecios[0] &&
                Math.ceil(+tour.tour_precio / 0.85) <= rangoPrecios[1] &&
                !!tour?.tour_disponibilidad?.find((disponibilidad) => {
                    const diaDisponibilidad = new Date(disponibilidad?.dia.seconds * 1000);

                    diaDisponibilidad.setHours(0, 0, 0, 0);

                    return (
                        diaDisponibilidad.getTime() === dia.getTime() &&
                        +disponibilidad.capacidad > 0 &&
                        diaDisponibilidad.getTime() >= tiempo_anticipacion.getTime()
                    );
                })
            );
        });
        let params = {};

        if (departamento) params['departamento'] = departamento;
        if (provincia) params['provincia'] = provincia;
        if (ciudad) params['ciudad'] = ciudad;
        if (tipo) params['tipo'] = tipo;
        params['pMin'] = rangoPrecios[0];
        params['pMax'] = rangoPrecios[1];
        params['m'] = moneda;

        tipo && (toursFiltrados = toursFiltrados.filter((tour) => tour.cliente_tipo === tipo));

        if (departamento) {
            toursFiltrados = toursFiltrados.filter((tour) => tour.tour_departamento === departamento);

            if (provincia) {
                toursFiltrados = toursFiltrados.filter((tour) => tour.tour_provincia === provincia);

                ciudad && (toursFiltrados = toursFiltrados.filter((tour) => tour.tour_ciudad === ciudad));
            }
        }

        props.history.push({
            pathname: '/vertours',
            search: '?' + new URLSearchParams(params).toString(),
        });

        setPagination({
            ...pagination,
            pageCount: Math.ceil(toursFiltrados.length / pagination.perPage),
        });

        setTours(toursFiltrados);
    };

    useEffect(() => {
        const depa = props.match.params.departamento;
        depa && document.querySelector('#lista-de-tours').scrollIntoView();
    }, [props.match.params.departamento]);

    return (
        <Fragment>
            <WhatsAppButton />
            <div>
                <SharedHeader imagen='tours' titulo='Tours' />
                <div className='container mt-4 mb-4' id='lista-de-tours'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <Search busqueda={busquedaTours} query={query} />
                        </div>
                        <div className='col-md-9 container-tours'>
                            <div className='d-flex mb-2'>
                                <button
                                    className='btn btn-outline-secondary btn-sm ml-auto'
                                    onClick={() => {
                                        setVista(!vista);
                                    }}
                                >
                                    <i className='far fa-eye'></i>
                                </button>
                            </div>
                            {/* Tarjetas Horizontales y Verticales */}
                            <div className='row'>
                                {vista === true ? (
                                    tours.length ? (
                                        tours
                                            .slice(pagination.offset, pagination.offset + pagination.perPage)
                                            .map((t, i) => (
                                                <div className='col-12' key={i}>
                                                    <div className='card mb-3'>
                                                        <div className='row no-gutters'>
                                                            <div className='col-md-4'>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/vertour/${t.id}`,
                                                                    }}
                                                                    onClick={() => {
                                                                        window.fbq('track', 'Buscar', {
                                                                            medio: 'Tours',
                                                                            nombre_tour: t.tour_nom,
                                                                            region: t.tour_departamento,
                                                                            code: t.id,
                                                                        });
                                                                    }}
                                                                    className='lugares-nombre-link'
                                                                    target='_blank'
                                                                >
                                                                    <img
                                                                        src={t.tour_imagen}
                                                                        className='card-img'
                                                                        alt='...'
                                                                        style={{
                                                                            height: '100%',
                                                                            'aspect-ratio': '4/3',
                                                                            'object-fit': 'cover',
                                                                        }}
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className='col-md-8'>
                                                                <div
                                                                    className='card-body'
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'space-between',
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/vertour/${t.id}`,
                                                                                }}
                                                                                className='lugares-nombre-link'
                                                                                target='_blank'
                                                                                onClick={() => {
                                                                                    window.fbq('track', 'Buscar', {
                                                                                        medio: 'Tours',
                                                                                        nombre_tour: t.tour_nom,
                                                                                        region: t.tour_departamento,
                                                                                        code: t.id,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <h5
                                                                                    className='card-title font-weight-bold'
                                                                                    style={{
                                                                                        margin: 0,
                                                                                    }}
                                                                                >
                                                                                    {t.tour_nom}
                                                                                </h5>
                                                                            </Link>
                                                                            <small
                                                                                style={{
                                                                                    color: '#999',
                                                                                    fontWeight: '500',
                                                                                }}
                                                                            >
                                                                                {t.tour_ciudad}
                                                                            </small>
                                                                        </div>
                                                                        <h6
                                                                            className='text-itoour font-weight-bold'
                                                                            style={{
                                                                                minWidth: 'max-content',
                                                                            }}
                                                                        >
                                                                            {t.tour_moneda == 'PEN' ? 'S/. ' : '$. '}
                                                                            {Math.ceil(t.tour_precio / 0.85)}
                                                                        </h6>
                                                                    </div>
                                                                    <p className='card-text'>{`${t.tour_desc.substring(
                                                                        0,
                                                                        170
                                                                    )}...`}</p>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <small className='font-weight-bold'>
                                                                            {t.tour_duracion}
                                                                        </small>
                                                                        <div>
                                                                            <i
                                                                                className='far fa-map mr-2'
                                                                                style={{
                                                                                    color: '#D6872B',
                                                                                }}
                                                                            ></i>
                                                                            <small>{t.tour_departamento}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Link
                                                                            className='btn btn-itoour btn-sm'
                                                                            as='a'
                                                                            to={{
                                                                                pathname: `/vertour/${t.id}`,
                                                                            }}
                                                                            target='_blank'
                                                                            onClick={() => {
                                                                                window.fbq('track', 'Buscar', {
                                                                                    medio: 'Tours',
                                                                                    nombre_tour: t.tour_nom,
                                                                                    region: t.tour_departamento,
                                                                                    code: t.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            Descubrir
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <div className='d-flex w-100 justify-content-center mt-5'>
                                            <p className='no-tours'>
                                                Estamos trabajando en brindarte más experiencias para este destino.
                                                Mientras tanto, puedes explorar otros lugares que tenemos para ofrecerte
                                                y que estamos seguros te encantarán.
                                            </p>
                                        </div>
                                    )
                                ) : tours.length ? (
                                    <div className='container-list-tours'>
                                        {tours
                                            .slice(pagination.offset, pagination.offset + pagination.perPage)
                                            .map((t, i) => (
                                                <div className='text-helvetica card-lugares' key={i}>
                                                    <div className='card'>
                                                        <Link
                                                            to={{
                                                                pathname: `/vertour/${t.id}`,
                                                            }}
                                                            target='_blank'
                                                            onClick={() => {
                                                                window.fbq('track', 'Buscar', {
                                                                    medio: 'Tours',
                                                                    nombre_tour: t.tour_nom,
                                                                    region: t.tour_departamento,
                                                                    code: t.id,
                                                                });
                                                            }}
                                                        >
                                                            <img
                                                                className='card-img-top img-ratio'
                                                                src={t.tour_imagen}
                                                                alt='...'
                                                            />
                                                        </Link>
                                                        <div className='card-body'>
                                                            <div className='d-flex justify-content-between'>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/vertour/${t.id}`,
                                                                    }}
                                                                    className='lugares-nombre-link'
                                                                    target='_blank'
                                                                    onClick={() => {
                                                                        window.fbq('track', 'Buscar', {
                                                                            medio: 'Tours',
                                                                            nombre_tour: t.tour_nom,
                                                                            region: t.tour_departamento,
                                                                            code: t.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <h5 className='font-weight-bold lugares-nombre'>
                                                                        {t.tour_nom}
                                                                    </h5>
                                                                </Link>
                                                                <h6 className='text-itoour font-weight-bold lugares-precio'>
                                                                    {t.tour_moneda == 'PEN' ? 'S/. ' : '$. '}
                                                                    {Math.ceil(t.tour_precio / 0.85)}
                                                                </h6>
                                                            </div>
                                                            <div className='lugares-desc'>
                                                                <LeerMasLeerMenos
                                                                    limit={50}
                                                                    textLength={t.tour_desc.length}
                                                                >
                                                                    {t.tour_desc}
                                                                </LeerMasLeerMenos>
                                                            </div>
                                                            <div className='card-info'>
                                                                <small className='font-weight-bold'>
                                                                    {t.tour_fullDay ? 'Full Day' : t.tour_duracion}
                                                                </small>
                                                                <div className='d-flex justify-content-between align-items-center lugares-container-btn'>
                                                                    <div>
                                                                        <i
                                                                            className='far fa-map mr-2'
                                                                            style={{
                                                                                color: '#D6872B',
                                                                            }}
                                                                        ></i>
                                                                        <small>{t.tour_departamento}</small>
                                                                    </div>
                                                                    <Link
                                                                        className='btn btn-itoour btn-sm'
                                                                        to={{
                                                                            pathname: `/vertour/${t.id}`,
                                                                        }}
                                                                        target='_blank'
                                                                        onClick={() => {
                                                                            window.fbq('track', 'Buscar', {
                                                                                medio: 'Tours',
                                                                                nombre_tour: t.tour_nom,
                                                                                region: t.tour_departamento,
                                                                                code: t.id,
                                                                            });
                                                                        }}
                                                                    >
                                                                        Descubrir
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    <div>
                                        <p>No se encontraron Tours disponibles.</p>
                                    </div>
                                )}
                            </div>
                            {tours.length ? (
                                <div className='d-flex justify-content-center mb-3'>
                                    <ReactPaginate
                                        containerClassName='pagination'
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        breakClassName='page-item'
                                        previousLabel={'Anterior'}
                                        nextLabel={'Siguiente'}
                                        breakLabel={'...'}
                                        pageClassName='page-item'
                                        pageCount={pagination.pageCount}
                                        previousClassName='page-item'
                                        nextClassName='page-item'
                                        pageLinkClassName='page-link'
                                        previousLinkClassName='page-link'
                                        nextLinkClassName='page-link'
                                        activeClassName='active'
                                        activeLinkClassName='paginate'
                                        onPageChange={(e) => {
                                            handlePageClick(e);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
