import React from 'react';
import './css/Footer.css';
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

export default function Footer() {
    return (
        <div>
            <footer className='page-footer font-small mdb-color pt-4'>
                <div className='container text-center text-md-left'>
                    <div className='row text-center text-md-left mt-3 pb-3'>
                        <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
                            <h6 className='mb-3 font-weight-bold'>itoour.com</h6>
                            <p style={{ textAlign: 'justify' }}>
                                Bienvenidos a la comunidad itoour amantes del turismo. Si eres un anfitrión, aquí podrás
                                compartir rutas y experiecias exclusivas en tu zona, si eres un invitado encontrarás un
                                paquete turístico acorde a tu presupuesto y necesidades.
                            </p>
                        </div>
                        <hr className='w-100 clearfix d-md-none' />
                        <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3' style={{ position: 'relative' }}>
                            <h6 className='mb-3 font-weight-bold'>Información</h6>
                            <p>
                                <Link to='/nosotros' className='footer-link'>
                                    Nosotros
                                </Link>
                                <br />
                                <span className='text-secondary'>
                                    <a
                                        href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FPOLITICAS%20DE%20PRIVACIDAD%20DE%20ITOOUR%20Y%20USO%20DE%20COOKIES.pdf?alt=media&token=f6cfd966-1bdb-4b79-9c0f-7ad7bb2f6c7c'
                                        target='_blank'
                                        className='footer-link'
                                    >
                                        Nuestras Políticas
                                    </a>
                                    <br />
                                    <a
                                        href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FTERMINOS%20Y%20CONDICIONES%20USUARIOS.pdf?alt=media&token=92f24b78-1bff-4217-8321-8d0f3718d0c7'
                                        target='_blank'
                                        className='footer-link'
                                    >
                                        Nuestros Términos
                                    </a>
                                </span>
                            </p>
                            <h6 className='mb-4 font-weight-bold'>Síguenos:</h6>
                            <div className='text-center text-md-left redes-sociales-icons'>
                                <ul className='list-unstyled list-inline footer-redes-list'>
                                    <li className='list-inline-item'>
                                        <a
                                            className='btn-floating btn-sm rgba-white-slight mx-1 red-social'
                                            href='https://youtube.com/channel/UCZ7YCX-DpmG-_Td619r0z2g'
                                            target='_blank'
                                        >
                                            <i className='fab fa-youtube fa-3x'></i>
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a
                                            className='btn-floating btn-sm rgba-white-slight mx-1 red-social'
                                            href='https://www.instagram.com/itoour.latam/'
                                            target='_blank'
                                        >
                                            <i className='fab fa-instagram fa-3x'></i>
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a
                                            className='btn-floating btn-sm rgba-white-slight mx-1 red-social'
                                            href='https://twitter.com/ItoourL'
                                            target='_blank'
                                        >
                                            <i className='fab fa-twitter fa-3x'></i>
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a
                                            className='btn-floating btn-sm rgba-white-slight mx-1 red-social'
                                            href='https://www.linkedin.com/company/itoour/'
                                            target='_blank'
                                        >
                                            <i className='fab fa-linkedin-in fa-3x'></i>
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a
                                            className='btn-floating btn-sm rgba-white-slight mx-1 red-social'
                                            href='https://www.facebook.com/itoour.latam'
                                            target='_blank'
                                        >
                                            <i className='fab fa-facebook fa-3x'></i>
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a
                                            className='btn-floating btn-sm rgba-white-slight mx-1 red-social'
                                            href='https://www.tiktok.com/@itoour?_t=8Wiould7Twk&_r=1'
                                            target='_blank'
                                        >
                                            <i className='fab fa-tiktok fa-3x'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr className='w-100 clearfix d-md-none' />
                        <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mt-3'>
                            <h6 className='mb-3 font-weight-bold'>Atención al Cliente</h6>
                            <p className='mb-0'>
                                <Link to='/nosotros' className='footer-link'>
                                    Preguntas Frecuentes
                                </Link>
                            </p>
                            <p>
                                <Link to='/contacto' className='footer-link'>
                                    Solicita ayuda
                                </Link>
                            </p>
                        </div>
                        <hr className='w-100 clearfix d-md-none' />
                        <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'>
                            <h6 className='mb-3 font-weight-bold'>Contáctanos</h6>
                            <p>
                                <i className='fas fa-map-marker mr-3'></i>
                                <a href='https://goo.gl/maps/f1prZAWVcKHJndF39' className='footer-link' target='_blank'>
                                    Av. Pedro de Osma 240, Barranco, Lima
                                </a>
                            </p>
                            <p>
                                <i className='fas fa-map-marker mr-3'></i>
                                <a href='https://goo.gl/maps/W8iMJE1ZnZsxyfet9' className='footer-link' target='_blank'>
                                    Av. Los Colibríes, Mz G3 Lote 5, Trujillo, La Libertad
                                </a>
                            </p>
                            <p>
                                <i className='fas fa-map-marker mr-3'></i>
                                <a href='https://goo.gl/maps/9kJeBtCAei2fZqjB6' className='footer-link' target='_blank'>
                                    Urb. San Andrés V etapa, Mz D Lote 14, Trujillo, La Libertad
                                </a>
                            </p>
                            <p>
                                <i className='fas fa-envelope mr-3'></i>
                                <a href='mailto:info@itoour.com' className='footer-link'>
                                    info@itoour.com
                                </a>
                            </p>
                            <p>
                                <i className='fas fa-phone mr-3'></i>
                                <a href='tel:+5117439064' className='footer-link'>
                                    +51-17439064
                                </a>
                            </p>
                            <p>
                                <WhatsAppIcon className='mr-2' />
                                <a
                                    href='https://api.whatsapp.com/send?phone=5117439064'
                                    className='footer-link'
                                    target='_blank'
                                >
                                    +51-17439064
                                </a>
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-12'>
                            <p className='text-center'>
                                Copyright itoour© 2020 Todos los derechos reservados | Este sitio web esta hecho con{' '}
                                <i className='fas fa-heart mx-1'></i> y <i className='fas fa-coffee mx-1'></i> por
                                <a href='http://poopaye.com/' target='blank' className='ml-1'>
                                    Poopaye SAC
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
