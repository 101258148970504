import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import fire from '../../config/Firebase';
import SimpleModal from '../../pages/Cliente/ModalNotificacion';

const StyledBadge = withStyles((theme) => ({
    badge: {
        // padding: "0 2px",
        borderRadius: '50%',
        color: 'white',
        background: 'red'
        // fontSize: "10px",
        // minWidth: "10px",
        // height: "15px",
    },
    root: {
        color: 'rgba(255,255,255,.5)'
    }
}))(Badge);

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: '375px'
    }
}));

export default function CustomizedBadges({ notificacionesCount, notificaciones, runReload }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [invisible, setInvisible] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setInvisible(true);
    };
    const handleClose = () => {
        const db = fire.firestore();
        setAnchorEl(null);
        Promise.all(
            notificaciones
                .filter((n) => !n.visto)
                .map((notificacion) => db.collection('notificaciones').doc(notificacion.id).update({ visto: true }))
        ).then(() => runReload());
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick}>
                {notificacionesCount === 0 ? (
                    <StyledBadge invisible={true}>
                        <NotificationsRoundedIcon />
                    </StyledBadge>
                ) : (
                    <StyledBadge badgeContent={notificacionesCount} max={9} invisible={invisible}>
                        <NotificationsRoundedIcon />
                    </StyledBadge>
                )}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Typography className={classes.typography}>
                    <div className="dropdown-header m-0 p-0 text-center">
                        <h5 className="text-dark font-weight-bold">Notificaciones</h5>
                    </div>
                    {notificaciones.length === 0 ? (
                        <div className="notificaciones__vacio text-muted">No tiene notificaciones.</div>
                    ) : (
                        notificaciones.map((tour, i) =>
                            i <= 8 ? (
                                <div key={i}>
                                    <hr className="text-secondary mb-0 mt-1 text-center" style={{ width: '85%' }} />
                                    {tour.tipoNotificacion === 'Pendiente de aprobación' ? (
                                        <div
                                            className="text__notificaciones"
                                            style={{
                                                backgroundColor: !tour?.visto ? 'rgba(0,0,0,0.1)' : 'transparent'
                                            }}
                                        >
                                            ¡Felicitaciones! Acabas de crear el tour{' '}
                                            <span className="text-primary">{tour.tourNombre}</span> y está en proceso de{' '}
                                            <strong>Revisión</strong>. En un máximo de 48 horas podrás disfrutar de tu
                                            publicación.
                                        </div>
                                    ) : tour.tipoNotificacion === 'Publicado' ? (
                                        <div
                                            className="text__notificaciones"
                                            style={{
                                                backgroundColor: !tour?.visto ? 'rgba(0,0,0,0.1)' : 'transparent'
                                            }}
                                        >
                                            El tour <span className="text-primary">{tour.tourNombre}</span> fue{' '}
                                            <strong>publicado</strong> y esta listo para recibir reservaciones
                                        </div>
                                    ) : tour.tipoNotificacion === 'Observado' ? (
                                        <div
                                            className="text__notificaciones"
                                            style={{
                                                backgroundColor: !tour?.visto ? 'rgba(0,0,0,0.1)' : 'transparent'
                                            }}
                                        >
                                            El tour <span className="text-primary">{tour.tourNombre}</span> fue{' '}
                                            <strong>observado</strong> y tiene{' '}
                                            <strong>{tour.tourNotificaciones.length}</strong> campo(s) a corregir
                                        </div>
                                    ) : tour.tipoNotificacion === 'Inhabilitado' ? (
                                        <div
                                            className="text__notificaciones"
                                            style={{
                                                backgroundColor: !tour?.visto ? 'rgba(0,0,0,0.1)' : 'transparent'
                                            }}
                                        >
                                            El tour <span className="text-primary">{tour.tourNombre}</span> fue{' '}
                                            <strong>inhabilitado</strong> por incumplir de manera critica la politica de
                                            la empresa
                                        </div>
                                    ) : null}
                                </div>
                            ) : null
                        )
                    )}
                    <SimpleModal notificacionesTours={notificaciones} />
                </Typography>
            </Popover>
        </div>
    );
}
