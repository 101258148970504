const initState = {
    authError: null,
    avatar: null,
    operatorAvatar: null,
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOGIN_ERROR":
            //console.log("login error");
            return { ...state, authError: "Error al loguearse" };
        case "LOGIN_SUCCESS":
            //console.log("login exitoso");
            return {
                ...state,
                authError: null,
            };
        case "SIGNOUT_SUCCESS":
            //console.log("Bye!")
            return state;
        case "SET_AVATAR":
            return {
                ...state,
                avatar: action.payload,
            };
        case "SET_OPERATOR_AVATAR":
            return {
                ...state,
                operatorAvatar: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
