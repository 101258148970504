import React, { useState } from 'react';
import './Contact.css';
import Logo from '../assets/logo.png';
import Footer from '../components/Shared/Footer';
import Swal from 'sweetalert2';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { MapsKey, PlacesKey } from '../config/Google';
import { functions } from '../config/Firebase';
import WhatsAppButton from '../components/Shared/WhatsAppButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Contact = (props) => {
    const [laoding, setLoading] = useState(true);
    let [correo, setCorreo] = useState({
        subject: '',
        email: '',
        telefono: '',
        message: '',
        name: '',
        to: '',
    });

    const enviarCorreo = (e) => {
        e.preventDefault();
        Swal.fire({
            icon: 'info',
            title: `Espere por favor`,
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        const functionEmail = functions.httpsCallable('enviarEmail');

        const body = `<p>Tienes una nueva petición de contacto</p>
            <h3>Detalles de contacto</h3>
            <ul>
                <li>Nombre: ${correo.name}</li>
                <li>Asunto: ${correo.subject}</li>
                <li>Teléfono: ${correo.telefono}</li>
                <li>Email: ${correo.email}</li>
            </ul>
            <h3>Mensaje</h3>
            <p>${correo.message}</p>`;

        return functionEmail({
            ...correo,
            subject: `${process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''}${correo.subject}`,
            to: 'info@itoour.com',
            body,
            info: false,
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Envío Exitoso!',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    props.history.push('/');
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops!!!',
                    text: 'Algo salió mal, por favor intente de nuevo',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    props.history.push('/');
                });
            });
    };

    return (
        <div>
            <WhatsAppButton />
            <div className='headercontacto'>
                <div className='container'>
                    {/*<img className="logo_img img-fluid" src={Logo} alt="logo"></img>
                    <span className="descubre p-5 m-5 d-flex justify-content-center">Contáctanos</span>
				<br />*/}
                </div>
            </div>
            <section className='contacto'>
                <div className='contacto__container container'>
                    <h3 className='pt-5 pb-4'>Información de Contacto</h3>
                    <div className='contacto__info row pb-3'>
                        <div className='col-md-6'>
                            <p style={{ margin: 0 }}>
                                <i className='fas fa-map-marker mr-3'></i>
                                <a href='https://goo.gl/maps/f1prZAWVcKHJndF39' target='_blank'>
                                    Av. Pedro de Osma 240, Barranco, Lima
                                </a>
                            </p>
                            <p style={{ margin: 0 }}>
                                <i className='fas fa-map-marker mr-3'></i>
                                <a href='https://goo.gl/maps/W8iMJE1ZnZsxyfet9' target='_blank'>
                                    Av. Los Colibríes, Mz G3 Lote 5, Trujillo, La Libertad
                                </a>
                            </p>
                            <p style={{ margin: 0 }}>
                                <i className='fas fa-map-marker mr-3'></i>
                                <a href='https://goo.gl/maps/9kJeBtCAei2fZqjB6' target='_blank'>
                                    Urb. San Andrés V etapa, Mz D Lote 14, Trujillo, La Libertad
                                </a>
                            </p>
                        </div>
                        <div className='col-md-3'>
                            <p>
                                <i className='fas fa-phone mr-3'></i>
                                <a href='tel:+5117439064'>+51-17439064</a>
                            </p>
                            <p>
                                <WhatsAppIcon className='mr-2' />
                                <a href='https://api.whatsapp.com/send?phone=5117439064' target='_blank'>
                                    +51-17439064
                                </a>
                            </p>
                        </div>
                        <div className='col-md-3'>
                            <p>
                                <i className='fas fa-envelope mr-3'></i>
                                <a href='mailto:info@itoour.com'>info@itoour.com</a>
                            </p>
                        </div>
                    </div>
                    <div className='contacto__formulario row pb-5'>
                        <div className='col-md-6'>
                            <form>
                                <div className='form-group pt-2'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='exampleInputEmail1'
                                        aria-describedby='emailHelp'
                                        placeholder='Nombre'
                                        value={correo.name}
                                        onChange={(e) => {
                                            setCorreo({
                                                ...correo,
                                                name: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form-group pt-2'>
                                    <input
                                        type='email'
                                        className='form-control'
                                        id='exampleInputEmail1'
                                        aria-describedby='emailHelp'
                                        placeholder='Correo Electronico'
                                        value={correo.email}
                                        onChange={(e) => {
                                            setCorreo({
                                                ...correo,
                                                email: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form-group pt-2'>
                                    <input
                                        type='tel'
                                        className='form-control'
                                        id='exampleInputEmail1'
                                        placeholder='Número Telefónico'
                                        value={correo.telefono}
                                        onChange={(e) => {
                                            setCorreo({
                                                ...correo,
                                                telefono: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='form-group pt-2'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='exampleInputPassword1'
                                        placeholder='Asunto'
                                        value={correo.subject}
                                        onChange={(e) => {
                                            setCorreo({
                                                ...correo,
                                                subject: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='from-group pt-2'>
                                    <textarea
                                        className='form-control'
                                        id='exampleFormControlTextarea1'
                                        rows='5'
                                        placeholder='Escribir Mensaje'
                                        value={correo.message}
                                        onChange={(e) => {
                                            setCorreo({
                                                ...correo,
                                                message: e.target.value,
                                            });
                                        }}
                                    ></textarea>
                                </div>
                                <div className='contacto__formulario--item pt-4'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={(e) => {
                                            enviarCorreo(e);
                                        }}
                                    >
                                        Enviar Mensaje
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className='col-md-6'>
                            <Map
                                google={props.google}
                                zoom={13}
                                style={{
                                    height: '450px',
                                    maxWidth: '450px',
                                    display: 'block',
                                }}
                                initialCenter={{
                                    lat: -12.1003374,
                                    lng: -77.032559,
                                }}
                            >
                                <Marker
                                    position={{
                                        lat: -12.1003374,
                                        lng: -77.032559,
                                    }}
                                />
                            </Map>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

// export default Contact;

export default GoogleApiWrapper({
    apiKey: MapsKey,
})(Contact);
