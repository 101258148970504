import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { functions } from '../../config/Firebase';

const InfoPersonal = ({
    state,
    setState,
    guardarUsuario,
    prevStep,
    nextStep,
    permisos,
    formState,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    email,
}) => {
    const [redirect, setRedirect] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const existEmptyInputs = () => {
        const { nombres, apellidos, nacimiento, dni, celular } = state;
        return !nombres || !apellidos || !nacimiento || !dni || !celular;
    };

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
        setDisabled(existEmptyInputs());
    };

    useEffect(() => {
        setDisabled(existEmptyInputs());
    }, []);

    const actualizarNacimiento = (fecha) => {
        setState({
            ...state,
            nacimiento: new Date(fecha),
        });
    };

    const finalizar = () => {
        guardarUsuario()
            .then(async () => {
                const enviarEmail = functions.httpsCallable('enviarEmail');
                const fecha = new Date();

                const body = `<p>Sea ha verificado la cuenta ${email} perteneciente a ${state.nombres} ${
                    state.apellidos
                } el día ${fecha.toLocaleDateString('us-GB')}, a las ${fecha.toLocaleTimeString('us-GB', {
                    hour12: true,
                })}`;

                await enviarEmail({
                    subject: `${
                        process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''
                    }Nueva verificación de cuenta`,
                    to: 'soporte@itoour.com,ventas@itoour.com',
                    body,
                    info: true,
                });
                window.location.replace('/');
            })
            .catch((error) => setRedirect(false));
    };

    if (redirect) return <Redirect to='/' />;

    return (
        <div className='complete-page w-100 h-100'>
            <Container className='complete-register h-100'>
                <Row className='d-flex flex-column justify-content-center align-items-center h-100'>
                    <Col lg={8}>
                        <Card>
                            <Card.Body>
                                {permisos === 'Usuario' ? (
                                    <>
                                        <h2 className='register-title'>Bienvenido a Itoour</h2>
                                        <p>
                                            Para continuar con el registro por favor tómese un momento para llenar el
                                            siguiente formulario.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <h2>Información Personal</h2>
                                    </>
                                )}
                                <Form>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col md={6} lg={6}>
                                                <Form.Label>Nombres:</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    //value={state.nombres}
                                                    value={formState.nombres}
                                                    name='nombres'
                                                    placeholder='Ingrese su nombre'
                                                    onChange={(e, value) => {
                                                        console.log({ value });
                                                        actualizarInput(e);
                                                        handleChange(e);
                                                        handleBlur(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.nombres && (
                                                    <small className='text-danger'>{errors.nombres}</small>
                                                )}
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Label>Apellidos:</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    //value={state.apellidos}
                                                    value={formState.apellidos}
                                                    name='apellidos'
                                                    placeholder='Ingrese su apellido'
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        actualizarInput(e);
                                                        handleChange(e);
                                                        handleBlur(e);
                                                    }}
                                                />
                                                {errors.apellidos && (
                                                    <small className='text-danger'>{errors.apellidos}</small>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col md={6} lg={6}>
                                                <Form.Label>Fecha de Nacimiento:</Form.Label>
                                                <div>
                                                    <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            format='DD/MM/YYYY'
                                                            margin='normal'
                                                            id='fecha'
                                                            value={state.nacimiento}
                                                            maxDate={
                                                                new Date(
                                                                    new Date().getTime() -
                                                                        18 * 365 * 24 * 60 * 60 * 1000,
                                                                )
                                                            }
                                                            onChange={(e) => {
                                                                actualizarNacimiento(e);
                                                            }}
                                                            className='form-control'
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Label>DNI:</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Ingrese su DNI'
                                                    //value={state.dni}
                                                    value={formState.dni}
                                                    name='dni'
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        actualizarInput(e);
                                                        handleChange(e);
                                                        handleBlur(e);
                                                    }}
                                                />
                                                {errors.dni && <small className='text-danger'>{errors.dni}</small>}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col md={6} lg={6}>
                                                <Form.Label>Teléfono (Opcional):</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Ingrese su Teléfono'
                                                    //value={state.telefono}
                                                    value={formState.telefono}
                                                    name='telefono'
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        actualizarInput(e);
                                                        handleChange(e);
                                                        handleBlur(e);
                                                    }}
                                                />
                                                {errors.telefono && (
                                                    <small className='text-danger'>{errors.telefono}</small>
                                                )}
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Form.Label>Celular:</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Ingrese su Celular'
                                                    //value={state.celular}
                                                    value={formState.celular}
                                                    name='celular'
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        actualizarInput(e);
                                                        handleChange(e);
                                                        handleBlur(e);
                                                    }}
                                                />
                                                {errors.celular && (
                                                    <small className='text-danger'>{errors.celular}</small>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    {permisos === 'usuario' ? (
                                        <Form.Group className='d-flex flex-row justify-content-end'>
                                            <Button
                                                variant='primary'
                                                className='btn-itoour'
                                                onClick={handleSubmit(finalizar, false)}
                                                disabled={disabled || Object.keys(errors).length}
                                            >
                                                Finalizar
                                            </Button>
                                        </Form.Group>
                                    ) : (
                                        <Form.Group className='d-flex flex-row justify-content-between mt-3'>
                                            <Button variant='primary' className='btn-itoour' onClick={prevStep}>
                                                Anterior
                                            </Button>
                                            <Button
                                                variant='primary'
                                                className='btn-itoour'
                                                onClick={nextStep}
                                                disabled={disabled || Object.keys(errors).length}
                                            >
                                                Siguiente
                                            </Button>
                                        </Form.Group>
                                    )}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InfoPersonal;
