import React, { Fragment } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import departamentos from '../../config/Departamentos';
//import Maps from "../../components/Shared/Maps";
import MapCliente from '../../components/Shared/MapCliente';
import Departamentos from '../../config/Departamentos.json';
import Provincias from '../../config/Provincias.json';
import Distritos from '../../config/Distritos.json';

export default function CrearTourSegPaso({
    value,
    actualizarInput,
    actualizarFecha,
    actualizarEsDiario,
    actualizarCoords,
    actualizarLugares,
    marker,
    formState,
    errors,
    handleChange,
    handleBlur,
}) {
    return (
        <Fragment>
            <div className='form-row'>
                <div className='form-group col-md-12'>
                    <p>
                        Por favor indicar el monto esperado a recibir por este servicio, este es el monto que se le
                        depositará cuando realice un servicio por cada persona.
                    </p>
                </div>
                <div className='form-group col-md-6'>
                    <label>Precio por adulto</label>
                    <input
                        type='number'
                        className='form-control'
                        placeholder='Precio'
                        name='precioAdulto'
                        min='20'
                        step='0.01'
                        value={formState.precioAdulto}
                        onChange={(e) => {
                            actualizarInput(e);
                            handleChange(e);
                            handleBlur(e);
                        }}
                        onBlur={handleBlur}
                    />
                    {errors.precioAdulto && <small className='text-danger'>{errors.precioAdulto}</small>}
                </div>
                <div className='form-group col-md-6'>
                    <label>Moneda</label>
                    <select
                        name='moneda'
                        className='form-control'
                        value={value.moneda}
                        onChange={(e) => {
                            actualizarInput(e);
                        }}
                    >
                        <option defaultValue>PEN</option>
                        <option>USD</option>
                    </select>
                </div>
                <div className='form-group col-md-6'>
                    <label>Precio por niño(a) (Opcional)</label>
                    <input
                        type='number'
                        className='form-control'
                        placeholder='Precio'
                        name='precioNino'
                        min='10'
                        value={formState.precioNino}
                        onChange={(e) => {
                            actualizarInput(e);
                            handleChange(e);
                            handleBlur(e);
                        }}
                        onBlur={handleBlur}
                    />
                    {errors.precioNino && <small className='text-danger'>{errors.precioNino}</small>}
                </div>
            </div>
            {/* ----- */}
            <Form.Group className='mt-3'>
                <Form.Label>Punto de encuentro</Form.Label>
                <Row>
                    <Col md={4} lg={4}>
                        <Form.Control
                            as='select'
                            name='departamentoPartida'
                            value={formState.departamentoPartida}
                            onChange={(e) => {
                                actualizarLugares['setDepartamentoPartida'](e);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value=''>Seleccionar Departamento</option>
                            {Departamentos.map((departamento, k) => (
                                <option value={`${departamento.id_ubigeo}`} key={`d${k}`}>
                                    {departamento.nombre_ubigeo}
                                </option>
                            ))}
                        </Form.Control>
                        {errors.departamentoPartida && (
                            <small className='text-danger'>{errors.departamentoPartida}</small>
                        )}
                    </Col>
                    <Col md={4} lg={4}>
                        <Form.Control
                            as='select'
                            name='provinciaPartida'
                            value={formState.provinciaPartida}
                            onChange={(e) => {
                                actualizarLugares['setProvinciaPartida'](e);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value=''>Seleccionar Provincia</option>
                            {value.departamentoPartida
                                ? Provincias[value.departamentoPartida].map((provincia, k) => (
                                      <option value={`${provincia.id_ubigeo}`} key={`p${k}`}>
                                          {provincia.nombre_ubigeo}
                                      </option>
                                  ))
                                : null}
                        </Form.Control>
                        {errors.provinciaPartida && <small className='text-danger'>{errors.provinciaPartida}</small>}
                    </Col>
                    <Col md={4} lg={4}>
                        <Form.Control
                            as='select'
                            name='ciudadPartida'
                            value={formState.ciudadPartida}
                            onChange={(e) => {
                                actualizarLugares['setCiudadPartida'](e);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value=''>Seleccionar Ciudad</option>
                            {value.provinciaPartida
                                ? Distritos[value.provinciaPartida].map((ciudad, k) => (
                                      <option value={`${ciudad.id_ubigeo}`} key={`c${k}`}>
                                          {ciudad.nombre_ubigeo}
                                      </option>
                                  ))
                                : null}
                        </Form.Control>
                        {errors.ciudadPartida && <small className='text-danger'>{errors.ciudadPartida}</small>}
                    </Col>
                </Row>
                {/*<div style={{ height: "450px" }}>
                    <MapCliente
                        marker={marker}
                        updateMarker={actualizarCoords}
                    />
                </div>*/}
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Control
                    type='text'
                    placeholder='Dirección de encuentro o referencia'
                    name='direccionPartida'
                    value={formState.direccionPartida}
                    onChange={(e) => {
                        actualizarInput(e);
                        handleChange(e);
                        handleBlur(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors.direccionPartida && <small className='text-danger'>{errors.direccionPartida}</small>}
            </Form.Group>
            {/* ---- */}
            <Form.Group className='mt-4 mb-3'>
                <Form.Label>Lugar de Destino</Form.Label>
                <Row>
                    <Col md={4} lg={4}>
                        <Form.Control
                            as='select'
                            name='departamento'
                            value={formState.departamento}
                            onChange={(e) => {
                                actualizarLugares['setDepartamento'](e);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value=''>Seleccionar Departamento</option>
                            {Departamentos.map((departamento, k) => (
                                <option value={`${departamento.id_ubigeo}`} key={`dp${k}`}>
                                    {departamento.nombre_ubigeo}
                                </option>
                            ))}
                        </Form.Control>
                        {errors.departamento && <small className='text-danger'>{errors.departamento}</small>}
                    </Col>
                    <Col md={4} lg={4}>
                        <Form.Control
                            as='select'
                            name='provincia'
                            value={formState.provincia}
                            onChange={(e) => {
                                actualizarLugares['setProvincia'](e);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value=''>Seleccionar Provincia</option>
                            {value.departamento
                                ? Provincias[value.departamento].map((provincia, k) => (
                                      <option value={`${provincia.id_ubigeo}`} key={`pp${k}`}>
                                          {provincia.nombre_ubigeo}
                                      </option>
                                  ))
                                : null}
                        </Form.Control>
                        {errors.provincia && <small className='text-danger'>{errors.provincia}</small>}
                    </Col>
                    <Col md={4} lg={4}>
                        <Form.Control
                            as='select'
                            name='ciudad'
                            value={formState.ciudad}
                            onChange={(e) => {
                                actualizarLugares['setCiudad'](e);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                        >
                            <option value=''>Seleccionar Ciudad</option>
                            {value.provincia
                                ? Distritos[value.provincia].map((ciudad, k) => (
                                      <option value={`${ciudad.id_ubigeo}`} key={`cp${k}`}>
                                          {ciudad.nombre_ubigeo}
                                      </option>
                                  ))
                                : null}
                        </Form.Control>
                        {errors.ciudad && <small className='text-danger'>{errors.ciudad}</small>}
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Control
                    type='text'
                    placeholder='Dirección de Destino o referencia'
                    name='direccion'
                    value={formState.direccion}
                    onChange={(e) => {
                        actualizarInput(e);
                        handleChange(e);
                        handleBlur(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors.direccion && <small className='text-danger'>{errors.direccion}</small>}
            </Form.Group>
            {/* ---- */}
        </Fragment>
    );
}
