import React, { useState, useEffect } from 'react';
import MenuCliente from '../../components/Shared/MenuCliente';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import fire from '../../config/Firebase';
import Swal from 'sweetalert2';
import FotoPerfil from '../../components/Shared/FotoPerfil';
import { encrypt, decrypt } from '../../Util/Crypt';
import { functions } from '../../config/Firebase';
import useForm from '../../hooks/useForm';

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'representante': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ&\s]{3,}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'El nombre del representante no puede tener menos de 3 caracteres'
                            : 'Sólo se permiten letras y espacios.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'dni_representante': {
                validates.push({
                    name,
                    isValid: /^[0-9]{8}$/i.test(formState[name]),
                    message: !formState[name]?.length
                        ? 'El documento es requerido'
                        : !/^[0-9]+$/i.test(formState[name])
                        ? 'Sólo se permiten números.'
                        : 'El dni debe tener 8 dígitos',
                });
                break;
            }

            case 'tel_representante': {
                validates.push({
                    name,
                    isValid: /^[0-9]+$/i.test(formState[name]),
                    message: formState[name]?.length ? 'No es un teléfono válido.' : 'Es un campo requerido',
                });
                break;
            }

            case 'tipo_cuenta': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'El campo es requerido',
                });
                break;
            }

            case 'titular_cuenta': {
                validates.push({
                    name,
                    isValid: formState[name]?.length > 2,
                    message: formState[name]?.length
                        ? 'El nombre no puede tener menos de 3 caracteres.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'cuenta': {
                validates.push({
                    name,
                    isValid: /^[0-9]{11,20}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 11 || formState[name]?.length > 20
                            ? 'No es un número de cuenta válido.'
                            : 'Sólo se permiten números.'
                        : 'Es un campo requerido',
                });
                break;
            }

            case 'cci': {
                validates.push({
                    name,
                    isValid: /^[0-9]+$/i.test(formState[name]),
                    message: !formState[name]?.length ? 'Es un campo requerido' : 'No es un CCI válido.',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

export default function InfoComercial(props) {
    const db = fire.firestore();
    const [state, setState] = useState({
        tipo: '',
        representante: '',
        dni_representante: '',
        tel_representante: '',
        dir_representante: '',
        tipo_cuenta: '',
        titular_cuenta: '',
        cuenta: '',
        cci: '',
        no_editar: true,
    });
    const [edited, setEdited] = useState({
        representante: false,
        dni_representante: false,
        tel_representante: false,
        dir_representante: false,
        tipo_cuenta: false,
        titular_cuenta: false,
        cuenta: false,
        cci: false,
    });
    const [prevComercial, setPrevComercial] = useState({});
    const [usuario, setUsuario] = useState('');
    const [email, setEmail] = useState('');
    const { formState, setFormState, focus, setFocus, errors, handleChange, handleBlur, handleSubmit } = useForm(
        {
            representante: '',
            dni_representante: '',
            tel_representante: '',
            tipo_cuenta: '',
            titular_cuenta: '',
            cuenta: '',
            cci: '',
        },
        validateForm,
    );
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (focus) {
            console.log(focus);
            document.getElementsByName(focus)[0].focus();
            setFocus(null);
        }
    }, [focus]);

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });

        setDisabled(false);
    };

    const obtenerUsuario = () => {
        return new Promise((resolve, reject) => {
            fire.auth().onAuthStateChanged((user) => {
                if (user) {
                    setEmail(user.email);
                    resolve(user.uid);
                } else {
                    reject('Error al obtener usuario');
                }
            });
        });
    };

    const enviarMensaje = (to, subject, body) => {
        const functionEmail = functions.httpsCallable('enviarEmail');

        return functionEmail({ to, subject, body, info: false });
    };

    const actualizarDatosComerciales = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Actualizar Datos',
            icon: 'info',
            text: 'Se está actualizando sus datos, por favor espere un momento...',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        Swal.showLoading();

        Promise.all([state.cuenta ? encrypt(state.cuenta) : '', state.cci ? encrypt(state.cci) : ''])
            .then((values) => {
                return db
                    .collection('cliente')
                    .doc(usuario)
                    .update({
                        cliente_representante: state.representante || '',
                        cliente_reprdni: state.dni_representante || '',
                        cliente_reprtel: state.tel_representante || '',
                        cliente_banco: state.tipo_cuenta || '',
                        cliente_titularcuenta: state.titular_cuenta || '',
                        cliente_cuenta: values[0],
                        cliente_cci: values[1],
                        no_editar_comercial: true,
                        cliente_prevrepresentante: prevComercial.representante || '',
                        cliente_prevreprdni: prevComercial.dni_representante || '',
                        cliente_prevreprtel: prevComercial.tel_representante || '',
                        cliente_prevbanco: prevComercial.tipo_cuenta || '',
                        cliente_prevtitularcuenta: prevComercial.titular_cuenta || '',
                        cliente_prevcuenta: prevComercial.cuenta,
                        cliente_prevcci: prevComercial.cci,
                    });
            })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Datos Comerciales Actualizado',
                    showConfirmButton: false,
                    timer: 2000,
                });
            })
            .then(() => {
                setTimeout(() => window.location.replace('/'), 1000);
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Ocurrió un error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    const onSubmit = handleSubmit(actualizarDatosComerciales, false);

    const solicitarEditar = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Enviando solicitud',
            icon: 'info',
            text: 'Se está enviando la solicitud, por favor espere un momento...',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        Swal.showLoading();

        return db
            .collection('solicitudes')
            .add({
                clienteId: usuario,
                mensaje:
                    'Solicito habilitar la edición de mis datos comerciales (Representante Legal y Cuenta Bancaria)',
                tipo: 'EDITAR_COMERCIAL',
            })
            .then(() => {
                const body_usuario = `<p>Se envió la solicitud de cambio de los datos comerciales de su cuenta de Itoour Latam (cuenta bancaria, representante, etc)</p>
                    <p>En un lapso máximo de 48 horas soporte estaría validando su identidad para proceder a habilitar la modificación de sus datos.</p>
                    <br/>
                    <p>Si usted no realizó la solicitud por favor ignore este mensaje y comuníquese con soporte.`;

                return enviarMensaje(email, 'Solicitud de modificación de datos Comerciales', body_usuario);
            })
            .then(() => {
                return Swal.fire({
                    title: 'Solicitud enviada',
                    text: 'La solicitud fue enviada con éxito.',
                    icon: 'success',
                    allowOutsideClick: false,
                    timer: 3000,
                });
            })
            .catch((error) => {
                return Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error al enviar la solicitud. Por favor, vuelva a intentarlo.',
                    icon: 'error',
                    allowOutsideClick: false,
                    timer: 3000,
                });
            });
    };

    useEffect(() => {
        obtenerUsuario()
            .then((userid) => {
                setUsuario(userid);

                return db.collection('cliente').doc(userid).get();
            })
            .then((usu) => {
                return Promise.all([
                    usu.data(),
                    usu.data().cliente_cuenta?.content ? decrypt(usu.data().cliente_cuenta) : '',
                    usu.data().cliente_cci?.content ? decrypt(usu.data().cliente_cci) : '',
                ]);
            })
            .then((data) => {
                setState({
                    ...state,
                    tipo: data[0].cliente_tipo,
                    representante: data[0].cliente_representante,
                    dni_representante: data[0].cliente_reprdni,
                    tel_representante: data[0].cliente_reprtel,
                    tipo_cuenta: data[0].cliente_banco || '',
                    titular_cuenta: data[0].cliente_titularcuenta || '',
                    cuenta: data[1],
                    cci: data[2],
                    no_editar: data[0].no_editar_comercial ?? false,
                });
                setPrevComercial({
                    cuenta: data[0]?.cliente_cuenta || '',
                    cci: data[0]?.cliente_cci || '',
                    representante: data[0]?.cliente_representante || '',
                    dni_representante: data[0]?.cliente_reprdni || '',
                    tel_representante: data[0]?.cliente_reprtel || '',
                    titular_cuenta: data[0]?.cliente_titularcuenta || '',
                    tipo_cuenta: data[0]?.cliente_banco || '',
                });

                let fields = {
                    tipo_cuenta: data[0]?.cliente_banco || '',
                    titular_cuenta: data[0]?.cliente_titularcuenta || '',
                    cuenta: data[1],
                    cci: data[2],
                };

                if (data[0]?.cliente_cuenta === 'TOUR OPERADOR') {
                    fields = {
                        representante: data[0]?.cliente_representante || '',
                        dni_representante: data[0]?.cliente_reprdni || '',
                        tel_representante: data[0]?.cliente_reprtel || '',
                        ...fields,
                    };
                }

                setFormState(fields);
            });
    }, []);

    return (
        <Container className='mt-4'>
            <Row className='mobile mb-3'>
                <Col md={12}>
                    <MenuCliente comercial={true} anfitrion={true} operador={true} />
                </Col>
            </Row>
            <Row>
                <Col lg='2' className='desktop'>
                    <MenuCliente comercial={true} vertical={true} anfitrion={true} operador={true} />
                </Col>
                <Col lg='10'>
                    <h2 className='text-itoour mb-3'>Información Comercial</h2>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (state.no_editar) solicitarEditar(e);
                            else onSubmit(e);
                        }}
                    >
                        {state.tipo === 'TOUR OPERADOR' ? (
                            <>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Representante Legal:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='representante'
                                        value={formState.representante}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        disabled={state.no_editar}
                                        className={edited.representante ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.representante && (
                                        <small className='text-danger'>{errors.representante}</small>
                                    )}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Form.Label>DNI del Representante:</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='dni_representante'
                                                value={formState.dni_representante}
                                                onChange={(e) => {
                                                    actualizarInput(e);
                                                    handleChange(e);
                                                    setEdited({ ...edited, [e.target.name]: true });
                                                }}
                                                onBlur={handleBlur}
                                                disabled={state.no_editar}
                                                className={edited.dni_representante ? 'bgColorEditInput' : ''}
                                            />
                                            {errors.dni_representante && (
                                                <small className='text-danger'>{errors.dni_representante}</small>
                                            )}
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Form.Label>Teléfono del Representante:</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='tel_representante'
                                                value={formState.tel_representante}
                                                onChange={(e) => {
                                                    actualizarInput(e);
                                                    handleChange(e);
                                                    setEdited({ ...edited, [e.target.name]: true });
                                                }}
                                                onBlur={handleBlur}
                                                disabled={state.no_editar}
                                                className={edited.tel_representante ? 'bgColorEditInput' : ''}
                                            />
                                            {errors.tel_representante && (
                                                <small className='text-danger'>{errors.tel_representante}</small>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </>
                        ) : null}
                        <Form.Group className='mb-3'>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Form.Label>Banco:</Form.Label>
                                    <Form.Control
                                        as='select'
                                        name='tipo_cuenta'
                                        value={formState.tipo_cuenta}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        disabled={state.no_editar}
                                        className={edited.tipo_cuenta ? 'bgColorEditInput' : ''}
                                    >
                                        <option value=''>Seleccionar</option>
                                        <option value='BCP'>BCP</option>
                                        <option value='BBVA'>BBVA</option>
                                        <option value='INTERBANK'>INTERBANK</option>
                                        <option value='SCOTIABANK'>SCOTIABANK</option>
                                        <option value='BANBIF'>BANBIF</option>
                                        <option value='BANCO DE CREDITO'>BANCO DE CREDITO</option>
                                        <option value='BANCO DE LA NACION'>BANCO DE LA NACION</option>
                                        <option value='BANCO FALABELLA'>BANCO FALABELLA</option>
                                        <option value='BANCO PICHINCHA'>BANCO PICHINCHA</option>
                                        <option value='BANCO RIPLEY'>BANCO RIPLEY</option>
                                        <option value='OTRO'>Otro</option>
                                    </Form.Control>
                                    {errors.tipo_cuenta && <small className='text-danger'>{errors.tipo_cuenta}</small>}
                                </Col>
                                <Col md={6} lg={6}>
                                    <Form.Label>Titular de la Cuenta:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='titular_cuenta'
                                        value={formState.titular_cuenta}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        disabled={state.no_editar}
                                        className={edited.titular_cuenta ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.titular_cuenta && (
                                        <small className='text-danger'>{errors.titular_cuenta}</small>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Form.Label>Cuenta Bancaria:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='cuenta'
                                        value={formState.cuenta}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        disabled={state.no_editar}
                                        className={edited.cuenta ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.cuenta && <small className='text-danger'>{errors.cuenta}</small>}
                                </Col>
                                <Col md={6} lg={6}>
                                    <Form.Label>CCI:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='cci'
                                        value={formState.cci}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        disabled={state.no_editar}
                                        className={edited.cci ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.cci && <small className='text-danger'>{errors.cci}</small>}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            {state.no_editar ? (
                                <Form.Control
                                    type='submit'
                                    value='Solicitar Modificación'
                                    className='btn-itoour btn-save'
                                />
                            ) : disabled || Object.keys(errors).length ? (
                                <Form.Control type='submit' value='Guardar Cambios' className='btn-itoour' disabled />
                            ) : (
                                <Form.Control type='submit' value='Guardar Cambios' className='btn-itoour btn-save' />
                            )}
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
