import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import fire from '../../config/Firebase';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/Auth';
import './css/ModalLoginRegister.css';
import useForm from '../../hooks/useForm';

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'email': {
                validates.push({
                    name,
                    isValid: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i.test(
                        formState[name]
                    ),
                    message: formState[name]?.length ? 'No es un email válido' : 'El email es requerido',
                });
                break;
            }

            case 'password': {
                validates.push({
                    name,
                    isValid: !!formState[name]?.length,
                    message: 'El campo es requerido',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

const initialForm = {
    email: '',
    password: '',
};

export default function ModalLoginRegister({ tourId }) {
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { formState, errors, focus, setFocus, handleChange, handleBlur, handleSubmit, resetForm } = useForm(
        initialForm,
        validateForm
    );
    const sAuth = new AuthService();

    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        resetForm();
    };

    const handleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(true);
    };

    const handleHidePassword = (e) => {
        e.preventDefault();
        setShowPassword(false);
    };

    const login = (e) => {
        e.preventDefault();

        return fire
            .auth()
            .signInWithEmailAndPassword(formState.email, formState.password)
            .then((user) => {
                Swal.fire({
                    icon: 'success',
                    title: `Ha iniciado sesión exitosamente`,
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => setTimeout(() => setShow(false), 1000));
            })
            .catch(() => {
                return Swal.fire({
                    icon: 'error',
                    title: `Login Incorrecto, Verifique sus datos`,
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => 'error');
            });
    };

    const loginWithGoogle = (e) => {
        e.preventDefault();
        sAuth
            .registerWithGoogle()
            .then(() => {
                setShow(false);
                resetForm();
            })
            .catch((error) => console.log({ error }));
    };

    const onLogin = handleSubmit(login);

    useEffect(() => {
        if (focus) {
            document.getElementsByName(focus)[0].focus();
            setFocus(null);
        }
    }, [focus]);

    return (
        <>
            <button className='btn btn-itoour btn-block btn-lg mt-4' onClick={handleShow}>
                Comprar Tour
            </button>
            {show ? (
                <>
                    <div className='modal-background' onClick={handleClose}></div>
                    <div className='modal-itoour'>
                        <div className='modal-itoour-header'>
                            <div className='login-logo-container'>
                                <div className='login-logo-itoour'>
                                    itoour
                                    <img src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2Fflechas.svg?alt=media&token=f0f5cdbe-32ba-45c2-bd5f-6f3d53d02f7c' />
                                </div>
                            </div>
                            <div className='slogan-itoour'>¡Nos alegra tenerte de vuelta!</div>
                            <span className='btn-close-modal-login' onClick={handleClose}>
                                &times;
                            </span>
                        </div>
                        <div className='modal-itoour-body'>
                            <form className='form-login-modal'>
                                <div className='field-login-modal'>
                                    <label>Correo electrónico:</label>
                                    <div className='input-login-modal'>
                                        <input
                                            type='email'
                                            placeholder='Ejemplo: usuario123@gmail.com'
                                            name='email'
                                            value={formState.email}
                                            onChange={handleChange}
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    onLogin(e);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <span className='btn-icon-login box-with-border'>
                                            <i class='fa fa-at btn-icon-email'></i>
                                        </span>
                                    </div>
                                    {errors.email && <small className='text-danger'>{errors.email}</small>}
                                </div>
                                <div className='field-login-modal'>
                                    <label>Contraseña:</label>
                                    <div className='input-login-modal'>
                                        {showPassword ? (
                                            <>
                                                <input
                                                    type='text'
                                                    name='password'
                                                    value={formState.password}
                                                    onChange={handleChange}
                                                    onKeyUp={(e) => {
                                                        if (e.key === 'Enter') {
                                                            onLogin(e);
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                <span
                                                    className='btn-icon-login btn-cursor-pointer'
                                                    onClick={handleHidePassword}
                                                >
                                                    <i className='fas fa-eye-slash btn-icon-eye-password'></i>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    type='password'
                                                    name='password'
                                                    value={formState.password}
                                                    onKeyUp={(e) => {
                                                        if (e.key === 'Enter') {
                                                            onLogin(e);
                                                        }
                                                    }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span
                                                    className='btn-icon-login btn-cursor-pointer'
                                                    onClick={handleShowPassword}
                                                >
                                                    <i className='far fa-eye btn-icon-eye-password'></i>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    {errors.password && <small className='text-danger'>{errors.password}</small>}
                                </div>
                                <div className='field-login-modal'>
                                    <div>
                                        <NavLink className='link-reset-pasword' to=''>
                                            ¿Olvidaste tu contraseña?
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='login-btn-container'>
                                    <div className='field-login-modal center'>
                                        <button className='login-btn' onClick={onLogin}>
                                            Ingresar
                                        </button>
                                    </div>
                                    <div className='login-separador'>
                                        <hr className='login-linea-separador' />
                                        <span className='login-o-tipo'>o</span>
                                        <hr className='login-linea-separador' />
                                    </div>
                                    <div className='field-login-modal center'>
                                        <button className='login-btn login-btn-google' onClick={loginWithGoogle}>
                                            Iniciar sesión con Google <i class='fab fa-google ml-2'></i>
                                        </button>
                                    </div>
                                    <div className='login-separador'>
                                        <hr className='login-linea-separador' />
                                        <span className='login-o-tipo'>o</span>
                                        <hr className='login-linea-separador' />
                                    </div>
                                    <div className='field-login-modal center'>
                                        <span className='text-register'>
                                            Si aún no eres usuario{' '}
                                            <NavLink
                                                className='link-register-itoour'
                                                to={{
                                                    pathname: '/registro',
                                                    prevUrl: `/vertour/${tourId}`,
                                                }}
                                            >
                                                REGÍSTRATE
                                            </NavLink>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}
