import React, { useEffect, useState, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import Swal from 'sweetalert2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';

import fire from '../../config/Firebase';

const Select = (props) => {
    const selectRef = useRef();

    const onChange = (e) => {
        const selectedOption = selectRef.current.selectedOptions[0];
        const options = selectRef.current.options;

        options[0].value = selectedOption.value;
        options[0].innerHTML = selectedOption.value;
        selectRef.current.value = selectedOption.value;
        props.onChange(e);
    };

    return (
        <select {...props} onChange={onChange} ref={selectRef}>
            {React.cloneElement(props.children[0], { style: { display: 'none' } })}
            {props.children}
        </select>
    );
};

export default function UserData({
    step,
    changeStep,
    values,
    focus,
    setFocus,
    setErrors,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
}) {
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const db = fire.firestore();

    const onShowHidePassword = (changeShow) => {
        return () => changeShow((prev) => !prev);
    };

    const validateEmail = () => {
        return db
            .collection('cliente')
            .where('cliente_email', '==', values.email)
            .get()
            .then((users) => {
                if (users?.size && users?.size > 0) {
                    return Swal.fire({
                        title: 'Error de registro',
                        text: `La cuenta que intentas crear ya existe, prueba ingresando otro correo o iniciando sesión.`,
                        icon: 'error',
                        allowOutsideClick: false,
                    }).then(() => true);
                }

                return false;
            });
    };

    const handleNext = async (e) => {
        e.preventDefault();

        await validateEmail().then((exist) => {
            if (!exist) {
                if (step + 1 < 4) {
                    changeStep(step + 1);
                }
            }
        });
    };

    useEffect(() => {
        if (focus) {
            const firstFieldError = document.getElementsByName(focus)[0];

            if (firstFieldError) {
                firstFieldError.focus();
                setFocus(null);
            }
        }
    }, [focus]);

    return (
        <div className='stepFormContainer'>
            <form>
                <div className='row-form'>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='inpNombres'>
                            Nombres:
                        </label>
                        <input
                            className='input-text-field'
                            type='text'
                            id='inpNombres'
                            name='nombres'
                            value={values.nombres}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.nombres && <small className='text-danger'>{errors.nombres}</small>}
                    </div>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='inpApellidos'>
                            Apellidos:
                        </label>
                        <input
                            className='input-text-field'
                            type='text'
                            id='inpApellidos'
                            name='apellidos'
                            value={values.apellidos}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.apellidos && <small className='text-danger'>{errors.apellidos}</small>}
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='selectDoc'>
                            Documento de identidad:
                        </label>
                        <div className='selector-container'>
                            <Select
                                id='selectDoc'
                                className='select-documents'
                                name='tipo_documento'
                                value={values.tipo_documento}
                                onChange={(e) => {
                                    const { documento: _, ...tmpErrors } = errors;

                                    handleChange(e);
                                    setErrors(tmpErrors);
                                }}
                                onBlur={handleBlur}
                            >
                                <option value='DNI'>DNI</option>
                                <option value='CE'>Carnet de Extranjería</option>
                                <option value='PAS'>Pasaporte</option>
                            </Select>
                            <input
                                className='input-text-field'
                                type='text'
                                name='documento'
                                value={values.documento}
                                onChange={(e) => handleChange(e, ['tipo_documento'])}
                                onBlur={(e) => handleBlur(e, ['tipo_documento'])}
                            />
                        </div>
                        {errors.documento && <small className='text-danger'>{errors.documento}</small>}
                    </div>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='selectTipo'>
                            Tipo:
                        </label>
                        <select
                            id='selectTipo'
                            className='select-tipo'
                            name='tipo'
                            value={values.tipo}
                            onChange={(e) => {
                                handleChange(e);
                                if (e.target.value === 'TOUR OPERADOR') {
                                    handleChange({ target: { name: 'sexo', value: '' } });

                                    const { sexo: _, ...tmpErrors } = errors;
                                    setErrors(tmpErrors);
                                } else {
                                    handleChange({ target: { name: 'representante', value: '' } });

                                    const { representante: _, ...tmpErrors } = errors;
                                    setErrors(tmpErrors);
                                }
                            }}
                            onBlur={handleBlur}
                        >
                            <option value='ANFITRION'>Anfitrión</option>
                            <option value='GUIA'>Guía Certificado</option>
                            <option value='TOUR OPERADOR'>Tour Operador</option>
                        </select>
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='selectDoc'>
                            Celular:
                        </label>
                        <PhoneInput
                            localization={es}
                            country={'pe'}
                            enableSearch={true}
                            value={values.celular}
                            name='celular'
                            onChange={(phone) => handleChange({ target: { name: 'celular', value: phone } })}
                            onBlur={(e) => {
                                const value = e.currentTarget.value?.split(' ').slice(1).join('');

                                handleBlur({ target: { name: 'celular', value } });
                            }}
                            placeholder='+51 987 654 321'
                            containerClass='select-telefono-container'
                        />
                        {errors.celular && <small className='text-danger'>{errors.celular}</small>}
                    </div>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='inpCorreo'>
                            Correo:
                        </label>
                        <input
                            className='input-text-field'
                            type='text'
                            id='inpCorreo'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.email && <small className='text-danger'>{errors.email}</small>}
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='inpPassword'>
                            Contraseña:
                        </label>
                        <div className='input-password'>
                            {showPassword ? (
                                <>
                                    <input
                                        className='input-text-field'
                                        type='text'
                                        id='inpPassword'
                                        placeholder='Crea una contraseña'
                                        name='password'
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span onClick={onShowHidePassword(setShowPassword)}>
                                        <i className='fas fa-eye-slash btn-icon-eye-password'></i>
                                    </span>
                                </>
                            ) : (
                                <>
                                    <input
                                        className='input-text-field'
                                        type='password'
                                        id='inpPassword'
                                        placeholder='Crea una contraseña'
                                        name='password'
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span onClick={onShowHidePassword(setShowPassword)}>
                                        <i className='fas fa-eye btn-icon-eye-password'></i>
                                    </span>
                                </>
                            )}
                        </div>
                        {errors.password && <small className='text-danger'>{errors.password}</small>}
                    </div>
                    <div className='col-form'>
                        <label className='label-field' htmlFor='inpRePassword'>
                            Repetir Contraseña:
                        </label>
                        <div className='input-password'>
                            {showRePassword ? (
                                <>
                                    <input
                                        className='input-text-field'
                                        type='text'
                                        id='inpRePassword'
                                        name='repassword'
                                        value={values.repassword}
                                        onChange={(e) => handleChange(e, ['password'])}
                                        onBlur={(e) => handleBlur(e, ['password'])}
                                    />
                                    <span onClick={onShowHidePassword(setShowRePassword)}>
                                        <i className='fas fa-eye-slash btn-icon-eye-password'></i>
                                    </span>
                                </>
                            ) : (
                                <>
                                    <input
                                        className='input-text-field'
                                        type='password'
                                        id='inpRePassword'
                                        name='repassword'
                                        value={values.repassword}
                                        onChange={(e) => handleChange(e, ['password'])}
                                        onBlur={(e) => handleBlur(e, ['password'])}
                                    />
                                    <span onClick={onShowHidePassword(setShowRePassword)}>
                                        <i className='fas fa-eye btn-icon-eye-password'></i>
                                    </span>
                                </>
                            )}
                        </div>
                        {errors.repassword && <small className='text-danger'>{errors.repassword}</small>}
                    </div>
                </div>
                <div className='row-form center'>
                    <button
                        className='btn-sgte-resgistro'
                        onClick={handleSubmit(
                            handleNext,
                            false,
                            ['documento', 'password'],
                            [
                                'nombres',
                                'apellidos',
                                'tipo_documento',
                                'documento',
                                'tipo',
                                'celular',
                                'email',
                                'password',
                                'repassword',
                            ]
                        )}
                    >
                        SIGUIENTE
                    </button>
                </div>
            </form>
        </div>
    );
}
