import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "react-bootstrap";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ReactPlayer from "react-player";
import iconanfitrion from "../../assets/icon/iconanfitrion.png";
import iconguiacertificado from "../../assets/icon/iconguiacertificado.png";
import iconoperador from "../../assets/icon/iconoperador.png";

export default function PreviewTour({ tour, tipo }) {
    return (
        <div className="col-md-12 mt-3">
            <div className="info">
                <Carousel className="imagen-slider-container">
                    {tour.tour_imagenes
                        ? tour.tour_imagenes.map((img, i) => {
                              return (
                                  <Carousel.Item key={i}>
                                      <img
                                          className="d-block w-100 img-ratio-principal imagen-slider"
                                          src={img}
                                          alt="..."
                                      />
                                  </Carousel.Item>
                              );
                          })
                        : null}
                </Carousel>
                {/* carousel */}
                <div className="center mt-2">
                    <span className="font-weight-bold">Tipo: </span>
                    <span>
                        {tipo === "ANFITRION" ? (
                            <>
                                <img
                                    src={iconanfitrion}
                                    className="icon-operator"
                                    style={{
                                        width: "20px",
                                    }}
                                />
                                Anfitrión
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Content>
                                                Personas, asociaciones y/o
                                                comunidades que poseen
                                                habilidades y conocimientos
                                                sobre su zona y que, por lo
                                                tanto, ofrecen diversos
                                                servicios relacionados al rubro
                                                turístico.
                                            </Popover.Content>
                                        </Popover>
                                    }
                                    trigger={["click", "hover", "focus"]}
                                >
                                    <Button
                                        variant="link"
                                        style={{
                                            padding: "5px",
                                            borderRadius: "50%",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <i
                                            class="fas fa-info-circle"
                                            style={{
                                                color: "#D6872B",
                                            }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                            </>
                        ) : null}
                        {tipo === "TOUR OPERADOR" ? (
                            <>
                                <img
                                    src={iconoperador}
                                    className="icon-operator"
                                    style={{
                                        width: "20px",
                                    }}
                                />
                                Tour Operador
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Content>
                                                Empresa formalmente constituida
                                                encargada de vender y desplegar
                                                sus servicios turísticos.
                                            </Popover.Content>
                                        </Popover>
                                    }
                                    trigger={["click", "hover", "focus"]}
                                >
                                    <Button
                                        variant="link"
                                        style={{
                                            padding: "5px",
                                            borderRadius: "50%",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <i
                                            class="fas fa-info-circle"
                                            style={{
                                                color: "#D6872B",
                                            }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                            </>
                        ) : null}
                        {tipo === "GUIA" ? (
                            <>
                                <img
                                    src={iconguiacertificado}
                                    className="icon-operator"
                                    style={{
                                        width: "20px",
                                    }}
                                />
                                Guía Certificado
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Content>
                                                Guías certificados que
                                                desarrollan su trabajo turístico
                                                en modalidad freelance.
                                            </Popover.Content>
                                        </Popover>
                                    }
                                    trigger={["click", "hover", "focus"]}
                                >
                                    <Button
                                        variant="link"
                                        style={{
                                            padding: "5px",
                                            borderRadius: "50%",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <i
                                            class="fas fa-info-circle"
                                            style={{
                                                color: "#D6872B",
                                            }}
                                        ></i>
                                    </Button>
                                </OverlayTrigger>
                            </>
                        ) : null}
                    </span>
                </div>
                <div className="mt-4 d-flex justify-content-between text-helvetica">
                    <div>
                        <h3 className="font-weight-bold">{tour.tour_nom}</h3>
                        <small>
                            <i
                                className="fas fa-map mr-2"
                                style={{
                                    color: "#753183",
                                }}
                            ></i>{" "}
                            {tour.tour_departamento}
                        </small>
                    </div>
                    <div className="box-tour-precio">
                        <div className="text-itoour font-weight-bold text-center">
                            <h3
                                className="font-weight-bold"
                                style={{
                                    width: "max-content",
                                }}
                            >
                                {tour.tour_moneda === "PEN" ? "S/." : "$."}{" "}
                                {Math.ceil(+tour.tour_precioAdulto / 0.85)}
                            </h3>
                            {!tour.tour_precioNino ||
                            +tour.tour_precioNino === 0
                                ? "por persona"
                                : "por adulto"}
                        </div>
                        {!tour.tour_precioNino ||
                        +tour.tour_precioNino === 0 ? null : (
                            <div className="text-itoour font-weight-bold text-center">
                                <h3
                                    className="font-weight-bold"
                                    style={{
                                        width: "max-content",
                                    }}
                                >
                                    {tour.tour_moneda === "PEN" ? "S/." : "$."}{" "}
                                    {Math.ceil(+tour.tour_precioNino / 0.85)}
                                </h3>
                                por niño
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <h5 className="font-weight-bold">Descripción del Tour</h5>
                    <p>{tour.tour_desc}</p>
                </div>
                {tour.tour_activ && tour.tour_activ?.length ? (
                    <div>
                        <h5 className="font-weight-bold">Actividades</h5>
                        <ul className="ul-itoour">
                            {tour.tour_activ
                                ? tour.tour_activ.map((activ, i) => {
                                      return (
                                          <li key={i} className="mb-3">
                                              {activ}
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </div>
                ) : null}
                {tour.tour_youtube ? (
                    <div className="video">
                        {tour.tour_youtube !== undefined ? (
                            <div>
                                <h5 className="font-weight-bold card-title">
                                    Video:
                                </h5>
                                <ReactPlayer
                                    url={tour.tour_youtube}
                                    style={{
                                        margin: "0 auto",
                                        maxWidth: "100%",
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
                <div style={{ marginTop: "10px" }}>
                    {tour?.tour_inc && tour?.tour_inc?.length ? (
                        <div className="card shadow mb-3">
                            <div className="card-body">
                                <h5 className="font-weight-bold card-title">
                                    Incluye:
                                </h5>
                                <ul className="ul-itoour">
                                    {tour.tour_inc
                                        ? tour.tour_inc.map((inc, i) => {
                                              return (
                                                  <li key={i} className="mb-3">
                                                      {inc}
                                                  </li>
                                              );
                                          })
                                        : null}
                                </ul>
                            </div>
                        </div>
                    ) : null}

                    {tour?.tour_noinc && tour?.tour_noinc?.length ? (
                        <div className="card shadow mb-3">
                            <div className="card-body">
                                <h5 className="font-weight-bold card-title">
                                    No Incluye:
                                </h5>
                                <ul className="ul-itoour">
                                    {tour.tour_inc
                                        ? tour.tour_noinc.map((noinc, i) => {
                                              return (
                                                  <li key={i} className="mb-3">
                                                      {noinc}
                                                  </li>
                                              );
                                          })
                                        : null}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {tour?.tour_notas && tour?.tour_notas?.length ? (
                        <div className="card shadow mb-3">
                            <div className="card-body">
                                <h5 className="font-weight-bold card-title">
                                    Notas adicionales:
                                </h5>
                                <ul className="ul-itoour">
                                    {tour.tour_inc
                                        ? tour.tour_notas.map((nota, i) => {
                                              return (
                                                  <li key={i} className="mb-3">
                                                      {nota}
                                                  </li>
                                              );
                                          })
                                        : null}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {tour?.tour_terminos && tour?.tour_terminos?.length ? (
                        <div className="card shadow mb-3">
                            <div className="card-body">
                                <h5 className="font-weight-bold card-title">
                                    Terminos y Condiciones:
                                </h5>
                                <ul className="ul-itoour">
                                    {tour.tour_terminos
                                        ? tour.tour_terminos.map((nota, i) => {
                                              return (
                                                  <li key={i} className="mb-3">
                                                      {nota}
                                                  </li>
                                              );
                                          })
                                        : null}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
