import React from 'react';
import { NavLink } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import { perfil, dashboard, iconos } from '../../helpers/accountMenuConfig';
import fire from '../../config/Firebase';
import Swal from 'sweetalert2';

export default function NavUsuario({ permisos, signOut, email, uid }) {
    const changeToGuia = (e) => {
        e.preventDefault();

        const db = fire.firestore();

        const data = {
            cliente_nombres: '',
            cliente_apellidos: '',
            cliente_dni: '',
            cliente_nacimiento: new Date(0),
            cliente_foto: '',
            cliente_celular: '',
            cliente_telefono: '',
            cliente_email: '',
            cliente_nomempresa: '',
            cliente_dirempresa: '',
            cliente_razon: '',
            cliente_ruc: '',
            cliente_tipo: 'ANFITRION',
            cliente_telefonocelular: '',
            cliente_telefonoreferencia: '',
            cliente_telempresa: '',
            cliente_reprdir: '',
            cliente_representante: '',
            cliente_reprtel: '',
            cliente_reprdni: '',
            cliente_banco: '',
            cliente_cci: '',
            cliente_cuenta: '',
            cliente_titularcuenta: '',
            cliente_prevreprdir: '',
            cliente_prevrepresentante: '',
            cliente_prevreprtel: '',
            cliente_prevreprdni: '',
            cliente_prevbanco: '',
            cliente_prevcci: '',
            cliente_prevcuenta: '',
            cliente_prevtitularcuenta: '',
            no_editar_comercial: false,
        };

        Swal.fire({
            title: '¿Desea habilitar el modo guía?',
            icon: 'warning',
            text: 'Este modo le permitirá publicar sus propios anuncios turísticos y llegar así a un público más amplio. Podrá regresar al modo turista cuando lo desee.',
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
        })
            .then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Espere uno segundos',
                        text: 'Estamos cargando su información.',
                        icon: 'info',
                        allowOutsideClick: false,
                    });

                    Swal.showLoading();

                    return db
                        .collection('usuario')
                        .doc(uid)
                        .get()
                        .then((usu) => {
                            const query = db
                                .collection('cliente')
                                .doc(uid)
                                .set({
                                    ...data,
                                    cliente_nombres: usu.data().usuario_nombres || '',
                                    cliente_apellidos: usu.data().usuario_apellidos || '',
                                    cliente_dni: usu.data().usuario_dni || '',
                                    cliente_nacimiento: usu.data().usuario_nacimiento || '',
                                    cliente_foto: usu.data().usuario_foto || '',
                                    cliente_celular: usu.data().usuario_celular || '',
                                    cliente_telefono: usu.data().usuario_telefono || '',
                                    cliente_email: email,
                                });

                            if (usu.exists) {
                                return query.then(() => {
                                    return db.collection('usuario').doc(uid).delete();
                                });
                            }
                        })
                        .then(() => {
                            return Swal.fire({
                                title: 'Datos actualizados',
                                icon: 'success',
                                text: 'Su cuenta fue actulizada con éxito.',
                                allowOutsideClick: false,
                                timer: 3000,
                            }).then(() => {
                                window.location.reload(false);
                            });
                        });
                }

                return null;
            })
            .catch((error) => {
                console.log({ error });
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    text: 'Ocurrió un error al actualizar su cuenta. Por favor, actualice la página y vuelva a intentarlo.',
                    allowOutsideClick: false,
                });
            });
    };

    return (
        <ul className='navbar-nav navbar-menu'>
            <li className='nav-item align-self-center acc-mobile'>
                <NavLink className='nav-link' to={perfil[permisos].link}>
                    {perfil[permisos].nombre}
                </NavLink>
            </li>
            <li className='nav-item align-self-center acc-mobile'>
                <NavLink className='nav-link' to={dashboard[permisos].link}>
                    {dashboard[permisos].nombre}
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/vertours'>
                    Tours
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/contacto'>
                    Contáctanos
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <span
                    className='nav-link guia active link-style-carrousel'
                    style={{ padding: 0, transform: 'translateY(-2px)' }}
                    onClick={changeToGuia}
                >
                    ¡Quiero ser guía!
                </span>
            </li>
            <li className='nav-item acc-menu'>
                <AccountMenu signOut={signOut} permisos={permisos} />
            </li>
            <li className='nav-item align-self-center acc-mobile mt-2'>
                <span onClick={() => signOut()}>
                    <i className={iconos[permisos].salir}></i>Salir
                </span>
            </li>
        </ul>
    );
}
