import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Shared/Navbar';
import MenuCliente from '../../components/Shared/MenuCliente';
import MenuUsuario from '../../components/Shared/MenuUsuario';
import { Row, Col, Table } from 'react-bootstrap';
import fire from '../../config/Firebase';
import LeerMasLeerMenos from '../../components/Shared/LeerMasLeerMenos';
import ModalDetallesReservas from '../../components/Usuario/ModalDetallesReservas';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { Button, FormGroup, FormControlLabel, FormControl } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import './css/Reservaciones.css';

const getInitialDate = (days = 0) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return new Date(now.getTime() + days * 86400000);
};

export default function Reservaciones({ uid, permisos }) {
    const [compras, setCompras] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inicio, setInicio] = useState(getInitialDate());
    const [fin, setFin] = useState(getInitialDate(1));
    const [disableReset, setDisableReset] = useState(true);

    const onChangeInicio = (e) => {
        setInicio(new Date(e));
        if (fin.getTime() < new Date(e).getTime()) setFin(new Date(e));
    };

    const onChangeFin = (e) => {
        setFin(new Date(e));
    };

    const onClickApply = (e) => {
        e.preventDefault();

        const start = new Date(inicio).getTime();
        const end = new Date(fin).getTime();

        setFiltered(
            compras.filter((compra) => {
                let fechaReserva = compra.fechaReserva.getTime();

                return start <= fechaReserva && fechaReserva <= end;
            })
        );

        setDisableReset(false);
    };

    const onClickReset = (e) => {
        e.preventDefault();

        setFiltered([...compras]);
        setDisableReset(true);
    };

    useEffect(() => {
        const db = fire.firestore();

        db.collection('compras')
            .where('idOperador', '==', uid)
            .where('estado', '==', 'PAGADO')
            .get()
            .then((result) => result.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            .then(async (reservas) => {
                const data = await Promise.all(
                    reservas.map(async (reserva) => {
                        const [tour, cliente] = await Promise.all([
                            db
                                .collection('tours')
                                .doc(reserva.idTour)
                                .get()
                                .then((tour) => tour.data()),
                            !reserva.idCliente
                                ? null
                                : db
                                      .collection('usuario')
                                      .doc(reserva.idCliente)
                                      .get()
                                      .then((usuario) => {
                                          if (usuario.exists) {
                                              return {
                                                  nombres: usuario.data().usuario_nombres,
                                                  apellidos: usuario.data().usuario_apellidos,
                                                  dni: usuario.data().usuario_dni,
                                                  telefono: usuario.data().usuario_telefono,
                                                  celular: usuario.data().usuario_celular,
                                              };
                                          }

                                          return db
                                              .collection('cliente')
                                              .doc(reserva.idCliente)
                                              .get()
                                              .then((cliente) => {
                                                  return {
                                                      nombres: cliente.data().cliente_nombres,
                                                      apellidos: cliente.data().cliente_apellidos,
                                                      dni: cliente.data().cliente_dni,
                                                      telefono: cliente.data().cliente_telefono,
                                                      celular: cliente.data().cliente_celular,
                                                  };
                                              });
                                      }),
                        ]);

                        return {
                            reserva: reserva.id,
                            codigo: reserva.codigo,
                            estado: reserva.estado.toUpperCase(),
                            fechaCompra: new Date(reserva.fechaCompra.seconds * 1000),
                            fechaReserva: new Date(reserva.fechaReserva.seconds * 1000),
                            cliente,
                            operador: reserva.idOperador,
                            tour: { ...tour, id: reserva.idTour },
                            moneda: reserva.moneda,
                            personas: +reserva.adultos + +reserva.ninos,
                            adultos: +reserva.adultos,
                            ninos: +reserva.ninos,
                            precioAdulto: reserva.precioAdulto,
                            precioNino: reserva.precioNino,
                            turistas: [...reserva.turistas],
                        };
                    })
                );
                setCompras(data);
                setFiltered(data);
            })
            .finally(() => setLoading(false));
    }, [uid]);

    return (
        <div>
            <div className='container mt-4'>
                <Row className='mobile mb-3'>
                    <Col md={12}>
                        <MenuCliente reservaciones={true} anfitrion={true} />
                    </Col>
                </Row>
                <div className='row'>
                    <div className='col-md-2 desktop'>
                        <MenuCliente reservaciones={true} anfitrion={true} vertical={true} />
                    </div>
                    <div className='col-md-10'>
                        <h2 className='text-itoour'>Reservaciones Pendientes</h2>
                        <div className='d-flex alignItems-center my-3'>
                            <div className='reservaciones-filter'>
                                <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        format='DD/MM/YYYY'
                                        name='inicio'
                                        label='Inicio'
                                        value={inicio}
                                        onChange={onChangeInicio}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        format='DD/MM/YYYY'
                                        name='fin'
                                        label='Fin'
                                        value={fin}
                                        minDate={inicio}
                                        onChange={onChangeFin}
                                    />
                                </MuiPickersUtilsProvider>
                                <FormGroup className='reservaciones-buttons'>
                                    <Button onClick={onClickApply} disabled={!disableReset}>
                                        Aplicar
                                    </Button>
                                    <Button onClick={onClickReset} disabled={disableReset}>
                                        Resetear
                                    </Button>
                                </FormGroup>
                            </div>
                        </div>
                        {filtered.length ? (
                            <div className='tabla-reservas-container'>
                                <table className='table table-borderless table-hover tablaReservas'>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th>Código</th>
                                            <th>Fecha de Reserva</th>
                                            <th>Turistas</th>
                                            <th>Contacto</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filtered.map((compra, i) => (
                                            <tr key={i}>
                                                <td>{compra.codigo}</td>
                                                <td>{compra.fechaReserva.toLocaleString('en-GB').split(',')[0]}</td>
                                                <td>{compra.personas}</td>
                                                <td>{compra?.cliente?.telefono || '--'}</td>
                                                <td>
                                                    <span
                                                        className={`badge ${
                                                            compra.estado === 'PAGADO'
                                                                ? 'badge-success'
                                                                : 'badge-warning'
                                                        }`}
                                                    >
                                                        {compra.estado}
                                                    </span>
                                                </td>
                                                <td>
                                                    <ModalDetallesReservas compra={compra} guia={true} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : loading ? (
                            <div className='d-flex justify-content-center'>
                                <h2 className='mt-5' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                    Cargando...
                                </h2>
                            </div>
                        ) : (
                            <h4>Aún no hay reservaciones pendientes.</h4>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
