import React, { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './css/Navbar.css';
import fire from '../../config/Firebase';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import NavSinLogin from './NavSinLogin';
import NavUsuario from './NavUsuario';
import NavCliente from './NavCliente';
import NavAdmin from './NavAdmin';
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

function NavBar(props) {
    let SeleccionarNav = (u) => {
        switch (props.permisos) {
            case 'usuario':
                return <NavUsuario signOut={signOut} permisos={props.permisos} email={props.email} uid={props.uid} />;
            case 'cliente-usuario':
                return (
                    <NavCliente
                        signOut={signOut}
                        permisos={props.permisos}
                        cambiarModo={props.cambiarModo}
                        uid={props.auth.uid}
                    />
                );
            case 'cliente-anfitrion':
                return (
                    <NavCliente
                        signOut={signOut}
                        permisos={props.permisos}
                        cambiarModo={props.cambiarModo}
                        uid={props.auth.uid}
                    />
                );
            case 'cliente-operador':
                return (
                    <NavCliente
                        signOut={signOut}
                        permisos={props.permisos}
                        cambiarModo={props.cambiarModo}
                        uid={props.auth.uid}
                    />
                );
            case 'admin':
                return <NavAdmin signOut={signOut} permisos={props.permisos} />;
            default:
                return <NavSinLogin />;
        }
    };

    let signOut = () => {
        props.logout();
    };

    if (props.estilo === 'transparent') {
        return (
            <Fragment>
                <nav className='navbar navbar-expand-lg navbar-light fixed-top'>
                    <div className='container'>
                        <button
                            className='navbar-toggler'
                            type='button'
                            data-toggle='collapse'
                            data-target='#navbarCollapse'
                            aria-controls='navbarNav'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                        >
                            <span className='navbar-toggler-icon'></span>
                        </button>
                        <div className='collapse navbar-collapse justify-content-end' id='navbarCollapse'>
                            {SeleccionarNav()}
                            {/* {user === "usuario" ? (<NavUsuario signOut={signOut}/>) : null} */}
                        </div>
                    </div>
                </nav>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <Navbar bg='dark' variant='dark' expand='lg' className='navitoour'>
                    <div className='container' style={{ justifyContent: 'space-between !important' }}>
                        {/*<Navbar.Brand href="#" className="logo">*/}
                        <NavLink to='/' className='logo'>
                            itoour
                        </NavLink>
                        {/*</Navbar.Brand>*/}
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse id='basic-navbar-nav'>
                            <Nav className='ml-auto'>{SeleccionarNav()}</Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>

                {/* <nav className="navbar navbar-expand-lg navbar-dark navitoour">
          <div className="container">
            <NavLink className="navbar-brand logo" to="#">
              itoour
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarCollapse"
            >
              {SeleccionarNav()}
            </div>
          </div>
        </nav> */}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log("navbar", state);
    return {
        auth: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
