import React, { useState } from "react";
import {
    Button,
    Modal,
    Container,
    Row,
    Col,
    Accordion,
    Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalResumenPDF from "../Shared/ModalResumenPDF";

const ItemData = ({ title, data, style }) => {
    return (
        <Row>
            <Col lg={3} md={4} sm={5} className="detalles-tour-modal">
                <p style={style}>{title}</p>
            </Col>
            <Col lg={9} md={8} sm={7} className="detalles-tour-modal">
                <p style={style}>{data}</p>
            </Col>
        </Row>
    );
};

const ItemTitle = ({ title, sx }) => {
    return (
        <Row>
            <Col lg={12} style={sx}>
                {title}
            </Col>
        </Row>
    );
};

export default function ModalDetallesReservas({ compra, guia = false }) {
    const [show, setShow] = useState(false);
    const { tour, operador, cliente } = compra;

    return (
        <>
            <span onClick={() => setShow(true)}>
                <i className="far fa-eye showReserva"></i>
            </span>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="modalReservas"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {tour.tour_nom}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="accordion-body-modal">
                    <ItemTitle
                        title="Detalles de Compra:"
                        sx={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Código:"
                        data={compra.codigo}
                        style={{ padding: "0 0 0 15px" }}
                    />
                    {!guia ? (
                        <>
                            <ItemData
                                title="Dirección de Partida:"
                                data={tour.tour_direccionpartida}
                                style={{ padding: "0 0 0 15px" }}
                            />
                            <ItemData
                                title="Fecha de Compra:"
                                data={compra.fechaCompra.toLocaleString(
                                    "en-GB",
                                    {
                                        hour12: true,
                                    }
                                )}
                                style={{ padding: "0 0 0 15px" }}
                            />
                        </>
                    ) : null}
                    <ItemData
                        title="Fecha del Tour:"
                        data={
                            compra.fechaReserva
                                .toLocaleString("en-GB", {
                                    hour12: true,
                                })
                                .split(",")[0]
                        }
                        style={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Hora del Tour:"
                        data={compra.fechaReserva
                            .toLocaleString("en-GB", {
                                hour12: true,
                            })
                            .split(",")[1]
                            .trim()}
                        style={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Turistas Adultos:"
                        data={+compra.adultos}
                        style={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Precio Adultos:"
                        data={`${compra.moneda === "PEN" ? "S/. " : "$ "} ${
                            guia
                                ? compra.precioAdulto
                                : Math.ceil(+compra.precioAdulto / 0.85)
                        }`}
                        style={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Turistas Niños:"
                        data={+compra.ninos}
                        style={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Precio Niños:"
                        data={`${compra.moneda === "PEN" ? "S/. " : "$ "} ${
                            guia
                                ? compra.precioNino
                                : Math.ceil(+compra.precioNino / 0.85)
                        }`}
                        style={{ padding: "0 0 0 15px" }}
                    />
                    <ItemData
                        title="Precio Total:"
                        data={`${compra.moneda === "PEN" ? "S/. " : "$ "} ${
                            +compra.adultos *
                                (guia
                                    ? compra.precioAdulto
                                    : Math.ceil(+compra.precioAdulto / 0.85)) +
                            +compra.ninos *
                                (guia
                                    ? compra.precioNino
                                    : Math.ceil(+compra.precioNino / 0.85))
                        }`}
                        style={{ padding: "0 0 0 15px", fontWeight: "bold" }}
                    />
                    <Row>
                        <Col lg={12} className="mt-3">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                        >
                                            Información de Turistas
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body style={{ paddingTop: "0" }}>
                                            {compra.turistas.map(
                                                (turista, i) => {
                                                    return (
                                                        <div key={`tour${i}`}>
                                                            <ItemTitle
                                                                title={`Turista ${
                                                                    i + 1
                                                                }:`}
                                                            />
                                                            <ItemData
                                                                title="Nombres:"
                                                                data={
                                                                    turista.nombres
                                                                }
                                                            />
                                                            <ItemData
                                                                title="Apellidos:"
                                                                data={
                                                                    turista.apellidos
                                                                }
                                                            />
                                                            <ItemData
                                                                title="Tipo de Documento:"
                                                                data={turista.tipodoc.toUpperCase()}
                                                            />
                                                            <ItemData
                                                                title="Documento:"
                                                                data={
                                                                    turista.documento
                                                                }
                                                            />
                                                            <ItemData
                                                                title="Pais:"
                                                                data={
                                                                    turista.pais
                                                                }
                                                            />
                                                            <ItemData
                                                                title="Ciudad:"
                                                                data={
                                                                    turista.ciudad
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                {!guia ? (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                eventKey="1"
                                            >
                                                Detalles de Contacto
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <ItemTitle title="" />
                                                <ItemData
                                                    title="Nombre:"
                                                    data={
                                                        operador.cliente_nomempresa
                                                    }
                                                />
                                                <ItemData
                                                    title="Tipo:"
                                                    data={operador.cliente_tipo}
                                                />
                                                <ItemData
                                                    title="Dirección:"
                                                    data={
                                                        operador.cliente_dirempresa
                                                    }
                                                />
                                                <ItemData
                                                    title="Email:"
                                                    data={
                                                        operador.cliente_email
                                                    }
                                                />
                                                <ItemData
                                                    title="Teléfono:"
                                                    data={
                                                        operador.cliente_telefono
                                                    }
                                                />
                                                <ItemData
                                                    title="Móvil:"
                                                    data={
                                                        operador.cliente_telempresa
                                                    }
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ) : null}
                            </Accordion>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ModalResumenPDF
                        compra={{
                            ...compra,
                            precioAdulto: Math.ceil(
                                +compra.precioAdulto / 0.85
                            ),
                            precioNino: Math.ceil(+compra.precioNino / 0.85),
                        }}
                    />
                    {/*<Button className='btn btn-itoour' as={Link} to={`/vertour/${tour.id}`}>
                        Ver Tour
                    </Button>*/}
                </Modal.Footer>
            </Modal>
        </>
    );
}
