import React, { Fragment, useEffect } from 'react';
import './css/Home.css';

import Lugares from '../components/Home/Lugares';
import Destinos from '../components/Home/Destinos';
import Header from '../components/Home/Header';
import Footer from '../components/Shared/Footer';
import WhatsAppButton from '../components/Shared/WhatsAppButton';

export default function Home() {
    return (
        <Fragment>
            <WhatsAppButton />
            {/* <Navbar estilo="transparent" /> */}
            <div className='chatbot-page'>
                <Header />
                {/* header */}

                <Destinos />
                <Lugares />
                <div className='container my-5'>
                    <div className='information-container'>
                        <div className='information'>
                            <i className='far fa-heart fa-4x icono'></i>
                            <div>
                                <p>Somos una</p>
                                <p>plataforma amigable</p>
                                <p> y para todo el</p>
                                <p>mundo.</p>
                            </div>
                        </div>
                        <div className='information'>
                            <i className='fas fa-hands-helping fa-4x icono'></i>
                            <div>
                                <p>Obtén soporte a lo</p>
                                <p>largo de tu viaje.</p>
                            </div>
                        </div>
                        <div className='information'>
                            <i className='fas fa-globe-americas fa-4x icono'></i>
                            <div>
                                <p>Escoje entre</p>
                                <p>destinos y</p>
                                <p>experiencias únicas</p>
                                <p>e increíbles.</p>
                            </div>
                        </div>
                        <div className='information'>
                            <i class='fas fa-map-marked-alt fa-4x icono'></i>
                            <div>
                                <p>Ofrecemos muchas</p>
                                <p>ofertas según tu</p>
                                <p>presupuesto y</p>
                                <p>necesidad.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className='logros'>
                    <div className='container'>
                        <p className='mensajelogro'>Mira lo que hemos logrado en itoour</p>
                        <p>Mas de 10000 Tours</p>
                        <div className='numeros row'>
                            <div className='col-md-4 text-center'>
                                <p>+5000</p>
                                <p>Agencias Asociadas</p>
                            </div>
                            <div className='col-md-4 text-center'>
                                <p>+20000</p>
                                <p>Clientes satisfechos</p>
                            </div>
                            <div className='col-md-4 text-center'>
                                <p>+300</p>
                                <p>Destinos Turísticos</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='caracteristicas'>
                    <div className='container row'>
                        <div className='col-md-3'>
                            <div className='tarjeta mb-3'>
                                <i className='far fa-heart fa-3x icono'></i>
                                <h4>Somos</h4>
                                <p>Una plataforma amigable y para todo el mundo.</p>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <div className='tarjeta'>
                                <i className='fas fa-hands-helping fa-3x icono'></i>
                                <h4>Obtén</h4>
                                <p>Soporte a lo largo de tu viaje.</p>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <div className='tarjeta'>
                                <i className='fas fa-globe-americas fa-3x icono'></i>
                                <h4>Escoje entre</h4>
                                <p>Destinos y experiencias únicas e increíbles.</p>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <div className='tarjeta tarjeta4'>
                                <i className='fas fa-route fa-3x icono'></i>
                                <h4>Ofrecemos</h4>
                                <p>Muchas ofertas según tu presupuesto y necesidad.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Footer />

                {/* FIN */}
            </div>
        </Fragment>
    );
}
