import React from 'react';
import { NavLink } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import { perfil, dashboard, iconos } from '../../helpers/accountMenuConfig';

export default function NavAdmin({ signOut, permisos }) {
    return (
        <ul className='navbar-nav my-2 navbar-menu'>
            <li className='nav-item align-self-center acc-mobile'>
                <NavLink className='nav-link' to={dashboard[permisos].link}>
                    Administrador
                </NavLink>
            </li>
            <li className='nav-item'>
                <NavLink className='nav-link' to='/vertours'>
                    Tours
                </NavLink>
            </li>
            <li className='nav-item'>
                <NavLink className='nav-link' to='/contacto'>
                    Contáctanos
                </NavLink>
            </li>
            <li className='nav-item acc-menu'>
                <AccountMenu signOut={signOut} permisos={permisos} />
            </li>
            <li className='nav-item align-self-center acc-mobile mt-2'>
                <span onClick={() => signOut()}>
                    <i className={iconos[permisos].salir}></i>Salir
                </span>
            </li>
        </ul>
    );
}
