import React, { useState, useEffect, useMemo } from 'react';
import './css/Destinos.css';
import firebase from 'firebase';
import fire from '../../config/Firebase';
import { Link } from 'react-router-dom';
// import Carousel from 'react-elastic-carousel';

export default function Destinos() {
    // let [tours, setTours] = useState(['Amazonas', 'Arequipa', 'Cusco', 'Ica', 'Puno', 'Tumbes']);
    // let [estilo, setEstilo] = useState('');
    const itemsPerPage = [
        { width: 1, numItems: 3 },
        { width: 768, numItems: 2 },
        { width: 992, numItems: 3 },
        { width: 1200, numItems: 4 },
    ];

    const [numResults, setNumResults] = useState(0);
    const [windowWidth, setWindowWidth] = useState(+window.innerWidth);

    const numResultsPerPage = useMemo(() => {
        const num = +itemsPerPage.filter((c) => +c.width <= windowWidth).sort((a, b) => +b.width - +a.width)[0]
            ?.numItems;

        setNumResults(2 * num);

        return num;
    }, [windowWidth]);

    const onClick = (e) => {
        e.preventDefault();

        setNumResults((prev) => prev + numResultsPerPage);
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(+window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            setNumResults(0);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const regiones = [
        {
            name: 'Amazonas',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FAMAZONAS.png?alt=media&token=ffb1c4aa-5c62-4cf1-9913-95670bd170ef',
            description:
                'Albergue de la majestuosa Fortaleza de Kuelap y la imponente catarata de Gocta, un lugar dedicado a atraer a los amantes de la religión, la historia y la naturaleza.',
        },
        {
            name: 'Arequipa',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FAREQUIPA.png?alt=media&token=d1e15096-59a4-4dbd-a4be-851d789cfb3c',
            description:
                '“Ciudad Blanca” de riqueza gastronómica y arquitectónica, que alberga en sus vestiduras lo mejor de la furia volcánica, adornándola de bellas estructuras que perduran en el tiempo.',
        },
        {
            name: 'Ancash',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FANCASH.png?alt=media&token=67c64423-2e87-4fa2-80f6-9304e1fd43a5',
            description:
                'Hogar de los más intrépidos y exploradores, a quienes se les otorga el deleite de conocer los mejores regalos brindados por la naturaleza y la cultura.',
        },
        {
            name: 'Apurimac',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FAPURIMAC.jpg?alt=media&token=2a4cf3f5-04ac-498e-ad72-6a59a4aac6b0',
            description:
                'Tierra llena de historia, cultura y actividades ancestrales, un tesoro escondido ansioso por ser descubierto, que recibe diariamente con los brazos abiertos a todos los visitantes que deciden deleitarse con sus hermosos celajes.',
        },
        {
            name: 'Ayacucho',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FAYACUCHO.png?alt=media&token=7e0b696d-e96d-452f-a097-7c558afda1fe',
            description:
                'Belleza turística que alberga lugares llenos de historia, cultura y majestuosidad arquitectónica, con imponentes calles que se visten de color en cada festividad y celebración popular.',
        },
        // {
        //     name: 'Cajamarca',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FCajamarca.jpg?alt=media&token=57477172-e80c-48fe-9bfd-6a3bc7ce9abe',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Callao',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FCallao.jpg?alt=media&token=37b8f039-8ad6-4d59-b782-5149638b1722',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        {
            name: 'Cusco',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FCUSCO.png?alt=media&token=d06e5c77-96fd-42e1-a339-7f9678eceecb',
            description:
                'Cuna de una maravilla del mundo y ciudad milenaria revestida de historia, que esconde en lo más profundo de sus construcciones incaicas miles de años de secretos y curiosidades por descubrir',
        },
        // {
        //     name: 'Huancavelica',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FHuancavelica.jpg?alt=media&token=e927f8a1-fb66-4fd8-b48a-81f761d203bd',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        {
            name: 'Huánuco',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FHU%C3%81NUCO.jpg?alt=media&token=8a7b9b60-c0bb-4edf-a258-b163f878e3ec',
            description:
                'Un lugar que no solo mantiene el mejor clima del mundo, sino que, además, se enorgullece por poseer todo tipo de atractivos: desde nevados y cordilleras, hasta cálidos valles y selvas amazónicas.',
        },
        {
            name: 'Ica',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FICA.png?alt=media&token=96bbde44-5705-4b20-82bd-87c5ec930836',
            description:
                'Tierra del sol eterno, lugar de los vinos y las buenas dunas, un oasis perfecto para que mezcla cultura, aventura y naturaleza.',
        },
        {
            name: 'Junín',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FJun%C3%ADn.jpg?alt=media&token=9ddbdbe6-8926-40cb-8700-b943808dc516',
            description:
                'Heróica ciudad de lugares gloriosos, que esconde en sus calles una interminable historia de valentía, libertad y lucha peruana.',
        },
        {
            name: 'La Libertad',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FLA%20LIBERTAD.png?alt=media&token=be6a33b9-e334-439b-b722-61d54c334ed5',
            description:
                'Ciudad de la eterna primavera, cuna de la marinera y paraíso de los surfistas. Un lugar que cautiva con su cultura, historia y tradición.',
        },
        // {
        //     name: 'Lambayeque',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FHuanuco.jpg?alt=media&token=d4a3781a-ee15-45a5-b935-f42329362345',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        {
            name: 'Lima',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FLIMA.png?alt=media&token=94d3213a-6c19-42db-a79e-60f1a86cc4e6',
            description:
                'Capital peruana de magnífica arquitectura colonial, exquisita gastronomía, distritos modernos y legado de culturas prehispánicas, la ciudad perfecta donde siempre encontrarás algo que hacer y conocer.',
        },
        {
            name: 'Loreto',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FLORETO.png?alt=media&token=c5bf2931-7fec-4896-a8ef-7b1faae3ef44',
            description:
                'Ciudad bañada por la naturaleza exótica del río amazonas y hogar de la biodiversidad más extravagante y única del Perú, conoce la maravilla de la selva peruana y déjate llevar por su encanto.',
        },
        // {
        //     name: 'Madre de Dios',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FMadre%20de%20Dios.jpg?alt=media&token=c3c1067e-e990-4fe7-b166-db6a392f6682',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Moquegua',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FMoquegua.jpg?alt=media&token=b1418df1-5a1c-4020-a89c-3fd125bed46c',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Pasco',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FPasco.jpg?alt=media&token=2bf18d3f-f8b4-4b26-873d-1486bfd6f08d',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Piura',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FPiura.jpg?alt=media&token=5de3d930-8ff8-4c23-88a6-72a61c7fa41e',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        {
            name: 'Puno',
            image: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FCiudades%2FPUNO.png?alt=media&token=9357ae4a-c18d-4984-82da-6197ebd8fe05',
            description:
                'Déjate envolver por su maravillosa tradición y sus incomparables costumbres, sumérgete en lo místico del Lago Titicaca y acércate a lo mejor de la historia a través de las Islas de los Uros.',
        },
        // {
        //     name: 'San Martin',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FSan%20Martin.jpg?alt=media&token=a6075dc3-f651-420f-9fed-1dd1c3e4128d',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Tacna',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FTacna.jpg?alt=media&token=bb756884-25d2-4429-b37b-3c7adc961310',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Tumbes',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FTumbes.jpg?alt=media&token=ae8776bb-3088-4e8e-9f32-00263bf3c4d0',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
        // {
        //     name: 'Ucayali',
        //     image: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2FCiudades%2FUcayali.jpg?alt=media&token=b47d22a7-1527-4ca5-8dd8-1e7a014ce02f',
        //     description:
        //         'Podrás conocer el Cañón de Apurímac, uno de los más profundos del mundo; el Cerro Quisapata, donde puedes realizar deportes extremos; el Santuario Nacional de Ampay.',
        // },
    ];

    // const breakPoints = [
    //     { width: 1, itemsToShow: 1 },
    //     { width: 550, itemsToShow: 3 },
    //     { width: 768, itemsToShow: 4 },
    //     { width: 992, itemsToShow: 4 },
    //     { width: 1200, itemsToShow: 4 },
    // ];

    /*let getTour = () => {
        let db = fire.firestore();
        let hoy = firebase.firestore.Timestamp.fromDate(new Date());
        db.collection('tours')
            //.where("tour_fechafin", ">=", hoy)
            .where('tour_activo', '==', true)
            .get()
            .then((querySnapshot) => {
                let ToursTmp = [];
                querySnapshot.forEach((tour) => {
                    let objTour = {
                        id: tour.id,

                        tour_activo: tour.data().tour_activo,
                        tour_departamento: tour.data().tour_departamento
                    };
                    ToursTmp.push(objTour);
                });

                let misDepartamentos = ToursTmp.map((tour) => {
                    return tour.tour_departamento;
                });
                let departamentos = misDepartamentos.filter((tour, indice) => {
                    return misDepartamentos.indexOf(tour) === indice;
                });

                setTours(departamentos);
                // if (props.match.params.ciudad) {
                //   let toursFiltrados = ToursTmp.filter(
                //     (tour) =>
                //       tour.tour_fecha < props.match.params.fecha && tour.tour_ciudad === props.match.params.ciudad
                //   );
                //   console.log("filtroheader",toursFiltrados)
                //   setTours(toursFiltrados);
                // } else {
                //   setTours(ToursTmp);
                // }
            });
    };

    useEffect(() => {
        getTour();
    }, []);*/

    return (
        <div className='destinos'>
            <div className='container'>
                <div className='titulo'>
                    <span style={{ color: '#61266d' }}>
                        <i class='fas fa-map-marked-alt'></i> Destinos
                    </span>
                    <h5
                        style={{
                            fontWeight: 'bold',
                            marginBottom: '2rem',
                            fontFamily: 'ArialMTBold',
                        }}
                    >
                        DESCUBRE LOS DESTINOS MÁS BUSCADOS
                    </h5>
                </div>
                <div className=''>
                    <div className='cards-regiones ' style={{ margin: '0 auto' }}>
                        <div>
                            {regiones
                                ? regiones.slice(0, numResults).map((region, i) => {
                                      return (
                                          <div className='card-region' key={i}>
                                              <img src={region.image} alt={region.name} className='image-region'></img>
                                              <div className='detalles-region'>
                                                  <div className='nombre-region'>{region.name}</div>
                                                  <div className='descripcion-region'>{region.description}</div>
                                              </div>
                                              <Link
                                                  to={`/vertours?departamento=${region.name
                                                      .trim()
                                                      .split(' ')
                                                      .join('%20')}`}
                                                  className='link-region'
                                                  onClick={() =>
                                                      window.fbq('track', 'Buscar', {
                                                          medio: 'Destinos más buscados',
                                                          region: region.name,
                                                      })
                                                  }
                                              >
                                                  Descubrir
                                              </Link>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                        {numResults < regiones.length ? (
                            <button className='btn-ver-mas-destinos' onClick={onClick}>
                                Ver más
                            </button>
                        ) : //     <button className='btn-ver-mas' onClick={onClick}>
                        //     <i class='fa fa-arrow-down' aria-hidden='true'></i>
                        // </button>
                        null}
                    </div>
                </div>
            </div>
        </div>
    );
}
