import React, { useState, useEffect, Fragment } from 'react';
import ReactLoading from 'react-loading';
import * as ModalReact from 'react-modal';
import fire, { functions } from '../../config/Firebase';
import Carousel from 'react-bootstrap/Carousel';
import Navbar from '../../components/Shared/Navbar';
import ComprarTour from './ComprarTour';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { MapsKey } from '../../config/Google';
import Cargando from '../../components/Shared/Cargando';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { CulqiProvider, Culqi } from 'react-culqi';
import axios from 'axios';
import Footer from '../../components/Shared/Footer';
import { Modal, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import iconanfitrion from '../../assets/icon/iconanfitrion.png';
import iconguiacertificado from '../../assets/icon/iconguiacertificado.png';
import iconoperador from '../../assets/icon/iconoperador.png';
//import MapUsuario from "../../components/Shared/MapUsuario";
import WhatsAppButton from '../../components/Shared/WhatsAppButton';
import CarouselRecomendaciones from '../../components/Usuario/CarouselRecomendaciones';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './css/VerTour.css';

const customStyles = {
    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.85)' },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function VerTour(props) {
    const auth = useSelector((e) => e.firebase.auth);
    let [tour, setTour] = useState({});
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pagado, setPagado] = useState('');
    const [horaCompra, setHoraCompra] = useState('');
    const compra_id = new Date().getTime();
    let [fechaCompra, setFechaCompra] = useState(new Date());
    let [personas, setPersonas] = useState(1);
    let [ninos, setNinos] = useState(0);
    let [tipo, setTipo] = useState('ANFITRION');
    const [dataUserPayment, setDataUserPayment] = useState({});

    const [agency, setAgency] = useState({});

    const [loadingSend, setLoadingSend] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [payMessage, setPayMessage] = useState(false);

    let getTour = (tourId) => {
        let db = fire.firestore();
        let ref = db.collection(`tours`).doc(tourId);

        ref.get()
            .then((doc) => {
                if (doc.exists) {
                    setTour({
                        ...doc.data(),
                    });

                    db.collection('cliente')
                        .doc(doc.data().uid)
                        .get()
                        .then((res) => {
                            const dataAgency = res.data();
                            setAgency(dataAgency);
                        });
                    setPersonas(doc?.data()?.tour_minimocompra || 1);
                    return db.collection('cliente').doc(doc.data().uid).get();
                } else {
                }
            })
            .then((user) => {
                if (user.data().cliente_tipo === undefined) {
                } else {
                    setTipo(user.data().cliente_tipo);
                }
                setLoading(false);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        let id = props.match.params.id;
        getTour(id);
    }, []);

    const findIndexTourTurno = () => {
        //remuevo horas minutos y segundos
        let fechaCalendario = new Date(fechaCompra).setHours(0, 0, 0, 0);
        let toursFiltrados = tour.tour_disponibilidad.findIndex(
            (item) => new Date(item.dia.seconds * 1000).setHours(0, 0, 0, 0) === fechaCalendario
        );
        return toursFiltrados;
    };

    const saveOrder = () => {
        if (!auth.uid) return;

        let db = fire.firestore();
        const parsedHora = JSON.parse(horaCompra);
        const localeTime = parsedHora.dia.seconds;
        const date = new Date(localeTime);
        db.collection('compras').add({
            compra_cliente: auth.uid,
            compra_fechatour: fechaCompra,
            compra_horarioTour: date.toLocaleTimeString(),
            compra_nombretour: tour.tour_nom,
            compra_personas: personas,
            compra_ninos: ninos,
            compra_precioAdulto: tour.tour_precioAdultos,
            compra_precioNino: tour.tour_precioNino,
            compra_montoPagado: pagado / 100,
            compra_descripcion: tour.tour_desc,
            compra_moneda: tour.tour_moneda,
            compra_ciudad: tour.tour_ciudad,
            compra_id: compra_id,
            compra_user_apellidos: dataUserPayment.apellidos,
            compra_user_ciudad: dataUserPayment.ciudad,
            compra_user_direccion: dataUserPayment.direccion,
            compra_user_documento: dataUserPayment.documento,
            compra_user_movil: dataUserPayment.movil,
            compra_user_nombres: dataUserPayment.nombres,
            compra_user_pais: dataUserPayment.pais,
            compra_user_telefono: dataUserPayment.telefono,
            compra_user_tipodoc: dataUserPayment.tipodoc,
            agency_telempresa: agency.cliente_telempresa,
        });

        tour.tour_disponibilidad[findIndexTourTurno()].capacidad = (
            parseInt(tour.tour_disponibilidad[findIndexTourTurno()].capacidad, 10) - personas
        ).toString();
        // Actualizo la cantidad de espacio disponible
        db.collection('tours').doc(props.match.params.id).update({
            tour_disponibilidad: tour.tour_disponibilidad,
        });
        setTour(tour);
    };

    return (
        <React.Fragment>
            <WhatsAppButton />
            <div style={{ height: '100%' }}>
                {/* {!cargando ? ()} */}

                {loading ? (
                    <Cargando style={{ height: '100%' }} />
                ) : (
                    <Fragment>
                        <div className='container mt-4 mb-4'>
                            <Link className='btn btn-outline-primary mb-3' to='/vertours'>
                                <i className='fas fa-backward mr-2'></i>Regresar
                            </Link>
                            <div className='row'>
                                <div className='col-md-4'>
                                    {tour.status === 'Publicado' ? (
                                        <div>
                                            {' '}
                                            {/* culqi */}
                                            <CulqiProvider
                                                publicKey='pk_test_lYjlLaI58HlNK44o'
                                                amount={Math.ceil((Math.ceil(tour.tour_precio / 0.85) / 0.96) * 100)}
                                                title='Comprar/Reservar Tour'
                                                description='Ingrese los datos solicitados'
                                                onToken={(token) => {
                                                    setLoadingSend(true);
                                                    const refHacerCompra = functions.httpsCallable('hacerCompra');
                                                    const parsedHora = JSON.parse(horaCompra);
                                                    const localeTime = parsedHora.dia.seconds;
                                                    const date = new Date(localeTime);

                                                    let fechaSinFormato = new Date(fechaCompra);
                                                    let fechaConFormato = `${fechaSinFormato.getDate()}/${fechaSinFormato.getMonth()}/${fechaSinFormato.getFullYear()}`;

                                                    let datacompra = {
                                                        token: token.id,
                                                        precio: Math.ceil(
                                                            (Math.ceil(tour.tour_precio / 0.85) / 0.96) * 100
                                                        ).toString(),
                                                        moneda: 'PEN',
                                                        email: auth.email,
                                                        metadata: {
                                                            order_id: compra_id,
                                                            user_id: token.uid,
                                                            user_apellidos: dataUserPayment.apellidos,
                                                            user_ciudad: dataUserPayment.ciudad,
                                                            user_direccion: dataUserPayment.direccion,
                                                            user_documento: dataUserPayment.documento,
                                                            user_movil: dataUserPayment.movil,
                                                            user_nombres: dataUserPayment.nombres,
                                                            user_pais: dataUserPayment.pais,
                                                            user_telefono: dataUserPayment.telefono,
                                                            user_tipodoc: dataUserPayment.tipodoc,
                                                            tour_nom: tour.tour_nom,
                                                            tour_phone: agency.cliente_telempresa,
                                                            tour_precio: tour.tour_precio,
                                                            tour_montoPagado: pagado / 100,
                                                            tour_personas: personas,
                                                            tour_ciudad: tour.tour_ciudad,
                                                            tour_fechaCompra: fechaConFormato,
                                                            tour_hora: date.toLocaleTimeString(),
                                                            agency_email: agency.cliente_email,
                                                        },
                                                    };
                                                    refHacerCompra(datacompra)
                                                        .then((rpta) => {
                                                            setLoadingSend(false);
                                                            setPayMessage(rpta.data.message);
                                                            if (rpta.data.success) {
                                                                saveOrder(rpta);
                                                                setShowSuccess(true);
                                                            } else {
                                                                setShowError(true);
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            setShowError(true);
                                                            setLoadingSend(false);
                                                            setPayMessage('Ocurrió un error al procesar el pago');
                                                        });

                                                    // axios.post(`http://localhost:2000/compra`,datacompra).then(data => {
                                                    // }).catch(err => //console.log("errpet",err))
                                                    // const config = {
                                                    //   headers: {
                                                    //     Accept: "application/json",
                                                    //     Authorization: `Bearer ${token.id}`,
                                                    //     "Content-Type": "application/json"
                                                    //   },
                                                    // };

                                                    // const bodyParameters = {
                                                    //   amount: tour.tour_precio,
                                                    //   currency_code: "PEN",
                                                    //   email: "test90@test.com",
                                                    //   source_id: token.id,
                                                    // };

                                                    // axios
                                                    //   .post(
                                                    //     "https://api.culqi.com/v2/charges",
                                                    //     bodyParameters,
                                                    //     config
                                                    //   )
                                                    //   .then((data) => //console.log("culqui", data))
                                                    //   .catch((err) => //console.log("culquierr", err));
                                                }}
                                                onError={(error) => {
                                                    setShowError(true);
                                                    setLoadingSend(false);
                                                    setPayMessage('Ocurrió un error al procesar el pago');
                                                }}
                                                options={{
                                                    style: {
                                                        maincolor: 'purple',
                                                        buttontext: 'white',
                                                        maintext: 'purple',
                                                        desctext: 'gray',
                                                        logo: 'https://firebasestorage.googleapis.com/v0/b/turismo-v1.appspot.com/o/static%2Fitoour.png?alt=media&token=0140495a-ff7a-4404-90be-57705b05f3ce',
                                                    },
                                                }}
                                            >
                                                <div>
                                                    <Culqi>
                                                        {({ openCulqi, setAmount }) => {
                                                            return (
                                                                <div>
                                                                    {/* {previo !== reserva ? openCulqi() : null} */}
                                                                    <ComprarTour
                                                                        idCliente={props.userId}
                                                                        tourId={props.match.params.id}
                                                                        tour={tour}
                                                                        comprar={(data) => {
                                                                            setDataUserPayment(data);
                                                                            openCulqi();
                                                                        }}
                                                                        fechaCompra={fechaCompra}
                                                                        horaCompra={setHoraCompra}
                                                                        setFechaCompra={setFechaCompra}
                                                                        cambiarMonto={(val) => {
                                                                            setPagado(val);
                                                                            setAmount(val);
                                                                        }}
                                                                        personas={personas}
                                                                        setPersonas={setPersonas}
                                                                        ninos={ninos}
                                                                        setNinos={setNinos}
                                                                        email={props.email}
                                                                        permisos={props.permisos}
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                    </Culqi>
                                                </div>
                                            </CulqiProvider>
                                            {/* ---culqui.-.- */}
                                        </div>
                                    ) : null}
                                </div>
                                <div className='col-md-8'>
                                    <div className='info'>
                                        <Carousel className='imagen-slider-container'>
                                            {tour.tour_imagenes
                                                ? tour.tour_imagenes.map((img, i) => {
                                                      return (
                                                          <Carousel.Item key={i}>
                                                              <img
                                                                  className='d-block w-100 img-ratio-principal imagen-slider'
                                                                  src={img}
                                                                  alt='...'
                                                              />
                                                          </Carousel.Item>
                                                      );
                                                  })
                                                : null}
                                        </Carousel>
                                        {/* carousel */}
                                        <div className='center mt-2'>
                                            <span className='font-weight-bold'>Tipo: </span>
                                            <span>
                                                {tipo === 'ANFITRION' ? (
                                                    <Fragment>
                                                        <img
                                                            src={iconanfitrion}
                                                            className='icon-operator'
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                        Anfitrión
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={
                                                                <Popover id='popover-basic'>
                                                                    <Popover.Content>
                                                                        Personas, asociaciones y/o comunidades que
                                                                        poseen habilidades y conocimientos sobre su zona
                                                                        y que, por lo tanto, ofrecen diversos servicios
                                                                        relacionados al rubro turístico.
                                                                    </Popover.Content>
                                                                </Popover>
                                                            }
                                                            trigger={['click', 'hover', 'focus']}
                                                        >
                                                            <Button
                                                                variant='link'
                                                                style={{
                                                                    padding: '5px',
                                                                    borderRadius: '50%',
                                                                    fontSize: '14px',
                                                                }}
                                                            >
                                                                <i
                                                                    class='fas fa-info-circle'
                                                                    style={{
                                                                        color: '#D6872B',
                                                                    }}
                                                                ></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Fragment>
                                                ) : null}
                                                {tipo === 'TOUR OPERADOR' ? (
                                                    <Fragment>
                                                        <img
                                                            src={iconoperador}
                                                            className='icon-operator'
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                        Tour Operador
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={
                                                                <Popover id='popover-basic'>
                                                                    <Popover.Content>
                                                                        Empresa formalmente constituida encargada de
                                                                        vender y desplegar sus servicios turísticos.
                                                                    </Popover.Content>
                                                                </Popover>
                                                            }
                                                            trigger={['click', 'hover', 'focus']}
                                                        >
                                                            <Button
                                                                variant='link'
                                                                style={{
                                                                    padding: '5px',
                                                                    borderRadius: '50%',
                                                                    fontSize: '14px',
                                                                }}
                                                            >
                                                                <i
                                                                    class='fas fa-info-circle'
                                                                    style={{
                                                                        color: '#D6872B',
                                                                    }}
                                                                ></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Fragment>
                                                ) : null}
                                                {tipo === 'GUIA' ? (
                                                    <Fragment>
                                                        <img
                                                            src={iconguiacertificado}
                                                            className='icon-operator'
                                                            style={{
                                                                width: '20px',
                                                            }}
                                                        />
                                                        Guía Certificado
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={
                                                                <Popover id='popover-basic'>
                                                                    <Popover.Content>
                                                                        Guías certificados que desarrollan su trabajo
                                                                        turístico en modalidad freelance.
                                                                    </Popover.Content>
                                                                </Popover>
                                                            }
                                                            trigger={['click', 'hover', 'focus']}
                                                        >
                                                            <Button
                                                                variant='link'
                                                                style={{
                                                                    padding: '5px',
                                                                    borderRadius: '50%',
                                                                    fontSize: '14px',
                                                                }}
                                                            >
                                                                <i
                                                                    class='fas fa-info-circle'
                                                                    style={{
                                                                        color: '#D6872B',
                                                                    }}
                                                                ></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Fragment>
                                                ) : null}
                                            </span>
                                        </div>
                                        <div className='mt-4 d-flex justify-content-between text-helvetica'>
                                            <div>
                                                <h3 className='font-weight-bold'>{tour.tour_nom}</h3>
                                                <small>
                                                    <i
                                                        className='fas fa-map mr-2'
                                                        style={{
                                                            color: '#753183',
                                                        }}
                                                    ></i>{' '}
                                                    {tour.tour_departamento}
                                                </small>
                                            </div>
                                            <div className='box-tour-precio'>
                                                <div className='text-itoour font-weight-bold text-center'>
                                                    <h3
                                                        className='font-weight-bold'
                                                        style={{
                                                            width: 'max-content',
                                                        }}
                                                    >
                                                        {tour.tour_moneda === 'PEN' ? 'S/.' : '$.'}{' '}
                                                        {Math.ceil(+tour.tour_precioAdulto / 0.85)}
                                                    </h3>
                                                    {!tour.tour_precioNino || +tour.tour_precioNino === 0
                                                        ? 'por persona'
                                                        : 'por adulto'}
                                                </div>
                                                {!tour.tour_precioNino || +tour.tour_precioNino === 0 ? null : (
                                                    <div className='text-itoour font-weight-bold text-center'>
                                                        <h3
                                                            className='font-weight-bold'
                                                            style={{
                                                                width: 'max-content',
                                                            }}
                                                        >
                                                            {tour.tour_moneda === 'PEN' ? 'S/.' : '$.'}{' '}
                                                            {Math.ceil(+tour.tour_precioNino / 0.85)}
                                                        </h3>
                                                        por niño
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <h5 className='font-weight-bold'>Descripción del Tour</h5>
                                            <p>{tour.tour_desc}</p>
                                        </div>
                                        <div>
                                            <h5 className='font-weight-bold'>Actividades</h5>
                                            <ul className='ul-itoour'>
                                                {tour.tour_activ
                                                    ? tour.tour_activ.map((activ, i) => {
                                                          return (
                                                              <li key={i} className='mb-3'>
                                                                  {activ}
                                                              </li>
                                                          );
                                                      })
                                                    : null}
                                            </ul>
                                        </div>
                                        {/*<div>
                                            <h5 className="font-weight-bold">
                                                Punto de partida
                                            </h5>
                                            <span className="font-weight-bold mr-1">
                                                Dirección:
                                            </span>*/}
                                        {/*tour.tour_partida*/}
                                        {/*tour.lat ? (
                                                <div>
                                                    <MapUsuario
                                                        marker={{
                                                            lat: tour.lat,
                                                            lng: tour.lng,
                                                        }}
                                                    />
                                                </div>
                                            ) : null*/}
                                        {/*</div>*/}
                                        {tour.tour_youtube ? (
                                            <div className='video'>
                                                {tour.tour_youtube !== undefined ? (
                                                    <div>
                                                        <h5 className='font-weight-bold card-title'>Video:</h5>
                                                        <ReactPlayer
                                                            url={tour.tour_youtube}
                                                            style={{
                                                                margin: '0 auto',
                                                                maxWidth: '100%',
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        <div style={{ marginTop: '10px' }}>
                                            {tour?.tour_inc && tour?.tour_inc?.length ? (
                                                <div className='card shadow mb-3'>
                                                    <div className='card-body'>
                                                        <h5 className='font-weight-bold card-title'>Incluye:</h5>
                                                        <ul className='ul-itoour'>
                                                            {tour.tour_inc
                                                                ? tour.tour_inc.map((inc, i) => {
                                                                      return (
                                                                          <li key={i} className='mb-3'>
                                                                              {inc}
                                                                          </li>
                                                                      );
                                                                  })
                                                                : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {tour?.tour_noinc && tour?.tour_noinc?.length ? (
                                                <div className='card shadow mb-3'>
                                                    <div className='card-body'>
                                                        <h5 className='font-weight-bold card-title'>No Incluye:</h5>
                                                        <ul className='ul-itoour'>
                                                            {tour.tour_inc
                                                                ? tour.tour_noinc.map((noinc, i) => {
                                                                      return (
                                                                          <li key={i} className='mb-3'>
                                                                              {noinc}
                                                                          </li>
                                                                      );
                                                                  })
                                                                : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {tour?.tour_notas && tour?.tour_notas?.length ? (
                                                <div className='card shadow mb-3'>
                                                    <div className='card-body'>
                                                        <h5 className='font-weight-bold card-title'>
                                                            Notas adicionales:
                                                        </h5>
                                                        <ul className='ul-itoour'>
                                                            {tour.tour_inc
                                                                ? tour.tour_notas.map((nota, i) => {
                                                                      return (
                                                                          <li key={i} className='mb-3'>
                                                                              {nota}
                                                                          </li>
                                                                      );
                                                                  })
                                                                : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {tour?.tour_terminos && tour?.tour_terminos?.length ? (
                                                <div className='card shadow mb-3'>
                                                    <div className='card-body'>
                                                        <h5 className='font-weight-bold card-title'>
                                                            Terminos y Condiciones:
                                                        </h5>
                                                        <ul className='ul-itoour'>
                                                            {tour.tour_terminos
                                                                ? tour.tour_terminos.map((nota, i) => {
                                                                      return (
                                                                          <li key={i} className='mb-3'>
                                                                              {nota}
                                                                          </li>
                                                                      );
                                                                  })
                                                                : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='my-4' style={{ maxWidth: '1000px', margin: 'auto' }}>
                                <CarouselRecomendaciones
                                    tourId={props.match.params.id}
                                    moneda={tour.tour_moneda}
                                    precio={tour.tour_precioAdulto}
                                    departamento={tour.tour_departamento}
                                    provincia={tour.tour_provincia}
                                    ciudad={tour.tour_ciudad}
                                />
                            </div>
                        </div>
                        <Footer />
                    </Fragment>
                )}
            </div>
            <ModalReact isOpen={loadingSend} onRequestClose={() => null} style={customStyles} ariaHideApp={false}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <ReactLoading type='bars' color='#753183' />
                    <br />
                    <div style={{ fontSize: '22px' }}>Estamos procesando su pago</div>
                    <div style={{ fontSize: '12px' }}>
                        Este proceso puede durar unos segundos, por favor sea paciente
                    </div>
                </div>
            </ModalReact>
            <Modal show={showSuccess} aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Body>
                    <div className='wrapStatusPayment'>
                        <img src='/check.svg' alt='' />
                        <h3>¡Felicitaciones!</h3>
                        <p>{payMessage}</p>
                        <Button
                            variant='primary'
                            onClick={() => {
                                setShowSuccess(false);
                                history.push('/reservas');
                            }}
                        >
                            Aceptar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showError} aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Body>
                    <div className='wrapStatusPayment wrapStatusPayment--error'>
                        <img src='/triangulo.svg' alt='' />
                        <h3>¡Ups!</h3>
                        <p>{payMessage}</p>
                        <Button variant='primary' onClick={() => setShowError(false)}>
                            Aceptar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    };
};

const WrappedContainer = GoogleApiWrapper({
    apiKey: MapsKey,
})(VerTour);

export default connect(mapStateToProps)(WrappedContainer);
