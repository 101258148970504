import React, { useState, useEffect, useMemo } from 'react';
import './css/Lugares.css';
import firebase from 'firebase';
import fire, { auth } from '../../config/Firebase';
import { Link } from 'react-router-dom';
import LeerMasLeerMenos from '../Shared/LeerMasLeerMenos';
// import Carousel from 'react-elastic-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Skeleton } from '@material-ui/lab';

export default function Lugares() {
    let [tours, setTours] = useState([]);
    const itemsPerPage = [
        { width: 1, numItems: 3 },
        { width: 768, numItems: 2 },
        { width: 992, numItems: 3 },
        { width: 1200, numItems: 4 },
    ];

    const [numResults, setNumResults] = useState(0);
    const [windowWidth, setWindowWidth] = useState(+window.innerWidth);

    const numResultsPerPage = useMemo(() => {
        const num = +itemsPerPage.filter((c) => +c.width <= windowWidth).sort((a, b) => +b.width - +a.width)[0]
            ?.numItems;

        setNumResults(2 * num);

        return num;
    }, [windowWidth]);

    const onClick = (e) => {
        e.preventDefault();

        setNumResults((prev) => prev + 2 * numResultsPerPage);
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(+window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            setNumResults(0);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const breakPoints = [
    //     { width: 1, itemsToShow: 1 },
    //     { width: 550, itemsToShow: 2 },
    //     { width: 768, itemsToShow: 3 },
    //     { width: 992, itemsToShow: 3 },
    //     { width: 1200, itemsToShow: 3 },
    // ];

    let getTour = () => {
        let db = fire.firestore();
        let date = new Date();
        let hoy = firebase.firestore.Timestamp.fromDate(date);
        date.setDate(date.getDate() + 365);
        let una_semana = firebase.firestore.Timestamp.fromDate(date);
        db.collection('tours')
            .where('status', '==', 'Publicado')
            //.where("tour_fecha", ">=", hoy)
            //.where("tour_fecha", "<=", una_semana)
            .orderBy('tour_fecha', 'desc')
            //.where("tour_fechafin", ">=", hoy)
            // .limit(10)
            .get()
            .then((querySnapshot) => {
                //console.log(querySnapshot);
                let ToursTmp = [];
                querySnapshot.forEach((tour) => {
                    let objTour = {
                        id: tour.id,
                        tour_nom: tour.data().tour_nom,
                        tour_activo: tour.data().tour_activo,
                        tour_precio: Math.ceil(+tour.data().tour_precioAdulto / 0.85),
                        tour_desc: tour.data().tour_desc,
                        tour_moneda: tour.data().tour_moneda,
                        tour_fecha: tour.data().tour_fechafin,
                        tour_ciudad: tour.data().tour_ciudad,
                        tour_departamento: tour.data().tour_departamento,
                        tour_imagen: tour.data().tour_imagenes[tour.data().tour_miniatura],
                        tour_disponibilidad: tour.data().tour_disponibilidad || [],
                        tour_minimoanticipacion: tour.data().tour_minimoanticipacion,
                        tour_tipoanticipacion: tour.data().tour_tipoanticipacion,
                        tour_dias: tour.data().tour_dias,
                        horasTour: tour.data().horasTour,
                        tour_duracion:
                            +(tour.data().horasTour ?? 0) + +(tour.data().minTour ?? 0) > 0
                                ? `${tour.data().horasTour ?? 0} hora${+(tour.data().horasTour ?? 0) > 1 ? 's' : ''} ${
                                      +(tour.data().minTour ?? 0) !== 0 ? `y ${tour.data().minTour} minutos` : ''
                                  }`
                                : `${tour.data().tour_dias} día${+tour.data().tour_dias > 1 ? 's' : ''} ${
                                      +tour.data().tour_noches !== 0
                                          ? `y ${tour.data().tour_noches} noche${
                                                tour.data().tour_noches > 1 ? 's' : ''
                                            }`
                                          : ''
                                  }`,
                        tour_fullDay: tour.data().fullDay,
                    };
                    // console.log(ToursTmp)
                    ToursTmp.push(objTour);
                });
                //console.log(ToursTmp);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                ToursTmp = ToursTmp.filter((t) => {
                    //const mult = t.tour_tipoanticipacion === 'mes' ? 30 : 1;
                    const disponibilidad = t.tour_disponibilidad
                        .map((turno) => {
                            return {
                                dia: new Date(turno.dia.seconds * 1000),
                                capacidad: +turno.capacidad,
                            };
                        })
                        .filter((turno) => {
                            const date = new Date(
                                today.getTime() +
                                    +t.tour_minimoanticipacion *
                                        (t.tour_tipoanticipacion === 'dias' ? 1 : 30) *
                                        24 *
                                        60 *
                                        60 *
                                        1000
                            );

                            return turno.dia.getTime() > date.getTime() && turno.capacidad > 0;
                        });

                    return !!disponibilidad.length;
                });

                ToursTmp = ToursTmp.filter((t) => {
                    return t.tour_fullDay || +t.horasTour > 0 || +t.tour_dias < 5;
                });
                setTours(ToursTmp);
            });
    };

    useEffect(() => {
        getTour();
    }, []);

    return (
        <div className='container my-5'>
            <div className='titulo'>
                <span style={{ color: '#61266d' }}>
                    <i class='fa fa-binoculars'></i> Tours
                </span>
                <h5 style={{ fontWeight: 'bold', marginBottom: '2rem', fontFamily: 'ArialMTBold' }}>
                    NUESTROS TOURS MÁS RECIENTES
                </h5>
            </div>
            {/*<div className="row mt-5">*/}
            <div className='cards-regiones ' style={{ margin: '0 auto' }}>
                <div className='cards-tours-container'>
                    {!tours?.length
                        ? Array.from({ length: numResultsPerPage }).map((_, i) => {
                              return (
                                  <div className='card-tour' key={i}>
                                      <Skeleton
                                          animation='wave'
                                          variant='rect'
                                          height={222}
                                          width={296}
                                          className='image-tour'
                                      />
                                      <Skeleton
                                          variant='rounded'
                                          className='precio-tour'
                                          style={{ backgroundColor: 'gray', borderColor: 'gray' }}
                                      />
                                  </div>
                              );
                          })
                        : tours.slice(0, numResults).map((t, i) => {
                              return (
                                  <div className='card-tour' key={i}>
                                      <Link
                                          to={`/vertour/${t.id}`}
                                          onClick={() =>
                                              window.fbq('track', 'Buscar', {
                                                  medio: 'Tours Recientes',
                                                  nombre_tour: t.tour_nom,
                                                  region: t.tour_departamento,
                                                  code: t.id,
                                              })
                                          }
                                      >
                                          <img src={t.tour_imagen} alt='prueba' className='image-tour'></img>
                                          <div className='sombra-tarjeta'></div>
                                          <div className='detalles-tour'>
                                              <div className='tipo-tour'>
                                                  {t.tour_fullDay ? 'Full day' : t.tour_duracion}
                                              </div>
                                              <div className='titulo-tour'>{t.tour_nom}</div>
                                              <div className='region-tour'>
                                                  <i className='fa fa-map-marked-alt'></i> {t.tour_departamento}
                                              </div>
                                          </div>
                                          <div className='precio-tour'>
                                              {t.tour_moneda === 'PEN' ? 'S/.' : '$'} {t.tour_precio}
                                          </div>
                                      </Link>
                                  </div>
                              );
                          })}
                </div>
                {numResults < tours.length ? (
                    <button className='btn-ver-mas-destinos' onClick={onClick}>
                        Ver más
                    </button>
                ) : null}
            </div>
        </div>
    );
}
