import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Country, State } from 'country-state-city';
import Swal from 'sweetalert2';
import ModalSubirFoto from '../../components/Shared/ModalSubirFoto';
import { uploadImage } from '../../Util/Imagen';
import { AuthService } from '../../services/Auth';

export default function ResumeRegister(props) {
    const [showModal, setShowModal] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const { changeStep, values, focus, errors, handleChange, handleSubmit, setEnabled } = props;

    const sAuth = new AuthService();

    const profilePic = {
        female: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FProfilePicFemale.png?alt=media&token=352b9239-9879-446b-977f-638c65da6eae',
        male: 'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FProfilePicMale.png?alt=media&token=c6bc6d8c-9ce2-4d89-90d6-462d5ae86620',
        noGender:
            'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FProfilePicNoGender.png?alt=media&token=62b982ba-3017-40f9-a430-7c2ede2b26e9',
        operator:
            'https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2FOperador.png?alt=media&token=438591cb-4101-4cdf-b948-f31d59f3f5ec',
    };

    const updatePic = (image, name) => {
        handleChange({ target: { name: 'foto', value: image } });
        handleChange({ target: { name: 'nombre_foto', value: name } });
    };

    const uploadProfilePic = () => {
        return uploadImage(values.foto.split(',')[1], 'profilePic/' + values.nombre_foto);
    };

    const onRegister = async (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Procesando registro',
            text: 'Por favor espere mientras se procesa su registro.',
            icon: 'info',
            allowOutsideClick: false,
        });
        Swal.showLoading();

        const promise = Promise.all([values.foto ? uploadProfilePic() : '']);

        return promise
            .then((url) => {
                return sAuth.registerClient(values.email, values.password, {
                    email: values.email,
                    nombres: values.nombres,
                    apellidos: values.apellidos,
                    tipodoc: values.tipo_documento,
                    documento: values.documento,
                    pais: values.pais ? Country.getCountryByCode(values.pais).name : '',
                    departamento: values.pais
                        ? values.departamento && values.departamento !== 'Sin Estado'
                            ? State.getStateByCodeAndCountry(values.departamento, values.pais)
                            : ''
                        : '',
                    ciudad: values.ciudad && values.ciudad !== 'Sin Ciudad' ? values.ciudad : '',
                    celular: values.celular,
                    razon: values.razon_social,
                    ruc: values.ruc,
                    nomempresa: values.nombre_anfitrion,
                    dirempresa: values.direccion,
                    tipo: values.tipo,
                    sexo: values.sexo,
                    representante: values.representante,
                    foto: url[0],
                });
            })
            .then((uid) => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Registro Exitoso!',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    props.history.push('/');
                });
            })
            .catch((err) => {
                console.log(err);
                if (err?.code === 'auth/email-already-in-use') {
                    Swal.fire({
                        title: 'Error de registro',
                        text: `La cuenta que intentas crear ya existe, prueba iniciando sesión.`,
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                        .then(() => props.history.push('/login'))
                        .catch(() => props.history.push('/'));
                } else {
                    Swal.fire({
                        title: 'Error de registro',
                        text: `Ocurrió un error inesperado al registrar su cuenta, por favor intentelo nuevamente más tarde.`,
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                        .then(() => props.history.push('/'))
                        .catch(() => props.history.push('/'));
                }
            });
    };

    useEffect(() => {
        if (focus) {
            if (
                [
                    'nombres',
                    'apellidos',
                    'tipo_documento',
                    'documento',
                    'tipo',
                    'celular',
                    'email',
                    'password',
                    'repassword',
                ].includes(focus)
            )
                changeStep(1);
            else changeStep(2);
        }
    }, [focus, errors]);

    useEffect(() => {
        setEnabled(true);
    }, []);

    return (
        <div className='stepFormContainer'>
            <form>
                <div className='row-form center'>
                    <div className='foto-container'>
                        <div className='foto-profile'>
                            <img
                                src={
                                    values.foto
                                        ? values.foto
                                        : values.tipo === 'TOUR OPERADOR'
                                        ? profilePic['operator']
                                        : values.sexo === 'Hombre'
                                        ? profilePic['male']
                                        : values.sexo === 'Mujer'
                                        ? profilePic['female']
                                        : profilePic['noGender']
                                }
                                alt='Foto perfil'
                            />
                            <div className='foto-btn' onClick={() => setShowModal(true)}>
                                Subir foto
                            </div>
                        </div>
                        <span onClick={() => setShowModal(true)}>Cambiar foto de perfil</span>
                        <ModalSubirFoto
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            actualizarFoto={updatePic}
                            title='Foto de Perfil'
                            noUpload={true}
                        />
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form full-width'>
                        <p className='label-field'>Nombre:</p>
                        <p className='input-text-field disabled-field'>
                            {values.nombres} {values.apellidos}
                        </p>
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form'>
                        <p className='label-field'>Documento de identidad:</p>
                        <div className='selector-container'>
                            <p className='select-documents disabled-select'>{values.tipo_documento}</p>
                            <p className='input-field-partial disabled-field'>{values.documento}</p>
                        </div>
                    </div>
                    <div className='col-form'>
                        <p className='label-field'>Tipo:</p>
                        <p className='input-text-field disabled-field'>
                            {values.tipo === 'ANFITRION'
                                ? 'Anfitrión'
                                : values.tipo === 'GUIA'
                                ? 'Guía Certificado'
                                : 'Tour Operador'}
                        </p>
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form'>
                        <p className='label-field'>Celular:</p>
                        <PhoneInput
                            value={values.celular}
                            containerClass='select-telefono-container'
                            inputClass='input-text-field-telefono'
                            buttonClass='btn-field-telefono'
                            disabled
                        />
                    </div>
                    <div className='col-form'>
                        <p className='label-field'>Correo:</p>
                        <p className='input-text-field disabled-field'>{values.email}</p>
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form full-width'>
                        <p className='label-field'>Nombre comercial</p>
                        <p className='input-text-field disabled-field'>{values.nombre_anfitrion}</p>
                    </div>
                </div>
                {values.pais === 'PE' ? (
                    <div className='row-form'>
                        <div className='col-form w-40'>
                            <p className='label-field'>RUC:</p>
                            <p className='input-text-field disabled-field'>{values.ruc}</p>
                        </div>
                        <div className='col-form w-60'>
                            <p className='label-field'>Apell. y Nom. o Razón Social:</p>
                            <p className='input-text-field disabled-field'>{values.razon_social}</p>
                        </div>
                    </div>
                ) : null}
                {values.tipo !== 'TOUR OPERADOR' ? (
                    <div className='row-form'>
                        <div className='col-form w-40'>
                            <p className='label-field'>Sexo:</p>
                            <p className='input-text-field disabled-field'>{values.sexo}</p>
                        </div>
                        <div className='col-form w-60'>
                            <p className='label-field'>Dirección:</p>
                            <p className='input-text-field disabled-field'>{values.direccion}</p>
                        </div>
                    </div>
                ) : (
                    <div className='row-form'>
                        <div className='col-form'>
                            <p className='label-field'>Representante legal:</p>
                            <p className='input-text-field disabled-field'>{values.representante}</p>
                        </div>
                        <div className='col-form'>
                            <p className='label-field'>Dirección:</p>
                            <p className='input-text-field disabled-field'>{values.direccion}</p>
                        </div>
                    </div>
                )}
                <div className='row-form'>
                    <div className='col-form full-width'>
                        <p className='label-field'>Sede:</p>
                        <div className='row-form'>
                            <div className='col-form w-33'>
                                <p className='select-tipo disabled-select'>
                                    {values.pais ? Country.getCountryByCode(values.pais).name : 'Sin Pais'}
                                </p>
                            </div>
                            <div className='col-form w-33'>
                                <p className='select-tipo disabled-select'>
                                    {!values.departamento || values.departamento === 'Sin Estado'
                                        ? 'Sin Estado'
                                        : State.getStateByCodeAndCountry(values.departamento, values.pais).name}
                                </p>
                            </div>
                            <div className='col-form w-33'>
                                <p className='select-tipo disabled-select'>{values.ciudad || 'Sin Ciudad'}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-form center mt-3'>
                    <div className='col-form inline check-terminos-politicas'>
                        <input
                            className='check-terminos'
                            type='checkbox'
                            id='chkTerminos'
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                        />
                        <label htmlFor='chkTerminos'>
                            Acepto los{' '}
                            <a
                                className='chk-terminos'
                                href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FTERMINOS%20Y%20CONDICIONES%20Operadores%20turisticos.pdf?alt=media&token=e0a99d14-bd78-4658-898b-f9dd60ea55ba'
                                target='_blank'
                            >
                                términos
                            </a>{' '}
                            y{' '}
                            <a
                                className='chk-terminos'
                                href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FPOLITICAS%20DE%20PRIVACIDAD%20DE%20ITOOUR%20Y%20USO%20DE%20COOKIES.pdf?alt=media&token=299ffe10-5b6e-44b1-ae2b-91c9a43b490b'
                                target='_blank'
                            >
                                políticas
                            </a>
                        </label>
                    </div>
                </div>
                <div className='row-form center'>
                    <button
                        className='btn-sgte-resgistro'
                        onClick={handleSubmit(onRegister, false, ['documento', 'password', 'pais', 'tipo'])}
                        disabled={!acceptTerms}
                    >
                        FINALIZAR
                    </button>
                </div>
            </form>
        </div>
    );
}
