import React from 'react';
import './css/SharedHeader.css';
// import imgTours from "../../assets/tours.png";
import imgTours from '../../assets/Tours.jpg';

export default function SharedHeader({ imagen, titulo }) {
    let imagenes = {
        tours: imgTours,
    };
    let fondo = {
        backgroundImage: `url('${window.location.origin + imagenes[imagen]}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100vw 100%',
        height: 'calc(100vw / 1.78)',
    };

    return (
        <div className='sharedheader' style={fondo}>
            {/* <h1>{titulo}</h1> */}
        </div>
    );
}
