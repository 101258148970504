import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import fire from '../../config/Firebase';
import CustomizedBadges from '../Shared/Notificaciones';
import { perfil, dashboard, iconos } from '../../helpers/accountMenuConfig';

export default function NavCliente(props) {
    const [notificaciones, setNotificaciones] = useState([]);
    const [reload, setReload] = useState(false);

    let getNotificaciones = () => {
        let db = fire.firestore();
        if (props.uid) {
            db.collection('notificaciones')
                .where('uid', '==', props.uid)
                .orderBy('fechaNotificacion', 'desc')
                .get()
                .then((querySnapshot) => {
                    let ToursTmp = [];
                    querySnapshot.forEach((tour) => {
                        let objTour = {
                            id: tour.id,
                            uid: tour.data().uid,
                            idTour: tour.data().idTour,
                            tourNombre: tour.data().tourNombre,
                            tourNotificaciones: tour.data().tourNotificaciones,
                            tipoNotificacion: tour.data().tipoNotificacion,
                            fechaNotificacion: tour.data().fechaNotificacion,
                            visto: tour?.data()?.visto,
                        };
                        ToursTmp.push(objTour);
                    });
                    setNotificaciones(ToursTmp);
                })
                .catch((err) => {
                    console.log('error al obtener doc', err);
                });
        }
    };

    useEffect(() => {
        getNotificaciones();
    }, [reload]);
    return (
        <ul className='navbar-nav navbar-menu'>
            {props.permisos === 'cliente-anfitrion' || props.permisos === 'cliente-operador' ? (
                <li className='nav-item icon--notification align-self-center'>
                    <CustomizedBadges
                        notificacionesCount={notificaciones.filter((n) => !n.visto).length}
                        notificaciones={notificaciones}
                        runReload={() => setReload((prev) => !prev)}
                        className='notificaciones'
                    />
                </li>
            ) : null}
            <li className='nav-item align-self-center acc-mobile'>
                <NavLink className='nav-link' to={perfil[props.permisos].link}>
                    {perfil[props.permisos].nombre}
                </NavLink>
            </li>
            {props.permisos === 'cliente-operador' || props.permisos === 'cliente-anfitrion' ? (
                <>
                    <li className='nav-item align-self-center acc-mobile'>
                        <NavLink className='nav-link' to='/perfilcomercial'>
                            Datos Comerciales
                        </NavLink>
                    </li>
                    <li className='nav-item align-self-center acc-mobile'>
                        <NavLink className='nav-link' to='/reservaciones'>
                            Reservaciones
                        </NavLink>
                    </li>
                </>
            ) : null}
            <li className='nav-item align-self-center acc-mobile'>
                <NavLink className='nav-link' to={dashboard[props.permisos].link}>
                    {dashboard[props.permisos].nombre}
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/vertours'>
                    Tours
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/contacto'>
                    Contáctanos
                </NavLink>
            </li>
            {props.permisos !== 'cliente-operador' ? (
                <li className='nav-item align-self-center'>
                    <span
                        className='nav-link link-button'
                        onClick={() => {
                            props.cambiarModo();
                            //getNotificaciones();
                        }}
                    >
                        {props.permisos === 'cliente-usuario' ? 'Modo Guía' : 'Modo Turista'}
                    </span>
                </li>
            ) : null}
            <li className='nav-item align-self-center acc-menu'>
                <AccountMenu signOut={props.signOut} permisos={props.permisos} />
            </li>
            <li className='nav-item align-self-center acc-mobile mt-2'>
                <span onClick={() => props.signOut()}>
                    <i className={iconos[props.permisos].salir}></i>Salir
                </span>
            </li>
        </ul>
    );
}
