import React, { useState, useEffect } from 'react';
import fire from '../../config/Firebase';
import { Accordion, Card, Button, FormCheck } from 'react-bootstrap';
import Navbar from '../../components/Shared/Navbar';
import CrearTourPrimerPaso from './CrearTourPrimerPaso';
import InputMultipleImages from '../../components/Shared/InputMultipleImages';
import CrearTourSegPaso from './CrearTourSegPaso';
import Lista from './Lista';
import { v4 as uuidv4 } from 'uuid';
import TourService from '../../services/Tour';
import Swal from 'sweetalert2';
import { storage } from '../../config/Firebase';
import CrearTourTercerPaso from './CrearTourTercerPaso';
import { EnumStatusTours } from '../../helpers/enumTours';
import { getImageNameByUrl, replaceImages } from '../../Util/Imagen';
import Departamentos from '../../config/Departamentos.json';
import Provincias from '../../config/Provincias.json';
import Distritos from '../../config/Distritos.json';
import useForm from '../../hooks/useForm';
import ModalPreviewTour from './ModalPreviewTour';

let archivosImagenes = [];
//for que me maneja las tareas asincronas
const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

let cambiarNombre = (nombre) => {
    let randomid = uuidv4();
    let extension = nombre.split('.');
    let nuevonombre = `${randomid}.${extension[1]}`;
    return nuevonombre;
};

//script de subida de archivo unitaria, SOLO SUBE UN ARCHIVO
const handleUpload = (imagen, refStorage) => {
    return new Promise((resolve, reject) => {
        const tarea = refStorage.put(imagen);
        tarea.on(
            'state_changed',
            (snapshot) => {
                // const porcentaje =
                //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (err) => {
                //console.error("% error", err);
                reject(err);
            },
            () => {
                tarea.snapshot.ref
                    .getDownloadURL()
                    .then((urlImagen) => {
                        resolve(urlImagen);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        );
    });
};

let timestampToDate = (arrDisponibilidad) => {
    let arrDate = [];
    return new Promise((resolve, reject) => {
        let contador = 0;
        for (let i = 0; i < arrDisponibilidad.length; i++) {
            let seg = arrDisponibilidad[i].dia.seconds * 1000;
            arrDate.push({
                dia: new Date(seg),
                capacidad: arrDisponibilidad[i].capacidad,
                capacidadEstatica: arrDisponibilidad[i].capacidadEstatica,
            });
            contador++;
        }
        if (contador == arrDisponibilidad.length) {
            resolve(arrDate);
        }
    });
};

const iniState = {
    nombre: '',
    descripcion: '',
    youtube: '',
    imgBlob: [],
    precioAdulto: '0',
    precioNino: '0',
    departamentoPartida: '',
    provinciaPartida: '',
    ciudadPartida: '',
    direccionPartida: '',
    departamento: '',
    provincia: '',
    ciudad: '',
    direccion: '',
    dias: '0',
    noches: '0',
    horasTour: '0',
    minTour: '0',
    disponible: [],
    minimoCompra: '1',
    minimoAnticipacion: 1,
    'tipo-duracion': 'dias',
};

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'nombre': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s\?\.\,;:¡!¿]/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 10
                            ? 'El nombre del tour no puede tener menos de 10 caracteres'
                            : 'Sólo se permiten letras, espacios y !¡¿?,;:'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'descripcion': {
                validates.push({
                    name,
                    isValid: !!formState[name]?.length && formState[name].length >= 30,
                    message: !formState[name]?.length
                        ? 'El campo es requerido.'
                        : 'La descripción debe tener como mínimo 30 caracteres.',
                });
                break;
            }

            case 'youtube': {
                validates.push({
                    name,
                    isValid:
                        !formState[name] || /^(https:\/\/)*(www\.)*youtube\.com\/watch\?v=\w+/g.test(formState[name]),
                    message: 'No es una url a un video de youtube válida.',
                });
                break;
            }

            case 'imgBlob': {
                validates.push({
                    name,
                    isValid: formState[name].length > 3,
                    message: 'Se necesitan mínimo 4 imágenes.',
                });
                break;
            }

            case 'precioAdulto': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? !/^[0-9\.]+$/i.test(formState[name])
                            ? 'Sólo se permiten punto y números.'
                            : 'El precio no puede ser cero.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'precioNino': {
                validates.push({
                    name,
                    isValid:
                        !formState[name].length ||
                        !+formState[name] ||
                        /^[1-9]+[0-9]*(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'Sólo se permiten punto y números.',
                });
                break;
            }

            case 'departamentoPartida': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'provinciaPartida': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'ciudadPartida': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'direccionPartida': {
                validates.push({
                    name,
                    isValid: /^[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ\.\s]+$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'La dirección no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras, números, espacios y puntos.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'departamento': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'provincia': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'ciudad': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'direccion': {
                validates.push({
                    name,
                    isValid: /^[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ\.\s]+$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'La dirección no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras, números, espacios y puntos.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'dias': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'Los días no pueden ser menores a un día.',
                });
                break;
            }

            case 'noches': {
                validates.push({
                    name,
                    isValid: /^[0-9]+(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'No es una cantidad válida.',
                });
                break;
            }

            case 'horasTour': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'Las horas no pueden ser menores a 1.',
                });
                break;
            }

            case 'minTour': {
                validates.push({
                    name,
                    isValid: /^[0-9]+(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'El valor no puede ser negativo o nulo.',
                });
                break;
            }

            case 'minimoCompra': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*$/i.test(formState[name]),
                    message: 'El mínimo de compra no puede ser menor a 1.',
                });
                break;
            }

            case 'minimoAnticipacion': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*$/i.test(formState[name]),
                    message:
                        formState[name] && +formState[name] > 45
                            ? 'El tiempo de anticipación no debe exceder los 45 días.'
                            : 'El tiempo de anticipación no puede ser menor a 1.',
                });
                break;
            }

            case 'disponible': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Debe agregar al menos un turno.',
                });
                break;
            }
        }
    }

    return validates;
};

export default function EditarTour(props) {
    const [state, setState] = useState({
        id: '',
        uid: '',
        nombre: '',
        descripcion: '',
        precio: '0',
        precioAdulto: '0',
        precioNino: '0',
        moneda: 'PEN',
        fechaini: new Date(),
        //fechafin: new Date(),
        listaIncluye: [],
        listaNoIncluye: [],
        listaActividades: [],
        listaNotas: [],
        listaTerminos: [],
        departamento: '',
        provincia: '',
        ciudad: '',
        direccion: '',
        departamentoPartida: '',
        provinciaPartida: '',
        ciudadPartida: '',
        direccionPartida: '',
        dias: '0',
        noches: '0',
        horasTour: '0',
        minTour: '0',
        partida: '',
        lat: 0,
        lng: 0,
        diario: false,
        activado: false,
        miniatura: 0,
        disponibilidad: 0,
        disponible: [],
        emailcli: '',
        youtube: '',
        fullDay: false,
        minimoCompra: '1',
        minimoAnticipacion: '1',
        tipoAnticipacion: 'dias',
        diasLaborales: [],
        restricciones: false,
    });

    const [imgBlob, setImgBlob] = useState([]);
    const [loading, setLoading] = useState(true);

    const { formState, setFormState, focus, errors, setErrors, handleChange, handleBlur, handleSubmit } = useForm(
        iniState,
        validateForm
    );

    const setDiasLaborales = (dias) => {
        setState({
            ...state,
            diasLaborales: [...dias],
        });
    };

    const activarRestricciones = (value) => {
        setState({
            ...state,
            restricciones: value,
        });
    };

    const resetRestricciones = () => {
        setState({
            ...state,
            tipoAnticipacion: 'dias',
            minimoAnticipacion: '1',
            minimoCompra: '1',
        });
        handleChange({ target: { name: 'minimoAnticipacion', value: '1' } });
        handleChange({ target: { name: 'minimoCompra', value: '1' } });
    };

    let getTour = (tourId) => {
        let db = fire.firestore();
        let ref = db.collection(`tours`).doc(tourId);
        ref.get().then((doc) => {
            if (doc.exists) {
                timestampToDate(doc.data().tour_disponibilidad).then((fechas) => {
                    const departamento = doc.data().tour_departamento
                        ? Departamentos.find((d) => d.nombre_ubigeo === doc.data().tour_departamento).id_ubigeo
                        : '';
                    const provincia =
                        departamento && doc.data().tour_provincia
                            ? Provincias[departamento].find((p) => p.nombre_ubigeo === doc.data().tour_provincia)
                                  .id_ubigeo
                            : '';
                    const ciudad =
                        departamento && provincia && doc.data().tour_ciudad
                            ? Distritos[provincia].find((c) => c.nombre_ubigeo === doc.data().tour_ciudad).id_ubigeo
                            : '';
                    const departamentoPartida = doc.data().tour_departamentopartida
                        ? Departamentos.find((d) => d.nombre_ubigeo === doc.data().tour_departamentopartida).id_ubigeo
                        : '';
                    const provinciaPartida =
                        departamentoPartida && doc.data().tour_provinciapartida
                            ? Provincias[departamentoPartida].find(
                                  (p) => p.nombre_ubigeo === doc.data().tour_provinciapartida
                              ).id_ubigeo
                            : '';
                    const ciudadPartida =
                        departamentoPartida && provinciaPartida && doc.data().tour_ciudadpartida
                            ? Distritos[provinciaPartida].find((c) => c.nombre_ubigeo === doc.data().tour_ciudadpartida)
                                  .id_ubigeo
                            : '';

                    setState({
                        ...state,
                        id: tourId,
                        uid: doc.data().uid,
                        nombre: doc.data().tour_nom,
                        descripcion: doc.data().tour_desc,
                        precioAdulto: doc.data().tour_precioAdulto,
                        precioNino: doc.data().tour_precioNino,
                        moneda: doc.data().tour_moneda,
                        fechaini: new Date(doc.data().tour_fecha.seconds * 1000),
                        //fechafin: new Date(doc.data().tour_fechafin.seconds * 1000),
                        listaIncluye: [...doc.data().tour_inc],
                        listaNoIncluye: [...doc.data().tour_noinc],
                        listaActividades: [...doc.data().tour_activ],
                        listaNotas: [...doc.data().tour_notas],
                        listaTerminos: [...doc.data().tour_terminos],
                        departamento: departamento,
                        provincia: provincia,
                        ciudad: ciudad,
                        direccion: doc.data().tour_direccion,
                        departamentoPartida: departamentoPartida,
                        provinciaPartida: provinciaPartida,
                        ciudadPartida: ciudadPartida,
                        direccionPartida: doc.data().tour_direccionpartida,
                        imagenes: doc.data().tour_imagenes,
                        dias: doc.data().tour_dias,
                        noches: doc.data().tour_noches,
                        horas: doc.data().horasTour,
                        minutos: doc.data().minTour,
                        horasTour: doc.data().horasTour,
                        minTour: doc.data().minTour,
                        partida: doc.data().tour_partida,
                        lat: doc.data().lat,
                        lng: doc.data().lng,
                        activado: true,
                        miniatura: doc.data().tour_miniatura,
                        disponible: fechas,
                        youtube: doc.data().tour_youtube,
                        status: doc.data().status,
                        fullDay: doc.data().fullDay,
                        minimoCompra: doc.data().tour_minimocompra,
                        minimoAnticipacion: doc.data().tour_minimoanticipacion,
                        tipoAnticipacion: doc.data().tour_tipoanticipacion,
                        diasLaborales: doc.data()?.tour_diaslaborales || [],
                        restricciones: !!doc.data()?.tour_restricciones,
                    });

                    const datos = {
                        nombre: doc.data().tour_nom,
                        descripcion: doc.data().tour_desc,
                        youtube: doc.data().tour_youtube,
                        imgBlob: doc.data().tour_imagenes,
                        precioAdulto: doc.data().tour_precioAdulto,
                        precioNino: doc.data().tour_precioNino,
                        departamentoPartida: departamentoPartida,
                        provinciaPartida: provinciaPartida,
                        ciudadPartida: ciudadPartida,
                        direccionPartida: doc.data().tour_direccionpartida,
                        departamento: departamento,
                        provincia: provincia,
                        ciudad: ciudad,
                        direccion: doc.data().tour_direccion,
                        disponible: fechas,
                        minimoCompra: doc.data().tour_minimocompra,
                        minimoAnticipacion: doc.data().tour_minimoanticipacion,
                        tipoAnticipacion: doc.data().tour_tipoanticipacion,
                        listaIncluye: [...doc.data().tour_inc],
                        listaActividades: [...doc.data().tour_activ],
                        listaTerminos: [...doc.data().tour_terminos],
                        'tipo-duracion':
                            (+doc.data()?.horasTour || 0) + (+doc.data()?.minTour || 0) > 0 ? 'horas' : 'dias',
                    };

                    if ((+doc.data()?.horasTour || 0) + (+doc.data()?.minTour || 0) > 0) {
                        datos.horasTour = doc.data().horasTour;
                        datos.minTour = doc.data().minTour;
                    } else {
                        datos.dias = doc.data().tour_dias;
                        datos.noches = doc.data().tour_noches;
                    }

                    setFormState(datos);
                });
            }
        });
    };

    const [emptyTime, setEmptyTime] = useState(true);
    const [fieldsChanged, setFieldsChanged] = useState({
        listaActividades: false,
        listaIncluye: false,
        listaTerminos: false,
    });

    useEffect(() => {
        let id = props.match.params.id;
        getTour(id);
    }, []);

    useEffect(() => {
        if (focus) {
            const element = document.getElementsByName(focus)[0];

            element && element.focus();
        }
    }, [focus]);

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const actualizarImagenes = (arrImagenes) => {
        archivosImagenes = arrImagenes;
    };

    const seleccionarMiniatura = (index) => {
        setState({
            ...state,
            miniatura: index,
        });
    };

    const actualizarFecha = (date, opcion) => {
        let nuevaFecha = new Date(date);
        setState({
            ...state,
            [opcion]: nuevaFecha,
        });
    };

    const actualizarCoords = (lat, lng) => {
        setState({
            ...state,
            lat: lat,
            lng: lng,
        });
    };

    const anadirOpciones = (item, opcion) => {
        setState({
            ...state,
            [opcion]: [...state[opcion], item],
        });
        handleChange({
            target: {
                name: opcion,
                value: [...state[opcion], item],
            },
        });
    };

    const eliminarOpcion = (index, opcion) => {
        let arrOpciones = [...state[opcion]];
        arrOpciones.splice(index, 1);
        setState({
            ...state,
            [opcion]: [...arrOpciones],
        });
        handleChange({
            target: {
                name: opcion,
                value: [...arrOpciones],
            },
        });
    };

    const anadirDisponibilidad = (turno) => {
        let turnosAAnadir = [...turno];
        let groupTurnos = [...state.disponible, ...turnosAAnadir];
        let orderTurnos = groupTurnos.slice().sort((a, b) => a.dia - b.dia);
        console.log('ordenado', orderTurnos);
        setState({
            ...state,
            disponible: [...orderTurnos],
        });
        handleChange({
            target: {
                name: 'disponible',
                value: [...orderTurnos],
            },
        });
    };

    const eliminarDisponibilidad = (index) => {
        let arrDisponible = [...state.disponible];
        arrDisponible.splice(index, 1);
        setState({
            ...state,
            disponible: [...arrDisponible],
        });
        handleChange({
            target: {
                name: 'disponible',
                value: [...arrDisponible],
            },
        });
    };

    const limpiarTurnos = (e) => {
        e.preventDefault();
        setState({
            ...state,
            disponible: [],
        });
        handleChange({
            target: {
                name: 'disponible',
                value: [],
            },
        });
    };

    let editarTour = async () => {
        Swal.fire({
            icon: 'info',
            title: `Espere por favor`,
            showConfirmButton: false,
        });

        let replacePromise = Promise.resolve([...state.imagenes]);

        if (imgBlob.length > 0) {
            replacePromise = Promise.resolve(
                replaceImages(
                    imgBlob.map((image) => {
                        return {
                            extension: image.name.split('.')[1],
                            data: image.blob.split(',')[1],
                            folder: 'imgsTours',
                        };
                    }),
                    state.imagenes.map((urls) => {
                        return `/imgsTours/${getImageNameByUrl(urls)}`;
                    })
                )
            );
        }

        replacePromise
            .then((newurls) => {
                const sTour = new TourService();
                let tour = {
                    ...state,
                    horasTour: formState?.horasTour || 0,
                    minTour: formState?.minTour || 0,
                    dias: formState?.dias || 0,
                    noches: formState?.noches || 0,
                    departamento: state.departamento
                        ? Departamentos.find((d) => d.id_ubigeo === state.departamento).nombre_ubigeo
                        : '',
                    provincia:
                        state.departamento && state.provincia
                            ? Provincias[state.departamento].find((p) => p.id_ubigeo === state.provincia).nombre_ubigeo
                            : '',
                    ciudad:
                        state.departamento && state.provincia && state.ciudad
                            ? Distritos[state.provincia].find((c) => c.id_ubigeo === state.ciudad).nombre_ubigeo
                            : '',
                    departamentoPartida: state.departamentoPartida
                        ? Departamentos.find((d) => d.id_ubigeo === state.departamentoPartida).nombre_ubigeo
                        : '',
                    provinciaPartida:
                        state.departamentoPartida && state.provinciaPartida
                            ? Provincias[state.departamentoPartida].find((p) => p.id_ubigeo === state.provinciaPartida)
                                  .nombre_ubigeo
                            : '',
                    ciudadPartida:
                        state.departamentoPartida && state.provinciaPartida && state.ciudadPartida
                            ? Distritos[state.provinciaPartida].find((c) => c.id_ubigeo === state.ciudadPartida)
                                  .nombre_ubigeo
                            : '',
                };

                return sTour.editarTour(tour, newurls, props.admin);
            })
            .then(() => {
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: `El Tour ha sido editado exitosamente`,
                    showConfirmButton: true,
                    confirmButtonText: 'Regresar al Dashboard',
                }).then(() => {
                    props.history.push(props.admin ? '/dashboardadmin' : '/dashboard');
                });
            })
            .catch((err) => {
                console.log(err);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: `Ha ocurrido un error`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
        /*let urls = [];
        if (imgBlob.length > 0) {
            await asyncForEach(archivosImagenes, async (imagen) => {
                let nuevonombre = cambiarNombre(imagen.name);
                const refStorage = storage.ref(`imgsTours/${nuevonombre}`);
                const urlImg = await handleUpload(imagen, refStorage);
                urls.push(urlImg);
            });
        }
        {
            urls = [...state.imagenes];
        }*/
        /*if (imgBlob.length > 0) {
            Promise.all(
                archivosImagenes.map((imagen) => {
                    const nombre = cambiarNombre(imagen.name);
                    const refStorage = storage.ref(`imgsTours/${nombre}`);

                    return handleUpload(imagen, refStorage);
                })
            )
                .then((urls) => {
                    const sTour = new TourService();
                    const prevUrl = [...state.imagenes];
                    //let tour = state;
                    //return sTour.editarTour(tour, urls);
                    sTour.editarTour(state, urls);
                    return prevUrl;
                })
                .then((prevUrl) => {
                    console.log(prevUrl);
                    if (prevUrl.length > 0) {
                        return Promise.all(
                            prevUrl.map((url) => {
                                const name = getImageNameByUrl(url);
                                console.log(name);
                                return deleteImage("imgsTours/" + name);
                            })
                        );
                    }

                    return Promise.resolve(true);
                })
                .then(() => {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: `El Tour ha sido editado exitosamente`,
                        showConfirmButton: true,
                        confirmButtonText: "Regresar al Dashboard",
                    }).then(() => {
                        props.history.push("/dashboard");
                    });
                })
                .catch((err) => {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: `Ha ocurrido un error`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });

            /*let sTour = new TourService();
        let tour = state;
        sTour
            .editarTour(tour, urls)
            .then(() => {
                Swal.close();
                Swal.fire({
                    icon: "success",
                    title: `El Tour ha sido editado exitosamente`,
                    showConfirmButton: true,
                    confirmButtonText: "Regresar al Dashboard",
                }).then(() => {
                    props.history.push("/dashboard");
                });
            })
            .catch((err) => {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: `Ha ocurrido un error`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });

        } else {
            const sTour = new TourService();

            sTour
                .editarTour(state, state.imagenes)
                .then(() => {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: `El Tour ha sido editado exitosamente`,
                        showConfirmButton: true,
                        confirmButtonText: "Regresar al Dashboard",
                    }).then(() => {
                        props.history.push("/dashboard");
                    });
                })
                .catch((err) => {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: `Ha ocurrido un error`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }*/
    };

    let cancelar = () => {
        Swal.fire({
            title: 'Desea cancelar la edición?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, cancelar',
        }).then((result) => {
            if (result.value) {
                // return <Redirect to="/dashboard"/>
                props.history.push('/dashboard');
            }
        });
    };

    const setDepartamento = (e) => {
        setState({
            ...state,
            departamento: e.target.value,
            provincia: '',
            ciudad: '',
        });
    };

    const setProvincia = (e) => {
        setState({
            ...state,
            provincia: e.target.value,
            ciudad: '',
        });
    };

    const setCiudad = (e) => {
        setState({
            ...state,
            ciudad: e.target.value,
        });
    };

    const setDepartamentoPartida = (e) => {
        setState({
            ...state,
            departamentoPartida: e.target.value,
            provinciaPartida: '',
            ciudadPartida: '',
        });
    };

    const setProvinciaPartida = (e) => {
        setState({
            ...state,
            provinciaPartida: e.target.value,
            ciudadPartida: '',
        });
    };

    const setCiudadPartida = (e) => {
        setState({
            ...state,
            ciudadPartida: e.target.value,
        });
    };

    const existEmptyRequiredFields = (fields) => {
        return fields.some((key) => {
            if (Array.isArray(formState[key])) return !formState[key].length;
            else if (/^[0-9\.]+$/i.test(formState[key])) return +formState[key] <= 0;
            else return !formState[key];
        });
    };

    const selectFields = (step) => {
        switch (step) {
            case -1:
                return [
                    'nombre',
                    'descripcion',
                    'imgBlob',
                    'precioAdulto',
                    'departamentoPartida',
                    'provinciaPartida',
                    'ciudadPartida',
                    'direccionPartida',
                    'departamento',
                    'provincia',
                    'ciudad',
                    'direccion',
                    'disponible',
                    'listaIncluye',
                    'listaActividades',
                    'listaTerminos',
                ];
            case 0:
                return ['nombre', 'descripcion', 'imgBlob'];
            case 1:
                return [
                    'precioAdulto',
                    'departamentoPartida',
                    'provinciaPartida',
                    'ciudadPartida',
                    'direccionPartida',
                    'departamento',
                    'provincia',
                    'ciudad',
                    'direccion',
                ];
            case 2:
                return ['disponible'];
            case 3:
                return ['listaIncluye', 'listaActividades', 'listaTerminos'];
            default:
                return [];
        }
    };

    const setDias = (value) => {
        setState({
            ...state,
            dias: value,
            noches: `${+value > 0 ? +value - 1 : 0}`,
        });
    };

    const resetDiasHoras = (tipoActual) => {
        if (tipoActual === 'dias') setState({ ...state, minTour: '0', horasTour: '0' });
        else setState({ ...state, diasTour: '0', nochesTour: '0' });
    };

    return (
        <div>
            <div className='container mt-4'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='text-itoour'>Editar Tour</h1>
                    <div>
                        <ModalPreviewTour
                            tour={{
                                ...state,
                                tour_nom: state.nombre,
                                tour_departamento: Departamentos.find((d) => d.id_ubigeo === state.departamento)
                                    ?.nombre_ubigeo,
                                tour_moneda: state.moneda,
                                tour_precioAdulto: state.precioAdulto,
                                tour_precioNino: state.precioNino,
                                tour_imagenes:
                                    Array.isArray(imgBlob) && imgBlob.length
                                        ? imgBlob.map((image) => image.blob)
                                        : state.imagenes,
                                tour_desc: state.descripcion,
                                tour_youtube: state.youtube,
                                tour_activ: state.listaActividades,
                                tour_inc: state.listaIncluye,
                                tour_noinc: state.listaNoIncluye,
                                tour_notas: state.listaNotas,
                                tour_terminos: state.listaTerminos,
                            }}
                            tipo={props.rol}
                        />
                        <button
                            className='btn btn-danger'
                            onClick={() => {
                                cancelar();
                            }}
                            style={{ color: 'white', height: '46px' }}
                        >
                            <i className='fas fa-times-circle mr-1'></i>Cancelar
                        </button>
                    </div>
                </div>
                <Accordion defaultActiveKey='0'>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                                Información General
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                <div>
                                    <CrearTourPrimerPaso
                                        value={state}
                                        actualizarInput={actualizarInput}
                                        formState={formState}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>
                                <InputMultipleImages
                                    actualizarImagenes={actualizarImagenes}
                                    seleccionarMiniatura={seleccionarMiniatura}
                                    imgBlob={imgBlob}
                                    setImgBlob={setImgBlob}
                                    imagenes={state.imagenes}
                                    miniatura={state.miniatura}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errors={errors}
                                />
                                <small className='text-secondary'>
                                    En caso de seleccionar nuevas imágenes se reemplazarán las anteriores. Seleccione
                                    hasta 10 imágenes en formato JPG/PNG de 800x600 px, después elija la imagen deseada
                                    para la miniatura
                                </small>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant='link' eventKey='1'>
                                Precio, Destino y Duración
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey='1'>
                            <Card.Body>
                                <CrearTourSegPaso
                                    value={state}
                                    actualizarInput={actualizarInput}
                                    //actualizarFecha={actualizarFecha}
                                    actualizarCoords={actualizarCoords}
                                    style={{ marginBottom: '400px !important' }}
                                    marker={{ lat: state.lat, lng: state.lng }}
                                    actualizarLugares={{
                                        setDepartamentoPartida,
                                        setProvinciaPartida,
                                        setCiudadPartida,
                                        setDepartamento,
                                        setProvincia,
                                        setCiudad,
                                    }}
                                    formState={formState}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                                {/* {console.log("editsegpaso",state.lat,state.lng)} */}
                                <small className='text-secondary'>
                                    Solo se cambiará el marcador anterior en caso indique uno nuevo en el mapa
                                </small>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant='link' eventKey='2'>
                                Disponibilidad
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey='2'>
                            <Card.Body>
                                <CrearTourTercerPaso
                                    value={state}
                                    actualizarFecha={actualizarFecha}
                                    anadirDisponibilidad={anadirDisponibilidad}
                                    eliminarDisponibilidad={eliminarDisponibilidad}
                                    actualizarInput={actualizarInput}
                                    disponible={state.disponible}
                                    actualizarFullDay={(value) => {
                                        setState({
                                            ...state,
                                            fullDay: value,
                                        });
                                    }}
                                    formState={formState}
                                    setFormState={setFormState}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setErrors={setErrors}
                                    setEmptyTime={setEmptyTime}
                                    setDias={setDias}
                                    resetDiasHoras={resetDiasHoras}
                                    setDiasLaborales={setDiasLaborales}
                                    activarRestricciones={activarRestricciones}
                                    resetRestricciones={resetRestricciones}
                                    limpiarTurnos={limpiarTurnos}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant='link' eventKey='3'>
                                Opciones y añadidos
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey='3'>
                            <Card.Body>
                                <div className='form-group'>
                                    <label>Actividades a realizar</label>
                                    <Lista
                                        place='Ingrese cada actividad a realizar'
                                        lista={state.listaActividades}
                                        anadir={(item) => anadirOpciones(item, 'listaActividades')}
                                        estilo='info'
                                        eliminar={(index) => eliminarOpcion(index, 'listaActividades')}
                                        nombre='listaActividades'
                                        setFieldsChanged={setFieldsChanged}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                    {fieldsChanged.listaActividades && !state.listaActividades?.length && (
                                        <small className='text-danger'>Debe agregar com mínimo una actividad</small>
                                    )}
                                </div>
                                <div className='form-group'>
                                    <label>Incluye</label>
                                    <Lista
                                        place='Ingrese que Incluye el Tour'
                                        lista={state.listaIncluye}
                                        anadir={(item) => anadirOpciones(item, 'listaIncluye')}
                                        estilo='primary'
                                        eliminar={(index) => eliminarOpcion(index, 'listaIncluye')}
                                        nombre='listaIncluye'
                                        setFieldsChanged={setFieldsChanged}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                    {fieldsChanged.listaIncluye && !state.listaIncluye?.length && (
                                        <small className='text-danger'>Debe agregar com mínimo un elemento.</small>
                                    )}
                                </div>
                                <div className='form-group'>
                                    <label>No Incluye</label>
                                    <Lista
                                        place='Ingrese que No Incluye el Tour'
                                        lista={state.listaNoIncluye}
                                        anadir={(item) => anadirOpciones(item, 'listaNoIncluye')}
                                        estilo='danger'
                                        eliminar={(index) => eliminarOpcion(index, 'listaNoIncluye')}
                                        nombre='listaNoIncluye'
                                        setFieldsChanged={setFieldsChanged}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                </div>
                                <div className='form-group mt-3'>
                                    <label>Notas a considerar</label>
                                    <Lista
                                        place='Ingrese Notas (información importante) acerca del Tour'
                                        lista={state.listaNotas}
                                        anadir={(item) => anadirOpciones(item, 'listaNotas')}
                                        estilo='warning'
                                        eliminar={(index) => eliminarOpcion(index, 'listaNotas')}
                                        nombre='listaNotas'
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label>Términos y Condiciones</label>
                                    <Lista
                                        place='Ingrese las clausulas a considerar acerca del tour'
                                        lista={state.listaTerminos}
                                        anadir={(item) => anadirOpciones(item, 'listaTerminos')}
                                        estilo='warning'
                                        eliminar={(index) => eliminarOpcion(index, 'listaTerminos')}
                                        nombre='listaTerminos'
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                    {fieldsChanged.listaTerminos && !state.listaTerminos?.length && (
                                        <small className='text-danger'>
                                            Los términos y condiciones son requeridos.
                                        </small>
                                    )}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <button
                    className='btn btn-itoour btn-lg btn-block mt-5'
                    onClick={handleSubmit(editarTour)}
                    disabled={existEmptyRequiredFields(selectFields(-1)) || Object.keys(errors).length}
                >
                    Guardar Cambios
                </button>
                <div style={{ height: '2rem' }}></div>
            </div>
        </div>
    );
}
