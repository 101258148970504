import React, { useState, useEffect } from 'react';
import fire from '../../config/Firebase';
import LeerMasLeerMenos from '../Shared/LeerMasLeerMenos';
import Carousel from 'react-elastic-carousel';
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Skeleton } from '@material-ui/lab';
import './css/CarouselRecomendaciones.css';

export default function CarouselRecomendaciones({ tourId, precio, moneda, departamento, provincia, ciudad }) {
    let [tours, setTours] = useState([]);

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 992, itemsToShow: 3 },
        { width: 1200, itemsToShow: 3 },
    ];

    let getTour = () => {
        let db = fire.firestore();
        let date = new Date();

        date.setDate(date.getDate() + 365);

        db.collection('tours')
            .get()
            .then((querySnapshot) => {
                let ToursTmp = [];

                querySnapshot.forEach((tour) => {
                    if (tour.id !== tourId) {
                        let objTour = {
                            id: tour.id,
                            status: tour.data().status,
                            tour_nom: tour.data().tour_nom,
                            tour_activo: tour.data().tour_activo,
                            tour_precio: Math.ceil(+tour.data().tour_precioAdulto / 0.85),
                            tour_desc: tour.data().tour_desc,
                            tour_moneda: tour.data().tour_moneda,
                            tour_fecha: tour.data().tour_fechafin,
                            tour_ciudad: tour.data().tour_ciudad,
                            tour_provincia: tour.data().tour_provincia,
                            tour_departamento: tour.data().tour_departamento,
                            tour_imagen: tour.data().tour_imagenes[tour.data().tour_miniatura],
                            tour_duracion:
                                +(tour.data().horasTour ?? 0) + +(tour.data().minTour ?? 0) > 0
                                    ? `${tour.data().horasTour ?? 0} hora${
                                          +(tour.data().horasTour ?? 0) > 1 ? 's' : ''
                                      } ${+(tour.data().minTour ?? 0) !== 0 ? `y ${tour.data().minTour} minutos` : ''}`
                                    : `${tour.data().tour_dias} día${+tour.data().tour_dias > 1 ? 's' : ''} ${
                                          +tour.data().tour_noches !== 0
                                              ? `y ${tour.data().tour_noches} noche${
                                                    +tour.data().tour_noches > 1 ? 's' : ''
                                                }`
                                              : ''
                                      }`,
                            tour_fullDay: tour.data().fullDay,
                            tour_disponibilidad: tour.data().tour_disponibilidad || [],
                            tour_minimoanticipacion: tour.data().tour_minimoanticipacion,
                            tour_tipoanticipacion: tour.data().tour_tipoanticipacion,
                        };

                        ToursTmp.push(objTour);
                    }
                });

                let toursFiltrados = [];

                const today = new Date();
                today.setHours(0, 0, 0, 0);

                ToursTmp = ToursTmp.filter((t) => {
                    //const mult = t.tour_tipoanticipacion === "mes" ? 30 : 1;
                    const disponibilidad = t.tour_disponibilidad
                        .map((turno) => {
                            return {
                                dia: new Date(turno.dia.seconds * 1000),
                                capacidad: +turno.capacidad,
                            };
                        })
                        .filter((turno) => {
                            const date = new Date(
                                today.getTime() +
                                    +t.tour_minimoanticipacion *
                                        (t.tour_tipoanticipacion === 'dias' ? 1 : 30) *
                                        24 *
                                        60 *
                                        60 *
                                        1000
                            );

                            return turno.dia.getTime() > date.getTime() && turno.capacidad > 0;
                        });

                    return !!disponibilidad.length;
                });

                ToursTmp = ToursTmp.filter((tour) => tour.status === 'Publicado');

                if (departamento && provincia && ciudad && precio && moneda) {
                    const toursTmp = ToursTmp.filter(
                        (tour) =>
                            tour.tour_departamento === departamento &&
                            tour.tour_provincia === provincia &&
                            tour.tour_ciudad === ciudad &&
                            tour.tour_moneda === moneda &&
                            +tour.tour_precio <= +precio
                    );

                    toursFiltrados = toursFiltrados.concat(toursTmp.slice(0, 6));
                }

                if (toursFiltrados.length < 6) {
                    const toursRestantes = 6 - toursFiltrados.length;

                    const toursTmp = ToursTmp.filter(
                        (tour) =>
                            tour.tour_departamento === departamento &&
                            tour.tour_provincia === provincia &&
                            tour.tour_ciudad === ciudad &&
                            tour.tour_moneda === moneda &&
                            +tour.tour_precio > +precio
                    );

                    toursFiltrados = toursFiltrados.concat(toursTmp.slice(0, toursRestantes));
                }

                if (toursFiltrados.length < 6) {
                    const toursRestantes = 6 - toursFiltrados.length;

                    const toursTmp = ToursTmp.filter(
                        (tour) =>
                            tour.tour_departamento === departamento &&
                            tour.tour_provincia === provincia &&
                            tour.tour_ciudad === ciudad &&
                            tour.tour_moneda !== moneda
                    );

                    toursFiltrados = toursFiltrados.concat(toursTmp.slice(0, toursRestantes));
                }

                if (toursFiltrados.length < 6) {
                    const toursRestantes = 6 - toursFiltrados.length;

                    const toursTmp = ToursTmp.filter(
                        (tour) =>
                            tour.tour_departamento === departamento &&
                            tour.tour_provincia === provincia &&
                            tour.tour_ciudad !== ciudad
                    );

                    toursFiltrados = toursFiltrados.concat(toursTmp.slice(0, toursRestantes));
                }

                if (toursFiltrados.length < 6) {
                    const toursRestantes = 6 - toursFiltrados.length;

                    const toursTmp = ToursTmp.filter(
                        (tour) => tour.tour_departamento === departamento && tour.tour_provincia !== provincia
                    );

                    toursFiltrados = toursFiltrados.concat(toursTmp.slice(0, toursRestantes));
                }

                if (toursFiltrados.length < 6) {
                    const toursRestantes = 6 - toursFiltrados.length;

                    const toursTmp = ToursTmp.filter((tour) => tour.tour_departamento !== departamento);

                    toursFiltrados = toursFiltrados.concat(toursTmp.slice(0, toursRestantes));
                }

                setTours(toursFiltrados);
            });
    };

    useEffect(() => {
        getTour();
    }, []);

    const onClickLink = (e, to) => {
        e.preventDefault();
        window.location.replace(to);
    };

    const Link = ({ children, className, to }) => {
        return (
            <div className={`link-style-carrousel ${className}`} onClick={(e) => onClickLink(e, to)}>
                {children}
            </div>
        );
    };

    return (
        <div className='container'>
            <div className='titulo'>
                <h2>También te puede interesar</h2>
            </div>
            <Carousel breakPoints={breakPoints} pagination={false} className='carousel-lugares mt-4'>
                {tours.length === 0
                    ? Array.from({ length: 3 }).map((_, i) => {
                          return (
                              <div className='card-tour' key={i}>
                                  <Skeleton
                                      animation='wave'
                                      variant='rect'
                                      height={222}
                                      width={296}
                                      className='image-tour'
                                  />
                                  <Skeleton
                                      variant='rounded'
                                      className='precio-tour'
                                      style={{
                                          backgroundColor: 'gray',
                                          borderColor: 'gray',
                                      }}
                                  />
                              </div>
                          );
                      })
                    : tours.map((t, i) => (
                          <div className='card-tour' key={i}>
                              <Link to={`/vertour/${t.id}`}>
                                  <img src={t.tour_imagen} alt='prueba' className='image-tour'></img>
                                  <div className='sombra-tarjeta'></div>
                                  <div className='detalles-tour'>
                                      <div className='tipo-tour'>{t.tour_fullDay ? 'Full day' : t.tour_duracion}</div>
                                      <div className='titulo-tour'>{t.tour_nom}</div>
                                      <div className='region-tour'>
                                          <i className='fa fa-map-marked-alt'></i> {t.tour_departamento}
                                      </div>
                                  </div>
                                  <div className='precio-tour'>
                                      {t.tour_moneda === 'PEN' ? 'S/.' : '$'} {t.tour_precio}
                                  </div>
                              </Link>
                          </div>
                      ))}
            </Carousel>
        </div>
    );
}
