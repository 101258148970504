import React, { Component } from 'react';
import './AboutUs.css';
import Logo from '../assets/logo.png';
import Footer from '../components/Shared/Footer';
import { Collapse } from 'react-bootstrap';
import PreguntasFrecuentes from './Usuario/PreguntasFrecuentes';
import WhatsAppButton from '../components/Shared/WhatsAppButton';

let info = [
    'Somos un equipo de profesionales dedicados a maximizar la experiencia de un tour a través de nuestros socios estratégicos, brindando soporte al turista así como a su anfitrión.',
    'Dinamizar el turismo mundial, creando experiencias únicas e innovadoras para los invitados a través de nuestros socios estratégicos.',
    'Convertirnos para el año 2025, en la mejor opción en servicio turístico en el mundo.'
];
export class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.quien = true;
        this.mision = true;
        this.vision = true;
        this.state = {
            numTexto: 0,
            open: false
        };

        // this.quien = document.getElementById("btnQuienes")
        // this.mision = document.getElementById("btnMision")
        // this.vision = document.getElementById("btnVision")
    }
    ocultarResto = () => {};
    render() {
        return (
            <div>
                <WhatsAppButton />
                <div className="headernosotros">
                    <div className="container">
                        <img className="logo_img img-fluid" src={Logo} alt="logo"></img>
                        <span className="descubre p-5 m-5 d-flex justify-content-center">Nosotros</span>
                        <br />
                    </div>
                </div>

                <section className="info container p-5">
                    <div className="row">
                        <div className="info__img col-md-6 pt-1 pb-3">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="info__text col-md-6 pt-4 pb-3">
                            <p>
                                <a
                                    onClick={() => this.setState({ numTexto: 0 })}
                                    className="btn btn-primary btn-md"
                                    data-toggle="collapse"
                                    autoFocus
                                >
                                    Quiénes Somos
                                </a>
                                <a
                                    className="btn btn-primary btn-md"
                                    data-toggle="collapse"
                                    id="btnMision"
                                    href="#mision"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    onClick={() => this.setState({ numTexto: 1 })}
                                >
                                    Misión
                                </a>
                                <a
                                    className="btn btn-primary btn-md"
                                    data-toggle="collapse"
                                    id="btnVision"
                                    href="#vision"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    onClick={() => this.setState({ numTexto: 2 })}
                                >
                                    Visión
                                </a>
                            </p>
                            <div className="collapse show" id="quienes">
                                <div className="card card-body">{info[this.state.numTexto]}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pregunta">
                    <div className="pregunta__container container p-5 pb-5">
                        <h1 className="pl-0 pb-5">Preguntas Frecuentes</h1>
                        <div className="pregunta__container--acordion accordion" id="accordion">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card mb-0">
                                        <PreguntasFrecuentes
                                            titulo="1. ¿Quién puede crear un tour?"
                                            texto="Podrá crear un tour cualquier persona que haya completado correctamente su registro como “Guía” en la plataforma “itoour.com”, habiendo llenado todos los campos establecidos."
                                        />
                                        <PreguntasFrecuentes
                                            titulo="2. ¿Cuántos tours puedo crear con mi perfil?"
                                            texto="No existe un límite establecido para la creación de tours, no obstante, se debe tener en consideración que cada uno de ellos deben encontrarse activos (en las fechas que establezca) y con toda la información correspondiente, con el fin de evitar posibles errores y confusiones."
                                        />
                                        <PreguntasFrecuentes
                                            titulo="3. ¿Cuántas categorías de proveedores turísticos puedo encontrar en la plataforma?"
                                            texto="Podrá encontrar las siguientes categorías: operador turístico, guía y anfitrión. Deberá elegir aquella opción que se adecue específicamente a su perfil como proveedor de servicio."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card mb-0">
                                        <PreguntasFrecuentes
                                            titulo="4. ¿Qué pasa si contraté un paquete turístico y mi proveedor no me responde?"
                                            texto="Podrá ponerse en contacto con nosotros a través de los siguientes canales de atención:"
                                        >
                                            <ul>
                                                <li>Teléfono o WhatsApp: (+51) 1 743 9064</li>
                                                <li>Correo: info@itoour.com</li>
                                            </ul>
                                        </PreguntasFrecuentes>
                                        <PreguntasFrecuentes
                                            titulo="5. ¿Con cuántos días de anticipación debo solicitar la cancelación del servicio? ¿Cuánto se me devuelve?"
                                            texto="La cancelación podrá ser realizada, como máximo, con 48 horas de anticipación antes del inicio del tour, a menos que el operador, guía o anfitrión turístico indique expresamente en los términos y condiciones de su anuncio otra especificación sobre ello."
                                        >
                                            <p>
                                                El monto a devolver será tal cual el abonado, exceptuando comisiones por
                                                transacción financiera y gastos administrativos propios de la
                                                plataforma, el cual no excederá del 4%.
                                            </p>
                                        </PreguntasFrecuentes>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default AboutUs;
