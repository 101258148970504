import React, { useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Departamento from '../../config/Departamentos.json';
import Provincia from '../../config/Provincias.json';
import Distrito from '../../config/Distritos.json';
import './css/Search.css';
import { useEffect } from 'react';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const getIdDepartamento = (departamento) => {
    return departamento ? Departamento.find((dpt) => dpt.nombre_ubigeo === departamento)?.id_ubigeo || '' : '';
};

const getIdProvincia = (departamento, provincia) => {
    const idDepartamento = getIdDepartamento(departamento);

    return departamento && provincia
        ? Provincia[idDepartamento].find((d) => d.nombre_ubigeo === provincia)?.id_ubigeo || ''
        : '';
};

const getIdCiudad = (departamento, provincia, ciudad) => {
    const idProvincia = getIdProvincia(departamento, provincia);

    return departamento && provincia && ciudad
        ? Distrito[idProvincia].find((d) => d.nombre_ubigeo === ciudad)?.id_ubigeo || ''
        : '';
};

const getDepartamento = (departamento) => {
    return departamento
        ? Departamento.find((dpt) => dpt.nombre_ubigeo.toLowerCase() === departamento?.toLowerCase())?.nombre_ubigeo ||
              ''
        : '';
};

const getProvincia = (departamento, provincia) => {
    const idDepartamento = getIdDepartamento(departamento);

    return departamento && provincia
        ? Provincia[idDepartamento].find((d) => d.nombre_ubigeo.toLowerCase() === provincia?.toLowerCase())
              ?.nombre_ubigeo || ''
        : '';
};

const getCiudad = (departamento, provincia, ciudad) => {
    const idProvincia = getIdProvincia(departamento, provincia);

    return departamento && provincia && ciudad
        ? Distrito[idProvincia].find((d) => d.nombre_ubigeo.toLowerCase() === ciudad?.toLowerCase())?.nombre_ubigeo ||
              ''
        : '';
};

const getTipo = (tipo) => {
    if (tipo) {
        switch (tipo.toLowerCase()) {
            case 'anfitrión':
                return 'ANFITRION';
            case 'anfitrion':
                return 'ANFITRION';
            case 'guía':
                return 'GUIA';
            case 'guia':
                return 'GUIA';
            case 'operador':
                return 'TOUR OPERADOR';
            case 'tour%20operador':
                return 'TOUR OPERADOR';
            default:
                return '';
        }
    } else return '';
};

const getRango = ({ minimo, maximo }) => {
    if (!minimo && !maximo) return [20, 10000];
    else {
        let rangoP = [];

        if (minimo) rangoP = [+minimo];
        else rangoP = [1];

        if (maximo) rangoP.push(+maximo);
        else rangoP.push(20000);

        return rangoP;
    }
};

const getMoneda = (moneda) => {
    if (moneda) {
        switch (moneda.toLowerCase()) {
            case 'pen':
                return 'PEN';
            case 'usd':
                return 'USD';
            default:
                return 'PEN';
        }
    } else return 'PEN';
};

export default function Search({ busqueda, query }) {
    const classes = useStyles();
    const depa = query.get('departamento');
    const prov = query.get('provincia');
    const ciu = query.get('ciudad');
    const t = query.get('tipo');
    const pMin = query.get('pMin');
    const pMax = query.get('pMax');
    const m = query.get('m');

    let [fecha, setFecha] = useState(new Date(new Date().getTime() + 86400000));
    let [departamento, setDepartamento] = useState(getDepartamento(depa));
    let [provincia, setProvincia] = useState(getProvincia(depa, prov));
    let [ciudad, setCiudad] = useState(getCiudad(depa, prov, ciu));
    let [tipo, setTipo] = useState(getTipo(t));
    let [rangoPrecios, setRangoPrecios] = useState(getRango({ minimo: pMin, maximo: pMax }));
    let [moneda, setMoneda] = useState(getMoneda(m));
    let cambiarRangoPrecios = (event, newValue) => {
        setRangoPrecios(newValue);
    };

    let buscarTours = (e) => {
        e.preventDefault(e);
        let objBusqueda = { departamento, provincia, ciudad, tipo, fecha, rangoPrecios, moneda };
        busqueda(objBusqueda);
    };

    const onChangeDepartamento = (e) => {
        setDepartamento(e.target.value);
        setProvincia('');
        setCiudad('');
    };

    const onChangeProvincia = (e) => {
        setProvincia(e.target.value);
        setCiudad('');
    };

    const onChangeCiudad = (e) => {
        setCiudad(e.target.value);
    };

    const onChangeTipo = (e) => {
        setTipo(e.target.value);
    };

    useEffect(() => {
        setDepartamento(getDepartamento(depa));
        setProvincia(getProvincia(depa, prov));
        setCiudad(getCiudad(depa, prov, ciu));
        setTipo(getTipo(t));
        setRangoPrecios(getRango({ minimo: pMin, maximo: pMax }));
        setMoneda(getMoneda(m));
    }, []);

    return (
        <div className='container-filtro'>
            <h4>Filtre los resultados</h4>
            <form
                className='form-group'
                onSubmit={(e) => {
                    buscarTours(e);

                    window.fbq('track', 'Buscar', {
                        medio: 'Filtro Tours',
                        departamento: departamento || 'Todos',
                        provincia: provincia || 'Todos',
                        ciudad: ciudad || 'Todos',
                        tipo: tipo || 'Todos',
                        fecha: fecha.toLocaleString('en-GB', { hour12: true }),
                        moneda,
                        precio_minimo: rangoPrecios[0],
                        precio_maximo: rangoPrecios[1],
                    });
                }}
            >
                <div className='form-group select-field-search'>
                    <small>Departamento</small>
                    <select className='form-control' value={departamento} onChange={onChangeDepartamento}>
                        <option value='' defaultValue>
                            Todos
                        </option>
                        {Departamento.map((dep, i) => {
                            return (
                                <option key={i} value={dep.nombre_ubigeo}>
                                    {dep.nombre_ubigeo}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className='form-group'>
                    <small>Provincia</small>
                    <select className='form-control select-field-search' value={provincia} onChange={onChangeProvincia}>
                        <option value='' defaultValue>
                            Todos
                        </option>
                        {departamento
                            ? Provincia[getIdDepartamento(departamento)].map((dep, i) => {
                                  return (
                                      <option key={i} value={dep.nombre_ubigeo}>
                                          {dep.nombre_ubigeo}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
                <div className='form-group'>
                    <small>Ciudad</small>
                    <select className='form-control select-field-search' value={ciudad} onChange={onChangeCiudad}>
                        <option value='' defaultValue>
                            Todos
                        </option>
                        {departamento && provincia
                            ? Distrito[getIdProvincia(departamento, provincia)].map((dep, i) => {
                                  return (
                                      <option key={i} value={dep.nombre_ubigeo}>
                                          {dep.nombre_ubigeo}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
                <div className='form-group'>
                    <small>Tipo de Anfitrión</small>
                    <select className='form-control' value={tipo} onChange={onChangeTipo}>
                        <option value='' defaultValue>
                            Todos
                        </option>
                        <option value='ANFITRION'>Anfitrión</option>
                        <option value='GUIA'>Guía Certificado</option>
                        <option value='TOUR OPERADOR'>Tour Operador</option>
                    </select>
                </div>
                <div className='form-group'>
                    <small>Fecha del Tour</small>
                    <div>
                        <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                            <KeyboardDatePicker
                                format='DD/MM/YYYY'
                                margin='normal'
                                id='fecha'
                                name='fecha'
                                minDate={new Date(new Date().getTime() + 86400000)}
                                value={fecha}
                                disablePast={false}
                                onChange={(e) => {
                                    setFecha(e);
                                }}
                                className='form-control'
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className='form-group'>
                    <small>Precios</small>
                    <div className={classes.root}>
                        <Slider
                            value={rangoPrecios}
                            onChange={cambiarRangoPrecios}
                            valueLabelDisplay='auto'
                            aria-labelledby='range-slider'
                            min={1}
                            max={20000}
                        />
                        {moneda === 'PEN' ? (
                            <small>{`S/. ${rangoPrecios[0]} A S/. ${rangoPrecios[1]}`}</small>
                        ) : (
                            <small>{`$/. ${rangoPrecios[0]} - $/. ${rangoPrecios[1]}`}</small>
                        )}
                    </div>
                </div>
                <div className='form-group'>
                    <small>Moneda</small>
                    <select
                        className='form-control'
                        value={moneda}
                        onChange={(e) => {
                            setMoneda(e.target.value);
                        }}
                    >
                        <option defaultValue value='PEN'>
                            Soles
                        </option>
                        <option value='USD'>Dólares</option>
                    </select>
                </div>
                <button type='submit' className='btn btn-itoour btn-block mt-2'>
                    Buscar
                </button>
            </form>
        </div>
    );
}
