import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import ReadMoreReadLess from "../../components/Shared/LeerMasLeerMenos";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: "1000px",
        maxHeight: "600px",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: "16px 32px 24px",
        overflow: "scroll",
        overflowX: "hidden",
    },
}));

export default function TransitionsModal({ notificacionesTours }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="card shadow">
            <button className="btn btn-itoour mt-2" type="button" onClick={handleOpen}>
                Ver todos
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div class="modal-content">
                            <form method="POST">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modal__title">
                                        Notificaciones
                                    </h5>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div class="col-md-12">
                                            <div class="form-row">
                                                <div>
                                                    {notificacionesTours.map((value, i) => (
                                                        <div key={i}>
                                                            {value.tipoNotificacion !== "Publicado" && value.tipoNotificacion !== "Inhabilitado" ? (
                                                                <div className="modal__notificaciones--container">
                                                                    <Link className="modal__notificaciones" to={`/editartour/${value.idTour}`} onClick={handleClose}>
                                                                        <span className="col-form-label h6 text-primary">
                                                                            {value.tourNombre} <strong>{value.tipoNotificacion}</strong>
                                                                        </span>
                                                                    </Link>
                                                                    {value.tourNotificaciones !== null ? (
                                                                        <div>
                                                                            {value.tourNotificaciones.map((valor, j) => (
                                                                                <div className="pl-2 text-secondary " key={j}>
                                                                                    <span className="text-dark font-weight-bold">{`${valor.campoObser} : `}</span>
                                                                                    <ReadMoreReadLess limit={125} textLength={valor.valorCampo.length}>
                                                                                        {valor.valorCampo}
                                                                                    </ReadMoreReadLess>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            ) : (
                                                                <div className="modal__notificaciones--container--default">
                                                                    <span className="col-form-label h6 text-primary">
                                                                        {value.tourNombre} <strong>{value.tipoNotificacion}</strong>
                                                                    </span>
                                                                    {value.tourNotificaciones !== null ? (
                                                                        <div>
                                                                            {value.tourNotificaciones.map((valor, j) => (
                                                                                <div className="pl-2 text-secondary" key={j}>
                                                                                    <span className="text-dark">{`${valor.campoObser} : `}</span>
                                                                                    <span className="text-muted pl-2">{valor.valorCampo}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" onClick={handleClose}>
                                        Cerrar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
