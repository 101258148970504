import React, { useState, useEffect, useRef } from 'react';
import fire from '../../config/Firebase';
import Carousel from 'react-bootstrap/Carousel';
import Navbar from '../../components/Shared/Navbar';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { MapsKey } from '../../config/Google';
import Cargando from '../../components/Shared/Cargando';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TourService from '../../services/Tour';
import Swal from 'sweetalert2';
import ReactPlayer from 'react-player';
import SimpleModal from './ModalToursAprob';

function VerTourAdmin(props) {
    let [tour, setTour] = useState({});
    let [contObs, setContObs] = useState([]);
    const [loading, setLoading] = useState(true);
    let [fechaCompra, setFechaCompra] = useState(new Date());
    const [idTour, setIdTour] = useState();
    let [constUid, setConstUid] = useState('');

    let getTour = (tourId) => {
        let db = fire.firestore();
        let ref = db.collection(`tours`).doc(tourId);
        ref.get()
            .then((doc) => {
                if (doc.exists) {
                    //   console.log("docdata", doc.data());
                    setTour({
                        ...doc.data(),
                        tour_precio: Math.ceil((doc.data().tour_precio * 1.03) / 0.85),
                    });
                    setLoading(false);
                } else {
                    // console.log("doc no existe");
                }
                setConstUid(doc.data().uid);
            })
            .catch((err) => {
                // console.log("error al obtener doc", err);
            });
    };

    useEffect(() => {
        // setId(props.match.params.id);
        // console.log("id", id);
        let id = props.match.params.id;
        getTour(id);
        setIdTour(id);
    }, []);

    let activarTour = () => {
        let id = props.match.params.id;
        Swal.fire({
            title: 'Desea activar el Tour?',
            icon: 'info',
            text: 'Al activar el Tour será publicado y no se podrá modificar',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, Activar',
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .activarTour(id)
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: `El Tour ha sido activado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };
    let addObs = (campo, aux, id) => {
        if (aux.checked === true) {
            setContObs([...contObs, { campo, aux: id }]);
        } else {
            const newObsCont = contObs.filter((campoObs) => campoObs.campo !== campo);
            setContObs(newObsCont);
        }
    };
    return (
        <div style={{ height: '100%' }}>
            {/* {!cargando ? ()} */}
            {loading ? (
                <Cargando style={{ height: '100%' }} />
            ) : (
                <div className='container mt-4 mb-4'>
                    <Link className='btn btn-outline-primary mb-3' to='/dashboardadmin'>
                        <i className='fas fa-backward mr-2'></i>Regresar
                    </Link>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='d-flex justify-content-between mb-1'>
                                <h2 className='text-itoour'>Solo Visualización</h2>
                                {/* <button
                                    className="btn btn-itoour"
                                    style={{ height: "40px" }}
                                    onClick={() => {
                                        activarTour();
                                    }}
                                >
                                    Activar
                                </button> */}
                            </div>
                            <div className='info'>
                                <div className='d-flex flex-column'>
                                    <Carousel>
                                        {tour.tour_imagenes
                                            ? tour.tour_imagenes.map((img, i) => {
                                                  return (
                                                      <Carousel.Item key={i}>
                                                          <img
                                                              className='d-block w-100 img-ratio-principal imagen-slider'
                                                              src={img}
                                                              // ref={`img${i}`}
                                                              alt='...'
                                                              // onLoad={() => console.log([`img${i}`].current.naturalHeight)}
                                                          />
                                                      </Carousel.Item>
                                                  );
                                              })
                                            : null}
                                    </Carousel>

                                    {/* carousel */}
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row mt-3 justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-image'
                                                data-value='Imágenes'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-image');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-image' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div className='mt-4 d-flex justify-content-between text-helvetica'>
                                    <div>
                                        <h3 className='font-weight-bold'>{tour.tour_nom}</h3>
                                        <span style={{ fontSize: '1.2rem' }}>
                                            <i className='fas fa-map mr-2' style={{ color: '#753183' }}></i>{' '}
                                            {tour.tour_departamento}
                                        </span>
                                    </div>
                                    <div className='text-itoour font-weight-bold text-center'>
                                        <h3 className='font-weight-bold'>
                                            {tour.tour_moneda === 'PEN' ? 'S/.' : '$.'} {tour.tour_precioAdulto}
                                        </h3>
                                        {tour.tour_precioNino && +tour.tour_precioNino > 0
                                            ? 'por adulto'
                                            : 'por persona'}
                                    </div>
                                    {tour.tour_precioNino && +tour.tour_precioNino > 0 ? (
                                        <div className='text-itoour font-weight-bold text-center'>
                                            <h3 className='font-weight-bold'>
                                                {tour.tour_moneda === 'PEN' ? 'S/.' : '$.'} {tour.tour_precioNino}
                                            </h3>
                                            por niño
                                        </div>
                                    ) : null}
                                </div>
                                <div className='mt-3'>
                                    <h5 className='font-weight-bold'>Descripción del Tour</h5>
                                    <p>{tour.tour_desc}</p>
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-descripcion'
                                                data-value='Descripción'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-descripcion');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-descripcion' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    <h5 className='font-weight-bold'> Disponibilidad</h5>
                                    <ul className='list-group'>
                                        {tour.tour_disponibilidad.length < 30 ? (
                                            tour.tour_disponibilidad.map((turno, i) => (
                                                <li className='list-group-item' key={i}>{`Fecha: ${new Date(
                                                    turno.dia.seconds * 1000
                                                ).toLocaleString()} | Capacidad: ${turno.capacidad}`}</li>
                                            ))
                                        ) : (
                                            <ul>Se ha creado un tour con fechas ilimitadas</ul>
                                        )}
                                    </ul>
                                    <br />
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row mt-3 justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-disponibilidad'
                                                data-value='Disponibilidad'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-disponibilidad');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-disponibilidad' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    <h5 className='font-weight-bold'>Actividades</h5>
                                    <ul className='ul-itoour'>
                                        {tour.tour_activ
                                            ? tour.tour_activ.map((activ, i) => {
                                                  return (
                                                      <li key={i} className='mb-3'>
                                                          {activ}
                                                      </li>
                                                  );
                                              })
                                            : null}
                                    </ul>
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-actividades'
                                                data-value='Actividades'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-actividades');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-actividades' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    <h5 className='font-weight-bold'>Punto de partida</h5>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Departamento:</span>
                                        <span>{tour.tour_departamentopartida}</span>
                                    </p>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Provincia:</span>
                                        <span>{tour.tour_provinciapartida}</span>
                                    </p>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Ciudad:</span>
                                        <span>{tour.tour_ciudadpartida}</span>
                                    </p>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Dirección:</span>
                                        <span>{tour.tour_direccionpartida}</span>
                                    </p>
                                    {tour.lat ? (
                                        <div className='d-flex justify-content-center'>
                                            <Map
                                                google={props.google}
                                                zoom={17}
                                                style={{
                                                    height: '300px',
                                                    width: '1110px',
                                                    display: 'block',
                                                    position: 'relative',
                                                }}
                                                initialCenter={{
                                                    lat: tour.lat,
                                                    lng: tour.lng,
                                                }}
                                                className='mt-2 position-relative'
                                            >
                                                <Marker
                                                    position={{
                                                        lat: tour.lat,
                                                        lng: tour.lng,
                                                    }}
                                                />
                                            </Map>
                                        </div>
                                    ) : null}
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row mt-3 justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-partida'
                                                data-value='Punto de partida'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-partida');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-partida' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div>
                                    <h5 className='font-weight-bold'>Punto de destino</h5>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Departamento:</span>
                                        <span>{tour.tour_departamento}</span>
                                    </p>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Provincia:</span>
                                        <span>{tour.tour_provincia}</span>
                                    </p>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Ciudad:</span>
                                        <span>{tour.tour_ciudad}</span>
                                    </p>
                                    <p>
                                        <span className='font-weight-bold mr-1'>Dirección:</span>
                                        <span>{tour.tour_direccion}</span>
                                    </p>
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row mt-3 justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-destino'
                                                data-value='Punto de destino'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-destino');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-destino' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div className='video'>
                                    <div className='position-relative' style={{ marginTop: '15px' }}>
                                        <h5 className='font-weight-bold card-title'>Video:</h5>
                                        {tour.tour_youtube ? (
                                            <ReactPlayer
                                                url={tour.tour_youtube}
                                                style={{ margin: '0 auto', maxWidth: '100%' }}
                                            />
                                        ) : null}
                                    </div>

                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <div className='row mt-5 justify-content-end pr-3'>
                                            <input
                                                type='checkbox'
                                                id='switch-video'
                                                data-value='Video'
                                                onClick={() => {
                                                    let aux = document.getElementById('switch-video');
                                                    let temp = aux.getAttribute('data-value');
                                                    let id = aux.getAttribute('id');
                                                    addObs(temp, aux, id);
                                                }}
                                            />
                                            <label for='switch-video' className='switch-permiso'></label>
                                        </div>
                                    ) : null}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <div className='card shadow mb-3'>
                                        <div className='card-body'>
                                            <h5 className='font-weight-bold card-title'>Incluye:</h5>
                                            <ul className='ul-itoour'>
                                                {tour.tour_inc
                                                    ? tour.tour_inc.map((inc, i) => {
                                                          return (
                                                              <li key={i} className='mb-3'>
                                                                  {inc}
                                                              </li>
                                                          );
                                                      })
                                                    : null}
                                            </ul>
                                            {tour.status === 'Pendiente de aprobación' ? (
                                                <div className='row justify-content-end'>
                                                    <input
                                                        type='checkbox'
                                                        id='switch-incluye'
                                                        data-value='Incluye'
                                                        onClick={() => {
                                                            let aux = document.getElementById('switch-incluye');
                                                            let temp = aux.getAttribute('data-value');
                                                            let id = aux.getAttribute('id');
                                                            addObs(temp, aux, id);
                                                        }}
                                                    />
                                                    <label for='switch-incluye' className='switch-permiso'></label>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='card shadow mb-3'>
                                        <div className='card-body'>
                                            <h5 className='font-weight-bold card-title'>No Incluye:</h5>
                                            <ul className='ul-itoour'>
                                                {tour.tour_noinc
                                                    ? tour.tour_noinc.map((noinc, i) => {
                                                          return (
                                                              <li key={i} className='mb-3'>
                                                                  {noinc}
                                                              </li>
                                                          );
                                                      })
                                                    : null}
                                            </ul>
                                            {tour.status === 'Pendiente de aprobación' ? (
                                                <div className='row justify-content-end'>
                                                    <input
                                                        type='checkbox'
                                                        id='switch-nincluye'
                                                        data-value='No incluye'
                                                        onClick={() => {
                                                            let aux = document.getElementById('switch-nincluye');
                                                            let temp = aux.getAttribute('data-value');
                                                            let id = aux.getAttribute('id');
                                                            addObs(temp, aux, id);
                                                        }}
                                                    />
                                                    <label for='switch-nincluye' className='switch-permiso'></label>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='card shadow mb-3'>
                                        <div className='card-body'>
                                            <h5 className='font-weight-bold card-title'>Notas adicionales:</h5>
                                            <ul className='ul-itoour'>
                                                {tour.tour_notas
                                                    ? tour.tour_notas.map((nota, i) => {
                                                          return (
                                                              <li key={i} className='mb-3'>
                                                                  {nota}
                                                              </li>
                                                          );
                                                      })
                                                    : null}
                                            </ul>
                                            {tour.status === 'Pendiente de aprobación' ? (
                                                <div className='row justify-content-end'>
                                                    <input
                                                        type='checkbox'
                                                        id='switch-nadicional'
                                                        data-value='Notas adicionales'
                                                        onClick={() => {
                                                            let aux = document.getElementById('switch-nadicional');
                                                            let temp = aux.getAttribute('data-value');
                                                            let id = aux.getAttribute('id');
                                                            addObs(temp, aux, id);
                                                        }}
                                                    />
                                                    <label for='switch-nadicional' className='switch-permiso'></label>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='card shadow mb-3'>
                                        <div className='card-body'>
                                            <h5 className='font-weight-bold card-title'>Términos y Condiciones:</h5>
                                            <ul className='ul-itoour'>
                                                {tour.tour_terminos
                                                    ? tour.tour_terminos.map((termino, i) => {
                                                          return (
                                                              <li key={i} className='mb-3'>
                                                                  {termino}
                                                              </li>
                                                          );
                                                      })
                                                    : null}
                                            </ul>
                                            {tour.status === 'Pendiente de aprobación' ? (
                                                <div className='row justify-content-end'>
                                                    <input
                                                        type='checkbox'
                                                        id='switch-nterminos'
                                                        data-value='Términos y Condiciones'
                                                        onClick={() => {
                                                            let aux = document.getElementById('switch-nterminos');
                                                            let temp = aux.getAttribute('data-value');
                                                            let id = aux.getAttribute('id');
                                                            addObs(temp, aux, id);
                                                        }}
                                                    />
                                                    <label for='switch-nterminos' className='switch-permiso'></label>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {tour.status === 'Pendiente de aprobación' ? (
                                        <SimpleModal
                                            resultGestionTour={contObs}
                                            idTour={idTour}
                                            uiId={constUid}
                                            tourNombre={tour.tour_nom}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    // console.log("userid", state);
    return {
        auth: state.firebase.auth,
    };
};

const WrappedContainer = GoogleApiWrapper({
    apiKey: MapsKey,
})(VerTourAdmin);

export default connect(mapStateToProps)(WrappedContainer);
