import { useJsApiLoader } from "@react-google-maps/api";
import { MapsKey } from "../config/Google";

export default class MapService {
    constructor({
        map,
        marker,
        defaultZoom = 5,
        defaultCoords = {
            lat: -12.0463731,
            lng: -77.042754,
        },
    }) {
        const { isLoaded } = useJsApiLoader({
            id: "google-map-script",
            googleMapsApiKey: MapsKey,
            libraries: ["places"],
        });

        this.isLoaded = isLoaded;
        this.defaultZoom =
            marker && (marker.lat || marker.lng) ? 15 : defaultZoom;
        this.defaultCoords =
            marker && (marker.lat || marker.lng) ? marker : defaultCoords;
        this.map = map;
        this.marker = marker;
    }
}

MapService.prototype.onUnmount = function (setMap) {
    return function (map) {
        setMap(null);
    };
};

MapService.prototype.onLoad = function (setMap) {
    return function (map) {
        const bounds = new window.google.maps.LatLngBounds(this.defaultCoords);
        map.fitBounds(bounds);
        setMap(map);
    };
};

MapService.prototype.searchPlace = async function (address) {
    const geocoder = new window.google.maps.Geocoder();

    try {
        const res = await geocoder.geocode({ address });
        const { lat, lng } = res.results[0].geometry.location;

        return { lat: lat(), lng: lng() };
    } catch (error) {
        return null;
    }
};

MapService.prototype.goToPlace = async function (
    address,
    setMarker,
    withMarker = false
) {
    const placeCoords = await this.searchPlace(address);

    if (placeCoords) {
        this.map.panTo(placeCoords);
        this.map.setZoom(15);

        if (withMarker) {
            setMarker(placeCoords);
        }
    }
};

MapService.prototype.route = function (
    origen,
    destino,
    setDirectionsResponse,
    setDistance,
    setDuration,
    setOrigen,
    setDestino
) {
    return [
        async function calculateRoute() {
            if (!origen || !destino) {
                return;
            }

            const directionsService =
                new window.google.maps.DirectionsService();
            const result = await directionsService.route({
                origin: origen,
                destination: destino,
                travelMode: window.google.maps.TravelMode.DRIVING,
            });

            setDirectionsResponse(result);
            setDistance(result.routes[0].legs[0].distance.text);
            setDuration(result.routes[0].legs[0].duration.text);
        },
        async function clearRoute() {
            setDirectionsResponse(null);
            setDistance("");
            setDuration("");
            setOrigen && setOrigen(null);
            setDestino && setDestino(null);
        },
    ];
};

MapService.prototype.getCurrentPosition = function (setMarkerPosition) {
    const infoWindow = new window.google.maps.InfoWindow();

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };

            this.map.setCenter(pos);
            this.map.setZoom(15);
            setMarkerPosition(pos);
        });
    }
};
