
export const EnumStatusTours = {
  EN_CREACIÓN: "En creación",
  APROBACION_PENDIENTE: "Pendiente de aprobación",
  OBSERVADO: "Observado",
  PUBLICADO: "Publicado",
  PAUSADO: "Pausado",
  CANCELADO: "Cancelado",
  INHABILITADO: "Inhabilitado",

}
