import { v4 as uuidv4 } from 'uuid';
import { storage } from '../config/Firebase';

export function genImageName(extension) {
    return uuidv4() + '.' + extension;
}

export function uploadImage(image, name) {
    const imgRef = storage.ref(name);

    return imgRef
        .putString(image, 'base64')
        .then(() => imgRef.getDownloadURL())
        .then((url) => url);
}

export function deleteImage(name) {
    const imgRef = storage.ref(name);

    return imgRef.delete();
}

export function getImageNameByUrl(url) {
    let destructure = url?.split ? url?.split('%')[1] : null;
    destructure = destructure?.split ? destructure.split('?')[0] : null;
    destructure = destructure?.slice ? destructure.slice(2) : null;

    return destructure;
}

export async function replaceImages(images, prevImages) {
    const urls = await Promise.all(
        images.map((image) => {
            const name = genImageName(image.extension);

            return uploadImage(image.data, `${image.folder}/${name}`);
        })
    );

    await Promise.all(prevImages.map((image) => deleteImage(image)));

    return urls;
}
