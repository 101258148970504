export const perfil = {
    usuario: {
        nombre: "Mi Perfil",
        link: "/perfil",
    },
    "cliente-usuario": {
        nombre: "Mi Cuenta",
        link: "/perfil",
    },
    "cliente-anfitrion": {
        nombre: "Mi Cuenta",
        link: "/perfilempresa",
    },
    "cliente-operador": {
        nombre: "Mi Cuenta",
        link: "/perfilempresa",
    },
};

export const dashboard = {
    usuario: {
        nombre: "Reservas",
        link: "/reservas",
    },
    "cliente-usuario": {
        nombre: "Reservas",
        link: "/reservas",
    },
    "cliente-anfitrion": {
        nombre: "Dashboard",
        link: "/dashboard",
    },
    "cliente-operador": {
        nombre: "Dashboard",
        link: "/dashboard",
    },
    admin: {
        nombre: "Dashboard",
        link: "/dashboardadmin",
    },
};

export const iconos = {
    usuario: {
        perfil: "fas fa-user-tie mr-2",
        dashboard: "fas fa-calendar-alt mr-2", // Reservas
        salir: "fas fa-sign-out-alt mr-2",
    },
    "cliente-usuario": {
        perfil: "fas fa-user-tie mr-2",
        dashboard: "fas fa-calendar-alt mr-2", // Reservas
        salir: "fas fa-sign-out-alt mr-2",
    },
    "cliente-anfitrion": {
        perfil: "fas fa-user-tie mr-2",
        dashboard: "fas fa-columns mr-2",
        salir: "fas fa-sign-out-alt mr-2",
    },
    "cliente-operador": {
        perfil: "fas fa-user-tie mr-2",
        dashboard: "fas fa-columns mr-2",
        salir: "fas fa-sign-out-alt mr-2",
    },
    admin: {
        dashboard: "fas fa-columns mr-2",
        salir: "fas fa-sign-out-alt mr-2",
    },
};
