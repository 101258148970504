import React, { useState } from "react";

const ReadMoreReadLess = ({
    limit,
    children,
    textLength,
    leerMas = " Leer Mas...",
    leerMenos = " Leer Menos",
}) => {
    let text = children;

    const [isReadMoreShow, setIsReadMoreShow] = useState(false);

    const toggleText = () => {
        setIsReadMoreShow((prevState) => !prevState);
    };
    return (
        <div
            className="read-more-read-less toggable-container"
            data-height="100"
        >
            {isReadMoreShow ? text : text.substr(0, limit)}
            {textLength >= limit ? (
                <span className="read__btn" onClick={toggleText}>
                    {isReadMoreShow ? leerMenos : leerMas}
                </span>
            ) : null}
        </div>
    );
};

export default ReadMoreReadLess;
