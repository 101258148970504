import React, { useState } from 'react';
import StepButtonPage from '../components/Shared/StepButtonPage';
import UserData from './Registro/UserData';
import EntityData from './Registro/EntityData';
import ResumeRegister from './Registro/ResumeRegister';
import useForm from '../hooks/useForm';
import './css/RegistroCliente.css';

const initialForm = {
    nombres: '',
    apellidos: '',
    tipo_documento: 'DNI',
    documento: '',
    tipo: 'ANFITRION',
    celular: '',
    email: '',
    password: '',
    repassword: '',
    nombre_anfitrion: '',
    ruc: '',
    razon_social: '',
    sexo: '',
    representante: '',
    direccion: '',
    pais: '',
    departamento: '',
    ciudad: '',
    foto: '',
};

const validateForm = (formState, compare_fields = {}) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'nombres': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'apellidos': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'documento': {
                const tipo = compare_fields['tipo_documento'];

                const validate =
                    tipo === 'DNI'
                        ? /^[0-9]{8}$/i.test(formState[name])
                        : /^[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ&]{1,12}$/i.test(formState[name]);

                const values = {
                    DNI: 'DNI',
                    CE: 'Carnet de Extranjería',
                    PAS: 'Pasaporte',
                };

                validates.push({
                    name,
                    isValid: !!formState[name].length && validate,
                    message: formState[name].length
                        ? !validate
                            ? `No es un ${values[tipo]} válido`
                            : ''
                        : 'Este campo es obligatorio',
                });
                break;
            }

            case 'celular': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'email': {
                validates.push({
                    name,
                    isValid: /^[áéíóúñ.\-_\w]+@[áéíóúñ.\-_\w]+\.[áéíóúñ.\-_\w]+$/i.test(formState[name]),
                    message: formState[name].length ? 'No es un correo válido' : 'Este campo es obligatorio',
                });
                break;
            }

            case 'password': {
                validates.push({
                    name,
                    isValid: !!formState[name].length && formState[name].length > 5,
                    message: formState[name].length
                        ? formState[name].length < 6
                            ? 'La contraseña de tener mínimo 6 caracteres'
                            : ''
                        : 'Este campo es obligatorio',
                });
                break;
            }

            case 'repassword': {
                const password = compare_fields['password'];

                validates.push({
                    name,
                    isValid: !!formState[name].length && formState[name] === password,
                    message: formState[name].length
                        ? formState[name] !== password
                            ? 'No coincide con la contraseña'
                            : ''
                        : 'Este campo es obligatorio',
                });
                break;
            }

            case 'nombre_anfitrion': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'ruc': {
                const pais = compare_fields['pais'];

                validates.push({
                    name,
                    isValid: pais !== 'PE' || /^(10|20)[0-9]{9}$/.test(formState[name]),
                    message: formState[name].length
                        ? /^(10|20)[0-9]{9}$/.test(formState[name])
                            ? ''
                            : `No es un RUC válido`
                        : 'Este campo es obligatorio',
                });
                break;
            }

            case 'razon_social': {
                const pais = compare_fields['pais'];

                validates.push({
                    name,
                    isValid: pais !== 'PE' || !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'representante': {
                const tipo = compare_fields['tipo'];

                validates.push({
                    name,
                    isValid: tipo !== 'TOUR OPERADOR' || !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'sexo': {
                const tipo = compare_fields['tipo'];

                validates.push({
                    name,
                    isValid: tipo === 'TOUR OPERADOR' || !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'direccion': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'pais': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'departamento': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            case 'ciudad': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Este campo es obligatorio',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

export default function RegistroCliente(props) {
    const [step, setStep] = useState(1);
    const [enabled, setEnabled] = useState(false);
    const { formState, focus, errors, setErrors, handleBlur, handleChangeWithErrors, handleSubmit, setFocus } = useForm(
        initialForm,
        validateForm
    );

    const selectStep = (numStep) => {
        switch (numStep) {
            case 1:
                return (
                    <UserData
                        step={step}
                        changeStep={setStep}
                        values={formState}
                        focus={focus}
                        setFocus={setFocus}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChangeWithErrors}
                        handleSubmit={handleSubmit}
                        setErrors={setErrors}
                    />
                );
            case 2:
                return (
                    <EntityData
                        step={step}
                        changeStep={setStep}
                        values={formState}
                        focus={focus}
                        setFocus={setFocus}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChangeWithErrors}
                        handleSubmit={handleSubmit}
                    />
                );
            case 3:
                return (
                    <ResumeRegister
                        {...props}
                        changeStep={setStep}
                        values={formState}
                        focus={focus}
                        errors={errors}
                        handleChange={handleChangeWithErrors}
                        handleSubmit={handleSubmit}
                        setEnabled={setEnabled}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className='registro-cliente-container'>
            <div className='foto-guia'>
                <img
                    src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FVarios%2Fregistro-foto-guia.jpg?alt=media&token=56f60197-74b6-4a40-b22d-b1e0c91bb58d'
                    alt='Foto de Guia Turístico'
                />
            </div>
            <div className='form-container'>
                <div className='stepPageContainer'>
                    <StepButtonPage
                        step={step}
                        changeStep={setStep}
                        items={['Datos de usuario', 'Empresa / marca', 'Confirmación']}
                        handleSubmit={handleSubmit}
                        enabled={enabled}
                    />
                </div>
                {selectStep(step)}
            </div>
        </div>
    );
}

// import React, { Fragment, useEffect, useState } from 'react';
// import './css/Login.css';
// //import { useForm } from 'react-hook-form';
// import { AuthService } from '../services/Auth';
// import LoadingFull from '../components/Shared/CargandoFull';
// import Swal from 'sweetalert2';
// import { Link, Redirect } from 'react-router-dom';
// import useForm from '../hooks/useForm';
// import fire, { googleProvider } from '../config/Firebase';

// const validateForm = (formState) => {
//     const validates = [];

//     for (let name in formState) {
//         switch (name) {
//             case 'email': {
//                 validates.push({
//                     name,
//                     isValid: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i.test(
//                         formState[name]
//                     ),
//                     message: formState[name]?.length ? 'No es un email válido' : 'El email es requerido',
//                 });
//                 break;
//             }

//             case 'password': {
//                 validates.push({
//                     name,
//                     isValid: formState[name].length >= 8,
//                     message: formState[name]?.length
//                         ? 'La contraseña de contener al menos 8 caracteres.'
//                         : 'El campo es requerido',
//                 });
//                 break;
//             }

//             case 'password2': {
//                 validates.push({
//                     name,
//                     isValid: formState[name].length >= 8,
//                     message: formState[name]?.length
//                         ? 'La contraseña de contener al menos 8 caracteres.'
//                         : 'El campo es requerido',
//                 });
//                 break;
//             }

//             default:
//                 validates.push({
//                     name,
//                     isValid: true,
//                     message: '',
//                 });
//         }
//     }

//     return validates;
// };

// const initialForm = {
//     email: '',
//     password: '',
//     password2: '',
// };

// export default function RegistroCliente(props) {
//     const [loading, setLoading] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);
//     const [showRePassword, setShowRePassword] = useState(false);
//     const [datos, setDatos] = useState(initialForm);
//     const [terms, setTerms] = useState(false);
//     const [redirect, setRedirect] = useState(false);
//     const {
//         formState,
//         setFormState,
//         errors,
//         setErrors,
//         focus,
//         setFocus,
//         handleChange,
//         handleBlur,
//         resetForm,
//         handleSubmit,
//     } = useForm(initialForm, validateForm);

//     const actualizarInput = (e) => {
//         setDatos({
//             ...datos,
//             [e.target.name]: e.target.value,
//         });
//     };

//     const existEmptyFields = (data) => {
//         for (let key in data) {
//             if (!data[key]) return true;
//         }

//         return false;
//     };

//     let sAuth = new AuthService();

//     let login = () => {
//         setLoading(true);
//         // console.log("data", data);
//         sAuth
//             .registerClient(datos.email, datos.password)
//             /*.then(() => {
//         return props.logout();
//       })*/
//             .then(() => {
//                 setLoading(false);
//                 Swal.fire({
//                     icon: 'success',
//                     title: 'Registro Exitoso!',
//                     showConfirmButton: false,
//                     timer: 2000,
//                 }).then(() => {
//                     props.history.push('/');
//                 });
//             })
//             .catch((err) => {
//                 if (err?.code === 'auth/email-already-in-use') {
//                     Swal.fire({
//                         title: 'Error de registro',
//                         text: `La cuenta que intentas crear ya existe, prueba iniciando sesión.`,
//                         icon: 'error',
//                         allowOutsideClick: false,
//                     })
//                         .then(() => props.history.push('/login'))
//                         .catch(() => props.history.push('/'));
//                 } else {
//                     Swal.fire({
//                         title: 'Error de registro',
//                         text: `Ocurrió un error inesperado al registrar su cuenta, por favor intentelo nuevamente más tarde.`,
//                         icon: 'error',
//                         allowOutsideClick: false,
//                     })
//                         .then(() => props.history.push('/'))
//                         .catch(() => props.history.push('/'));
//                 }
//             });
//     };

//     const loginWithGoogle = (e) => {
//         e.preventDefault();
//         sAuth
//             .registerClientWithGoogle(setRedirect)
//             .then((res) => {
//                 if (res === null) {
//                     return Swal.fire({
//                         title: 'Error de registro',
//                         text: `La cuenta que intentas crear ya existe, prueba iniciando sesión.`,
//                         icon: 'error',
//                         allowOutsideClick: false,
//                     })
//                         .then(() => setTimeout(() => props.history.push('/login'), 200))
//                         .catch(() => setTimeout(() => props.history.push('/'), 200));
//                 }
//             })
//             .catch((error) => console.log({ error }));
//     };

//     const EyeButton = ({ show, setShow }) => {
//         return (
//             <>
//                 {show ? (
//                     <span className='input-group-text eye-password' onClick={(e) => setShow(false)}>
//                         <i className='fas fa-eye-slash'></i>
//                     </span>
//                 ) : (
//                     <span className='input-group-text eye-password' onClick={(e) => setShow(true)}>
//                         <i className='fas fa-eye'></i>
//                     </span>
//                 )}
//             </>
//         );
//     };

//     useEffect(() => {
//         if (props.logout) {
//             props.logout();
//         }
//     }, []);

//     if (redirect) return <Redirect to={props?.location?.prevUrl || ''} />;

//     return (
//         <Fragment>
//             {loading ? (
//                 <LoadingFull />
//             ) : (
//                 <Fragment>
//                     <div className='contenedor'>
//                         <div className='imagenregistro'>
//                             <span>itoour.com</span>
//                         </div>
//                         <div className='formulario'>
//                             <div className='card'>
//                                 <div className='card-header'>Registro Agencias</div>
//                                 <div className='card-body'>
//                                     <div className='card-text'>
//                                         <div className='clearfix'>
//                                             <h3 className='float-left'></h3>
//                                             <div className='font-weight-bold float-right' id='registro'>
//                                                 <span>¿Ya tienes cuenta?</span>{' '}
//                                                 <Link to='/login'>
//                                                     <span
//                                                         style={{
//                                                             color: '#d6872b',
//                                                         }}
//                                                     >
//                                                         Iniciar Sesión
//                                                     </span>
//                                                 </Link>
//                                             </div>
//                                         </div>
//                                         <form>
//                                             <label htmlFor='email' className='font-weight-bold'>
//                                                 Correo Electrónico
//                                             </label>
//                                             <br />
//                                             <input
//                                                 type='email'
//                                                 className='form-control auth-email-input'
//                                                 id='email'
//                                                 name='email'
//                                                 placeholder='Ejemplo: matias23@ejemplo.com'
//                                                 value={formState.email}
//                                                 onChange={(e) => {
//                                                     actualizarInput(e);
//                                                     handleChange(e);
//                                                     handleBlur(e);
//                                                 }}
//                                                 onBlur={handleBlur}
//                                             />
//                                             {errors.email && <small className='text-danger'>{errors.email}</small>}
//                                             <br />
//                                             <label htmlFor='pass' className='font-weight-bold'>
//                                                 Contraseña
//                                             </label>
//                                             <br />
//                                             <div className='form-row'>
//                                                 <div className='form-group col-12'>
//                                                     <div className='input-group'>
//                                                         <input
//                                                             type={showPassword ? 'text' : 'password'}
//                                                             className='form-control'
//                                                             id='password'
//                                                             name='password'
//                                                             placeholder='Ingresar contraseña'
//                                                             value={formState.password}
//                                                             onChange={(e) => {
//                                                                 actualizarInput(e);
//                                                                 handleChange(e);
//                                                                 handleBlur(e);
//                                                             }}
//                                                             onBlur={handleBlur}
//                                                         />
//                                                         <div className='input-group-prepend'>
//                                                             <EyeButton show={showPassword} setShow={setShowPassword} />
//                                                         </div>
//                                                     </div>
//                                                     {errors.password && (
//                                                         <small className='text-danger'>{errors.password}</small>
//                                                     )}
//                                                 </div>
//                                             </div>
//                                             <label htmlFor='pass' className='font-weight-bold'>
//                                                 Confirmar Contraseña
//                                             </label>
//                                             <div className='form-row'>
//                                                 <div className='form-group col-12'>
//                                                     <div className='input-group'>
//                                                         <input
//                                                             type={showRePassword ? 'text' : 'password'}
//                                                             className='form-control'
//                                                             id='password2'
//                                                             name='password2'
//                                                             placeholder='Repita la contraseña'
//                                                             value={formState.password2}
//                                                             onChange={(e) => {
//                                                                 actualizarInput(e);
//                                                                 handleChange(e);
//                                                                 handleBlur(e);
//                                                             }}
//                                                             onBlur={handleBlur}
//                                                         />
//                                                         <div className='input-group-prepend'>
//                                                             <EyeButton
//                                                                 show={showRePassword}
//                                                                 setShow={setShowRePassword}
//                                                             />
//                                                         </div>
//                                                     </div>
//                                                     {(formState.password !== formState.password2 && (
//                                                         <small className='text-danger'>
//                                                             Las contraseñas no coinciden
//                                                         </small>
//                                                     )) ||
//                                                         (errors.password && (
//                                                             <small className='text-danger'>{errors.password}</small>
//                                                         ))}
//                                                 </div>
//                                                 <br />
//                                             </div>
//                                             <div className='custom-control custom-checkbox'>
//                                                 <input
//                                                     type='checkbox'
//                                                     className='custom-control-input'
//                                                     id='terms'
//                                                     name='terms'
//                                                     checked={terms}
//                                                     onClick={(e) => {
//                                                         setTerms(e.target.checked);
//                                                     }}
//                                                 />
//                                                 <label className='custom-control-label' htmlFor='terms'>
//                                                     Acepto los términos y políticas de privacidad
//                                                 </label>
//                                                 <br />
//                                                 {errors.terms && (
//                                                     <small className='text-danger'>{errors.terms.message}</small>
//                                                 )}
//                                             </div>
//                                             <br />
//                                             <button
//                                                 className='btn btn-block btn-login text-light'
//                                                 disabled={
//                                                     existEmptyFields(datos) ||
//                                                     Object.keys(errors).length ||
//                                                     !terms ||
//                                                     formState.password !== formState.password2
//                                                 }
//                                                 onClick={handleSubmit(login)}
//                                             >
//                                                 Registrarse
//                                             </button>
//                                             <div className='separador-login'>
//                                                 <div className='linea-separador'></div>o
//                                                 <div className='linea-separador'></div>
//                                             </div>
//                                             <button
//                                                 className='btn btn-block btn-login btn-login-google text-light'
//                                                 disabled={!terms}
//                                                 onClick={loginWithGoogle}
//                                             >
//                                                 <i class='fab fa-google'></i> Registrarse con Google
//                                             </button>
//                                             <small className='text-secondary'>
//                                                 Al registrarte aceptas las{' '}
//                                                 <a
//                                                     href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FPOLITICAS%20DE%20PRIVACIDAD%20DE%20ITOOUR%20Y%20USO%20DE%20COOKIES.pdf?alt=media&token=299ffe10-5b6e-44b1-ae2b-91c9a43b490b'
//                                                     target='_blank'
//                                                 >
//                                                     políticas
//                                                 </a>{' '}
//                                                 y{' '}
//                                                 <a
//                                                     href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FTERMINOS%20Y%20CONDICIONES%20Operadores%20turisticos.pdf?alt=media&token=e0a99d14-bd78-4658-898b-f9dd60ea55ba'
//                                                     target='_blank'
//                                                 >
//                                                     términos
//                                                 </a>{' '}
//                                                 de itoour
//                                             </small>
//                                             <br />
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Fragment>
//             )}
//         </Fragment>
//     );
// }
