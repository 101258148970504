import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import fire from "../../config/Firebase";
import TourService from "../../services/Tour";
import Swal from "sweetalert2";
import firebase from "firebase";
import ReactPaginate from "react-paginate";
import { getBadgeStatus } from "../../Util/badgeTour";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ReadMoreReadLess from "../../components/Shared/LeerMasLeerMenos";
import "./css/ListaToursEdit.css";
import { sortByField, filterFieldsByText } from "../../Util/Tools";
import ModalPreviewTour from "./ModalPreviewTour";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    root: {
        border: "1px solid #d3d4d5",
        display: "flex",
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function ListaToursEdit({ uid, rol }) {
    const [tours, setTours] = useState([]);
    const [filteredTours, setFilteredTours] = useState([]);
    const [search, setSearch] = useState("");
    const [estado, setEstado] = useState(" ");
    let [pagination, setPagination] = useState({
        offset: 0,
        perPage: 10,
        currentPage: 0,
        pageCount: 0,
    });
    const classes = useStyles();

    let db = fire.firestore();
    let getTours = () => {
        let query = db.collection("tours").where("uid", "==", uid);
        query.onSnapshot((querySnapshot) => {
            let ToursTmp = [];
            querySnapshot.forEach((tour) => {
                let dateInicial = new Date(
                    tour.data().tour_fecha.seconds * 1000
                );

                let fechaCancelacion = "";

                if (tour.data().fechaCancelada) {
                    const dateCancelacion = new Date(
                        tour.data().fechaCancelada.toDate()
                    );
                    fechaCancelacion = `${dateCancelacion.getDate()}/${
                        dateCancelacion.getMonth() + 1
                    }/${dateCancelacion.getFullYear()}`;
                }
                let objTour = {
                    id: tour.id,
                    tour_nom: tour.data().tour_nom,
                    tour_precio: tour.data().tour_precioAdulto,
                    tour_precioAdulto: tour.data().tour_precioAdulto,
                    tour_precioNino: tour.data().tour_precioNino,
                    tour_imagenes: tour.data().tour_imagenes,
                    tour_desc: tour.data().tour_desc,
                    tour_moneda: tour.data().tour_moneda,
                    tour_departamento: tour.data().tour_departamento,
                    tour_ciudad: tour.data().tour_ciudad,
                    tour_activo: tour.data().tour_activo,
                    tour_fecha: `${dateInicial.getDate()}/${
                        dateInicial.getMonth() + 1
                    }/${dateInicial.getFullYear()}`,
                    tour_fechaInicial: dateInicial,
                    status: tour.data().status,
                    fechaCancelada: fechaCancelacion,
                    tour_youtube: tour.data().tour_youtube,
                    tour_activ: tour.data().tour_activ,
                    tour_noinc: tour.data().tour_noinc,
                    tour_inc: tour.data().tour_inc,
                    tour_notas: tour.data().tour_notas,
                    tour_terminos: tour.data().tour_terminos,
                };
                ToursTmp.push(objTour);
            });
            ToursTmp = sortByField(ToursTmp, "tour_fechaInicial", "desc");
            setPagination({
                ...pagination,
                pageCount: Math.ceil(ToursTmp.length / pagination.perPage),
            });
            setTours(ToursTmp);
            setFilteredTours(ToursTmp);
        });
    };

    useEffect(() => {
        getTours();
    }, []);

    useMemo(() => {
        let filteredData = filterFieldsByText(
            tours,
            ["tour_nom", "tour_ciudad", "tour_desc"],
            search,
            "desc",
            "tour_fechaInicial"
        );

        filteredData =
            estado !== " "
                ? filteredData.filter((item) => item.status === estado)
                : filteredData;

        setFilteredTours(filteredData);
        setPagination({
            ...pagination,
            pageCount: Math.ceil(filteredData.length / pagination.perPage),
            offset: 0,
            currentPage: 0,
        });
    }, [search, estado]);

    let handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * pagination.perPage;

        setPagination({
            ...pagination,
            currentPage: selectedPage,
            offset: offset,
        });
    };

    let activarTour = (id) => {
        Swal.fire({
            title: "Desea activar el Tour?",
            icon: "info",
            text: "Al activar el Tour será publicado y no se podrá modificar",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, continuar",
            confirmButtonText: "Si, Activar",
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .activarTour(id)
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: `El Tour ha sido activado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    const pausarTour = (id) => {
        Swal.fire({
            title: "¿Estás seguro de pausar este tour?",
            icon: "info",
            text: "Recuerda que al pausar un tour este deja de ser visualizado en nuestra página, sin embargo, puedes reanudarlo cuando desees.",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, continuar",
            confirmButtonText: "Si, pausar",
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .pausarTour(id)
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: `El Tour ha sido pausado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    const cancelarTour = (id) => {
        Swal.fire({
            title: "¿Estás seguro de cancelar este tour?",
            icon: "info",
            text: "Recuerda que al cancelar un tour este pasa a ser eliminado, por lo que si deseas volver a ofertarlo tendrás que crear uno nuevo.",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, continuar",
            confirmButtonText: "Si, cancelar",
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .cancelarTour(id)
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: `El Tour ha sido cancelado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    const onChangeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    };

    const onClearButton = (e) => {
        e.preventDefault();
        setSearch("");
    };

    const onClicked = (event) => {
        const value = event.target.checked;
        setEstado(event.target.value);
    };

    const divStyle = {
        color: "white",
    };

    const tableStyle = {
        height: "10px",
        overflow: "hidden",
    };

    return (
        <Fragment>
            <div className="container mt-4">
                <div className="card p-4 my-3">
                    <p className="card-subtitle text-muted">
                        Al crear un tour, este pasa a una verificación
                        inmediata, de ser aprobado su publicación en la
                        plataforma es automática, no obstante, aún podrá editar
                        el anuncio desde la sección de dashboard, ante cualquier
                        inconveniente puede comunicarse con soporte@itoour.com
                    </p>
                </div>
                <div className={"container my-4 " + classes.container}>
                    <Paper component="form" className={classes.root}>
                        <InputBase
                            className={classes.input}
                            placeholder="Buscar Tour"
                            inputProps={{ "aria-label": "Buscar Tour" }}
                            value={search}
                            onChange={onChangeSearch}
                        />
                        <IconButton
                            type="button"
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={onClearButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Paper>
                </div>
                <div className="container my-3">
                    <p className="fst-italic">Filtro de Tour</p>
                    <div className="form-check form-check-inline options-list-container">
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                defaultChecked
                                name="filtro-estado"
                                value=" "
                                id="todoList"
                                onChange={onClicked}
                            ></input>
                            <label
                                htmlFor="todoList"
                                style={divStyle}
                                className="form-check-label badge bg-primary"
                            >
                                Todos
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="filtro-estado"
                                value="Pendiente de aprobación"
                                id="pendienteList"
                                onChange={onClicked}
                            ></input>
                            <label
                                htmlFor="pendienteList"
                                style={divStyle}
                                className="form-check-label badge badge-warning"
                            >
                                Pendiente de Aprobacion
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="filtro-estado"
                                value="Observado"
                                id="observadoList"
                                onClick={onClicked}
                            ></input>
                            <label
                                htmlFor="observadoList"
                                style={divStyle}
                                className="form-check-label badge badge-info"
                            >
                                Observado
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="filtro-estado"
                                value="Publicado"
                                id="publicadoList"
                                onClick={onClicked}
                            ></input>
                            <label
                                htmlFor="publicadoList"
                                style={divStyle}
                                className="form-check-label badge badge-success"
                            >
                                Publicado
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="filtro-estado"
                                value="Pausado"
                                id="pausadoList"
                                onClick={onClicked}
                            ></input>
                            <label
                                htmlFor="pausadoList"
                                style={divStyle}
                                className="form-check-label badge badge-danger"
                            >
                                Pausado
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="filtro-estado"
                                value="Cancelado"
                                id="canceladoList"
                                onClick={onClicked}
                            ></input>
                            <label
                                htmlFor="canceladoList"
                                style={divStyle}
                                className="form-check-label badge badge-secondary"
                            >
                                Cancelado
                            </label>
                        </div>
                    </div>
                </div>
                <div className="tour-list-dashboard-container">
                    <table className="table table-borderless table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th>Nombre</th>
                                {/*<th>Descripción</th>*/}
                                <th>Ciudad</th>
                                {/*<th>Inicio</th>
                                <th>Fin</th>*/}
                                <th>Estado</th>
                                <th>Ver</th>
                                <th>Editar</th>
                                <th>
                                    {estado === "Pausado"
                                        ? "Reanudar"
                                        : "Pausar"}
                                </th>
                                <th>Cancelar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {estado === " "
                                ? filteredTours
                                      .slice(
                                          pagination.offset,
                                          pagination.offset + pagination.perPage
                                      )
                                      .map((tour, i) => (
                                          <tr key={i}>
                                              <td className="tour-list-nombre">
                                                  {tour.tour_nom}
                                              </td>
                                              {/*<td className='descipcion'>
                                                  <ReadMoreReadLess limit={50} textLength={tour.tour_desc.length}>
                                                      {tour.tour_desc}
                                                  </ReadMoreReadLess>
                                              </td>*/}
                                              <td className="tour-list-ciudad">
                                                  {tour.tour_ciudad}
                                              </td>
                                              {/*<td>{tour.tour_fecha}</td>
                                              <td>{tour.fechaCancelada}</td>*/}
                                              <td style={{ maxWidth: "100px" }}>
                                                  <span
                                                      className={getBadgeStatus(
                                                          tour.status
                                                      )}
                                                      style={{
                                                          maxWidth: "100%",
                                                      }}
                                                  >
                                                      {tour.status}
                                                  </span>
                                              </td>
                                              <td>
                                                  <ModalPreviewTour
                                                      tour={tour}
                                                      tipo={rol}
                                                      onlyIcon={true}
                                                  />
                                              </td>
                                              <td
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >
                                                  <Link
                                                      to={`/editartour/${tour.id}`}
                                                      className="btn btn-success"
                                                      style={{ color: "white" }}
                                                  >
                                                      <i className="fas fa-pencil-alt"></i>
                                                  </Link>
                                              </td>
                                              <td
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >
                                                  {tour.status === "Pausado" ? (
                                                      <button
                                                          className="btn btn-info"
                                                          style={{
                                                              color: "white",
                                                          }}
                                                          onClick={() => {
                                                              activarTour(
                                                                  tour.id
                                                              );
                                                          }}
                                                      >
                                                          <i className="fas fa-play"></i>
                                                      </button>
                                                  ) : (
                                                      <button
                                                          className="btn btn-warning"
                                                          style={{
                                                              color: "white",
                                                          }}
                                                          onClick={() => {
                                                              pausarTour(
                                                                  tour.id
                                                              );
                                                          }}
                                                      >
                                                          <i className="fas fa-pause"></i>
                                                      </button>
                                                  )}
                                              </td>
                                              <td
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >
                                                  <button
                                                      className="btn btn-danger"
                                                      style={{ color: "white" }}
                                                      onClick={() => {
                                                          cancelarTour(tour.id);
                                                      }}
                                                  >
                                                      <i className="fas fa-ban"></i>
                                                  </button>
                                              </td>
                                          </tr>
                                      ))
                                : filteredTours
                                      //.filter((tour) => tour.status === estado)
                                      .slice(
                                          pagination.offset,
                                          pagination.offset + pagination.perPage
                                      )
                                      .map((tour, i) => (
                                          <tr key={i}>
                                              <td>{tour.tour_nom}</td>
                                              <td className="descipcion">
                                                  <ReadMoreReadLess
                                                      limit={50}
                                                      textLength={
                                                          tour.tour_desc.length
                                                      }
                                                  >
                                                      {tour.tour_desc}
                                                  </ReadMoreReadLess>
                                              </td>
                                              <td>{tour.tour_ciudad}</td>
                                              <td>{tour.tour_fecha}</td>
                                              {/* <td>{tour.fechaCancelada}</td> */}
                                              <td style={{ maxWidth: "100px" }}>
                                                  <span
                                                      className={getBadgeStatus(
                                                          tour.status
                                                      )}
                                                      style={{
                                                          maxWidth: "100%",
                                                      }}
                                                  >
                                                      {tour.status}
                                                  </span>
                                              </td>
                                              <td
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >
                                                  <Link
                                                      to={`/editartour/${tour.id}`}
                                                      className="btn btn-success"
                                                      style={{ color: "white" }}
                                                  >
                                                      <i className="fas fa-pencil-alt"></i>
                                                  </Link>
                                              </td>
                                              <td
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >
                                                  {tour.status === "Pausado" ? (
                                                      <button
                                                          className="btn btn-info"
                                                          style={{
                                                              color: "white",
                                                          }}
                                                          onClick={() => {
                                                              activarTour(
                                                                  tour.id
                                                              );
                                                          }}
                                                      >
                                                          <i className="fas fa-play"></i>
                                                      </button>
                                                  ) : (
                                                      <button
                                                          className="btn btn-warning"
                                                          style={{
                                                              color: "white",
                                                          }}
                                                          onClick={() => {
                                                              pausarTour(
                                                                  tour.id
                                                              );
                                                          }}
                                                      >
                                                          <i className="fas fa-pause"></i>
                                                      </button>
                                                  )}
                                              </td>
                                              <td>
                                                  <button
                                                      className="btn btn-danger"
                                                      style={{ color: "white" }}
                                                      onClick={() => {
                                                          cancelarTour(tour.id);
                                                      }}
                                                  >
                                                      <i className="fas fa-ban"></i>
                                                  </button>
                                              </td>
                                          </tr>
                                      ))}
                        </tbody>
                    </table>
                </div>

                <div className="d-flex justify-content-center mb-3">
                    <ReactPaginate
                        containerClassName="pagination"
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        breakClassName="page-item"
                        previousLabel={"Anterior"}
                        nextLabel={"Siguiente"}
                        breakLabel={"..."}
                        pageClassName="page-item"
                        pageCount={pagination.pageCount}
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        activeLinkClassName="paginate"
                        onPageChange={(e) => {
                            handlePageClick(e);
                        }}
                        forcePage={pagination.currentPage}
                    />
                </div>
            </div>
        </Fragment>
    );
}
