import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ProfilePicture from '@dsalvagni/react-profile-picture';
import '@dsalvagni/react-profile-picture/dist/ProfilePicture.css';
import { genImageName, uploadImage, deleteImage } from '../../Util/Imagen';

export default function ModalSubirFoto(props) {
    const profilePictureRef = React.createRef();

    const handleUpload = (e) => {
        const PP = profilePictureRef.current;
        const ext = PP.state.file.name.split('.')[1];
        const imageAsDataURL = PP.getImageAsDataUrl();
        const imageName = genImageName(ext);

        if (props?.noUpload) {
            props.actualizarFoto(imageAsDataURL, imageName);
            props.onHide();
        } else {
            uploadImage(imageAsDataURL.split(',')[1], 'profilePic/' + imageName)
                .then((url) => {
                    props.actualizarFoto(url, imageName);
                    return url;
                })
                .then(() => {
                    if (!props.prevImage) return null;
                    return deleteImage(`profilePic/${props.prevImage}`);
                })
                .then(() => {
                    props.onHide();
                });
        }
    };

    return (
        <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProfilePicture ref={profilePictureRef} useHelper={true} cropSize={300} frameFormat='circle' />
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-itoour' onClick={handleUpload}>
                    {props.noUpload ? 'Confirmar' : 'Subir'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
