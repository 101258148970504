import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PreviewTour from "../../components/Shared/PreviewTour";

export default function ModalPreviewTour({ tour, tipo, onlyIcon = false }) {
    const [show, setShow] = useState(false);

    return (
        <>
            <span onClick={() => setShow(true)} className="mr-2">
                {onlyIcon ? (
                    <button
                        className="btn"
                        style={{
                            color: "white",
                            backgroundColor: "gray",
                        }}
                    >
                        <i className="far fa-eye"></i>
                    </button>
                ) : (
                    <button
                        className="btn"
                        style={{
                            color: "white",
                            height: "46px",
                            backgroundColor: "#E37B24",
                        }}
                    >
                        <i className="far fa-eye"></i> Previsualizar
                    </button>
                )}
            </span>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="modalPreviewTour"
                centered
                scrollable
            >
                <Modal.Body className="accordion-body-modal">
                    <PreviewTour tour={tour} tipo={tipo} />
                </Modal.Body>
            </Modal>
        </>
    );
}
