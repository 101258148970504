import React, { useState, useEffect } from 'react';
import './css/CrearTour.css';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Lista from './Lista';
import TourService from '../../services/Tour';
import { storage } from '../../config/Firebase';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import CrearTourPrimerPaso from './CrearTourPrimerPaso';
import InputMultipleImages from '../../components/Shared/InputMultipleImages';
import CrearTourSegPaso from './CrearTourSegPaso';
import { connect } from 'react-redux';
import CrearTourTercerPaso from './CrearTourTercerPaso';
import fire from '../../config/Firebase';
import { Redirect, withRouter } from 'react-router-dom';
import Departamentos from '../../config/Departamentos.json';
import Provincias from '../../config/Provincias.json';
import Distritos from '../../config/Distritos.json';
import useForm from '../../hooks/useForm';
import { replaceImages } from '../../Util/Imagen';
import ModalPreviewTour from './ModalPreviewTour';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        backgroundColor: '#753183',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
//Por alguna razón no me deja hacer un state de archivos pero si me deja trabajar con una variable
let archivosImagenes = [];
//for que me maneja las tareas asincronas
const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

let cambiarNombre = (nombre) => {
    let randomid = uuidv4();
    let extension = nombre.split('.');
    let nuevonombre = `${randomid}.${extension[1]}`;
    return nuevonombre;
};

//script de subida de archivo unitaria, SOLO SUBE UN ARCHIVO
const handleUpload = (imagen, refStorage) => {
    return new Promise((resolve, reject) => {
        const tarea = refStorage.put(imagen);
        tarea.on(
            'state_changed',
            (snapshot) => {
                const porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log("% subida", porcentaje);
            },
            (err) => {
                // console.error("% error", err);
                reject(err);
            },
            () => {
                tarea.snapshot.ref
                    .getDownloadURL()
                    .then((urlImagen) => {
                        // console.log("%exito", urlImagen);
                        resolve(urlImagen);
                    })
                    .catch((err) => {
                        // console.error("errorURL", err);
                        reject(err);
                    });
            }
        );
    });
};

const iniState = {
    nombre: '',
    descripcion: '',
    youtube: '',
    imgBlob: [],
    precioAdulto: '0',
    precioNino: '0',
    departamentoPartida: '',
    provinciaPartida: '',
    ciudadPartida: '',
    direccionPartida: '',
    departamento: '',
    provincia: '',
    ciudad: '',
    direccion: '',
    dias: '0',
    noches: '0',
    horasTour: '0',
    minTour: '0',
    disponible: [],
    minimoCompra: '1',
    minimoAnticipacion: '1',
    'tipo-duracion': 'dias',
};

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'nombre': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s\?\.\,;:¡!¿]{10,}/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 10
                            ? 'El nombre del tour no puede tener menos de 10 caracteres'
                            : 'Sólo se permiten letras, espacios y !¡¿?,;:'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'descripcion': {
                validates.push({
                    name,
                    isValid: !!formState[name]?.length && formState[name].length >= 30,
                    message: !formState[name]?.length
                        ? 'El campo es requerido.'
                        : 'La descripción debe tener como mínimo 30 caracteres.',
                });
                break;
            }

            case 'youtube': {
                validates.push({
                    name,
                    isValid:
                        !formState[name] || /^(https:\/\/)*(www\.)*youtube\.com\/watch\?v=\w+/g.test(formState[name]),
                    message: 'No es una url a un video de youtube válida.',
                });
                break;
            }

            case 'imgBlob': {
                validates.push({
                    name,
                    isValid: formState[name].length > 3,
                    message: 'Se necesitan mínimo 4 imágenes.',
                });
                break;
            }

            case 'precioAdulto': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*(\.){0,1}[0-9]{0,2}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? !/^[-\+]*[0-9\.]+$/i.test(formState[name])
                            ? 'Sólo se permiten punto y números.'
                            : formState[name]?.split('.')[1]?.length > 2
                            ? 'Sólo se permiten 2 decimales'
                            : 'El precio no puede ser menor a 20.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'precioNino': {
                validates.push({
                    name,
                    isValid:
                        !formState[name].length ||
                        !+formState[name] ||
                        /^[1-9]+[0-9]*(\.){0,1}[0-9]{0,2}$/i.test(formState[name]),
                    message: !/^[-\+]*[0-9\.]+$/i.test(formState[name])
                        ? 'Sólo se permiten punto y números.'
                        : formState[name]?.split('.')[1]?.length > 2
                        ? 'Sólo se permiten 2 decimales'
                        : 'El precio no puede ser menor a 10.',
                });
                break;
            }

            case 'departamentoPartida': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'provinciaPartida': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'ciudadPartida': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'direccionPartida': {
                validates.push({
                    name,
                    isValid: /^[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ\.\s]+$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'La dirección no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras, números, espacios y puntos.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'departamento': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'provincia': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'ciudad': {
                validates.push({
                    name,
                    isValid: !!formState[name],
                    message: 'Es un campo requerido',
                });
                break;
            }

            case 'direccion': {
                validates.push({
                    name,
                    isValid: /^[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ\.\s]+$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'La dirección no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras, números, espacios y puntos.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'dias': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'Los días no pueden ser menores a un día.',
                });
                break;
            }

            case 'noches': {
                validates.push({
                    name,
                    isValid: /^[0-9]+(\.){0,1}[0-9]*$/i.test(formState[name]),
                    message: 'No es una cantidad válida.',
                });
                break;
            }

            case 'horasTour': {
                validates.push({
                    name,
                    isValid: /^[0-9]+$/i.test(formState[name]) && +formState[name] > 0 && +formState[name] < 25,
                    message: !formState[name].length
                        ? 'El valor no puede estar vacío.'
                        : +formState[name] < 1
                        ? 'Las horas no pueden ser menores a 1.'
                        : +formState[name] > 24
                        ? 'Las horas no pueden ser mayores a 24 horas.'
                        : 'No es un valor válido.',
                });
                break;
            }

            case 'minTour': {
                validates.push({
                    name,
                    isValid: /^[0-9]+$/i.test(formState[name]) && +formState[name] >= 0 && +formState[name] < 60,
                    message: !formState[name].length
                        ? 'El valor no puede estar vacío.'
                        : +formState[name] < 0
                        ? 'El valor no puede ser negativo.'
                        : +formState[name] > 59
                        ? 'El valor no puede ser mayor a 59.'
                        : 'No es un valor válido.',
                });
                break;
            }

            case 'minimoCompra': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*$/i.test(formState[name]),
                    message: 'El mínimo de compra no puede ser menor a 1.',
                });
                break;
            }

            case 'minimoAnticipacion': {
                validates.push({
                    name,
                    isValid: /^[1-9]+[0-9]*$/i.test(formState[name]),
                    message:
                        formState[name] && +formState[name] > 45
                            ? 'El tiempo de anticipación no debe exceder los 45 días.'
                            : 'El tiempo de anticipación no puede ser menor a 1.',
                });
                break;
            }

            case 'disponible': {
                validates.push({
                    name,
                    isValid: !!formState[name].length,
                    message: 'Debe agregar al menos un turno.',
                });
                break;
            }
        }
    }

    return validates;
};

function CrearTour2(props) {
    // console.log('propscrear', props);
    const [state, setState] = useState({
        uid: '',
        nombre: '',
        descripcion: '',
        precio: '0',
        precioAdulto: '0',
        precioNino: '0',
        moneda: 'PEN',
        fechaini: new Date(),
        //fechafin: new Date(),
        listaIncluye: [],
        listaNoIncluye: [],
        listaActividades: [],
        listaNotas: [],
        listaTerminos: [],
        departamento: '',
        provincia: '',
        ciudad: '',
        direccion: '',
        departamentoPartida: '',
        provinciaPartida: '',
        ciudadPartida: '',
        direccionPartida: '',
        dias: '0',
        noches: '0',
        horasTour: '0',
        minTour: '0',
        partida: '',
        lat: 0,
        lng: 0,
        diario: false,
        activado: false,
        miniatura: 0,
        disponibilidad: 0,
        disponible: [],
        emailcli: '',
        youtube: '',
        fullDay: false,
        minimoCompra: '1',
        minimoAnticipacion: '1',
        tipoAnticipacion: 'dias',
        diasLaborales: [],
        restricciones: false,
    });
    const [imgBlob, setImgBlob] = useState([]);
    //steps
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Información General', 'Precio y Destino', 'Duración y Disponibilidad', 'Opciones y añadidos'];
    const [emptyTime, setEmptyTime] = useState(true);
    const [fieldsChanged, setFieldsChanged] = useState({
        listaActividades: false,
        listaIncluye: false,
        listaTerminos: false,
    });

    const { formState, setFormState, errors, setErrors, handleChange, handleBlur, handleSubmit } = useForm(
        iniState,
        validateForm
    );

    let obtenerCliente = () => {
        return new Promise((resolve, reject) => {
            fire.auth().onAuthStateChanged((user) => {
                if (user) {
                    // resolve(user.uid);
                    resolve(user);
                } else {
                    reject('Error al obtener cliente');
                }
            });
        });
    };

    const resetRestricciones = () => {
        setState({
            ...state,
            tipoAnticipacion: 'dias',
            minimoAnticipacion: '1',
            minimoCompra: '1',
        });
        handleChange({ target: { name: 'minimoAnticipacion', value: '1' } });
        handleChange({ target: { name: 'minimoCompra', value: '1' } });
    };

    useEffect(() => {
        obtenerCliente().then((user) => {
            const db = fire.firestore();

            return db
                .collection('cliente')
                .doc(user.uid)
                .get()
                .then((doc) => {
                    setState({
                        ...state,
                        uid: user.uid,
                        emailcli: user.email,
                        cliente_tipo: doc.data().cliente_tipo,
                        cliente_nombre: doc.data().cliente_nomempresa,
                    });
                });
        });
    }, []);

    const setDias = (value) => {
        setState({
            ...state,
            dias: value,
            noches: `${+value > 0 ? +value - 1 : 0}`,
        });
    };

    const setDiasLaborales = (dias) => {
        setState({
            ...state,
            diasLaborales: [...dias],
        });
    };

    const activarRestricciones = (value) => {
        setState({
            ...state,
            restricciones: value,
        });
    };

    const limpiarInputs = () => {
        setState({
            ...iniState,
            uid: props.auth.uid,
            fechaini: new Date(),
            fechafin: new Date(),
        });
    };

    const setDepartamento = (e) => {
        setState({
            ...state,
            departamento: e.target.value,
            provincia: '',
            ciudad: '',
        });
    };

    const setProvincia = (e) => {
        setState({
            ...state,
            provincia: e.target.value,
            ciudad: '',
        });
    };

    const setCiudad = (e) => {
        setState({
            ...state,
            ciudad: e.target.value,
        });
    };

    const setDepartamentoPartida = (e) => {
        setState({
            ...state,
            departamentoPartida: e.target.value,
            provinciaPartida: '',
            ciudadPartida: '',
        });
    };

    const setProvinciaPartida = (e) => {
        setState({
            ...state,
            provinciaPartida: e.target.value,
            ciudadPartida: '',
        });
    };

    const setCiudadPartida = (e) => {
        setState({
            ...state,
            ciudadPartida: e.target.value,
        });
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const plusStep = () => {
        if (activeStep === 3) {
            return;
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const actualizarFullDay = (value) => {
        setState({
            ...state,
            fullDay: value,
        });
    };

    const actualizarImagenes = (arrImagenes) => {
        archivosImagenes = arrImagenes;
    };

    const seleccionarMiniatura = (index) => {
        setState({
            ...state,
            miniatura: index,
        });
    };

    const actualizarFecha = (date, opcion) => {
        let nuevaFecha = new Date(date);
        setState({
            ...state,
            [opcion]: nuevaFecha,
        });
    };

    const actualizarCoords = (lat, lng) => {
        setState({
            ...state,
            lat: lat,
            lng: lng,
        });
    };

    const anadirOpciones = (item, opcion) => {
        setState({
            ...state,
            [opcion]: [...state[opcion], item],
        });
        handleChange({
            target: {
                name: opcion,
                value: [...state[opcion], item],
            },
        });
    };

    const eliminarOpcion = (index, opcion) => {
        let arrOpciones = [...state[opcion]];
        arrOpciones.splice(index, 1);
        setState({
            ...state,
            [opcion]: [...arrOpciones],
        });
        handleChange({
            target: {
                name: opcion,
                value: [...arrOpciones],
            },
        });
    };

    const anadirDisponibilidad = (turno) => {
        // setState({
        //   ...state,
        //   disponible: [...state.disponible, ...turno],
        // });
        let turnosAAnadir = [...turno];
        let groupTurnos = [...state.disponible, ...turnosAAnadir];
        let orderTurnos = groupTurnos.slice().sort((a, b) => a.dia - b.dia);
        setState({
            ...state,
            disponible: [...orderTurnos],
        });
        handleChange({
            target: {
                name: 'disponible',
                value: [...orderTurnos],
            },
        });
    };

    const eliminarDisponibilidad = (index) => {
        let arrDisponible = [...state.disponible];
        arrDisponible.splice(index, 1);
        setState({
            ...state,
            disponible: [...arrDisponible],
        });
        handleChange({
            target: {
                name: 'disponible',
                value: [...arrDisponible],
            },
        });
    };

    const limpiarTurnos = (e) => {
        e.preventDefault();
        setState({
            ...state,
            disponible: [],
        });
        handleChange({
            target: {
                name: 'disponible',
                value: [],
            },
        });
    };

    let crearTour = async () => {
        Swal.fire({
            icon: 'info',
            title: `Creando tour`,
            text: 'Se está creando tu anuncio, esto puede tardar unos minutos',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        Swal.showLoading();
        let urls = [];
        /*await asyncForEach(archivosImagenes, async (imagen) => {
            let nuevonombre = cambiarNombre(imagen.name);
            const refStorage = storage.ref(`imgsTours/${nuevonombre}`);
            const urlImg = await handleUpload(imagen, refStorage);
            urls.push(urlImg);
        });*/
        const imagenes = imgBlob.map((image) => {
            return {
                extension: image.name.split('.')[1],
                data: image.blob.split(',')[1],
                folder: 'imgsTours',
            };
        });

        urls = await replaceImages(imagenes, []);

        let sTour = new TourService();
        let tour = {
            ...state,
            horasTour: formState?.horasTour || '0',
            minTour: formState?.minTour || '0',
            dias: formState?.dias || '0',
            noches: formState?.noches || '0',
            departamento: state.departamento
                ? Departamentos.find((d) => d.id_ubigeo === state.departamento).nombre_ubigeo
                : '',
            provincia:
                state.departamento && state.provincia
                    ? Provincias[state.departamento].find((p) => p.id_ubigeo === state.provincia).nombre_ubigeo
                    : '',
            ciudad:
                state.departamento && state.provincia && state.ciudad
                    ? Distritos[state.provincia].find((c) => c.id_ubigeo === state.ciudad).nombre_ubigeo
                    : '',
            departamentoPartida: state.departamentoPartida
                ? Departamentos.find((d) => d.id_ubigeo === state.departamentoPartida).nombre_ubigeo
                : '',
            provinciaPartida:
                state.departamentoPartida && state.provinciaPartida
                    ? Provincias[state.departamentoPartida].find((p) => p.id_ubigeo === state.provinciaPartida)
                          .nombre_ubigeo
                    : '',
            ciudadPartida:
                state.departamentoPartida && state.provinciaPartida && state.ciudadPartida
                    ? Distritos[state.provinciaPartida].find((c) => c.id_ubigeo === state.ciudadPartida).nombre_ubigeo
                    : '',
        };

        sTour
            .crearTour(tour, urls)
            .then((res) => {
                return sTour.notificarClienteTour(state.uid, res, null, tour.nombre, 'Pendiente de aprobación', '', 0);
            })
            .then(() => {
                limpiarInputs();
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: `El Tour ha sido creado exitosamente`,
                    text: 'Gracias por ser parte de Itoour, a la brevedad posible un administrador revisará tu anuncio para que todo esté Genial!. Prepárate para recibir a tu primer visitante.',
                    showConfirmButton: true,
                    confirmButtonText: 'Regresar al Dashboard',
                    allowOutsideClick: false,
                }).then(() => {
                    props.history.push('/dashboard');
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: `Ha ocurrido un error`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    const resetDiasHoras = (tipoActual) => {
        if (tipoActual === 'dias') setState({ ...state, minTour: '0', horasTour: '0' });
        else setState({ ...state, diasTour: '0', nochesTour: '0' });
    };

    const mostrarPaso = () => {
        switch (activeStep) {
            case 0:
                return (
                    <div>
                        <CrearTourPrimerPaso
                            value={state}
                            actualizarInput={actualizarInput}
                            formState={formState}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                        <div>
                            <label>Seleccionar Imágenes</label>
                            <InputMultipleImages
                                actualizarImagenes={actualizarImagenes}
                                seleccionarMiniatura={seleccionarMiniatura}
                                imgBlob={formState.imgBlob}
                                setImgBlob={setImgBlob}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors}
                            />

                            <small className='text-secondary'>
                                Seleccione como mínimo 4 imágenes con un máximo de 10 en formato JPG/PNG de 800x600 px,
                                después elija la imagen deseada para la miniatura
                            </small>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <CrearTourSegPaso
                        value={state}
                        actualizarInput={actualizarInput}
                        actualizarFecha={actualizarFecha}
                        actualizarCoords={actualizarCoords}
                        actualizarLugares={{
                            setDepartamentoPartida,
                            setProvinciaPartida,
                            setCiudadPartida,
                            setDepartamento,
                            setProvincia,
                            setCiudad,
                        }}
                        formState={formState}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                    />
                );
            case 2:
                return (
                    <CrearTourTercerPaso
                        value={state}
                        actualizarFecha={actualizarFecha}
                        anadirDisponibilidad={anadirDisponibilidad}
                        eliminarDisponibilidad={eliminarDisponibilidad}
                        disponible={state.disponible}
                        actualizarInput={actualizarInput}
                        actualizarFullDay={actualizarFullDay}
                        formState={formState}
                        setFormState={setFormState}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setErrors={setErrors}
                        setEmptyTime={setEmptyTime}
                        setDias={setDias}
                        resetDiasHoras={resetDiasHoras}
                        setDiasLaborales={setDiasLaborales}
                        activarRestricciones={activarRestricciones}
                        resetRestricciones={resetRestricciones}
                        limpiarTurnos={limpiarTurnos}
                    />
                );
            case 3:
                // El cuarto paso no esta agrupado
                return (
                    <div>
                        <div className='form-group'>
                            <label>Actividades a realizar</label>
                            <Lista
                                place='Ingrese cada actividad a realizar'
                                lista={state.listaActividades}
                                anadir={(item) => anadirOpciones(item, 'listaActividades')}
                                estilo='info'
                                eliminar={(index) => eliminarOpcion(index, 'listaActividades')}
                                nombre='listaActividades'
                                setFieldsChanged={setFieldsChanged}
                                errors={errors}
                                setErrors={setErrors}
                            />
                            {fieldsChanged.listaActividades && !state.listaActividades?.length && (
                                <small className='text-danger'>Debe agregar como mínimo una actividad</small>
                            )}
                        </div>
                        <div className='form-group'>
                            <label>Incluye</label>
                            <Lista
                                place='Ingrese que Incluye el Tour'
                                lista={state.listaIncluye}
                                anadir={(item) => anadirOpciones(item, 'listaIncluye')}
                                estilo='primary'
                                eliminar={(index) => eliminarOpcion(index, 'listaIncluye')}
                                nombre='listaIncluye'
                                setFieldsChanged={setFieldsChanged}
                                errors={errors}
                                setErrors={setErrors}
                            />
                            {fieldsChanged.listaIncluye && !state.listaIncluye?.length && (
                                <small className='text-danger'>Debe agregar como mínimo un elemento.</small>
                            )}
                        </div>
                        <div className='form-group'>
                            <label>No Incluye (Opcional)</label>
                            <Lista
                                place='Ingrese que No Incluye el Tour'
                                lista={state.listaNoIncluye}
                                anadir={(item) => anadirOpciones(item, 'listaNoIncluye')}
                                estilo='danger'
                                eliminar={(index) => eliminarOpcion(index, 'listaNoIncluye')}
                                nombre='listaNoIncluye'
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </div>
                        <div className='form-group'>
                            <label>Notas a considerar (Opcional)</label>
                            <Lista
                                place='Ingrese Notas (información importante) acerca del Tour'
                                lista={state.listaNotas}
                                anadir={(item) => anadirOpciones(item, 'listaNotas')}
                                estilo='warning'
                                eliminar={(index) => eliminarOpcion(index, 'listaNotas')}
                                nombre='listaNotas'
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </div>
                        <div className='form-group'>
                            <label>Términos y Condiciones</label>
                            <Lista
                                place='Ingrese las clausulas a considerar acerca del tour'
                                lista={state.listaTerminos}
                                anadir={(item) => anadirOpciones(item, 'listaTerminos')}
                                estilo='warning'
                                eliminar={(index) => eliminarOpcion(index, 'listaTerminos')}
                                nombre='listaTerminos'
                                setFieldsChanged={setFieldsChanged}
                                errors={errors}
                                setErrors={setErrors}
                            />
                            {fieldsChanged.listaTerminos && !state.listaTerminos?.length && (
                                <small className='text-danger'>Los términos y condiciones son requeridos.</small>
                            )}
                        </div>
                        <button
                            className='btn btn-itoour btn-lg btn-block mb-4 mt-5'
                            onClick={handleSubmit(crearTour)}
                            disabled={existEmptyRequiredFields(selectFields(activeStep)) || Object.keys(errors).length}
                        >
                            Guardar Tour
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    const existEmptyRequiredFields = (fields) => {
        return fields.some((key) => {
            if (Array.isArray(formState[key])) return !formState[key].length;
            else if (/^[0-9\.]+$/i.test(formState[key])) return +formState[key] <= 0;
            else return !formState[key];
        });
    };

    const selectFields = (step) => {
        switch (step) {
            case 0:
                return ['nombre', 'descripcion', 'imgBlob'];
            case 1:
                return [
                    'precioAdulto',
                    'departamentoPartida',
                    'provinciaPartida',
                    'ciudadPartida',
                    'direccionPartida',
                    'departamento',
                    'provincia',
                    'ciudad',
                    'direccion',
                ];
            case 2:
                return ['disponible'];
            case 3:
                return ['listaIncluye', 'listaActividades', 'listaTerminos'];
            default:
                return [];
        }
    };

    let cancelar = () => {
        Swal.fire({
            title: 'Desea cancelar la creación del Tour?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, cancelar',
        }).then((result) => {
            if (result.value) {
                props.history.push('/dashboard');
            }
        });
    };

    return (
        <div>
            <div className='container mt-4'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='text-itoour'>Crear Tour</h1>
                    <div>
                        <ModalPreviewTour
                            tour={{
                                ...state,
                                tour_nom: state.nombre,
                                tour_departamento: Departamentos.find((d) => d.id_ubigeo === state.departamento)
                                    ?.nombre_ubigeo,
                                tour_moneda: state.moneda,
                                tour_precioAdulto: state.precioAdulto,
                                tour_precioNino: state.precioNino,
                                tour_imagenes: imgBlob.map((image) => image.blob),
                                tour_desc: state.descripcion,
                                tour_youtube: state.youtube,
                                tour_activ: state.listaActividades,
                                tour_inc: state.listaIncluye,
                                tour_noinc: state.listaNoIncluye,
                                tour_notas: state.listaNotas,
                                tour_terminos: state.listaTerminos,
                            }}
                            tipo={props.rol}
                        />
                        <button
                            className='btn btn-danger'
                            onClick={() => {
                                cancelar();
                            }}
                            style={{ color: 'white', height: '46px' }}
                        >
                            <i className='fas fa-times-circle mr-1'></i>Cancelar
                        </button>
                    </div>
                </div>
                {/* stepini */}
                <div className={classes.root}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    .
                                    <StepButton onClick={handleStep(index)} disabled {...buttonProps}>
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <hr />
                {/* stepfin */}
                {mostrarPaso()}
                <div className='d-flex justify-content-between mt-5' style={{ zIndex: '1000' }}>
                    {activeStep === 0 ? null : (
                        <button
                            className='btn btn-itoour btn-lg'
                            onClick={() => {
                                setActiveStep(activeStep - 1);
                            }}
                            style={{ zIndex: '1001' }}
                        >
                            Anterior
                        </button>
                    )}
                    <div></div>
                    {activeStep === 3 ? null : (
                        <button
                            className='btn btn-itoour btn-lg'
                            onClick={() => {
                                plusStep();
                            }}
                            style={{ zIndex: '1001' }}
                            disabled={
                                (+activeStep === 2 && emptyTime) ||
                                existEmptyRequiredFields(selectFields(activeStep)) ||
                                Object.keys(errors).length
                            }
                        >
                            Siguiente
                        </button>
                    )}
                </div>
                <div style={{ height: '2rem' }}></div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
    };
};

export default withRouter(connect(mapStateToProps)(CrearTour2));

// export default CrearTour2;
