import React, { useState, useEffect, useMemo } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Form } from 'react-bootstrap';
import { toHaveFormValues } from '@testing-library/jest-dom';

export default function CrearTourTercerPaso({
    value,
    actualizarFecha,
    anadirDisponibilidad,
    eliminarDisponibilidad,
    disponible,
    actualizarInput,
    actualizarFullDay,
    formState,
    setFormState,
    errors,
    setErrors,
    handleChange,
    handleBlur,
    setEmptyTime,
    setDias,
    resetDiasHoras,
    setDiasLaborales,
    activarRestricciones,
    resetRestricciones,
    limpiarTurnos,
}) {
    const [dia, setDia] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [fecha, setFecha] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [fechaFinTour, setFechaFinTour] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [capacidad, setCapacidad] = useState('1');
    const [capacidadEstatica, setCapacidadEstatica] = useState('1');
    const [disabled, setDisabled] = React.useState(false);
    const [disabledDisponibilidad, setDisabledDisponibilidad] = React.useState(false);
    const [fullDayActive, setFullDayActive] = useState(false);
    const [tipoDuracion, setTipoDuracion] = useState('dias');
    const [tipoDisponibilidad, setTipoDisponibilidad] = useState('ilimitado');
    const [diasActivos, setDiasActivos] = useState({
        todos: true,
        lunes: true,
        martes: true,
        miércoles: true,
        jueves: true,
        viernes: true,
        sábado: true,
        domingo: true,
    });

    const onChangeTodos = (e) => {
        if (e.target.checked) {
            setDiasActivos({
                todos: true,
                lunes: true,
                martes: true,
                miércoles: true,
                jueves: true,
                viernes: true,
                sábado: true,
                domingo: true,
            });
            setDiasLaborales(['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo']);
        } else {
            setDiasActivos({
                ...diasActivos,
                todos: false,
            });
        }
    };

    const onChangeDia = (dia) => {
        return (e) => {
            let activos = {};

            if (e.target.checked) activos = { ...diasActivos, [dia]: true };
            else activos = { ...diasActivos, todos: false, [dia]: false };

            setDiasActivos(activos);

            const diasHabilitados = Object.keys(activos).filter((key) => activos[key]);
            const { diasSeleccionados, ...restErrors } = errors;

            setDiasLaborales(diasHabilitados);

            if (!diasHabilitados.length) {
                setErrors({
                    ...restErrors,
                    diasSeleccionados: 'Debe seleccionar al menos un día laboral.',
                });
            } else {
                setErrors({ ...restErrors });
            }
        };
    };

    useEffect(() => {
        if (+value.horas + +value.minutos > 0) {
            setTipoDuracion('horas');
        } else {
            setTipoDuracion('dias');
        }
    }, [value.horas, value.minutos]);

    const actualizarFechaTurno = (date) => {
        setFecha(new Date(date));
    };

    const actualizarFechaFin = (date) => {
        setFechaFinTour(new Date(date));
    };

    const disponibilidad = (event) => {
        const value = event.target.value;
        setTipoDisponibilidad(value);
    };

    const variosDias = () => {
        let dt1 = fecha;
        let dt2 = fechaFinTour;
        let resultado;

        tipoDisponibilidad === 'fechas'
            ? (resultado =
                  Math.floor(
                      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
                          (1000 * 60 * 60 * 24)
                  ) + 1)
            : tipoDisponibilidad === 'ilimitado'
            ? (resultado = 180)
            : (resultado = 1);

        return resultado;
    };

    let crearVariosTurnos = () => {
        return new Promise((resolve, reject) => {
            let arrTurnos = [];
            let i = 0;
            let newDias;
            const diasHabilitados = Object.keys(diasActivos).filter((key) => diasActivos[key]);

            newDias = variosDias();

            for (i; i < newDias; i++) {
                if (tipoDisponibilidad !== 'unico') {
                    let diaSgte = new Date(fecha.getTime() + i * 86400000);

                    const nombreDia = diaSgte.toLocaleDateString('es-ES', {
                        weekday: 'long',
                    });

                    if (diasHabilitados.includes(nombreDia)) {
                        //let objTurno = { dia: diaSgte, capacidad, capacidadEstatica };
                        let cur_dia = new Date(fecha.getTime());
                        cur_dia.setHours(0, 0, 0, 0);
                        let cur_turno = new Date(dia.getTime());
                        let aux = new Date(dia.getTime());
                        aux.setHours(0, 0, 0, 0);
                        cur_turno = Math.floor(cur_turno.getTime() - aux.getTime()) / 1000;
                        let horas = Math.floor(cur_turno / 3600);
                        cur_turno = cur_turno % 3600;
                        let minutos = Math.floor(cur_turno / 60);

                        diaSgte.setHours(horas, minutos, 0, 0);

                        let objTurno = {
                            dia: diaSgte,
                            capacidad,
                            capacidadEstatica,
                        };
                        arrTurnos.push(objTurno);
                    }
                } else {
                    let diaSgte = new Date(fecha.getTime() + i * 86400000);
                    let cur_dia = new Date(fecha.getTime());
                    cur_dia.setHours(0, 0, 0, 0);
                    let cur_turno = new Date(dia.getTime());
                    let aux = new Date(dia.getTime());
                    aux.setHours(0, 0, 0, 0);
                    cur_turno = Math.floor(cur_turno.getTime() - aux.getTime()) / 1000;
                    let horas = Math.floor(cur_turno / 3600);
                    cur_turno = cur_turno % 3600;
                    let minutos = Math.floor(cur_turno / 60);

                    diaSgte.setHours(horas, minutos, 0, 0);

                    let objTurno = {
                        dia: diaSgte,
                        capacidad,
                        capacidadEstatica,
                    };
                    arrTurnos.push(objTurno);
                }
            }
            resolve(arrTurnos);
        });
    };
    let anadirTurno = () => {
        // if (dias === 1) {
        //   let objTurno = { dia, capacidad };
        //   // console.log("turnopaso", objTurno);
        //   anadirDisponibilidad(objTurno);
        // } else {
        //   crearVariosTurnos().then((turnos) => {
        //     // console.log("crearVariosTurnos",turnos)
        //     anadirDisponibilidad(turnos);
        //   });
        // }
        crearVariosTurnos().then((turnos) => {
            anadirDisponibilidad(turnos);
        });
    };

    let limiteDias = () => {
        let dt1 = value.fechaini;
        let dt2 = value.fechafin;
        return Math.floor(
            (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
                (1000 * 60 * 60 * 24)
        );
    };

    const onChange = (event) => {
        const value1 = event.target.value;
        setTipoDuracion(value1);
        setEmptyTime(true);
        handleChange(event);
    };

    const fullDay = (event) => {
        const value = event.target.checked;
        value === true ? setDisabled(true) : setDisabled(false);
        value === true ? setFullDayActive(true) : setFullDayActive(false);
    };

    const existEmptyRequiredFields = (fields) => {
        return fields.some((key) => {
            if (Array.isArray(formState[key])) return !formState[key].length;
            else if (/^[0-9\.]+$/i.test(formState[key])) return +formState[key] <= 0;
            else return !formState[key];
        });
    };

    const tiempoAnticipacion = useMemo(() => {
        const tiempoSeleccionado = value.tipoActicipacion === 'meses' ? 30 : 1;
        const diasAgregar = +formState.minimoAnticipacion * tiempoSeleccionado * 24 * 60 * 60 * 1000;
        const tiempoEstimado = new Date(new Date().getTime() + diasAgregar);

        tiempoEstimado.setHours(0, 0, 0, 0);

        if (tiempoEstimado.getTime() > fecha.getTime()) {
            setFecha(tiempoEstimado);
            if (fechaFinTour.getTime() < tiempoEstimado.getTime()) setFechaFinTour(tiempoEstimado);
        } else if (fechaFinTour.getTime() < fecha.getTime()) setFechaFinTour(fecha);

        return tiempoEstimado;
    }, [formState.minimoAnticipacion]);

    useEffect(() => {
        var { dias, noches, horasTour, minTour, ...noTimeState } = formState;

        if (formState['tipo-duracion'] === 'dias') {
            setFormState({
                ...noTimeState,
                dias: dias || '0',
                noches: noches || '0',
            });
            resetDiasHoras('dias');
        } else {
            setFormState({
                ...noTimeState,
                horasTour: horasTour || '0',
                minTour: minTour || '0',
            });
            resetDiasHoras('horas');
        }

        var { dias, noches, horasTour, minTour, ...noTimeErrors } = errors;

        setErrors(noTimeErrors);
    }, [tipoDuracion]);

    useEffect(() => {
        let activos = {
            todos: false,
            lunes: false,
            martes: false,
            miércoles: false,
            jueves: false,
            viernes: false,
            sábado: false,
            domingo: false,
        };

        if (value?.diasLaborales?.length) {
            for (let i = 0; i < value.diasLaborales.length; i++) {
                activos[value.diasLaborales[i]] = true;
            }
        } else {
            activos = {
                todos: true,
                lunes: true,
                martes: true,
                miércoles: true,
                jueves: true,
                viernes: true,
                sábado: true,
                domingo: true,
            };
        }

        setDiasActivos(activos);
    }, [value.uid]);

    useEffect(() => {
        if (!value?.restricciones) resetRestricciones();
    }, [value.restricciones]);

    const getHoras = (date) => {
        let ampm = date?.toLocaleString('en-GB', { hour12: true })?.split(' ')[2];
        let [h, m, s] = date?.toLocaleString('en-GB')?.split(',')[1]?.substring(1)?.split(':');

        h = +h > 12 ? +h - 12 : h;

        return `${h}:${m}:${s} ${ampm}`;
    };

    return (
        <div>
            <div className='form-row'>
                {/*<div className="form-group col-md-6">
                    <label>Inicio Publicación</label>
                    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            name="fechaini"
                            className="form-control"
                            disablePast={true}
                            value={value.fechaini}
                            onChange={(e) => {
                                actualizarFecha(e, "fechaini");
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>*/}
                <div className='form-group col-md-6'>
                    <label>Tipo de duración</label>
                    <br />
                    <div className='form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='tipo-duracion'
                            value='dias'
                            checked={formState['tipo-duracion'] === 'dias'}
                            onChange={onChange}
                        ></input>
                        <label className='form-check-label'>Dias</label>
                    </div>
                    <div className='form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='tipo-duracion'
                            value='horas'
                            onChange={onChange}
                            checked={formState['tipo-duracion'] === 'horas'}
                        />
                        <label className='form-check-label'>Horas</label>
                    </div>
                </div>
            </div>
            <div>
                {formState['tipo-duracion'] === 'dias' ? (
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label>Días</label>
                            <input
                                type='number'
                                className='form-control'
                                min='0'
                                name='dias'
                                value={formState.dias}
                                onChange={(e) => {
                                    setDias(e.target.value);
                                    handleChange(e);
                                    handleChange({
                                        target: {
                                            name: 'noches',
                                            value: `${+e.target.value > 0 ? +e.target.value - 1 : '0'}`,
                                        },
                                    });
                                    //setEmptyTime(
                                    //    tipoDuracion === 'dias'
                                    //        ? existEmptyRequiredFields(['dias'])
                                    //        : existEmptyRequiredFields(['horasTour'])
                                    //);
                                    setEmptyTime(!e.target?.value || +e.target.value <= 0);
                                    handleBlur(e);
                                }}
                                onBlur={handleBlur}
                            />
                            {errors.dias && <small className='text-danger'>{errors.dias}</small>}
                        </div>
                        <div className='form-group col-md-6'>
                            <label>Noches</label>
                            <input
                                type='number'
                                min={`${+formState.dias > 0 ? +formState.dias - 1 : 0}`}
                                max={`${+formState.dias + 1}`}
                                className='form-control'
                                name='noches'
                                value={formState.noches}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setEmptyTime(
                                        tipoDuracion === 'dias'
                                            ? existEmptyRequiredFields(['dias'])
                                            : existEmptyRequiredFields(['horasTour'])
                                    );
                                    handleBlur(e);
                                }}
                                onBlur={handleBlur}
                            />
                            {errors.noches && <small className='text-danger'>{errors.noches}</small>}
                        </div>
                    </div>
                ) : (
                    <div className='form-row'>
                        <p className='text-warning font-weight-bold col-md-10'>
                            Si el tour es de mas de 8 horas se considera full day.
                        </p>
                        <br />
                        <div className='form-group col-md-4'>
                            <label>Horas</label>
                            <input
                                disabled={disabled}
                                type='number'
                                min='0'
                                max='23'
                                className='form-control'
                                name='horasTour'
                                value={formState.horasTour}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setEmptyTime(!e.target?.value || +e.target.value <= 0);
                                    if ((+e.target?.value || 0) + (+formState?.minTour / 60 || 0) >= 8) {
                                        actualizarFullDay(true);
                                    } else {
                                        actualizarFullDay(false);
                                    }
                                    handleBlur(e);
                                }}
                                onBlur={handleBlur}
                            />
                            {errors.horasTour && <small className='text-danger'>{errors.horasTour}</small>}
                        </div>
                        <div className='form-group col-md-4'>
                            <label>Minutos</label>
                            <input
                                disabled={disabled}
                                type='number'
                                min='0'
                                max='59'
                                className='form-control'
                                name='minTour'
                                value={formState.minTour}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setEmptyTime(
                                        tipoDuracion === 'dias'
                                            ? existEmptyRequiredFields(['dias'])
                                            : existEmptyRequiredFields(['horasTour'])
                                    );
                                    if ((+formState.horasTour || 0) + (+e.target?.value / 60 || 0) >= 8) {
                                        actualizarFullDay(true);
                                    } else {
                                        actualizarFullDay(false);
                                    }
                                    handleBlur(e);
                                }}
                                onBlur={handleBlur}
                            />
                            {errors.minTour && <small className='text-danger'>{errors.minTour}</small>}
                        </div>
                        <div className='col-md-4'>
                            <div
                                style={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                {value.fullDay && !errors.horasTour && !errors.minTour && (
                                    <span
                                        className='pl-3 pt-3'
                                        style={{
                                            fontWeight: 'bold',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Full Day
                                    </span>
                                )}
                            </div>
                        </div>
                        {/*<div className='form-group col-md-4'>
                            <div>
                                <FormControlLabel
                                    label='Full Day'
                                    control={
                                        <Checkbox
                                            //checked={state.checkedB}
                                            onChange={fullDay}
                                            onClick={(e) => {
                                                actualizarFullDay(e);
                                            }}
                                            name='fullDay'
                                            color='primary'
                                        />
                                    }
                                />
                                <br />
                            </div>
                                </div>*/}
                    </div>
                )}
            </div>

            <div className='form-group'>
                <label>Condiciones de Reserva</label>
                <div className='restricciones'>
                    <div>
                        <span>
                            Puede activar el siguiente apartado para especificar: el tiempo mínimo de anticipación y, la
                            cantidad de viajeros y/o turistas que serán necesarios para llevar a cabo la reserva del
                            servicio. De no activarlo, se considerará por defecto "1 día de anticipación" y a "1
                            turista" como mínimo para poder apartar y/o finalizar la compra.
                        </span>
                    </div>
                    <Form.Check
                        inline
                        label=''
                        name='group0'
                        type='checkbox'
                        className='check-dia'
                        checked={value?.restricciones}
                        onChange={(e) => {
                            activarRestricciones(e.target.checked);
                        }}
                    />
                </div>
                <br />
                {value?.restricciones ? (
                    <>
                        <div className='form-group col-md-6'>
                            <label>Tiempo de anticipación</label>
                            <div className='box'>
                                <div className='form-check'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='tipoAnticipacion'
                                        value='dias'
                                        checked={value.tipoAnticipacion === 'dias'}
                                        onChange={actualizarInput}
                                    ></input>
                                    <label className='form-check-label'>Días</label>
                                </div>
                                <div className='form-check'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='tipoAnticipacion'
                                        value='meses'
                                        onChange={actualizarInput}
                                        checked={value.tipoAnticipacion === 'meses'}
                                    ></input>
                                    <label className='form-check-label'>Meses</label>
                                </div>
                            </div>
                        </div>
                        <div className='form-check col-md-6'>
                            <small className='text-secondary'>
                                {value.tipoAnticipacion === 'dias' ? 'Días de Anticipación' : 'Meses de Anticipación'}
                            </small>
                            <input
                                type='number'
                                min='1'
                                max={value.tipoAnticipacion === 'dias' ? '45' : '6'}
                                className='form-control'
                                placeholder={value.tipoAnticipacion === 'dias' ? 'Días' : 'Meses'}
                                value={formState.minimoAnticipacion}
                                name='minimoAnticipacion'
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                }}
                                onBlur={(e) => {
                                    const d = {
                                        target: {
                                            name: 'minimoAnticipacion',
                                            value: '1',
                                        },
                                    };

                                    if (!e.target.value || +e.target.value < 1) {
                                        actualizarInput(d);
                                        handleChange(d);
                                    }

                                    if (value.tipoAnticipacion === 'dias') {
                                        const { minimoAnticipacion, ...restErrors } = errors;

                                        if (e.target?.value && +e.target.value > 45) {
                                            setErrors({
                                                ...restErrors,
                                                minimoAnticipacion:
                                                    'El tiempo de anticipación no debe exceder los 45 días.',
                                            });
                                        } else setErrors({ ...restErrors });
                                    } else if (value.tipoAnticipacion === 'meses') {
                                        const { minimoAnticipacion, ...restErrors } = errors;

                                        if (e.target?.value && +e.target.value > 6) {
                                            setErrors({
                                                ...restErrors,
                                                minimoAnticipacion:
                                                    'El tiempo de anticipación no debe exceder los 6 meses.',
                                            });
                                        } else setErrors({ ...restErrors });
                                    }
                                }}
                            />
                            {errors.minimoAnticipacion && (
                                <small className='text-danger'>{errors.minimoAnticipacion}</small>
                            )}
                        </div>
                        <br />
                        <label className='ml-3'>Mínimo de turistas por reservación</label>
                        <div className='form-check col-md-6'>
                            <small className='text-secondary'>Turistas</small>
                            <input
                                type='number'
                                min='1'
                                className='form-control'
                                placeholder='Turistas'
                                value={formState.minimoCompra}
                                name='minimoCompra'
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    if (+e.target.value > capacidad) {
                                        setCapacidad(e.target.value);
                                        setCapacidadEstatica(e.target.value);
                                    }
                                }}
                                onBlur={(e) => {
                                    const d = {
                                        target: {
                                            name: 'minimoCompra',
                                            value: '1',
                                        },
                                    };

                                    if (!e.target.value || +e.target.value < 1) {
                                        actualizarInput(d);
                                        handleChange(d);
                                    }

                                    const { minimoCompra, ...restErrors } = errors;

                                    if (+formState.minimoCompra > 10) {
                                        setErrors({
                                            ...restErrors,
                                            minimoCompra:
                                                'El mínimo de turistas por reservación no puede ser mayor a 10.',
                                        });
                                    } else setErrors({ ...restErrors });
                                }}
                            />
                            {errors.minimoCompra && <small className='text-danger'>{errors.minimoCompra}</small>}
                        </div>
                    </>
                ) : null}
            </div>

            <div className='form-group'>
                <label>Agregar Disponibilidad</label>
                <br />
                <div className='form-group col-md-6'>
                    <label>Tipo de Disponibilidad</label>
                    <div className='box tipo-turnos'>
                        <div className='form-check'>
                            <input
                                type='radio'
                                className='form-check-input'
                                name='tipo-disponible'
                                value='ilimitado'
                                onChange={(e) => {
                                    disponibilidad(e);
                                    setEmptyTime(
                                        tipoDuracion === 'dias'
                                            ? existEmptyRequiredFields(['dias', 'noches'])
                                            : existEmptyRequiredFields(['horasTour', 'minTour'])
                                    );
                                }}
                                checked={tipoDisponibilidad === 'ilimitado'}
                            ></input>
                            <label className='form-check-label'>Ilimitado</label>
                        </div>
                        <div className='form-check'>
                            <input
                                type='radio'
                                className='form-check-input'
                                name='tipo-disponible'
                                value='fechas'
                                onChange={disponibilidad}
                                checked={tipoDisponibilidad === 'fechas'}
                            ></input>
                            <label className='form-check-label'>Rango de fechas</label>
                        </div>
                        <div className='form-check'>
                            <input
                                type='radio'
                                className='form-check-input'
                                name='tipo-disponible'
                                value='unico'
                                checked={tipoDisponibilidad === 'unico'}
                                onChange={disponibilidad}
                            ></input>
                            <label className='form-check-label'>Fecha única</label>
                        </div>
                    </div>
                </div>
                {tipoDisponibilidad !== 'unico' ? (
                    <div className='form-group col-md-12'>
                        <label>Días Laborables</label>
                        <div className='mb-3'>
                            <Form.Check
                                inline
                                label='Todos'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.todos}
                                onChange={onChangeTodos}
                            />
                            <Form.Check
                                inline
                                label='Lunes'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.lunes}
                                onChange={onChangeDia('lunes')}
                            />
                            <Form.Check
                                inline
                                label='Martes'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.martes}
                                onChange={onChangeDia('martes')}
                            />
                            <Form.Check
                                inline
                                label='Miércoles'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.miércoles}
                                onChange={onChangeDia('miércoles')}
                            />
                            <Form.Check
                                inline
                                label='Jueves'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.jueves}
                                onChange={onChangeDia('jueves')}
                            />
                            <Form.Check
                                inline
                                label='Viernes'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.viernes}
                                onChange={onChangeDia('viernes')}
                            />
                            <Form.Check
                                inline
                                label='Sábado'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.sábado}
                                onChange={onChangeDia('sábado')}
                            />
                            <Form.Check
                                inline
                                label='Domingo'
                                name='group1'
                                type='checkbox'
                                className='check-dia'
                                checked={diasActivos.domingo}
                                onChange={onChangeDia('domingo')}
                            />
                        </div>
                        {errors.diasSeleccionados && <small className='text-danger'>{errors.diasSeleccionados}</small>}
                    </div>
                ) : null}
                <div>
                    {tipoDisponibilidad === 'unico' ? (
                        <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant='inline'
                                            format='DD/MM/YYYY'
                                            margin='normal'
                                            name='fecha'
                                            label='Día del Tour'
                                            className='form-control'
                                            minDate={tiempoAnticipacion}
                                            disablePast={true}
                                            value={fecha}
                                            onChange={(e) => {
                                                actualizarFechaTurno(e);
                                                setEmptyTime(
                                                    tipoDuracion === 'dias'
                                                        ? existEmptyRequiredFields(['dias', 'noches'])
                                                        : existEmptyRequiredFields(['horasTour', 'minTour'])
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-md-3'>
                                    <KeyboardTimePicker
                                        variant='inline'
                                        margin='normal'
                                        id='time-picker'
                                        className='form-control'
                                        label='Hora de inicio del tour'
                                        value={dia}
                                        onChange={(e) => {
                                            setDia(new Date(e));
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                    />
                                </div>

                                <div className='col-md-3 pt-2'>
                                    <small className='text-secondary'>
                                        {!fullDayActive && tipoDuracion !== 'dias'
                                            ? 'Capacidad por turno'
                                            : 'Capacidad total'}
                                    </small>
                                    <input
                                        type='number'
                                        min={formState.minimoCompra || '1'}
                                        className='form-control-sm'
                                        placeholder='Capacidad del Tour'
                                        value={capacidad}
                                        onChange={(e) => {
                                            setCapacidad(e.target.value);
                                            setCapacidadEstatica(e.target.value);
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                        onBlur={(e) => {
                                            if (!capacidad || +capacidad < +formState.minimoCompra) {
                                                setCapacidad(+formState.minimoCompra);
                                                setCapacidadEstatica(+formState.minimoCompra);
                                            }
                                        }}
                                    />
                                </div>
                                <div className='col-md-12'>
                                    <button
                                        className='btn btn-primary mt-4 mr-2'
                                        onClick={() => {
                                            anadirTurno();
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                    >
                                        <i className='fas fa-plus mr-1'></i>
                                        Agregar
                                    </button>
                                    <button
                                        className='btn btn-primary mt-4'
                                        onClick={(e) => {
                                            limpiarTurnos(e);
                                        }}
                                        disabled={!disponible?.length}
                                    >
                                        <i className='fas fa-plus mr-1'></i>
                                        Limpiar
                                    </button>
                                </div>
                            </div>
                        </MuiPickersUtilsProvider>
                    ) : tipoDisponibilidad === 'fechas' ? (
                        <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant='inline'
                                            format='DD/MM/YYYY'
                                            margin='normal'
                                            name='fecha'
                                            label='Primera fecha del tour'
                                            minDate={tiempoAnticipacion}
                                            className='form-control'
                                            disablePast={true}
                                            value={fecha}
                                            onChange={(e) => {
                                                actualizarFechaTurno(e);
                                                new Date(e).getTime() > fechaFinTour.getTime() && actualizarFechaFin(e);
                                                setEmptyTime(
                                                    tipoDuracion === 'dias'
                                                        ? existEmptyRequiredFields(['dias', 'noches'])
                                                        : existEmptyRequiredFields(['horasTour', 'minTour'])
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-md-3'>
                                    <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant='inline'
                                            format='DD/MM/YYYY'
                                            margin='normal'
                                            name='fechaFinTour'
                                            label='Última fecha del tour'
                                            className='form-control'
                                            minDate={fecha}
                                            disablePast={true}
                                            value={fechaFinTour}
                                            onChange={(e) => {
                                                actualizarFechaFin(e);
                                                setEmptyTime(
                                                    tipoDuracion === 'dias'
                                                        ? existEmptyRequiredFields(['dias', 'noches'])
                                                        : existEmptyRequiredFields(['horasTour', 'minTour'])
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-md-3'>
                                    <KeyboardTimePicker
                                        variant='inline'
                                        margin='normal'
                                        id='time-picker'
                                        className='form-control'
                                        label='Hora de inicio del tour'
                                        value={dia}
                                        onChange={(e) => {
                                            setDia(new Date(e));
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                    />
                                </div>

                                <div className='col-md-3 pt-2'>
                                    <small className='text-secondary'>
                                        {!fullDayActive && tipoDuracion !== 'dias'
                                            ? 'Capacidad por turno'
                                            : 'Capacidad total'}
                                    </small>
                                    <input
                                        type='number'
                                        min={formState.minimoCompra || '1'}
                                        className='form-control-sm'
                                        placeholder='Capacidad del Tour'
                                        value={capacidad}
                                        onChange={(e) => {
                                            setCapacidad(e.target.value);
                                            setCapacidadEstatica(e.target.value);
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                        onBlur={(e) => {
                                            if (!capacidad || +capacidad < +formState.minimoCompra) {
                                                setCapacidad(+formState.minimoCompra);
                                                setCapacidadEstatica(+formState.minimoCompra);
                                            }
                                        }}
                                    />
                                </div>
                                <div className='col-md-12'>
                                    <button
                                        className='btn btn-primary mt-4 mr-2'
                                        onClick={() => {
                                            anadirTurno();
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                        disabled={!!errors.diasSeleccionados}
                                    >
                                        <i className='fas fa-plus mr-1'></i>
                                        Agregar
                                    </button>
                                    <button
                                        className='btn btn-primary mt-4'
                                        onClick={(e) => {
                                            limpiarTurnos(e);
                                        }}
                                        disabled={!disponible?.length}
                                    >
                                        <i className='fas fa-plus mr-1'></i>
                                        Limpiar
                                    </button>
                                </div>
                            </div>
                        </MuiPickersUtilsProvider>
                    ) : (
                        <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant='inline'
                                            format='DD/MM/YYYY'
                                            margin='normal'
                                            name='fecha'
                                            label='Primera fecha del tour'
                                            minDate={tiempoAnticipacion}
                                            className='form-control'
                                            disablePast={true}
                                            value={fecha}
                                            onChange={(e) => {
                                                actualizarFechaTurno(e);
                                                setEmptyTime(
                                                    tipoDuracion === 'dias'
                                                        ? existEmptyRequiredFields(['dias', 'noches'])
                                                        : existEmptyRequiredFields(['horasTour', 'minTour'])
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-md-3'>
                                    <KeyboardTimePicker
                                        variant='inline'
                                        margin='normal'
                                        id='time-picker'
                                        className='form-control'
                                        label='Hora de inicio del tour'
                                        value={dia}
                                        onChange={(e) => {
                                            setDia(new Date(e));
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias', 'noches'])
                                                    : existEmptyRequiredFields(['horasTour', 'minTour'])
                                            );
                                        }}
                                    />
                                </div>

                                <div className='col-md-3 pt-2'>
                                    <small className='text-secondary'>
                                        {!fullDayActive && tipoDuracion !== 'dias'
                                            ? 'Capacidad por turno'
                                            : 'Capacidad total'}
                                    </small>
                                    <input
                                        type='number'
                                        min={formState.minimoCompra || '1'}
                                        className='form-control-sm'
                                        placeholder='Capacidad del Tour'
                                        value={capacidad}
                                        onChange={(e) => {
                                            setCapacidad(e.target.value);
                                            setCapacidadEstatica(e.target.value);
                                            setEmptyTime(
                                                tipoDuracion === 'dias'
                                                    ? existEmptyRequiredFields(['dias'])
                                                    : existEmptyRequiredFields(['horasTour'])
                                            );
                                        }}
                                        onBlur={(e) => {
                                            if (!capacidad || +capacidad < +formState.minimoCompra) {
                                                setCapacidad(+formState.minimoCompra);
                                                setCapacidadEstatica(+formState.minimoCompra);
                                            }
                                        }}
                                    />
                                </div>
                                <div className='col-md-12'>
                                    <button
                                        className='btn btn-primary mt-4 mr-2'
                                        onClick={() => {
                                            anadirTurno();
                                        }}
                                        disabled={!!errors.diasSeleccionados}
                                    >
                                        <i className='fas fa-plus mr-1'></i>
                                        Agregar
                                    </button>
                                    <button
                                        className='btn btn-primary mt-4'
                                        onClick={(e) => {
                                            limpiarTurnos(e);
                                        }}
                                        disabled={!disponible?.length}
                                    >
                                        <i className='fas fa-plus mr-1'></i>
                                        Limpiar
                                    </button>
                                </div>
                            </div>
                        </MuiPickersUtilsProvider>
                    )}
                </div>

                <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                    <ul className='list-group list-group-item-info mt-4'>
                        {!!disponible?.length ? (
                            disponible.map((turno, i) => (
                                <li className='list-group-item d-flex justify-content-between' key={i}>
                                    <div>
                                        <span>{`Fecha: ${
                                            turno.dia?.toLocaleString('en-GB')?.split(',')[0]
                                        } | Hora: ${getHoras(turno.dia)} | Capacidad: ${
                                            turno.capacidad
                                        } Persona(s)`}</span>
                                    </div>
                                    <button
                                        className='btn btn-secondary btn-sm'
                                        onClick={() => {
                                            eliminarDisponibilidad(i);
                                        }}
                                    >
                                        <i className='fas fa-minus-circle' />
                                    </button>
                                </li>
                            ))
                        ) : (
                            <li>Al dar clic en AGREGAR se crearan fechas disponibles hasta dentro de seis meses</li>
                        )}
                    </ul>
                    {errors.disponible && <small className='text-danger'>{errors.disponible}</small>}
                </div>
            </div>
        </div>
    );
}
