import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Spinner from "react-spinkit";
import TourService from "../services/Tour";

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FailurePage = ({ logged }) => {
    const query = useQuery();
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState(false);
    const paymentId = query.get("payment_id");
    const status = query.get("status");
    const paymentType = query.get("payment_type");
    const merchantOrderId = query.get("merchant_order_id");
    const preferenceId = query.get("preference_id");
    const siteId = query.get("site_id");
    const processingMode = query.get("processing_mode");
    const sTour = new TourService();

    useEffect(() => {
        sTour.obtenerReserva(preferenceId).then((reserva) => {
            if (reserva && reserva?.estado?.toLowerCase() === "pendiente") {
                return sTour
                    .validarPago(
                        merchantOrderId,
                        preferenceId,
                        paymentId,
                        status,
                        reserva.idTour
                    )
                    .then((isValid) => {
                        if (isValid) {
                            return sTour
                                .actualizarReserva({
                                    paymentId,
                                    status,
                                    paymentType,
                                    merchantOrderId,
                                    preferenceId,
                                    siteId,
                                    processingMode,
                                })
                                .then(() => setRedirect(true))
                                .catch((error) => {
                                    setRedirect(true);
                                });
                        } else {
                            setRedirect(true);
                        }
                    });
            } else {
                setRedirect(true);
            }
        });
    }, []);

    if (
        !paymentId ||
        !status ||
        !paymentType ||
        !merchantOrderId ||
        !preferenceId ||
        !siteId ||
        !processingMode
    )
        return <Redirect to="/" />;

    if (redirect) return <Redirect to="/reservas" />;

    return (
        <div
            className="container d-flex flex-column justify-content-center align-items-center"
            style={{ color: "#757575" }}
        >
            <h2 className="text-center" style={{ "margin-top": "8rem" }}>
                Ocurrió un error al procesar el pago
            </h2>
            <span className="text-center">Por favor intentelo nuevamente.</span>
            <Spinner
                className="mt-5"
                name="ball-scale-ripple-multiple"
                color="purple"
            />
        </div>
    );
};

export default FailurePage;
