import React,{useState} from 'react'
import Swal from "sweetalert2";

import fire, { auth } from "../config/Firebase";

export default function RestaurarContrasena() {
    const [correo,setCorreo] = useState('')
    const auth = fire.auth()
    let restaurarCorreo = () => {
        if(correo === "admin@itoour.com"){
            return
        }
        auth.sendPasswordResetEmail(correo).then(() => {
            Swal.fire({
                icon: "info",
                title: `Se ha enviado un correo a ${correo}`,
                showConfirmButton: false,
                timer: 1500,
              })
        }).catch((err)=>{
            // console.log(err)
            Swal.fire({
                icon: "error",
                title: "Ha ocurrido un error",
                showConfirmButton: false,
                timer: 1500,
              })
        })
    }
    return (
        <div>
            <div className="container mt-3">
                <h1 className="text-itouur">Restaurar contraseña</h1>
                <input type="text" className="form-control mt-2" placeholder="Ingresa tu correo" value={correo} onChange={(e)=>{setCorreo(e.target.value)}}/>
                <br/>
                <button className="btn btn-itoour btn-block" onClick={()=>{restaurarCorreo()}}>Restaurar Contraseña</button>
            </div>
        </div>
    )
}
