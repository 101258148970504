import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import {
    reduxFirestore,
    getFirestore,
    createFirestoreInstance,
} from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import { fbConfig } from "./config/Firebase";
import firebase from "firebase/app";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(
        // const store = createStore(
        //   rootReducer,
        //   compose(
        applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
        reduxFirestore(fbConfig)
    )
);
const rrfProps = {
    firebase,
    config: fbConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
    userProfile: "users",
    presence: "presence",
    sessions: "sessions",
};

// function AuthIsLoaded({children}){
//     const auth = useSelector(state => state.firebase.auth);
//     if(!isLoaded(auth)) return <div>Cargando</div>;
//     return children;
// }

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App id="app" />
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
