import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
// import {TransitionGroup} from 'react-transition-group';
export default function PreguntasFrecuentes({ titulo, texto, children }) {
    let [open, setOpen] = useState(false);
    return (
        <div className="collapse__container">
            <div
                className="card-header collapsed d-flex justify-content-between"
                data-toggle="collapse"
                onClick={() => {
                    setOpen(!open);
                }}
                aria-expanded={open}
            >
                <a className="card-title">{titulo}</a>
                <i className="fas fa-chevron-down mt-1" />
            </div>
            {/* <TransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}> */}

            <Collapse in={open}>
                <div className="p-3">
                    <p>{texto}</p>
                    {children}
                </div>
            </Collapse>
            {/* </TransitionGroup> */}
        </div>
    );
}
