import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Shared/Navbar';
import fire from '../../config/Firebase';
import TourService from '../../services/Tour';
import Swal from 'sweetalert2';
import firebase from 'firebase';
import ReactPaginate from 'react-paginate';
import LeerMasLeerMenos from '../../components/Shared/LeerMasLeerMenos';
import { EnumStatusTours } from '../../helpers/enumTours';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { sortByField, filterFieldsByText } from '../../Util/Tools';
import { makeStyles } from '@material-ui/core/styles';
import { getBadgeStatus } from '../../Util/badgeTour';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    root: {
        border: '1px solid #d3d4d5',
        display: 'flex',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function ListaTours(props) {
    const classes = useStyles();
    // console.log("listatours",props)
    const [tours, setTours] = useState([]);
    const [notificaciones, setNotificaciones] = useState([]);
    const [filteredTours, setFilteredTours] = useState([]);
    const [search, setSearch] = useState('');
    let [pagination, setPagination] = useState({
        offset: 0,
        perPage: 10,
        currentPage: 0,
        pageCount: 0,
    });
    const [estado, setEstado] = useState('Pendiente de aprobación');

    const divStyle = {
        color: 'white',
    };

    let db = fire.firestore();
    let getTours = async (id) => {
        let hoy = firebase.firestore.Timestamp.fromDate(new Date());
        let query = db.collection('tours').where('uid', '==', id);
        await query.onSnapshot((querySnapshot) => {
            let ToursTmp = [];
            querySnapshot.forEach((tour) => {
                let dateInicial = new Date(tour.data().tour_fecha.seconds * 1000);
                //let dateFin = new Date(tour.data().tour_fechafin.toDate());
                let objTour = {
                    id: tour.id,
                    tour_nom: tour.data().tour_nom,
                    tour_precio: tour.data().tour_precio,
                    tour_desc: tour.data().tour_desc,
                    tour_moneda: tour.data().tour_moneda,
                    tour_ciudad: tour.data().tour_ciudad,
                    tour_fecha: `${dateInicial.getDate()}/${dateInicial.getMonth()}/${dateInicial.getFullYear()}`,
                    tour_fechaInicial: dateInicial,
                    //tour_fechafin: `${dateFin.getDate()}/${dateFin.getMonth()}/${dateFin.getFullYear()}`,
                    tour_activo: tour.data().tour_activo,
                    status: tour.data().status,
                    // cli_email: tour.data().cli_email
                };
                ToursTmp.push(objTour);
            });

            ToursTmp = sortByField(ToursTmp, 'tour_fechaInicial', 'desc');

            setTours(ToursTmp);
            setFilteredTours(ToursTmp.filter((item) => item.status === estado));
            setPagination({
                ...pagination,
                pageCount: Math.ceil(ToursTmp.filter((item) => item.status === estado).length / pagination.perPage),
            });
        });
        await db
            .collection('notificaciones')
            .where('uid', '==', id)
            .onSnapshot((querySnapshot) => {
                let notificacionesTmp = [];
                querySnapshot.forEach((notificacion) => {
                    notificacionesTmp.push({ ...notificacion.data(), id: notificacion.id });
                });
                setNotificaciones(notificacionesTmp);
            });
    };

    useEffect(() => {
        let id = props.match.params.id;
        getTours(id);
    }, []);

    let handlePageClick = (e) => {
        // console.log("pageclick",e)
        const selectedPage = e.selected;
        const offset = selectedPage * pagination.perPage;

        setPagination({
            ...pagination,
            currentPage: selectedPage,
            offset: offset,
        });
    };

    let activarTour = (id) => {
        Swal.fire({
            title: 'Desea activar el Tour?',
            icon: 'info',
            text: 'Al activar el Tour será publicado y visible',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, Activar',
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .activarTour(id)
                    .then(() => {
                        const tour = tours.find((tour) => tour.id === id);
                        const noti = notificaciones.filter((noti) => noti.idTour === id);

                        if (noti.length === 0) {
                            return sTour.notificarClienteTour(
                                props.match.params.id,
                                id,
                                null,
                                tour.nombre,
                                EnumStatusTours.PUBLICADO,
                                '',
                                0
                            );
                        } else {
                            return sTour.notificarClienteTour(
                                props.match.params.id,
                                id,
                                null,
                                tour.nombre,
                                EnumStatusTours.PUBLICADO,
                                noti[0].id,
                                noti.length
                            );
                        }
                    })
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: `El Tour ha sido activado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    const onClicked = (event) => {
        const value = event.target.checked;
        setEstado(event.target.value);
    };

    let inhabilitarTour = (id) => {
        Swal.fire({
            title: 'Desea desactivar el Tour?',
            icon: 'warning',
            text: 'Esto hará que los usuarios no puedan interactuar con el tour',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, Desactivar',
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .inhabilitarTour(id)
                    .then(() => {
                        const tour = tours.find((tour) => tour.id === id);
                        const noti = notificaciones.filter((noti) => noti.idTour === id);

                        if (noti.length === 0) {
                            return sTour.notificarClienteTour(
                                props.match.params.id,
                                id,
                                null,
                                tour.nombre,
                                EnumStatusTours.INHABILITADO,
                                '',
                                0
                            );
                        } else {
                            return sTour.notificarClienteTour(
                                props.match.params.id,
                                id,
                                null,
                                tour.nombre,
                                EnumStatusTours.INHABILITADO,
                                noti[0].id,
                                noti.length
                            );
                        }
                    })
                    .then((rpta) => {
                        // console.log("rpta",rpta)
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: `El Tour ha sido desactivado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    useEffect(() => {
        let filteredData = filterFieldsByText(
            tours,
            ['tour_nom', 'tour_ciudad', 'tour_desc', 'status'],
            search,
            'desc',
            'tour_fechaInicial'
        );

        filteredData = filteredData.filter((item) => item.status === estado);

        setPagination({
            ...pagination,
            pageCount: Math.ceil(filteredData.length / pagination.perPage),
            offset: 0,
            currentPage: 0,
        });
        setFilteredTours(filteredData);
    }, [search, estado]);

    const onChangeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    };

    const onClearButton = (e) => {
        e.preventDefault();
        setSearch('');
    };

    return (
        <div className='container mt-4 mb-4'>
            <Link className='btn btn-outline-primary mb-3' to='/dashboardadmin'>
                <i className='fas fa-backward mr-2'></i>Regresar
            </Link>
            <div className={'container my-4 ' + classes.container}>
                <Paper component='form' className={classes.root}>
                    <InputBase
                        className={classes.input}
                        placeholder='Buscar Tour'
                        inputProps={{ 'aria-label': 'Buscar Tour' }}
                        value={search}
                        onChange={onChangeSearch}
                    />
                    <IconButton
                        type='button'
                        className={classes.iconButton}
                        aria-label='search'
                        onClick={onClearButton}
                    >
                        <CloseIcon />
                    </IconButton>
                </Paper>
            </div>
            <div className='container p-4 my-3'>
                <p className='fst-italic'>Filtro de Tour</p>
                <div className='form-check form-check-inline options-list-container'>
                    <div className='form-check form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            defaultChecked
                            name='filtro-estado'
                            value='Pendiente de aprobación'
                            id='pendienteList'
                            onChange={onClicked}
                        ></input>
                        <label for='pendienteList' style={divStyle} className='form-check-label badge badge-warning'>
                            Pendiente de Aprobacion
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='filtro-estado'
                            value='Inhabilitado'
                            id='todoList'
                            onChange={onClicked}
                        ></input>
                        <label for='todoList' style={divStyle} className='form-check-label badge bg-primary'>
                            Inhabilitado
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='filtro-estado'
                            value='Observado'
                            id='observadoList'
                            onClick={onClicked}
                        ></input>
                        <label for='observadoList' style={divStyle} className='form-check-label badge badge-info'>
                            Observado
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='filtro-estado'
                            value='Publicado'
                            id='publicadoList'
                            onClick={onClicked}
                        ></input>
                        <label for='publicadoList' style={divStyle} className='form-check-label badge badge-success'>
                            Publicado
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='filtro-estado'
                            value='Pausado'
                            id='pausadoList'
                            onClick={onClicked}
                        ></input>
                        <label for='pausadoList' style={divStyle} className='form-check-label badge badge-danger'>
                            Pausado
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                            type='radio'
                            className='form-check-input'
                            name='filtro-estado'
                            value='Cancelado'
                            id='canceladoList'
                            onClick={onClicked}
                        ></input>
                        <label for='canceladoList' style={divStyle} className='form-check-label badge badge-secondary'>
                            Cancelado
                        </label>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='tour-list-dashboard-container'>
                    <table className='table table-borderless table-hover' style={{ maxWidth: '100%' }}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Ciudad</th>
                                <th>Inicio</th>
                                {/*<th>Fin</th>*/}
                                <th>Estado</th>
                                <th>Editar</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTours
                                .slice(pagination.offset, pagination.offset + pagination.perPage)
                                .map((tour, i) => (
                                    <tr key={i}>
                                        <td>{tour.tour_nom}</td>
                                        <td className='tour-admin-desc'>
                                            <LeerMasLeerMenos limit={170} textLength={tour.tour_desc.length}>
                                                {tour.tour_desc}
                                            </LeerMasLeerMenos>
                                        </td>
                                        <td>{tour.tour_ciudad}</td>
                                        <td>{tour.tour_fecha}</td>
                                        {/*<td>{tour.tour_fechafin}</td>*/}
                                        <td className='tour-admin-status'>
                                            <span className={getBadgeStatus(tour.status)} style={{ maxWidth: '100%' }}>
                                                {tour.status}
                                            </span>
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Link
                                                to={`/editartour/${tour.id}`}
                                                className='btn btn-success'
                                                style={{ color: 'white' }}
                                            >
                                                <i className='fas fa-pencil-alt'></i>
                                            </Link>
                                        </td>
                                        <td>
                                            {tour.status !== 'Publicado' ? (
                                                <button
                                                    className='btn btn-success'
                                                    onClick={() => {
                                                        activarTour(tour.id);
                                                    }}
                                                >
                                                    <i className='fas fa-check'></i>
                                                </button>
                                            ) : (
                                                <button
                                                    className='btn btn-danger'
                                                    onClick={() => {
                                                        inhabilitarTour(tour.id);
                                                    }}
                                                >
                                                    <i class='fas fa-ban'></i>
                                                </button>
                                            )}
                                            <Link
                                                to={`/vertouradmin/${tour.id}`}
                                                className='btn btn-primary'
                                                style={{ color: 'white' }}
                                            >
                                                <i className='fas fa-eye'></i>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>

                <div className='d-flex justify-content-center mb-3'>
                    <ReactPaginate
                        containerClassName='pagination'
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        breakClassName='page-item'
                        previousLabel={'Anterior'}
                        nextLabel={'Siguiente'}
                        breakLabel={'...'}
                        pageClassName='page-item'
                        pageCount={pagination.pageCount}
                        previousClassName='page-item'
                        nextClassName='page-item'
                        pageLinkClassName='page-link'
                        previousLinkClassName='page-link'
                        nextLinkClassName='page-link'
                        activeClassName='active'
                        activeLinkClassName='paginate'
                        onPageChange={(e) => {
                            handlePageClick(e);
                        }}
                        forcePage={pagination.currentPage}
                    />
                </div>
            </div>
        </div>
    );
}
