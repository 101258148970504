import { EnumStatusTours } from '../helpers/enumTours';

export const getBadgeStatus = (status) => {
    if (status === EnumStatusTours.EN_CREACIÓN) {
        return 'badge badge-primary status-tour-span';
    }
    if (status === EnumStatusTours.APROBACION_PENDIENTE) {
        return 'badge badge-pill badge-warning status-tour-span';
    }
    if (status === EnumStatusTours.OBSERVADO) {
        return 'badge badge-info status-tour-span';
    }
    if (status === EnumStatusTours.PUBLICADO) {
        return 'badge badge-success status-tour-span';
    }
    if (status === EnumStatusTours.PAUSADO) {
        return 'badge badge-danger status-tour-span';
    }
    if (status === EnumStatusTours.CANCELADO) {
        return 'badge badge-secondary status-tour-span';
    }
    if (status === EnumStatusTours.INHABILITADO) {
        return 'badge bg-primary status-tour-span';
    }
};
