import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "./css/MenuCliente.css";

export default function MenuUsuario({
    perfil = false,
    reservas = false,
    vertical = false,
}) {
    return (
        <Nav
            className={`menu-cliente ${
                vertical ? "flex-column" : "justify-content-center"
            }`}
        >
            <Nav.Link as={Link} to="/perfil" active={perfil}>
                {/*<i className="fas fa-user-tie mr-2"></i>*/}Perfil
            </Nav.Link>
            <Nav.Link as={Link} to="/reservas" active={reservas}>
                {/*<i className="fas fa-calendar-alt mr-2"></i>*/}Reservas
            </Nav.Link>
        </Nav>
    );
}
