import React, { useMemo } from 'react';
import styled from 'styled-components';

const ButtonNumber = styled.button`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    background: ${(props) => (props.active ? '#db6e15' : props.enabled ? '#753183' : 'rgba(117, 49, 131, 0.7)')};
    border-radius: 50%;
    text-decoration: none;
    font-family: KeepCalm;
    outline: none;
    border: none;
    &:hover {
        background: ${(props) => (props.active ? '#db6e15' : props.enabled ? '#863896' : 'rgba(117, 49, 131, 0.7)')};
        cursor: ${(props) => (props.active || !props.enabled ? 'default' : 'pointer')};
    }
    &:focus {
        outline: none;
    }
`;

const TitleButton = styled.button`
    color: black;
    text-decoration: none;
    text-align: center;
    width: 100px;
    font-size: 14px;
    font-family: KeepCalm;
    border: none;
    background: none;

    &:hover {
        color: ${(props) => (props.active || !props.enabled ? 'black' : 'rgba(0, 0, 0, 0.8)')};
        cursor: ${(props) => (props.active || !props.enabled ? 'default' : 'pointer')};
    }

    &:focus {
        outline: none;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;

const SeparatorLine = styled.hr`
    position: absolute;
    width: 110px;
    height: 2px;
    top: 5px;
    background: #753183;
    border: none;
    left: ${(props) => `${70 + props?.orden * 150}px`};

    @media (max-width: 720px) {
        width: 60px;
        left: ${(props) => `${70 + props?.orden * 100}px`};
    }
`;

const StepButton = ({ orden, title, step, changeStep, handleSubmit, enabled = false }) => {
    const onClick = (e) => {
        e.preventDefault();

        if (step !== orden && enabled) {
            changeStep(orden);
        }
    };

    const fields = useMemo(() => {
        switch (step) {
            case 1:
                return ['tipo_documento', 'password'];
            case 2:
                return ['pais', 'tipo'];
            default:
                return [];
        }
    }, [step]);

    const fieldsCompare = useMemo(() => {
        switch (step) {
            case 1:
                return [
                    'nombres',
                    'apellidos',
                    'tipo_documento',
                    'documento',
                    'tipo',
                    'celular',
                    'email',
                    'password',
                    'repassword',
                ];
            case 2:
                return [
                    'nombre_anfitrion',
                    'ruc',
                    'razon_social',
                    'representante',
                    'sexo',
                    'direccion',
                    'pais',
                    'departamento',
                    'ciudad',
                ];
            default:
                return [];
        }
    }, [step]);

    return (
        <ItemContainer>
            <ButtonNumber
                onClick={handleSubmit(onClick, false, fields, fieldsCompare)}
                active={orden === step}
                enabled={enabled}
            >
                {orden}
            </ButtonNumber>
            <TitleButton
                onClick={handleSubmit(onClick, false, fields, fieldsCompare)}
                active={orden === step}
                enabled={enabled}
            >
                {title}
            </TitleButton>
        </ItemContainer>
    );
};

const StepButtonsContainer = styled.div`
    width: max-content;
    display: flex;
    position: relative;
    gap: 50px;

    @media (max-width: 720px) {
        gap: 0;
    }
`;

export default function StepPageButtons({ items, step, changeStep, handleSubmit, enabled = false }) {
    const formateButtons = (items = []) => {
        if (!items.length) return [];
        else {
            let orden = 2;
            let orden_sep = 0;
            const new_arr = [{ title: items.shift(), orden: 1 }];

            while (items.length > 0) {
                new_arr.push({ type: 'sep', orden: orden_sep });
                new_arr.push({ title: items.shift(), orden });

                orden += 1;
                orden_sep += 1;
            }

            return new_arr;
        }
    };

    return (
        <StepButtonsContainer>
            {formateButtons(items).map((item) => {
                if (item?.type === 'sep') return <SeparatorLine orden={item.orden} key={`sep${item.orden}`} />;
                else
                    return (
                        <StepButton
                            orden={item.orden}
                            title={item.title}
                            changeStep={changeStep}
                            handleSubmit={handleSubmit}
                            step={step}
                            enabled={enabled}
                            key={`itemStep${item.orden}`}
                        />
                    );
            })}
        </StepButtonsContainer>
    );
}
