import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "./css/MenuCliente.css";

export default function MenuCliente({
    perfil = false,
    empresa = false,
    comercial = false,
    reservaciones = false,
    reservas = false,
    dashboard = false,
    vertical = false,
    anfitrion = false,
    operador = false,
}) {
    return (
        <Nav
            className={`menu-cliente ${
                vertical ? "flex-column" : "justify-content-center"
            }`}
        >
            {anfitrion ? (
                <>
                    <Nav.Link as={Link} to="/perfilempresa" active={empresa}>
                        {/*<i className="fas fa-briefcase mr-2"></i>*/}Perfil
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/perfilcomercial"
                        active={comercial}
                    >
                        Comercial
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/reservaciones"
                        active={reservaciones}
                    >
                        Reservaciones
                    </Nav.Link>
                    <Nav.Link as={Link} to="/dashboard" active={dashboard}>
                        {/*<i className="fas fa-columns mr-2"></i>*/}Dashboard
                    </Nav.Link>
                </>
            ) : (
                <>
                    <Nav.Link as={Link} to="/perfil" active={perfil}>
                        {/*<i className="fas fa-user-tie mr-2"></i>*/}Perfil
                    </Nav.Link>
                    <Nav.Link as={Link} to="/reservas" active={reservas}>
                        {/*<i className="fas fa-calendar-alt mr-2"></i>*/}
                        Reservas
                    </Nav.Link>
                </>
            )}
        </Nav>
    );
}
