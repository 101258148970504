import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import fire from '../config/Firebase';
import InfoPersonal from '../components/Usuario/InfoPersonal';
import useForm from '../hooks/useForm';
import './css/CompleteRegistration.css';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'nombres': {
                validates.push({
                    name,
                    isValid: formState[name] && formState[name]?.length > 2,
                    message: formState[name]?.length
                        ? 'El nombre no puede tener menos de 3 caracteres.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'apellidos': {
                validates.push({
                    name,
                    isValid: formState[name] && formState[name]?.length > 2,
                    message: formState[name]?.length
                        ? 'El apellido no puede tener menos de 3 caracteres.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'dni': {
                validates.push({
                    name,
                    isValid: /^[0-9]{8}$/i.test(formState[name]),
                    message: !formState[name]?.length
                        ? 'El documento es requerido'
                        : !/^[0-9]+$/i.test(formState[name])
                        ? 'Sólo se permiten números.'
                        : 'El dni debe tener 8 dígitos',
                });
                break;
            }

            case 'telefono': {
                validates.push({
                    name,
                    isValid: /^[0-9]+$/i.test(formState[name]),
                    message: formState[name]?.length ? 'No es un teléfono válido.' : 'El campo es requerido',
                });
                break;
            }

            case 'celular': {
                validates.push({
                    name,
                    isValid: /^[0-9]+$/i.test(formState[name]),
                    message: formState[name]?.length ? 'No es un celular válido.' : 'El campo es requerido.',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

const initialState = {
    nombres: '',
    apellidos: '',
    dni: '',
    telefono: '',
    celular: '',
};

const CompleteRegistration = ({ permisos, uid, email }) => {
    const [state, setState] = useState({
        nombres: '',
        apellidos: '',
        nacimiento: new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000),
        dni: '',
        telefono: '',
        celular: '',
    });
    const query = useQuery();
    const m = query.get('m');
    const c = query.get('c');
    const k = query.get('k');

    const guardarUsuario = () => {
        const db = fire.firestore();

        return db.collection('usuario').doc(uid).update({
            usuario_nombres: state.nombres,
            usuario_apellidos: state.apellidos,
            usuario_nacimiento: state.nacimiento,
            usuario_dni: state.dni,
            usuario_telefono: state.telefono,
            usuario_celular: state.celular,
        });
    };

    const { formState, errors, focus, setFocus, handleChange, handleBlur, resetForm, handleSubmit } = useForm(
        initialState,
        validateForm,
    );

    useEffect(() => {
        if (focus) {
            document.getElementsByName(focus)[0].focus();
            setFocus(null);
        }
    }, [focus]);

    if (!m || !k || !c) return <Redirect to='/' />;

    switch (permisos) {
        case 'usuario':
            return (
                <InfoPersonal
                    state={state}
                    setState={setState}
                    guardarUsuario={guardarUsuario}
                    prevStep={() => {}}
                    nextStep={() => {}}
                    permisos={permisos}
                    formState={formState}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    email={email}
                />
            );

        default:
            return <Redirect to='/' push />;
    }
};

export default CompleteRegistration;
