export function sortByField(arr, fieldName, type = 'asc') {
    switch (type.toLowerCase()) {
        case 'asc':
            return arr.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1));
        case 'desc':
            return arr.sort((a, b) => (a[fieldName] < b[fieldName] ? 1 : -1));
        default:
            return [...arr];
    }
}

export function sortByFieldDate(arr, fieldName, type = 'asc') {
    switch (type.toLowerCase()) {
        case 'asc':
            return arr.sort((a, b) => a[fieldName].getTime() - b[fieldName].getTime());
        case 'desc':
            return arr.sort((a, b) => b[fieldName].getTime() - a[fieldName].getTime());
        default:
            return [...arr];
    }
}

export function filterFieldsByText(arr, fields, text, type, sortBy) {
    return sortByField(
        arr.filter((item) => {
            return fields.some((field) => {
                return item[field].toLowerCase().includes(text.toLowerCase());
            });
        }),
        sortBy,
        type
    );
}
