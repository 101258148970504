import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Spinner from 'react-spinkit';
import { AuthService } from '../services/Auth';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const VerificationEmail = ({ logged, setVerificado }) => {
    const query = useQuery();
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState(false);
    const mode = query.get('mode');
    const oobCode = query.get('oobCode');
    const apiKey = query.get('apiKey');
    const auth = new AuthService();

    useEffect(() => {
        if (mode && oobCode && apiKey) {
            auth.handleAction(mode, oobCode)
                .then(() => {
                    setVerificado();
                    setTimeout(() => setRedirect(true), 5000);
                })
                .catch((error) => {
                    setError(true);
                });
        }
    }, []);

    if (!mode || !oobCode || !apiKey) return <Redirect to='/' />;

    if (redirect) return <Redirect to={`/completeregistration?k=${apiKey}&c=${oobCode}&m=${mode}`} />;

    if (error)
        return (
            <div
                className='container d-flex flex-column justify-content-center align-items-center'
                style={{ color: '#757575' }}
            >
                <h2 className='text-center' style={{ 'margin-top': '8rem' }}>
                    Opps! Algo salió mal.
                </h2>
                <span className='text-center'>Este enlace no es válido o ha expirado.</span>
            </div>
        );

    return (
        <div
            className='container d-flex flex-column justify-content-center align-items-center'
            style={{ color: '#757575' }}
        >
            <h2 className='text-center' style={{ 'margin-top': '8rem' }}>
                Su cuenta ha sido verificada
            </h2>
            <span className='text-center'>Por favor espere mientras continuamos con el proceso de registro.</span>
            <Spinner className='mt-5' name='ball-scale-ripple-multiple' color='purple' />
        </div>
    );
};

export default VerificationEmail;
