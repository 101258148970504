import React, { useState, useEffect, useMemo } from 'react';
import Navbar from '../../components/Shared/Navbar';
import fire from '../../config/Firebase';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import TourService from '../../services/Tour';
import ReadMoreReadLess from '../../components/Shared/LeerMasLeerMenos';
import { sortByField, sortByFieldDate, filterFieldsByText } from '../../Util/Tools';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import fotoPerfil from '../../assets/defaultPic.jpg';
import CardEmpresa from './CardEmpresa';
import ReactPaginate from 'react-paginate';
import './css/DashboardAdmin.css';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        color: 'white',
        backgroundColor: 'rgb(158, 79, 173)',
    },
}));

const useStylesSearch = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    root: {
        border: '1px solid #d3d4d5',
        display: 'flex',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function DashboardAdmin() {
    const classes = useStyles();
    const classesSearch = useStylesSearch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filteredEmpresas, setFilteredEmpresas] = useState([]);
    const [filteredTours, setFilteredTours] = useState([]);
    const [search, setSearch] = useState('');
    const [stats, setStats] = useState({
        total: 0,
        pendientes: 0,
        observados: 0,
        pausados: 0,
        inhabilitados: 0,
        publicados: 0,
        cancelados: 0,
    });

    const [statsEmpresas, setStatsEmpresas] = useState({
        total: 0,
        anfitriones: 0,
        guias: 0,
        operadores: 0,
    });

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const [empresas, setEmpresas] = useState([]);
    let [tours, setTours] = useState([]);
    let [activo, setActivo] = useState([]);
    const [estado, setEstado] = useState('Pendiente de aprobación');
    const [estadoEmpresas, setEstadoEmpresas] = useState('');
    const [paginationEmpresas, setPaginationEmpresas] = useState({
        offset: 0,
        perPage: 12,
        currentPage: 0,
        pageCount: 0,
    });
    const [paginationTours, setPaginationTours] = useState({
        offset: 0,
        perPage: 10,
        currentPage: 0,
        pageCount: 0,
    });

    const onClicked = (event) => {
        const value = event.target.checked;
        setEstado(event.target.value);
    };

    const onClickedTipo = (event) => {
        const value = event.target.checked;
        setEstadoEmpresas(event.target.value);
    };

    const divStyle = {
        color: 'white',
    };
    let [vista, setVista] = useState(true);
    let db = fire.firestore();
    let traerEmpresas = () => {
        db.collection('cliente')
            .get()
            .then((clientes) => {
                let clientesTmp = [];
                clientes.forEach((cli) => {
                    let objCliente = {
                        id: cli.id,
                        nombre: cli.data().cliente_nomempresa,
                        razon: cli.data().cliente_razon,
                        usuario: `${cli.data().cliente_nombres} ${cli.data().cliente_apellidos}`,
                        telefono: cli.data().cliente_telefono,
                        telempresa: cli.data().cliente_telempresa,
                        reprtel: cli.data().cliente_reprtel,
                        representante: cli.data().cliente_representante,
                        ruc: cli.data().cliente_ruc,
                        email: cli.data().cliente_email,
                        direccion: cli.data().cliente_dirempresa,
                        foto: cli.data()?.cliente_foto || fotoPerfil,
                        tipo: cli.data().cliente_tipo,
                        fecha_creacion: new Date(cli.data().fecha_creacion?.seconds * 1000 || 0),
                    };
                    clientesTmp.push(objCliente);
                });
                clientesTmp = sortByFieldDate(clientesTmp, 'fecha_creacion', 'desc');

                setPaginationEmpresas({
                    ...paginationEmpresas,
                    pageCount: Math.ceil(clientesTmp.length / paginationEmpresas.perPage),
                });
                setEmpresas(clientesTmp);
                setFilteredEmpresas(clientesTmp);
            });
    };

    useEffect(() => {
        traerEmpresas();
        getTour();
    }, []);

    useEffect(() => {
        setStats({
            total: tours.length,
            pendientes: tours.filter((t) => t.status === 'Pendiente de aprobación').length,
            publicados: tours.filter((t) => t.status === 'Publicado').length,
            observados: tours.filter((t) => t.status === 'Observado').length,
            pausados: tours.filter((t) => t.status === 'Pausado').length,
            inhabilitados: tours.filter((t) => t.status === 'Inhabilitado').length,
            cancelados: tours.filter((t) => t.status === 'Cancelado').length,
        });
    }, [tours]);

    useEffect(() => {
        setStatsEmpresas({
            total: empresas.length,
            anfitriones: empresas.filter((t) => t.tipo === 'ANFITRION').length,
            guias: empresas.filter((t) => t.tipo === 'GUIA').length,
            operadores: empresas.filter((t) => t.tipo === 'TOUR OPERADOR').length,
        });
    }, [empresas]);

    let getTour = () => {
        let hoy = firebase.firestore.Timestamp.fromDate(new Date());
        db.collection('tours')
            .get()
            .then((querySnapshot) => {
                let ToursTmp = [];
                querySnapshot.forEach((tour) => {
                    let objTour = {
                        id: tour.id,
                        tour_nom: tour.data().tour_nom,
                        tour_activo: tour.data().tour_activo,
                        tour_precio: tour.data().tour_precioAdulto,
                        tour_precioAdulto: tour.data().tour_precioAdulto,
                        tour_precioNino: tour.data().tour_precioNino,
                        tour_desc: tour.data().tour_desc,
                        tour_moneda: tour.data().tour_moneda,
                        tour_fecha: tour.data().tour_fecha,
                        tour_fechaInicio: new Date(tour.data().tour_fecha.seconds * 1000),
                        tour_ciudad: tour.data().tour_ciudad,
                        tour_imagen: tour.data().tour_imagenes[tour.data().tour_miniatura],
                        cli_email: tour.data().cli_email,
                        status: tour.data().status,
                        // tour_duracion: `${tour.data().tour_dias} día(s), ${
                        //   tour.data().tour_noches
                        // } noche(s)`,
                    };
                    ToursTmp.push(objTour);
                });

                ToursTmp = sortByField(ToursTmp, 'tour_fechaInicio');

                setPaginationTours({
                    ...paginationTours,
                    pageCount: Math.ceil(ToursTmp.length / paginationTours.perPage),
                });

                setTours(ToursTmp);
                setFilteredTours(ToursTmp);
            });
    };

    let desactivarTour = (id) => {
        Swal.fire({
            title: 'Desea desactivar el Tour?',
            icon: 'warning',
            text: 'Esto hará que los usuarios no puedan interactuar con el tour',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, Desactivar',
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .desactivarTour(id)
                    .then(() => {
                        getTour();
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: `El Tour ha sido desactivado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    let activarTour = (id) => {
        Swal.fire({
            title: 'Desea activar el Tour?',
            icon: 'info',
            text: 'Al activar el Tour será publicado y no se podrá modificar',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, continuar',
            confirmButtonText: 'Si, Activar',
        }).then((result) => {
            if (result.value) {
                let sTour = new TourService();
                sTour
                    .activarTour(id)
                    .then(() => {
                        getTour();
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: `El Tour ha sido activado`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: `Ha ocurrido un error`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        });
    };

    let toursPendientes = tours.filter((tour) => tour.status === 'Pendiente de aprobación').length;

    useEffect(() => {
        if (vista) {
            let filteredData = filterFieldsByText(
                empresas,
                ['nombre', 'razon', 'ruc', 'telefono', 'telempresa', 'email', 'direccion', 'usuario'],
                search,
                'desc',
                'fecha_creacion'
            );

            filteredData =
                estadoEmpresas !== '' ? filteredData.filter((item) => item.tipo === estadoEmpresas) : filteredData;

            setPaginationEmpresas({
                ...paginationEmpresas,
                pageCount: Math.ceil(filteredData.length / paginationEmpresas.perPage),
            });

            setFilteredEmpresas(filteredData);
        } else {
            let filteredData = filterFieldsByText(
                tours,
                ['tour_nom', 'tour_ciudad', 'tour_desc'],
                search,
                'asc',
                'tour_fechaInicio'
            );

            filteredData = filteredData.filter((item) => item.status === (estado || 'Pendiente de aprobación'));

            setPaginationTours({
                ...paginationTours,
                pageCount: Math.ceil(filteredData.length / paginationTours.perPage),
            });

            setFilteredTours(filteredData);
        }
    }, [search, estado, estadoEmpresas]);

    const onChangeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    };

    const onClearButton = (e) => {
        e.preventDefault();
        setSearch('');
    };

    let handlePageEmpresasClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * paginationEmpresas.perPage;

        setPaginationEmpresas({
            ...paginationEmpresas,
            currentPage: selectedPage,
            offset: offset,
        });
    };

    let handlePageToursClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * paginationTours.perPage;

        setPaginationTours({
            ...paginationTours,
            currentPage: selectedPage,
            offset: offset,
        });
    };

    return (
        <div>
            <div className='container mt-4'>
                <div className='d-flex mb-2'>
                    <div className={classes.root}>
                        <Badge badgeContent={toursPendientes} color='primary'>
                            <button aria-describedby={id} type='button' onClick={handleClick}>
                                <AnnouncementIcon />
                            </button>
                        </Badge>
                        <Popper id={id} open={open} anchorEl={anchorEl}>
                            <div className={classes.paper}>Tiene {toursPendientes} tours por aprobar.</div>
                        </Popper>
                    </div>
                    <button
                        className='btn btn-outline-secondary btn-sm ml-auto'
                        onClick={(e) => {
                            e.preventDefault();

                            setEstado('');
                            setEstadoEmpresas('');
                            setVista(!vista);
                        }}
                    >
                        {vista ? 'Ver Tours ' : 'Ver Empresas '}
                        <i className='far fa-eye'></i>
                    </button>
                </div>
                {vista ? (
                    <div className='tours-stats-container'>
                        <div className='tours-stat tours-total'>
                            <p className='tours-stat-name'>TOTAL</p>
                            <p className='tours-stat-value'>{statsEmpresas.total}</p>
                        </div>
                        <div className='tours-stat tours-publicados'>
                            <p className='tours-stat-name'>ANFITRIONES</p>
                            <p className='tours-stat-value'>{statsEmpresas.anfitriones}</p>
                        </div>
                        <div className='tours-stat tours-pendientes'>
                            <p className='tours-stat-name'>GUÍAS</p>
                            <p className='tours-stat-value'>{statsEmpresas.guias}</p>
                        </div>
                        <div className='tours-stat tours-pausados'>
                            <p className='tours-stat-name'>OPERADORES</p>
                            <p className='tours-stat-value'>{statsEmpresas.operadores}</p>
                        </div>
                    </div>
                ) : (
                    <div className='tours-stats-container'>
                        <div className='tours-stat tours-total'>
                            <p className='tours-stat-name'>TOTAL</p>
                            <p className='tours-stat-value'>{stats.total}</p>
                        </div>
                        <div className='tours-stat tours-publicados'>
                            <p className='tours-stat-name'>PUBLICADOS</p>
                            <p className='tours-stat-value'>{stats.publicados}</p>
                        </div>
                        <div className='tours-stat tours-pendientes'>
                            <p className='tours-stat-name'>PENDIENTES</p>
                            <p className='tours-stat-value'>{stats.pendientes}</p>
                        </div>
                        <div className='tours-stat tours-pausados'>
                            <p className='tours-stat-name'>PAUSADOS</p>
                            <p className='tours-stat-value'>{stats.pausados}</p>
                        </div>
                        <div className='tours-stat tours-observados'>
                            <p className='tours-stat-name'>OBSERVADOS</p>
                            <p className='tours-stat-value'>{stats.observados}</p>
                        </div>
                        <div className='tours-stat tours-inhabilitados'>
                            <p className='tours-stat-name'>INHABILITADOS</p>
                            <p className='tours-stat-value'>{stats.inhabilitados}</p>
                        </div>
                        <div className='tours-stat tours-cancelados'>
                            <p className='tours-stat-name'>CANCELADOS</p>
                            <p className='tours-stat-value'>{stats.cancelados}</p>
                        </div>
                    </div>
                )}
                <div className={'container my-4 ' + classesSearch.container}>
                    <Paper component='form' className={classesSearch.root}>
                        <InputBase
                            className={classesSearch.input}
                            placeholder={vista ? 'Buscar Cliente' : 'Buscar Tour'}
                            inputProps={{ 'aria-label': 'Buscar Empresa' }}
                            value={search}
                            onChange={onChangeSearch}
                        />
                        <IconButton
                            type='button'
                            className={classesSearch.iconButton}
                            aria-label='search'
                            onClick={onClearButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Paper>
                </div>
                {vista ? (
                    <div className='container p-4 my-3'>
                        <p className='fst-italic'>Filtro de Clientes</p>
                        <div className='form-check form-check-inline'>
                            <div className='form-check form-check-inline'>
                                <input
                                    type='radio'
                                    className='form-check-input'
                                    defaultChecked
                                    name='filtro-estado'
                                    value=''
                                    id='todoListEmpresas'
                                    onChange={onClickedTipo}
                                ></input>
                                <label for='todoListEmpresas' className='form-check-label'>
                                    Todos
                                </label>
                            </div>
                            <div className='form-check form-check-inline'>
                                <input
                                    type='radio'
                                    className='form-check-input'
                                    name='filtro-estado'
                                    value='ANFITRION'
                                    id='anfitrionList'
                                    onChange={onClickedTipo}
                                ></input>
                                <label for='anfitrionList' className='form-check-label'>
                                    Anfitrión
                                </label>
                            </div>
                            <div className='form-check form-check-inline'>
                                <input
                                    type='radio'
                                    className='form-check-input'
                                    name='filtro-estado'
                                    value='GUIA'
                                    id='guiaList'
                                    onClick={onClickedTipo}
                                ></input>
                                <label for='guiaList' className='form-check-label'>
                                    Guía
                                </label>
                            </div>
                            <div className='form-check form-check-inline'>
                                <input
                                    type='radio'
                                    className='form-check-input'
                                    name='filtro-estado'
                                    value='TOUR OPERADOR'
                                    id='operadorList'
                                    onClick={onClickedTipo}
                                ></input>
                                <label for='operadorList' className='form-check-label'>
                                    Tour Operador
                                </label>
                            </div>
                        </div>
                    </div>
                ) : null}
                {vista ? (
                    <>
                        <div className='card-empresa-container'>
                            {filteredEmpresas?.length
                                ? filteredEmpresas
                                      .slice(
                                          paginationEmpresas.offset,
                                          paginationEmpresas.offset + paginationEmpresas.perPage
                                      )
                                      .map((empresa, i) => <CardEmpresa key={i} empresa={empresa} tours={tours} />)
                                : null}
                        </div>
                        {filteredEmpresas.length ? (
                            <div className='d-flex justify-content-center my-3'>
                                <ReactPaginate
                                    containerClassName='pagination'
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    breakClassName='page-item'
                                    previousLabel={'Anterior'}
                                    nextLabel={'Siguiente'}
                                    breakLabel={'...'}
                                    pageClassName='page-item'
                                    pageCount={paginationEmpresas.pageCount}
                                    previousClassName='page-item'
                                    nextClassName='page-item'
                                    pageLinkClassName='page-link'
                                    previousLinkClassName='page-link'
                                    nextLinkClassName='page-link'
                                    activeClassName='active'
                                    activeLinkClassName='paginate'
                                    onPageChange={handlePageEmpresasClick}
                                />
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div>
                        <div className='container p-4 my-3'>
                            <p className='fst-italic'>Filtro de Tour</p>
                            <div className='form-check form-check-inline options-list-container'>
                                <div className='form-check form-check-inline'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        defaultChecked
                                        name='filtro-estado'
                                        value='Pendiente de aprobación'
                                        id='pendienteList'
                                        onChange={onClicked}
                                    ></input>
                                    <label
                                        for='pendienteList'
                                        style={divStyle}
                                        className='form-check-label badge badge-warning'
                                    >
                                        Pendiente de Aprobacion
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='filtro-estado'
                                        value='Inhabilitado'
                                        id='todoList'
                                        onChange={onClicked}
                                    ></input>
                                    <label
                                        for='todoList'
                                        style={divStyle}
                                        className='form-check-label badge bg-primary'
                                    >
                                        Inhabilitado
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='filtro-estado'
                                        value='Observado'
                                        id='observadoList'
                                        onClick={onClicked}
                                    ></input>
                                    <label
                                        for='observadoList'
                                        style={divStyle}
                                        className='form-check-label badge badge-info'
                                    >
                                        Observado
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='filtro-estado'
                                        value='Publicado'
                                        id='publicadoList'
                                        onClick={onClicked}
                                    ></input>
                                    <label
                                        for='publicadoList'
                                        style={divStyle}
                                        className='form-check-label badge badge-success'
                                    >
                                        Publicado
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='filtro-estado'
                                        value='Pausado'
                                        id='pausadoList'
                                        onClick={onClicked}
                                    ></input>
                                    <label
                                        for='pausadoList'
                                        style={divStyle}
                                        className='form-check-label badge badge-danger'
                                    >
                                        Pausado
                                    </label>
                                </div>
                                <div className='form-check form-check-inline'>
                                    <input
                                        type='radio'
                                        className='form-check-input'
                                        name='filtro-estado'
                                        value='Cancelado'
                                        id='canceladoList'
                                        onClick={onClicked}
                                    ></input>
                                    <label
                                        for='canceladoList'
                                        style={divStyle}
                                        className='form-check-label badge badge-secondary'
                                    >
                                        Cancelado
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='tour-list-dashboard-container'>
                            <table className='table table-borderless table-hover'>
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Ciudad</th>

                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTours
                                        .filter((tour) => tour.status === (estado || 'Pendiente de aprobación'))
                                        .slice(paginationTours.offset, paginationTours.offset + paginationTours.perPage)
                                        .map((tour, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <span>
                                                        {empresas.find((emp) => tour.cli_email === emp.email).nombre
                                                            ? empresas.find((emp) => tour.cli_email === emp.email)
                                                                  .nombre
                                                            : 'Empresa sin configurar'}
                                                    </span>
                                                </td>
                                                <td className='field-admin-dashboard'>{tour.tour_nom}</td>
                                                <td className='descipcion'>
                                                    <ReadMoreReadLess limit={60} textLength={tour.tour_desc.length}>
                                                        {tour.tour_desc}
                                                    </ReadMoreReadLess>
                                                </td>
                                                <td className='field-admin-dashboard'>{tour.tour_ciudad}</td>
                                                <td className='field-admin-dashboard'>
                                                    {tour.status === 'Publicado' ? (
                                                        <Link
                                                            to={`/vertouradmin/${tour.id}`}
                                                            className='btn btn-primary'
                                                            style={{ color: 'white' }}
                                                        >
                                                            <i className='fas fa-eye'></i>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to={`/vertouradmin/${tour.id}`}
                                                            className='btn btn-warning'
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        >
                                                            <i className='fas fa-eye'></i>
                                                        </Link>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        {filteredTours.length ? (
                            <div className='d-flex justify-content-center my-3'>
                                <ReactPaginate
                                    containerClassName='pagination'
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    breakClassName='page-item'
                                    previousLabel={'Anterior'}
                                    nextLabel={'Siguiente'}
                                    breakLabel={'...'}
                                    pageClassName='page-item'
                                    pageCount={paginationTours.pageCount}
                                    previousClassName='page-item'
                                    nextClassName='page-item'
                                    pageLinkClassName='page-link'
                                    previousLinkClassName='page-link'
                                    nextLinkClassName='page-link'
                                    activeClassName='active'
                                    activeLinkClassName='paginate'
                                    onPageChange={handlePageToursClick}
                                />
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
}
