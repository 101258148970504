import { useState } from 'react';

export default function useForm(initialState = {}, validateForm = {}) {
    const [formState, setFormState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [focus, setFocus] = useState(null);
    const [firstEdited, setFirstEdited] = useState(() => {
        const fields = {};

        for (let field_name in initialState) {
            fields[field_name] = false;
        }

        return fields;
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));

        if (focus) setFocus(null);
    };

    const handleChangeWithErrors = (e, condition_fields = []) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));

        if (firstEdited[name]) handleBlur(e, condition_fields);

        if (focus) setFocus(null);
    };

    const handleBlur = (e, condition_fields = []) => {
        const { name, value } = e.target;
        const { [name]: _, ...tmpErrors } = errors;
        const fields = {};

        for (let field_name of condition_fields) {
            fields[field_name] = formState[field_name];
        }

        const results = validateForm({ [name]: value }, fields);

        if (!results[0].isValid) tmpErrors[name] = results[0].message;

        if (!firstEdited[name]) setFirstEdited({ ...firstEdited, [name]: true });

        setErrors(tmpErrors);
    };

    const resetForm = () => {
        setFormState(initialState);
        setErrors({});
        setFocus(null);
    };

    const handleSubmit = (action, reset = true, condition_fields = [], fields_to_validate = Object.keys(formState)) => {
        return (e) => {
            e.preventDefault();

            const fields = {};
            const fieldsToValidate = {};

            for (let field_name of condition_fields) {
                fields[field_name] = formState[field_name];
            }

            for (let field_name of fields_to_validate) {
                fieldsToValidate[field_name] = formState[field_name];
            }

            const results = validateForm(fieldsToValidate, fields);

            if (results.every((result) => result.isValid)) {
                Promise.resolve(action(e)).then((res) => (res && res === 'error' ? null : reset && resetForm()));
            } else {
                let curFocus = null;
                const tmpErrors = {};

                results.forEach((field) => {
                    if (!field.isValid) {
                        tmpErrors[field.name] = field.message;

                        if (!curFocus) curFocus = field.name;
                    }
                });

                setErrors(tmpErrors);
                setFocus(curFocus);
            }
        };
    };

    return {
        formState,
        setFormState,
        errors,
        setErrors,
        focus,
        setFocus,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,
        handleChangeWithErrors,
    };
}
