import firebase from 'firebase';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const configDev = {
    apiKey: 'AIzaSyCYEQt2l5v6feMl4LkdK9YK7YyD_ezwYeU',
    authDomain: 'itoour-v1.firebaseapp.com',
    databaseURL: 'https://itoour-v1.firebaseio.com',
    projectId: 'itoour-v1',
    storageBucket: 'itoour-v1.appspot.com',
    messagingSenderId: '602241519187',
    appId: '1:602241519187:web:478ee2192f0b0db723c0a6',
};

const configProd = {
    apiKey: 'AIzaSyBohVQVDqjESL6oQl0YxJnatF7pu3XKzDI',
    authDomain: 'itoour-5f3b6.firebaseapp.com',
    databaseURL: 'https://itoour-5f3b6-default-rtdb.firebaseio.com',
    projectId: 'itoour-5f3b6',
    storageBucket: 'itoour-5f3b6.appspot.com',
    messagingSenderId: '1067368218749',
    appId: '1:1067368218749:web:e53f5d9efa4932622c7114',
};

const options = process.env.NODE_ENV === 'production' ? configProd : configDev;

const fire = firebase.initializeApp(options);
const storage = firebase.storage();
const functions = firebase.functions();
const auth = firebase.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();
//const facebookProvider = new firebase.auth.FacebookAuthProvider();
//const twitterProvider = new firebase.auth.TwitterAuthProvider();

export {
    googleProvider,
    //facebookProvider,
    //twitterProvider,
    storage,
    functions,
    auth,
    firebase as fbConfig,
    fire as default,
};
