import React, { useState, useEffect } from 'react';
import Navbar from '../components/Shared/Navbar';
//import MenuPerfil from "./MenuPerfil";
import MenuCliente from '../components/Shared/MenuCliente';
import MenuUsuario from '../components/Shared/MenuUsuario';
import { Row, Col, Table } from 'react-bootstrap';
import fire from '../config/Firebase';
import LeerMasLeerMenos from '../components/Shared/LeerMasLeerMenos';
import './css/Reservas.css';
import ModalDetallesReservas from '../components/Usuario/ModalDetallesReservas';

export default function Reservas({ uid, permisos }) {
    const [compras, setCompras] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const db = fire.firestore();

        db.collection('compras')
            .where('idCliente', '==', uid)
            .get()
            .then((result) => result.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            .then(async (reservas) => {
                const data = await Promise.all(
                    reservas.map(async (reserva) => {
                        const [tour, operador] = await Promise.all([
                            db.collection('tours').doc(reserva.idTour).get(),
                            db.collection('cliente').doc(reserva.idOperador).get(),
                        ]).then(([tour, operador]) => [tour.data(), operador.data()]);

                        return {
                            reserva: reserva.id,
                            codigo: reserva.codigo,
                            estado: reserva.estado.toUpperCase(),
                            fechaCompra: new Date(reserva.fechaCompra.seconds * 1000),
                            fechaReserva: new Date(reserva.fechaReserva.seconds * 1000),
                            idCliente: reserva.idCliente,
                            operador,
                            tour: { ...tour, id: reserva.idTour },
                            moneda: reserva.moneda,
                            personas: +reserva.adultos + +reserva.ninos,
                            adultos: +reserva.adultos,
                            ninos: +reserva.ninos,
                            precioAdulto: reserva.precioAdulto,
                            precioNino: reserva.precioNino,
                            turistas: [...reserva.turistas],
                        };
                    })
                );
                setCompras(data.filter((d) => d.estado === 'PAGADO'));
            })
            .finally(() => setLoading(false));
    }, [uid]);

    return (
        <div>
            <div className='container mt-4'>
                <Row className='mobile mb-3'>
                    <Col md={12}>
                        {permisos === 'usuario' ? <MenuUsuario reservas={true} /> : <MenuCliente reservas={true} />}
                    </Col>
                </Row>
                <div className='row'>
                    <div className='col-md-2 desktop'>
                        {permisos === 'usuario' ? (
                            <MenuUsuario reservas={true} vertical={true} />
                        ) : (
                            <MenuCliente reservas={true} vertical={true} />
                        )}
                    </div>
                    <div className='col-md-10'>
                        <h2 className='text-itoour'>Tus Compras</h2>
                        <div className='compras-container'>
                            {/*compras.length !== 0 ? (
                            compras.map((compra, i) => (
                                <div className="card mb-4 shadow" key={i}>
                                    <div className="card-body">
                                        <h5 className="card-title mb-1º text-itoour font-weight-bold mb-3">
                                            Nombre tour:{" "}
                                            {compra.compra_nombretour}
                                            <br />
                                            <span
                                                style={{
                                                    fontSize: "16px",
                                                    marginTop: "10px",
                                                    display: "block",
                                                }}
                                            >
                                                Nro contacto:{" "}
                                                {compra.agency_telempresa}
                                            </span>
                                        </h5>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Número de compra:{" "}
                                                    </span>
                                                    #{compra.compra_id}
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Precio de tour:{" "}
                                                    </span>
                                                    {compra.compra_moneda ===
                                                    "PEN"
                                                        ? "S/"
                                                        : "$"}{" "}
                                                    {compra.compra_precio}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Monto pagado:{" "}
                                                    </span>
                                                    {compra.compra_moneda ===
                                                    "PEN"
                                                        ? "S/"
                                                        : "$"}{" "}
                                                    {compra.compra_montoPagado}
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cantidad de personas:{" "}
                                                    </span>
                                                    {compra.compra_personas}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Ciudad:{" "}
                                                    </span>
                                                    {compra.compra_ciudad}
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Fecha del Tour:{" "}
                                                    </span>
                                                    {compra.compra_fechatour}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Hora del Tour:{" "}
                                                    </span>
                                                    {compra.compra_horarioTour}
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente nombres:{" "}
                                                    </span>
                                                    {compra.compra_user_nombres}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente apellidos:{" "}
                                                    </span>
                                                    {
                                                        compra.compra_user_apellidos
                                                    }
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente tipo documento:{" "}
                                                    </span>
                                                    {compra.compra_user_tipodoc}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente número
                                                        documento:{" "}
                                                    </span>
                                                    {
                                                        compra.compra_user_documento
                                                    }
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente teléfono:{" "}
                                                    </span>
                                                    {
                                                        compra.compra_user_telefono
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente celular:{" "}
                                                    </span>
                                                    {compra.compra_user_movil}
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente país:{" "}
                                                    </span>
                                                    {compra.compra_user_pais}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row row mb-3">
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente ciudad:{" "}
                                                    </span>
                                                    {compra.compra_user_ciudad}
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="card-subtitle">
                                                    <span className="font-weight-bold">
                                                        Cliente dirección:{" "}
                                                    </span>
                                                    {
                                                        compra.compra_user_direccion
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h4>Aún no has hecho compras.</h4>
                        )*/}
                            {compras.length ? (
                                <table className='table table-borderless table-hover tablaReservas'>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th>Nombre del Tour</th>
                                            <th>Turistas</th>
                                            <th>Fecha de Compra</th>
                                            <th>Fecha del Tour</th>
                                            <th>Hora del Tour</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {compras.map((compra, i) => (
                                            <tr key={i}>
                                                <td className='tourNombreReserva'>
                                                    <LeerMasLeerMenos
                                                        limit={25}
                                                        textLength={compra.tour.tour_nom.length}
                                                        leerMas='...'
                                                    >
                                                        {compra.tour.tour_nom}
                                                    </LeerMasLeerMenos>
                                                </td>
                                                <td>{compra.personas}</td>
                                                <td>{compra.fechaCompra.toLocaleString('en-GB').split(',')[0]}</td>
                                                <td>{compra.fechaReserva.toLocaleString('en-GB').split(',')[0]}</td>
                                                <td>
                                                    {compra.fechaReserva
                                                        .toLocaleString('en-GB', {
                                                            hour12: true,
                                                        })
                                                        .split(',')[1]
                                                        .substring(1)}
                                                </td>
                                                <td>
                                                    <span
                                                        className={`badge ${
                                                            compra.estado === 'PAGADO'
                                                                ? 'badge-success'
                                                                : 'badge-warning'
                                                        }`}
                                                    >
                                                        {compra.estado}
                                                    </span>
                                                </td>
                                                <td>
                                                    <ModalDetallesReservas compra={compra} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : loading ? (
                                <div className='d-flex justify-content-center'>
                                    <h2 className='mt-5' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                        Cargando...
                                    </h2>
                                </div>
                            ) : (
                                <h4>Aún no has hecho compras.</h4>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
