import React, { useState, useEffect } from 'react';
import MenuCliente from '../components/Shared/MenuCliente';
import MenuUsuario from '../components/Shared/MenuUsuario';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import fire from '../config/Firebase';
import Swal from 'sweetalert2';
import './css/Menu.css';
import FotoPerfil from '../components/Shared/FotoPerfil';
import useForm from '../hooks/useForm';
import './css/perfil.css';

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'nombres': {
                validates.push({
                    name,
                    isValid: !formState[name] || formState[name]?.length > 2,
                    message: formState[name]?.length
                        ? 'El nombre no puede tener menos de 3 caracteres'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'apellidos': {
                validates.push({
                    name,
                    isValid: !formState[name] || formState[name]?.length > 2,
                    message: formState[name]?.length
                        ? 'El apellido no puede tener menos de 3 caracteres'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'dni': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[0-9]{8}$/i.test(formState[name]),
                    message: !formState[name]?.length
                        ? 'El documento es requerido'
                        : !/^[0-9]+$/i.test(formState[name])
                        ? 'Sólo se permiten números.'
                        : 'El dni debe tener 8 dígitos',
                });
                break;
            }

            case 'telefono': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[0-9]+$/i.test(formState[name]),
                    message: 'No es un teléfono válido.',
                });
                break;
            }

            case 'celular': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[0-9]+$/i.test(formState[name]),
                    message: 'No es un celular válido.',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

export default function InfoPersonal({ permisos }) {
    const db = fire.firestore();
    const [state, setState] = useState({
        email: '',
        nombres: '',
        apellidos: '',
        nacimiento: new Date(),
        dni: '',
        telefono: '',
        celular: '',
        foto: '',
    });
    const [edited, setEdited] = useState({
        email: false,
        nombres: false,
        apellidos: false,
        nacimiento: false,
        dni: false,
        telefono: false,
        celular: false,
        foto: false,
    });
    const [usuario, setUsuario] = useState('');
    const [empty, setEmpty] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const { formState, setFormState, focus, setFocus, errors, handleChange, handleBlur, handleSubmit } = useForm(
        { nombres: '', apellidos: '', dni: '', telefono: '', celular: '' },
        validateForm,
    );

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
        setDisabled(false);
    };

    const actualizarNacimiento = (fecha) => {
        setState({
            ...state,
            nacimiento: new Date(fecha),
        });
    };

    const obtenerUsuario = () => {
        return new Promise((resolve, reject) => {
            fire.auth().onAuthStateChanged((user) => {
                if (user) {
                    resolve(user.uid);
                } else {
                    reject('Error al obtener usuario');
                }
            });
        });
    };

    const emptyFields = (data) => {
        for (let key in data) {
            if (key !== 'nacimiento' && data[key]) return false;
        }

        return true;
    };

    const actualizarUsuario = (e) => {
        e.preventDefault();

        Swal.fire({
            icon: 'info',
            title: 'Actualizando Perfil',
            text: 'Espere mientras se guardan los cambios',
            allowOutsideClick: false,
        });
        Swal.showLoading();

        const docRef =
            permisos === 'usuario' ? db.collection('usuario').doc(usuario) : db.collection('cliente').doc(usuario);

        if (permisos === 'usuario') {
            const query = empty
                ? docRef.set({
                      usuario_nombres: state.nombres,
                      usuario_apellidos: state.apellidos,
                      usuario_nacimiento: state.nacimiento,
                      usuario_dni: state.dni,
                      usuario_telefono: state.telefono,
                      usuario_celular: state.celular,
                      usuario_foto: state.foto,
                  })
                : docRef.update({
                      usuario_nombres: state.nombres,
                      usuario_apellidos: state.apellidos,
                      usuario_nacimiento: state.nacimiento,
                      usuario_dni: state.dni,
                      usuario_telefono: state.telefono,
                      usuario_celular: state.celular,
                      usuario_foto: state.foto,
                  });

            query
                .then(() => {
                    setDisabled(true);

                    Swal.fire({
                        icon: 'success',
                        title: 'Foto de Perfil Actualizada',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrió un error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } else {
            const query = emptyFields(state)
                ? docRef.set({
                      cliente_nombres: state.nombres,
                      cliente_apellidos: state.apellidos,
                      cliente_dni: state.dni,
                      cliente_telefono: state.telefono,
                      cliente_celular: state.celular,
                      cliente_foto: state.foto || '',
                  })
                : docRef.update({
                      cliente_nombres: state.nombres,
                      cliente_apellidos: state.apellidos,
                      cliente_dni: state.dni,
                      cliente_telefono: state.telefono,
                      cliente_celular: state.celular,
                  });

            query
                .then(() => {
                    setDisabled(true);

                    Swal.fire({
                        icon: 'success',
                        title: 'Foto de perfil Actualizada',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrió un error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };

    const onSubmit = handleSubmit(actualizarUsuario, false);

    const actualizarFoto = (foto, nombre) => {
        Swal.fire({
            title: 'Subiendo foto de perfil...',
            text: 'Por favor espere...',
            allowOutsideClick: false,
        });
        Swal.showLoading();

        const docRef =
            permisos === 'usuario' ? db.collection('usuario').doc(usuario) : db.collection('cliente').doc(usuario);

        if (permisos === 'usuario') {
            const query = empty ? docRef.set({ usuario_foto: foto }) : docRef.update({ usuario_foto: foto });

            return query
                .then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Perfil Actualizado',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setState({
                        ...state,
                        foto,
                        nombre_foto: nombre,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrió un error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } else {
            const query = empty ? docRef.set({ cliente_foto: foto }) : docRef.update({ cliente_foto: foto });

            return query
                .then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Perfil Actualizado',
                        showConfirmButton: false,
                        timer: 1500,
                    });

                    setState({
                        ...state,
                        foto,
                        nombre_foto: nombre,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrió un error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };

    useEffect(() => {
        obtenerUsuario()
            .then((userid) => {
                setUsuario(userid);

                if (permisos === 'usuario') return db.collection('usuario').doc(userid).get();
                else return db.collection('cliente').doc(userid).get();
            })
            .then((usu) => {
                if (permisos === 'usuario') {
                    const data = {
                        ...state,
                        nombre_foto: usu.data().usuario_nombrefoto,
                        nombres: usu.data()?.usuario_nombres,
                        apellidos: usu.data()?.usuario_apellidos,
                        nacimiento: new Date((usu.data()?.usuario_nacimiento?.seconds ?? 0) * 1000),
                        dni: usu.data()?.usuario_dni,
                        telefono: usu.data()?.usuario_telefono,
                        celular: usu.data()?.usuario_celular,
                        foto: usu.data()?.usuario_foto,
                    };

                    setState(data);

                    setFormState({
                        nombres: usu.data()?.usuario_nombres || '',
                        apellidos: usu.data()?.usuario_apellidos || '',
                        dni: usu.data()?.usuario_dni || '',
                        telefono: usu.data()?.usuario_telefono || '',
                        celular: usu.data()?.usuario_celular || '',
                    });

                    setEmpty(emptyFields(data));
                } else {
                    const data = {
                        ...state,
                        //foto: usu.data().cliente_foto,
                        //nombre_foto: usu.data().cliente_nombrefoto,
                        email: usu.data()?.cliente_email,
                        nombres: usu.data()?.cliente_nombres,
                        apellidos: usu.data()?.cliente_apellidos,
                        dni: usu.data()?.cliente_dni,
                        telefono: usu.data()?.cliente_telefono,
                        celular: usu.data()?.cliente_celular,
                        foto: usu.data()?.cliente_foto,
                    };

                    setState(data);

                    setFormState({
                        nombres: usu.data()?.cliente_nombres || '',
                        apellidos: usu.data()?.cliente_apellidos || '',
                        dni: usu.data()?.cliente_dni || '',
                        telefono: usu.data()?.cliente_telefono || '',
                        celular: usu.data()?.cliente_celular || '',
                    });

                    setEmpty(emptyFields(data));
                }
            });
    }, []);

    useEffect(() => {
        if (focus) {
            document.getElementsByName(focus)[0].focus();
            setFocus(null);
        }
    }, [focus]);

    return (
        <Container className='mt-4'>
            <Row className='mobile mb-3'>
                <Col md={12}>
                    {permisos === 'usuario' ? <MenuUsuario perfil={true} /> : <MenuCliente perfil={true} />}
                </Col>
            </Row>
            <Row>
                <Col lg='2' className='desktop'>
                    {permisos === 'usuario' ? (
                        <MenuUsuario perfil={true} vertical={true} />
                    ) : (
                        <MenuCliente perfil={true} vertical={true} />
                    )}
                </Col>
                <Col lg='10'>
                    {permisos === 'usuario' ? (
                        <>
                            <h2 className='text-itoour'>Tu Cuenta en Itoour</h2>
                            <p>
                                Hola! Tomate un tiempo para registrarte correctamente, esta información nos servirá para
                                poder asistirte de manera correcta en nuestra plataforma. Gracias por tu tiempo.
                                Disfruta del paseo!.
                            </p>
                            <FotoPerfil
                                actualizarFoto={actualizarFoto}
                                title='Subir foto de perfil'
                                permisos={permisos}
                            />
                            <Form onSubmit={onSubmit}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Nombres:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su nombre'
                                        name='nombres'
                                        value={formState.nombres}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.nombres ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.nombres && <small className='text-danger'>{errors.nombres}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Apellidos:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su apellido'
                                        name='apellidos'
                                        value={formState.apellidos}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.apellidos ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.apellidos && <small className='text-danger'>{errors.apellidos}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Fecha de Nacimiento:</Form.Label>
                                    <div>
                                        <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                format='YYYY/MM/DD'
                                                margin='normal'
                                                id='fecha'
                                                name='nacimiento'
                                                value={state.nacimiento}
                                                maxDate={new Date(new Date().getTime() - 6570 * 24 * 60 * 60 * 1000)}
                                                onChange={(e) => {
                                                    actualizarNacimiento(e);
                                                    setEdited({ ...edited, [e.target.name]: true });
                                                }}
                                                className={
                                                    edited.nacimiento ? 'form-control bgColorEditInput' : 'form-control'
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>DNI:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su DNI'
                                        name='dni'
                                        value={formState.dni}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.dni ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.dni && <small className='text-danger'>{errors.dni}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Teléfono:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su Teléfono'
                                        name='telefono'
                                        value={formState.telefono}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.telefono ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.telefono && <small className='text-danger'>{errors.telefono}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Celular:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su Celular'
                                        name='celular'
                                        value={formState.celular}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.celular ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.celular && <small className='text-danger'>{errors.celular}</small>}
                                </Form.Group>
                                {disabled || Object.keys(errors).length ? (
                                    <Form.Group className='mt-3'>
                                        <Form.Control
                                            type='submit'
                                            value='Guardar Cambios'
                                            className='btn-itoour'
                                            disabled
                                        />
                                    </Form.Group>
                                ) : (
                                    <Form.Group className='mt-3'>
                                        <Form.Control
                                            type='submit'
                                            value='Guardar Cambios'
                                            className='btn-itoour btn-save'
                                        />
                                    </Form.Group>
                                )}
                            </Form>
                        </>
                    ) : (
                        <>
                            <h2 className='text-itoour mb-3'>Información personal</h2>
                            <FotoPerfil
                                foto={state.foto}
                                actualizarFoto={actualizarFoto}
                                title='Subir foto de perfil'
                                prevImage={state.nombre_foto}
                            />
                            <Form onSubmit={onSubmit}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Correo Registrado:</Form.Label>
                                    <Form.Control type='email' value={state.email} disabled />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Nombres:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su nombre'
                                        name='nombres'
                                        value={formState.nombres}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.nombres ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.nombres && <small className='text-danger'>{errors.nombres}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Apellidos:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su apellido'
                                        name='apellidos'
                                        value={formState.apellidos}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.apellidos ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.apellidos && <small className='text-danger'>{errors.apellidos}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>DNI:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su DNI'
                                        name='dni'
                                        value={formState.dni}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.dni ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.dni && <small className='text-danger'>{errors.dni}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Teléfono:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su telefono'
                                        name='telefono'
                                        value={formState.telefono}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.telefono ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.telefono && <small className='text-danger'>{errors.telefono}</small>}
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Celular:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Ingrese su celular'
                                        name='celular'
                                        value={formState.celular}
                                        onChange={(e) => {
                                            actualizarInput(e);
                                            handleChange(e);
                                            setEdited({ ...edited, [e.target.name]: true });
                                        }}
                                        onBlur={handleBlur}
                                        className={edited.celular ? 'bgColorEditInput' : ''}
                                    />
                                    {errors.celular && <small className='text-danger'>{errors.celular}</small>}
                                </Form.Group>
                                {disabled || Object.keys(errors).length ? (
                                    <Form.Group className='mt-3'>
                                        <Form.Control
                                            type='submit'
                                            value='Guardar Cambios'
                                            className='btn-itoour'
                                            disabled
                                        />
                                    </Form.Group>
                                ) : (
                                    <Form.Group className='mt-3'>
                                        <Form.Control
                                            type='submit'
                                            value='Guardar Cambios'
                                            className='btn-itoour btn-save'
                                        />
                                    </Form.Group>
                                )}
                            </Form>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
