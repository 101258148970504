import React, { useEffect } from 'react';
import { Country, State, City } from 'country-state-city';

export default function EntityData({
    step,
    changeStep,
    values,
    focus,
    setFocus,
    setErrors,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
}) {
    const handleNext = (e) => {
        e.preventDefault();

        if (step + 1 < 4) {
            changeStep(step + 1);
        }
    };

    useEffect(() => {
        if (focus) {
            const firstFieldError = document.getElementsByName(focus)[0];

            if (firstFieldError) {
                firstFieldError.focus();
                setFocus(null);
            }
        }
    }, [focus]);

    return (
        <div className='stepFormContainer'>
            <form>
                <div className='row-form'>
                    <div className='col-form full-width'>
                        <label className='label-field' htmlFor='inpNombresAnfitrion'>
                            Nombre comercial:
                        </label>
                        <input
                            className='input-text-field'
                            type='text'
                            id='inpNombresAnfitrion'
                            name='nombre_anfitrion'
                            value={values.nombre_anfitrion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.nombre_anfitrion && <small className='text-danger'>{errors.nombre_anfitrion}</small>}
                    </div>
                </div>
                {values.tipo !== 'TOUR OPERADOR' ? (
                    <div className='row-form'>
                        <div className='col-form w-40'>
                            <label className='label-field' htmlFor='selectGenero'>
                                Sexo:
                            </label>
                            <select
                                id='selectGenero'
                                className='select-tipo'
                                name='sexo'
                                value={values.sexo}
                                onChange={(e) => handleChange(e, ['tipo'])}
                                onBlur={(e) => handleBlur(e, ['tipo'])}
                            >
                                <option value=''>Seleccionar</option>
                                <option value='No especifica'>No especifica</option>
                                <option value='Hombre'>Hombre</option>
                                <option value='Mujer'>Mujer</option>
                            </select>
                            {errors.sexo && <small className='text-danger'>{errors.sexo}</small>}
                        </div>
                        <div className='col-form w-60'>
                            <label className='label-field' htmlFor='inpDireccion'>
                                Dirección:
                            </label>
                            <input
                                className='input-text-field'
                                type='text'
                                id='inpDireccion'
                                name='direccion'
                                value={values.direccion}
                                onChange={(e) => handleChange(e, ['tipo'])}
                                onBlur={(e) => handleBlur(e, ['tipo'])}
                            />
                            {errors.direccion && <small className='text-danger'>{errors.direccion}</small>}
                        </div>
                    </div>
                ) : (
                    <div className='row-form'>
                        <div className='col-form'>
                            <label className='label-field' htmlFor='inpRepresentante'>
                                Representante legal:
                            </label>
                            <input
                                className='input-text-field'
                                type='text'
                                id='inpRepresentante'
                                name='representante'
                                value={values.representante}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.representante && <small className='text-danger'>{errors.representante}</small>}
                        </div>
                        <div className='col-form'>
                            <label className='label-field' htmlFor='inpDireccion'>
                                Dirección:
                            </label>
                            <input
                                className='input-text-field'
                                type='text'
                                id='inpDireccion'
                                name='direccion'
                                value={values.direccion}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.direccion && <small className='text-danger'>{errors.direccion}</small>}
                        </div>
                    </div>
                )}
                <div className='row-form'>
                    <div className='col-form full-width'>
                        <label className='label-field' htmlFor='selectGenero'>
                            Sede:
                        </label>
                        <div className='row-form'>
                            <div className='col-form w-33'>
                                <select
                                    id='selectPais'
                                    className='select-tipo'
                                    name='pais'
                                    value={values.pais}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleChange({ target: { name: 'departamento', value: '' } });
                                        handleChange({ target: { name: 'ciudad', value: '' } });
                                        if (
                                            e.target.value?.toLowerCase() !== 'perú' &&
                                            e.target.value?.toLowerCase() !== 'peru'
                                        ) {
                                            handleChange({ target: { name: 'ruc', value: '' } });
                                            handleChange({ target: { name: 'razon_social', value: '' } });
                                        }
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value=''>País</option>
                                    {Country.getAllCountries().map((pais, index) => {
                                        return (
                                            <option value={pais.isoCode} key={`country${index}`}>
                                                {pais.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                {errors.pais && <small className='text-danger'>{errors.pais}</small>}
                            </div>
                            <div className='col-form w-33'>
                                <select
                                    id='selectDepartamento'
                                    className='select-tipo'
                                    name='departamento'
                                    value={values.departamento}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleChange({ target: { name: 'ciudad', value: '' } });
                                    }}
                                    onBlur={handleBlur}
                                >
                                    <option value=''>{values.pais === 'PE' ? 'Departamento' : 'Estado'}</option>
                                    {State.getStatesOfCountry(values.pais)?.length ? (
                                        State.getStatesOfCountry(values.pais).map((depa, index) => {
                                            return (
                                                <option value={depa.isoCode} key={`state${index}`}>
                                                    {depa.name}
                                                </option>
                                            );
                                        })
                                    ) : (
                                        <option value='Sin Estado'>Sin Estado</option>
                                    )}
                                </select>
                                {errors.departamento && <small className='text-danger'>{errors.departamento}</small>}
                            </div>
                            <div className='col-form w-33'>
                                <select
                                    id='selectCiudad'
                                    className='select-tipo'
                                    name='ciudad'
                                    value={values.ciudad}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <option value=''>Ciudad</option>
                                    {values.departamento === 'Sin Estado' ||
                                    !City.getCitiesOfState(values.pais, values.departamento)?.length ? (
                                        <option value='Sin Ciudad'>Sin Ciudad</option>
                                    ) : (
                                        City.getCitiesOfState(values.pais, values.departamento).map((ciudad, index) => {
                                            return (
                                                <option value={ciudad.name} key={`city${index}`}>
                                                    {ciudad.name}
                                                </option>
                                            );
                                        })
                                    )}
                                </select>
                                {errors.ciudad && <small className='text-danger'>{errors.ciudad}</small>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row-form'>
                    <div className='col-form w-40'>
                        <label className='label-field' htmlFor='inpRuc'>
                            RUC:
                        </label>
                        <input
                            className='input-text-field'
                            type='text'
                            id='inpRuc'
                            name='ruc'
                            value={values.ruc}
                            onChange={(e) => handleChange(e, ['pais'])}
                            onBlur={(e) => handleBlur(e, ['pais'])}
                            disabled={values.pais !== 'PE'}
                        />
                        {errors.ruc && <small className='text-danger'>{errors.ruc}</small>}
                    </div>
                    <div className='col-form w-60'>
                        <label className='label-field' htmlFor='inpRazonSocial'>
                            Apell. y Nom. o Razón Social:
                        </label>
                        <input
                            className='input-text-field'
                            type='text'
                            id='inpRazonSocial'
                            name='razon_social'
                            value={values.razon_social}
                            onChange={(e) => handleChange(e, ['pais'])}
                            onBlur={(e) => handleBlur(e, ['pais'])}
                            disabled={values.pais !== 'PE'}
                        />
                        {errors.razon_social && <small className='text-danger'>{errors.razon_social}</small>}
                    </div>
                </div>
                <div className='row-form center'>
                    <button
                        className='btn-sgte-resgistro'
                        onClick={handleSubmit(
                            handleNext,
                            false,
                            ['pais', 'tipo'],
                            [
                                'nombre_anfitrion',
                                'ruc',
                                'razon_social',
                                'representante',
                                'sexo',
                                'direccion',
                                'pais',
                                'departamento',
                                'ciudad',
                            ],
                        )}
                    >
                        SIGUIENTE
                    </button>
                </div>
            </form>
        </div>
    );
}
