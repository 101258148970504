import React, { useState } from 'react';
import './css/CrearTour.css';

export default function Lista(props) {
    // console.log(place,lista)
    let [input, setInput] = useState('');

    let anadirItem = (e) => {
        e.preventDefault();
        if (input !== '') {
            props.anadir(input);
            setInput('');
        }
    };

    let eliminarItem = (index) => {
        props.eliminar(index);
    };

    return (
        <div className='inputlista'>
            <div className='header-list-container'>
                <input
                    className='form-control'
                    type='text'
                    placeholder={props.place}
                    id='lista'
                    name='lista'
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value);

                        const { [props.nombre]: lista, ...restError } = props.errors;

                        const minimo = props.nombre === 'listaTerminos' ? 30 : 3;

                        if (e.target?.value && e.target.value.length < minimo) {
                            props.setErrors({
                                ...restError,
                                [props.nombre]: `El texto no puede tener menos de ${minimo} caracteres.`,
                            });
                        } else props.setErrors({ ...restError });
                    }}
                    onBlur={(e) => {
                        props?.setFieldsChanged &&
                            props.setFieldsChanged((prev) => {
                                return {
                                    ...prev,
                                    [props.nombre]: true,
                                };
                            });

                        const { [props.nombre]: lista, ...restError } = props.errors;

                        const minimo = props.nombre === 'listaTerminos' ? 30 : 3;

                        if (e.target?.value && e.target.value.length < minimo) {
                            props.setErrors({
                                ...restError,
                                [props.nombre]: `El texto no puede tener menos de ${minimo} caracteres.`,
                            });
                        } else props.setErrors({ ...restError });
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter' && !props.errors[props.nombre]) {
                            anadirItem(e);
                        }
                    }}
                />
                <button
                    className='btn btn-primary'
                    onClick={(e) => {
                        anadirItem(e);
                    }}
                    disabled={!!props.errors[props.nombre] || !input}
                >
                    Añadir
                </button>
            </div>
            {props.errors[props.nombre] && <small className='text-danger'>{props.errors[props.nombre]}</small>}
            <div className='list-container'>
                <ul className='list-group lista'>
                    {props.lista
                        ? props.lista.map((item, i) => {
                              return (
                                  <li
                                      key={i}
                                      className={`list-group-item list-group-item-${props.estilo} d-flex justify-content-between`}
                                  >
                                      <div>
                                          {i + 1}. {item}
                                      </div>{' '}
                                      <button
                                          className='btn btn-secondary btn-sm'
                                          onClick={() => {
                                              eliminarItem(i);
                                          }}
                                      >
                                          <i className='fas fa-minus-circle'></i>
                                      </button>
                                  </li>
                              );
                          })
                        : null}
                </ul>
            </div>
        </div>
    );
}
