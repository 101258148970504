import React, { useEffect, useState } from 'react';
import { getImageNameByUrl } from '../../Util/Imagen';

let imageListToBlob = async (listaImagenes) => {
    let getBlob = (file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onloadend = () => {
                let objImagen = {
                    name: file.name,
                    blob: reader.result,
                };
                resolve(objImagen);
            };
            reader.readAsDataURL(file);
        });
    };

    let promesas = [];

    for (let i = 0; i < listaImagenes.length; i++) {
        promesas.push(getBlob(listaImagenes[i]));
    }
    return await Promise.all(promesas);
};

export default function InputMultipleImages({
    actualizarImagenes,
    seleccionarMiniatura,
    imgBlob,
    setImgBlob,
    imagenes,
    miniatura,
    handleChange,
    handleBlur,
    errors,
}) {
    let [radio, setRadio] = useState(0);
    let [imagenesBlob, setImagenesBlob] = useState([]);

    let manejarImagenes = async (e) => {
        e.preventDefault();
        if (e.target.files.length > 10 || imgBlob.length >= 10) {
            return;
        }
        let misImagenes = Array.from(e.target.files);
        let arrBlob = imageListToBlob(misImagenes);
        arrBlob.then((result) => {
            actualizarImagenes([...misImagenes]);
            setImgBlob([...imgBlob, ...result]);
            handleChange({
                target: {
                    name: 'imgBlob',
                    value: [...imgBlob, ...result],
                },
            });
            handleBlur({
                target: {
                    name: 'imgBlob',
                    value: [...imgBlob, ...result],
                },
            });
        });
    };

    let manejarRadio = (index) => {
        setRadio(index);
        seleccionarMiniatura(index);
    };

    useEffect(() => {
        setRadio(miniatura ? miniatura : 0);
    }, [miniatura]);

    return (
        <div>
            <form>
                <div className='form-group'>
                    <label className='btn btn-primary'>
                        <i className='fas fa-images mr-2'></i>Elegir Fotos
                        <input
                            type='file'
                            className='form-control'
                            multiple
                            accept='image/png, image/jpeg'
                            placeholder='Seleccione una o varias imagenes'
                            onChange={(e) => {
                                manejarImagenes(e);
                                manejarRadio(0);
                            }}
                            hidden
                        />
                    </label>
                </div>
            </form>
            <div className='row'>
                {imgBlob.length > 0 ? (
                    imgBlob.map((imagen, i) => (
                        <div className='col-2' key={i}>
                            <div className='card mb-2' style={{ width: '150px' }}>
                                <img
                                    src={imagen.blob}
                                    width='100'
                                    className='card-img-top'
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <div className='card-footer d-flex justify-content-between'>
                                    <div>
                                        <input
                                            type='radio'
                                            className='form-check-input'
                                            name='miniatura'
                                            value={i}
                                            checked={radio === i}
                                            onChange={(e) => {
                                                manejarRadio(i);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <small>{imagen.name}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : imagenes?.length > 0 ? (
                    imagenes.map((imagen, i) => (
                        <div className='col-2' key={i}>
                            <div className='card mb-2' style={{ width: '150px' }}>
                                <img
                                    src={imagen}
                                    width='100'
                                    className='card-img-top'
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <div className='card-footer d-flex justify-content-between'>
                                    <div>
                                        <small>{getImageNameByUrl(imagen)}</small>
                                    </div>
                                    <div>
                                        <input
                                            type='radio'
                                            className='form-check-input'
                                            name='miniatura'
                                            checked={radio === i}
                                            onChange={(e) => {
                                                manejarRadio(i);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='alert alert-warning col-12' role='alert'>
                        Aún no ha seleccionado imágenes
                    </div>
                )}
            </div>
            {errors.imgBlob && <small className='text-danger'>{errors.imgBlob}</small>}
        </div>
    );
}
