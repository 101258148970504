import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button } from 'react-bootstrap';
import './css/FotoPerfil.css';
import ModalSubirFoto from './ModalSubirFoto';
import { getImageNameByUrl } from '../../Util/Imagen';

export default function FotoPerfil({ actualizarFoto, title, permisos, noUpload = false }) {
    const [showModal, setShowModal] = useState(false);
    const avatar = useSelector((state) => state.auth.avatar);
    const operatorAvatar = useSelector((state) => state.auth.operatorAvatar);
    const dispatch = useDispatch();

    const updatePic = (image, name) => {
        actualizarFoto(image, name).then(() => {
            if (permisos === 'cliente-operador' || permisos === 'cliente-anfitrion') {
                dispatch({ type: 'SET_OPERATOR_AVATAR', payload: image });
            } else {
                dispatch({ type: 'SET_AVATAR', payload: image });
            }
        });
    };

    return (
        <div className='container mb-5'>
            <div className='row mb-3 justify-content-center'>
                {/*<Image
                    src={
                        permisos === "cliente-operador" ||
                        permisos === "cliente-anfitrion"
                            ? operatorAvatar
                            : avatar
                    }
                    className="foto-perfil"
                    id="foto"
                    roundedCircle
                />*/}
                <Image src={avatar || operatorAvatar} className='foto-perfil' id='foto' roundedCircle />
            </div>
            {!noUpload ? (
                <>
                    <div className='row justify-content-center'>
                        <Button className='btn-subir-foto btn-itoour' onClick={() => setShowModal(true)}>
                            Cambiar foto
                        </Button>
                    </div>
                    <ModalSubirFoto
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        actualizarFoto={updatePic}
                        title={title}
                        prevImage={getImageNameByUrl(avatar || operatorAvatar)}
                    />
                </>
            ) : null}
        </div>
    );
}
