import React, { useState, useEffect, Fragment } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import ModalComprarTour from '../../components/Shared/ModalComprarTour';
import ModalLoginRegister from '../../components/Shared/ModalLoginRegister';

const defaultState = {
    codigo: '',
    email: '',
    turistas: [],
};

export default function ComprarTour({
    idCliente,
    tourId,
    tour,
    comprar,
    fechaCompra,
    setFechaCompra,
    cambiarMonto,
    horaCompra,
    personas,
    ninos,
    setPersonas,
    setNinos,
    email,
    permisos,
}) {
    horaCompra(JSON.stringify(tour.tour_disponibilidad[0]));
    const [tos, setTos] = useState(false);
    let [opcion, setOpcion] = useState(JSON.stringify(tour?.tour_disponibilidad[0] || {}));
    let [state, setState] = useState(defaultState);
    const [rangeDate, setRangeDate] = useState({
        minDate: new Date(new Date().getTime() + 86400000),
        maxDate: new Date(new Date().getTime() + 86400000),
    });
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const handleClose = () => {
        setShow(false);
        setTos(false);
    };
    const handleShow = () => setShow(true);
    const { register, handleSubmit, errors } = useForm();

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    let filtrarTurnos = () => {
        //remuevo horas minutos y segundos
        const today = new Date(
            new Date().getTime() +
                +tour.tour_minimoanticipacion * (tour.tour_tipoanticipacion === 'dias' ? 1 : 30) * 24 * 60 * 60 * 1000
        );
        today.setHours(0, 0, 0, 0);
        const fechaCalendario = new Date(fechaCompra);
        fechaCalendario.setHours(0, 0, 0, 0);
        const aux = today < fechaCalendario ? fechaCalendario : today;

        let toursFiltrados = tour.tour_disponibilidad.filter((item) => {
            const turno = new Date(item.dia.seconds * 1000);
            const day = new Date(turno);
            day.setHours(0, 0, 0, 0);

            return day.getTime() === fechaCalendario.getTime() && aux <= turno;
        });

        return toursFiltrados;
    };

    useEffect(() => {
        if (show === false) {
            setState(defaultState);
            setTos(false);
        }
    }, [show]);

    useEffect(() => {
        setOpcion(JSON.stringify(filtrarTurnos()[0] || {}));
        horaCompra(JSON.stringify(filtrarTurnos()[0] || {}));
    }, [fechaCompra]);

    useEffect(() => {
        cambiarMonto(personas * tour.tour_precioAdultos + ninos * tour.tour_precioNino);
    }, [personas, ninos]);

    useEffect(() => {
        if (!permisos) setDisabled(true);
        else if (permisos === 'sinlogin') setDisabled(true);
        else setDisabled(false);
    }, [permisos]);

    /*let obtenerUsuario = () => {
        fire.auth().onAuthStateChanged((user) => {
            let sessionTimeout = null;
            try {
                user.getIdTokenResult(true)
                    .then((token) => {
                        const authTime = token.claims.auth_time * 1000;
                        const sessionDuration = 12 * 60 * 60 * 1000;
                        let millisecondsUntilExpiration =
                            sessionDuration - (Date.now() - authTime);
                        millisecondsUntilExpiration =
                            millisecondsUntilExpiration < 0
                                ? 0
                                : millisecondsUntilExpiration;

                        sessionTimeout = setTimeout(
                            () => this.logoutInTimeout(),
                            millisecondsUntilExpiration
                        );

                        if (token.claims.admin && millisecondsUntilExpiration)
                            return "admin";

                        if (token.claims.cliente && millisecondsUntilExpiration)
                            return "cliente";

                        if (millisecondsUntilExpiration) return true;

                        return false;
                    })
                    .then((permisos) => {
                        /**
                         * si eres cliente puedes comprar
                         * si eres admin no puedes comprar
                         * si no estass logueado no puedes comprar
                         */

    /*if (permisos === false) {
                            setDisabled(true);
                        }
                        if (permisos === "cliente" || permisos === "admin") {
                            setDisabled(true);
                        }
                        if (permisos === true) {
                            setDisabled(false);
                        }
                    });
            } catch (e) {
                console.log("e", e);
            }
        });
    };

    obtenerUsuario();*/

    function getAvisoCupos(cupos) {
        if (cupos == 0) {
            Swal.fire({
                title: 'No hay cupos para ese horario',
                icon: 'warning',
                text: 'El horario que eligio no cuenta con cupos disponibles',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Entiendo',
            });
        }
    }

    const setPriceComprarTour = () => {
        cambiarMonto(tour.tour_precioAdulto * 100 * personas);
        setShow(true);
    };

    const getMinAndMaxDate = (disponibilidad) => {
        const date = new Date(
            new Date().getTime() +
                +tour.tour_minimoanticipacion * (tour.tour_tipoanticipacion === 'dias' ? 1 : 30) * 24 * 60 * 60 * 1000
        );
        date.setHours(0, 0, 0, 0);

        let fechas = disponibilidad
            .map((d) => {
                return {
                    dia: new Date(d.dia.seconds * 1000),
                    capacidad: +d.capacidad,
                };
            })
            .sort((a, b) => a.dia.getTime() - b.dia.getTime());

        fechas = fechas.filter((f) => f.dia.getTime() > date.getTime() && f.capacidad > 0);

        if (fechas.length) {
            return {
                minDate: fechas[0].dia.getTime() > date.getTime() ? fechas[0].dia : date,
                maxDate:
                    fechas[fechas.length - 1].dia.getTime() <= date.getTime() ? date : fechas[fechas.length - 1].dia,
            };
        } else {
            return {
                minDate: new Date(),
                maxDate: new Date(),
            };
        }
    };

    useEffect(() => {
        const rango = getMinAndMaxDate(tour.tour_disponibilidad);
        setRangeDate(rango);
        setFechaCompra(rango.minDate);
    }, []);

    const getHoras = (date) => {
        let ampm = date?.toLocaleString('en-GB', { hour12: true })?.split(' ')[2];
        let [h, m, s] = date?.toLocaleString('en-GB')?.split(',')[1]?.substring(1)?.split(':');

        h = +h > 12 ? +h - 12 : h;

        return `${h}:${m}:${s} ${ampm}`;
    };

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h5 className='card-title font-weight-bold'>Fechas Disponibles</h5>
                    <div className='p-3'>
                        <Calendar
                            onChange={(e) => {
                                setFechaCompra(e);
                            }}
                            value={fechaCompra}
                            minDate={rangeDate.minDate}
                            maxDate={rangeDate.maxDate}
                        />
                    </div>
                    <div className='text-center mt-3'>
                        <h6 className='font-weight-bold'>Disponibilidad</h6>
                        <select
                            className='form-control mb-2'
                            value={opcion}
                            onChange={(e) => {
                                setOpcion(e.target.value);
                                horaCompra(e.target.value);
                            }}
                        >
                            {tour ? (
                                filtrarTurnos().length !== 0 ? (
                                    filtrarTurnos().map((turno, i) => (
                                        <option key={i} value={JSON.stringify(turno)}>
                                            Hora {getHoras(new Date(turno.dia.seconds * 1000))}
                                        </option>
                                    ))
                                ) : (
                                    <option defaultValue>Sin turnos programados</option>
                                )
                            ) : null}
                        </select>
                        <p className='font-weight-bold' style={{ color: '#D6872B' }}>
                            {filtrarTurnos().length !== 0 && opcion !== undefined ? (
                                <>
                                    {`Quedan ${('disp', JSON.parse(opcion).capacidad)} espacio(s) disponibles!`}
                                    {getAvisoCupos(JSON.parse(opcion).capacidad)}
                                </>
                            ) : null}
                        </p>
                        <h6 className='font-weight-bold'>Duración del Tour</h6>
                        <p className='text-secondary font-weight-bold'>
                            {tour.fullDay
                                ? `Full day`
                                : +(tour.horasTour ?? 0) + +(tour.minTour ?? 0) > 0
                                ? `${tour.horasTour ?? 0} hora${+(tour.horasTour ?? 0) > 1 ? 's' : ''} ${
                                      +(tour.minTour ?? 0) !== 0 ? `y ${tour.minTour} minutos` : ''
                                  }`
                                : `${tour.tour_dias} día${+tour.tour_dias > 1 ? 's' : ''} ${
                                      +tour.tour_noches !== 0
                                          ? `y ${tour.tour_noches} noche${+tour.tour_noches > 1 ? 's' : ''}`
                                          : ''
                                  }`}
                        </p>

                        <h3 className='font-weight-bolder text-itoour text-helvetica'>
                            {tour.tour_moneda === 'PEN' ? 'S/.' : '$.'} {Math.ceil(+tour.tour_precioAdulto / 0.85)}
                        </h3>
                        <h4 className='font-weight-bolder text-itoour text-helvetica'>
                            {!tour.tour_precioNino || +tour.tour_precioNino === 0 ? 'por persona' : 'por adulto'}
                        </h4>
                        {!tour.tour_precioNino || +tour.tour_precioNino === 0 ? null : (
                            <>
                                <br />
                                <h3 className='font-weight-bolder text-itoour text-helvetica'>
                                    {tour.tour_moneda === 'PEN' ? 'S/.' : '$.'}{' '}
                                    {Math.ceil(+tour.tour_precioNino / 0.85)}
                                </h3>
                                <h4 className='font-weight-bolder text-itoour text-helvetica'>por niño</h4>
                            </>
                        )}
                        <br />
                        <h6 className='font-weight-bold'>
                            Número de {!tour.tour_precioNino || +tour.tour_precioNino === 0 ? 'personas' : 'adultos'}
                        </h6>
                        <input
                            type='number'
                            className='form-control'
                            pattern='\d*'
                            min={`${tour?.tour_minimocompra || 1}`}
                            max={`${JSON.parse(opcion).capacidad}`}
                            placeholder={!tour.tour_precioNino ? 'Personas en el Tour' : 'Adultos en el Tour'}
                            value={personas}
                            onChange={(e) => {
                                const maximo = JSON.parse(opcion).capacidad || 1;
                                const restante = maximo - +ninos;

                                if (+e.target.value < 0) setPersonas(+tour?.tour_minimocompra || 1);
                                else if (+e.target.value > restante) setPersonas(restante);
                                else setPersonas(e.target.value);
                            }}
                            onBlur={(e) => {
                                const maximo = JSON.parse(opcion).capacidad || 1;
                                const restante = maximo - +ninos;

                                if (!e?.target?.value || +e.target.value <= 0)
                                    setPersonas(
                                        restante < +tour?.tour_minimocompra ? restante : +tour?.tour_minimocompra
                                    );
                                else if (+e.target.value > restante) setPersonas(+restante);
                            }}
                        />
                        {!tour.tour_precioNino || +tour.tour_precioNino === 0 ? null : (
                            <>
                                <br />
                                <h6 className='font-weight-bold'>Número de niños</h6>
                                <input
                                    type='number'
                                    className='form-control'
                                    min='0'
                                    max={`${+JSON.parse(opcion).capacidad - 1}`}
                                    placeholder={'Niños en el Tour'}
                                    value={ninos}
                                    onChange={(e) => {
                                        const maximo = JSON.parse(opcion).capacidad || 1;
                                        const restante = maximo - +personas;

                                        if (+e.target.value < 0) setNinos(+tour?.tour_minimocompra || 1);
                                        else if (+e.target.value > restante) setPersonas(restante);
                                        else setNinos(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        const maximo = JSON.parse(opcion).capacidad || 1;
                                        const restante = maximo - +personas;

                                        if (!e?.target?.value || +e.target.value <= 0) setNinos(0);
                                        else if (+e.target.value > restante) setNinos(+restante);
                                    }}
                                />
                            </>
                        )}
                        <br />
                        <h3 className='font-weight-bolder text-itoour text-helvetica'>
                            <u>
                                Total:{' '}
                                {+personas * Math.ceil(+tour.tour_precioAdulto / 0.85) +
                                    +ninos * Math.ceil(+tour.tour_precioNino / 0.85)}
                            </u>
                        </h3>
                    </div>
                    {disabled === false ? (
                        permisos === 'cliente-operador' || permisos === 'admin' ? null : filtrarTurnos().length !== 0 &&
                          opcion !== undefined &&
                          JSON.parse(opcion).capacidad != 0 &&
                          +personas > 0 ? (
                            <Fragment>
                                <br />
                                <ModalComprarTour
                                    idCliente={idCliente}
                                    tourId={tourId}
                                    tour={tour}
                                    comprar={comprar}
                                    fechaCompra={fechaCompra}
                                    personas={personas}
                                    ninos={ninos}
                                    state={state}
                                    setState={setState}
                                    fechaSeleccionada={JSON.parse(opcion).dia}
                                    email={email}
                                />
                            </Fragment>
                        ) : (
                            <button className='btn btn-secondary btn-lg btn-block disabled mt-4' disabled>
                                Comprar Tour
                            </button>
                        )
                    ) : (
                        <ModalLoginRegister tourId={tourId} />
                    )}
                </div>
            </div>
        </div>
    );
}
