import React, { Fragment, useState, useEffect } from 'react';
import './css/Login.css';
//import { useForm } from 'react-hook-form';
import { AuthService } from '../services/Auth';
import Swal from 'sweetalert2';
import { Link, Redirect } from 'react-router-dom';
import LoadingFull from '../components/Shared/CargandoFull';
import fire, { googleProvider } from '../config/Firebase';
import useForm from '../hooks/useForm';

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'email': {
                validates.push({
                    name,
                    isValid: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i.test(
                        formState[name],
                    ),
                    message: formState[name]?.length ? 'No es un email válido' : 'El email es requerido',
                });
                break;
            }

            case 'password': {
                validates.push({
                    name,
                    isValid: formState[name].length >= 6,
                    message: formState[name]?.length
                        ? 'La contraseña de contener al menos 6 caracteres.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'password2': {
                validates.push({
                    name,
                    isValid: formState[name].length >= 6,
                    message: formState[name]?.length
                        ? 'La contraseña de contener al menos 6 caracteres.'
                        : 'El campo es requerido',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

const initialForm = {
    email: '',
    password: '',
    password2: '',
};

export default function Registro(props) {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    // console.log("registro",props)
    const [datos, setDatos] = useState(initialForm);
    const [terms, setTerms] = useState(false);
    const { formState, errors, handleChange, handleBlur, handleSubmit } = useForm(initialForm, validateForm);
    const [redirect, setRedirect] = useState(false);

    const actualizarInput = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value,
        });
    };

    const existEmptyFields = (data) => {
        for (let key in data) {
            if (!data[key]) return true;
        }

        return false;
    };

    let sAuth = new AuthService();

    let login = () => {
        setLoading(true);
        sAuth
            .register(datos.email, datos.password)
            /*.then(() => {
        return props.logout();
      })*/
            .then(() => {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Registro Exitoso!',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    props.history.push(props?.location?.prevUrl || '/');
                });
            })
            .catch((err) => {
                if (err?.code === 'auth/email-already-in-use') {
                    Swal.fire({
                        title: 'Error de registro',
                        text: `La cuenta que intentas crear ya existe, prueba iniciando sesión.`,
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                        .then(() => props.history.push(props?.location?.prevUrl || '/login'))
                        .catch(() => props.history.push(props?.location?.prevUrl || '/'));
                } else {
                    Swal.fire({
                        title: 'Error de registro',
                        text: `Ocurrió un error inesperado al registrar su cuenta, por favor intentelo nuevamente más tarde.`,
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                        .then(() => props.history.push(props?.location?.prevUrl || '/'))
                        .catch(() => props.history.push(props?.location?.prevUrl || '/'));
                }
            });
    };

    const loginWithGoogle = (e) => {
        e.preventDefault();
        sAuth
            .registerWithGoogle(setRedirect)
            .then((res) => {
                if (res === null) {
                    return Swal.fire({
                        title: 'Error de registro',
                        text: `La cuenta que intentas crear ya existe.`,
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                        .then(() => setTimeout(() => props.history.push(props?.location?.prevUrl || '/'), 200))
                        .catch(() => setTimeout(() => props.history.push(props?.location?.prevUrl || '/'), 200));
                }
            })
            .catch((error) => console.log({ error }));
    };

    const EyeButton = ({ show, setShow }) => {
        return (
            <>
                {show ? (
                    <span className='input-group-text eye-password' onClick={(e) => setShow(false)}>
                        <i className='fas fa-eye-slash'></i>
                    </span>
                ) : (
                    <span className='input-group-text eye-password' onClick={(e) => setShow(true)}>
                        <i className='fas fa-eye'></i>
                    </span>
                )}
            </>
        );
    };

    useEffect(() => {
        if (props.logout) {
            props.logout();
        }
    }, []);

    if (redirect) return <Redirect to={props?.location?.prevUrl || '/'} />;

    return (
        <Fragment>
            {loading ? (
                <LoadingFull />
            ) : (
                <Fragment>
                    <div className='contenedor'>
                        <div className='imagenregistro'>
                            <span>itoour.com</span>
                        </div>
                        <div className='formulario'>
                            <div className='card'>
                                <div className='card-header'>itoour.com</div>

                                <div className='card-body'>
                                    <div className='card-text'>
                                        <div className='clearfix'>
                                            <h3 className='float-left'>Regístrate</h3>
                                            <div className='font-weight-bold float-right' id='registro'>
                                                <span>¿Ya tienes cuenta?</span>{' '}
                                                <Link to='/login'>
                                                    <span
                                                        style={{
                                                            color: '#d6872b',
                                                        }}
                                                    >
                                                        Iniciar Sesión
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                        <form>
                                            <label htmlFor='email' className='font-weight-bold'>
                                                Correo Electrónico
                                            </label>
                                            <br />
                                            <input
                                                type='email'
                                                className='form-control auth-email-input'
                                                id='email'
                                                name='email'
                                                placeholder='Ejemplo: matias23@ejemplo.com'
                                                value={formState.email}
                                                onChange={(e) => {
                                                    actualizarInput(e);
                                                    handleChange(e);
                                                    handleBlur(e);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.email && <small className='text-danger'>{errors.email}</small>}
                                            <br />
                                            <label htmlFor='pass' className='font-weight-bold'>
                                                Contraseña
                                            </label>
                                            <br />
                                            <div className='form-row'>
                                                <div className='form-group col-12'>
                                                    <div className='input-group'>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className='form-control'
                                                            id='password'
                                                            name='password'
                                                            placeholder='Ingresar contraseña'
                                                            value={formState.password}
                                                            onChange={(e) => {
                                                                actualizarInput(e);
                                                                handleChange(e);
                                                                handleBlur(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <div className='input-group-prepend'>
                                                            <EyeButton show={showPassword} setShow={setShowPassword} />
                                                        </div>
                                                    </div>
                                                    {errors.password && (
                                                        <small className='text-danger'>{errors.password}</small>
                                                    )}
                                                </div>
                                            </div>
                                            <label htmlFor='pass' className='font-weight-bold'>
                                                Confirmar Contraseña
                                            </label>
                                            <div className='form-row'>
                                                <div className='form-group col-12'>
                                                    <div
                                                        className='input-group'
                                                        style={{
                                                            flexWrap: 'wrap',
                                                        }}
                                                    >
                                                        <input
                                                            type={showRePassword ? 'text' : 'password'}
                                                            className='form-control'
                                                            id='password2'
                                                            name='password2'
                                                            placeholder='Repita la contraseña'
                                                            value={formState.password2}
                                                            onChange={(e) => {
                                                                actualizarInput(e);
                                                                handleChange(e);
                                                                handleBlur(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <div className='input-group-prepend'>
                                                            <EyeButton
                                                                show={showRePassword}
                                                                setShow={setShowRePassword}
                                                            />
                                                        </div>
                                                    </div>
                                                    {(formState.password !== formState.password2 && (
                                                        <small className='text-danger'>
                                                            Las contraseñas no coinciden
                                                        </small>
                                                    )) ||
                                                        (errors.password && (
                                                            <small className='text-danger'>{errors.password}</small>
                                                        ))}
                                                </div>
                                            </div>
                                            <div className='custom-control custom-checkbox'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='terms'
                                                    name='terms'
                                                    checked={terms}
                                                    onClick={(e) => {
                                                        setTerms(e.target.checked);
                                                    }}
                                                />
                                                <label className='custom-control-label' htmlFor='terms'>
                                                    Acepto los términos y políticas de privacidad
                                                </label>

                                                <br />
                                                {errors.terms && (
                                                    <small className='text-danger'>{errors.terms.message}</small>
                                                )}
                                            </div>
                                            <br />
                                            <button
                                                className='btn btn-block btn-login text-light'
                                                disabled={
                                                    existEmptyFields(datos) ||
                                                    Object.keys(errors).length ||
                                                    !terms ||
                                                    formState.password !== formState.password2
                                                }
                                                onClick={handleSubmit(login)}
                                            >
                                                Registrarse
                                            </button>
                                            <div className='separador-login'>
                                                <div className='linea-separador'></div>o
                                                <div className='linea-separador'></div>
                                            </div>
                                            <button
                                                className='btn btn-block btn-login btn-login-google text-light'
                                                disabled={!terms}
                                                onClick={loginWithGoogle}
                                            >
                                                <i class='fab fa-google'></i> Registrarse con Google
                                            </button>
                                            <small className='text-secondary'>
                                                Al registrarte aceptas las{' '}
                                                <a
                                                    href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FPOLITICAS%20DE%20PRIVACIDAD%20DE%20ITOOUR%20Y%20USO%20DE%20COOKIES.pdf?alt=media&token=299ffe10-5b6e-44b1-ae2b-91c9a43b490b'
                                                    target='_blank'
                                                >
                                                    políticas
                                                </a>{' '}
                                                y{' '}
                                                <a
                                                    href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FTERMINOS%20Y%20CONDICIONES%20usuarios.pdf?alt=media&token=2c25f0e9-cf7a-4ece-859e-9438a905b367'
                                                    target='_blank'
                                                >
                                                    términos
                                                </a>{' '}
                                                de itoour
                                            </small>
                                            <br />
                                        </form>

                                        {/* <button className="btn btn-outline-danger btn-sm">
                  <i className="fab fa-google"></i> Registro con Google
                </button>
                <button className="btn btn-outline-primary btn-sm float-right">
                  <i className="fab fa-facebook-f"></i> Registro con Facebook
                </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
}
