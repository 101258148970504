import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ActionsPage = ({ logged, setVerificado }) => {
    const query = useQuery();
    const mode = query.get('mode');
    const oobCode = query.get('oobCode');
    const apiKey = query.get('apiKey');

    switch (mode) {
        case 'verifyEmail':
            return <Redirect to={`/verification?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}`} />;

        case 'resetPassword':
            return <Redirect to={`/reset?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}`} />;

        default:
            return <Redirect to="/" />;
    }
};

export default ActionsPage;
