import fire from '../config/Firebase';
import { EnumStatusTours } from '../helpers/enumTours';
import { functions } from '../config/Firebase';
import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

export default class TourService {
    constructor() {
        this.db = fire.firestore();
    }

    async crearTour(
        {
            nombre,
            descripcion,
            precioAdulto,
            precioNino,
            moneda,
            fechaini,
            //fechafin,
            listaIncluye,
            listaNoIncluye,
            listaActividades,
            listaNotas,
            listaTerminos,
            ciudad,
            miniatura,
            dias,
            noches,
            horasTour,
            minTour,
            fullDay,
            partida,
            lat,
            lng,
            diario,
            activado,
            disponible,
            uid,
            emailcli,
            youtube,
            status,
            fechaCancelada,
            departamento,
            provincia,
            departamentoPartida,
            provinciaPartida,
            ciudadPartida,
            direccionPartida,
            direccion,
            cliente_tipo,
            minimoCompra,
            minimoAnticipacion,
            tipoAnticipacion,
            diasLaborales,
            restricciones,
            cliente_nombre,
        },
        urls,
    ) {
        const codigo = await this.generateCode('TOUR');

        return this.db
            .collection('tours')
            .add({
                codigo,
                tour_nom: nombre,
                tour_desc: descripcion,
                tour_precioAdulto: precioAdulto,
                tour_precioNino: precioNino,
                tour_moneda: moneda,
                tour_fecha: new Date(fechaini),
                //tour_fechafin:new Date(fechafin),
                tour_inc: listaIncluye,
                tour_noinc: listaNoIncluye,
                tour_activ: listaActividades,
                tour_notas: listaNotas,
                tour_terminos: listaTerminos,
                tour_ciudad: ciudad,
                tour_dias: dias,
                tour_noches: noches,
                horasTour: horasTour,
                minTour: minTour,
                fullDay: fullDay,
                tour_partida: partida,
                lat: lat,
                lng: lng,
                tour_imagenes: urls,
                tour_diario: diario,
                tour_activo: activado,
                tour_miniatura: miniatura,
                tour_disponibilidad: disponible,
                uid,
                cli_email: emailcli,
                tour_youtube: youtube,
                status: EnumStatusTours.APROBACION_PENDIENTE,
                fechaCancelada: '',
                tour_departamento: departamento,
                tour_provincia: provincia,
                tour_ciudad: ciudad,
                tour_departamentopartida: departamentoPartida,
                tour_provinciapartida: provinciaPartida,
                tour_ciudadpartida: ciudadPartida,
                tour_direccionpartida: direccionPartida,
                tour_direccion: direccion,
                cliente_tipo: cliente_tipo,
                tour_minimocompra: minimoCompra,
                tour_minimoanticipacion: minimoAnticipacion,
                tour_tipoanticipacion: tipoAnticipacion,
                tour_diaslaborales: diasLaborales,
                tour_restricciones: restricciones,
            })
            .then(async (res) => {
                const enviarEmail = functions.httpsCallable('enviarEmail');
                const fecha = new Date(fechaini);

                const body = `<p>Se ha creado el tour: ${nombre}, a través de la cuenta ${emailcli}, de tipo ${cliente_tipo}, perteneciente a ${cliente_nombre}, el día ${fecha.toLocaleDateString(
                    'us-GB',
                )} a las horas ${fecha.toLocaleTimeString('us-GB', {
                    hour12: true,
                })}. Favor de revisar el tour para su pronta aprobación.`;

                await enviarEmail({
                    subject: `${process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''}Nuevo tour creado`,
                    to: 'soporte@itoour.com,ventas@itoour.com',
                    body,
                    info: true,
                });

                return res.id;
            });
    }

    editarTour(
        {
            id,
            nombre,
            descripcion,
            precioAdulto,
            precioNino,
            moneda,
            fechaini,
            listaIncluye,
            listaNoIncluye,
            listaActividades,
            listaNotas,
            listaTerminos,
            ciudad,
            miniatura,
            dias,
            noches,
            horasTour,
            minTour,
            fullDay,
            partida,
            lat,
            lng,
            disponible,
            youtube,
            status,
            fechaCancelada,
            departamento,
            provincia,
            departamentoPartida,
            provinciaPartida,
            ciudadPartida,
            direccionPartida,
            direccion,
            minimoCompra,
            minimoAnticipacion,
            tipoAnticipacion,
            diasLaborales,
            restricciones,
        },
        urls,
        admin = false,
    ) {
        const data = {
            tour_nom: nombre,
            tour_desc: descripcion,
            tour_precioAdulto: precioAdulto,
            tour_precioNino: precioNino,
            tour_moneda: moneda,
            tour_fecha: new Date(fechaini),
            tour_inc: listaIncluye,
            tour_noinc: listaNoIncluye,
            tour_activ: listaActividades,
            tour_notas: listaNotas,
            tour_terminos: listaTerminos,
            tour_ciudad: ciudad,
            tour_dias: dias,
            tour_noches: noches,
            horasTour: horasTour,
            minTour: minTour,
            fullDay: fullDay,
            tour_partida: partida,
            lat: lat,
            lng: lng,
            tour_imagenes: urls,
            tour_miniatura: miniatura,
            tour_disponibilidad: disponible,
            tour_youtube: youtube,
            fechaCancelada: '',
            tour_departamento: departamento,
            tour_provincia: provincia,
            tour_ciudad: ciudad,
            tour_departamentopartida: departamentoPartida,
            tour_provinciapartida: provinciaPartida,
            tour_ciudadpartida: ciudadPartida,
            tour_direccionpartida: direccionPartida,
            tour_direccion: direccion,
            tour_minimocompra: minimoCompra,
            tour_minimoanticipacion: minimoAnticipacion,
            tour_tipoanticipacion: tipoAnticipacion,
            tour_diaslaborales: diasLaborales,
            tour_restricciones: restricciones,
        };

        if (!admin) data.status = EnumStatusTours.APROBACION_PENDIENTE;

        return this.db.collection('tours').doc(id).update(data);
    }

    pausarTour(id) {
        return this.db.collection('tours').doc(id).update({
            status: EnumStatusTours.PAUSADO,
            fechaCancelada: new Date(),
        });
    }

    cancelarTour(id) {
        return this.db.collection('tours').doc(id).update({
            status: EnumStatusTours.CANCELADO,
            fechaCancelada: new Date(),
        });
    }

    async activarTour(id) {
        return await this.db.collection('tours').doc(id).update({
            status: EnumStatusTours.PUBLICADO,
        });
    }

    desactivarTour(id) {
        return this.db.collection('tours').doc(id).update({
            status: EnumStatusTours.OBSERVADO,
            fechaCancelada: new Date(),
        });
    }

    inhabilitarTour(id) {
        return this.db.collection('tours').doc(id).update({
            status: EnumStatusTours.INHABILITADO,
            fechaCancelada: new Date(),
        });
    }

    async notificarClienteTour(
        uid,
        idTour,
        camposObservados,
        tourNombre,
        tipoNotificacion,
        idNotificacion,
        lengthArrayNotificacion,
    ) {
        if (lengthArrayNotificacion !== 0) {
            return await this.db
                .collection('notificaciones')
                .doc(idNotificacion)
                .update({
                    fechaNotificacion: new Date(),
                    tourNotificaciones: !camposObservados ? null : camposObservados,
                    tipoNotificacion: tipoNotificacion,
                    visto: false,
                });
        } else {
            return await this.db.collection('notificaciones').add({
                tourNotificaciones: camposObservados,
                uid: uid,
                idTour: idTour,
                tourNombre: tourNombre,
                tipoNotificacion: tipoNotificacion,
                fechaNotificacion: new Date(),
                visto: false,
            });
        }
    }

    comprarTour(id, disponibilidad) {
        return this.db.collection('tours').doc(id).update({
            tour_disponibilidad: disponibilidad,
        });
    }

    reservarTour(id, datosReserva) {
        const hacerCompra =
            process.env.NODE_ENV === 'production'
                ? 'https://us-central1-itoour-5f3b6.cloudfunctions.net/hacerCompra'
                : 'https://us-central1-itoour-v1.cloudfunctions.net/hacerCompra';

        return axios({
            method: 'post',
            baseURL: hacerCompra,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                id,
                precioAdulto: Math.ceil((Math.ceil(+datosReserva.precioAdulto / 0.85) * 100) / 0.96) / 100,
                precioNino: Math.ceil((Math.ceil(+datosReserva.precioNino / 0.85) * 100) / 0.96) / 100,
                titulo: datosReserva.nombre,
                descripcion: datosReserva.descripcion,
                imagen: datosReserva.imagen,
                cantidadAdultos: +datosReserva.cantidadAdultos,
                cantidadNinos: +datosReserva.cantidadNinos,
                moneda: datosReserva.moneda,
            },
            withCredentials: true,
        }).then((response) => {
            return {
                preferenceId: response.data.id,
                url: response.data.url,
            };
        });
    }

    validarPago(merchantOrderId, preferenceId, paymentId, status, idTour) {
        const validarCompra = functions.httpsCallable('validarCompra');

        return validarCompra({
            merchantOrderId,
            preferenceId,
            paymentId,
            status,
            idTour,
        }).then((res) => res.data);
    }

    async registrarReserva(preferenceId, reserva, url) {
        const fechaTour = new Date(reserva?.fechaReserva);
        const codigo = await this.generateCode(reserva.moneda);

        return this.db
            .collection('compras')
            .doc()
            .set({
                ...reserva,
                codigo,
                estado: 'pendiente',
                preferenceId,
                urlPago: url,
                mercadoPago: {
                    paymentId: '',
                    status: '',
                    paymentType: '',
                    merchantOrderId: '',
                    urlPago: url,
                    preferenceId,
                    siteId: '',
                    processingMode: '',
                },
            });
    }

    obtenerReserva(preferenceId) {
        return this.db
            .collection('compras')
            .where('preferenceId', '==', preferenceId)
            .get()
            .then((querySnapshot) => {
                return querySnapshot.docs[0]?.data();
            });
    }

    async generateCode(prefijo) {
        const now = new Date().toString().split(' ');

        return `${prefijo.toUpperCase()}${now[2]}${now[1].toUpperCase()}${now[3].substring(2)}${now[4]
            .split(':')
            .join('')}`;
    }

    async actualizarReserva({ paymentId, status, paymentType, merchantOrderId, preferenceId, siteId, processingMode }) {
        const reserva = await this.db
            .collection('compras')
            .where('preferenceId', '==', preferenceId)
            .get()
            .then((r) => {
                const data = r?.docs[0]?.data() || {};
                return {
                    ...data,
                    idCompra: r?.docs[0]?.id,
                };
            });

        if (reserva.mercadoPago.paymentId) return null;

        const fechaTour = new Date(reserva.fechaReserva.seconds * 1000);

        const tour = await this.db
            .collection('tours')
            .doc(reserva.idTour)
            .get()
            .then((doc) => doc.data());

        let tour_disponibilidad = tour?.tour_disponibilidad?.map((item) => {
            const dia = new Date(item.dia.seconds * 1000);

            if (dia.getTime() === fechaTour.getTime())
                return {
                    ...item,
                    capacidad: +item.capacidad - +reserva.adultos - +reserva.ninos,
                };

            return item;
        });

        return this.db
            .collection('tours')
            .doc(reserva.idTour)
            .update({
                tour_disponibilidad,
            })
            .then(() => {
                return this.db
                    .collection('compras')
                    .doc(reserva.idCompra)
                    .update({
                        estado: status === 'approved' ? 'PAGADO' : status === 'pending' ? 'PENDIENTE' : status,
                        mercadoPago: {
                            paymentId,
                            status,
                            paymentType,
                            merchantOrderId,
                            preferenceId,
                            siteId,
                            processingMode,
                        },
                    });
            });
    }

    /*async registrarReserva({
        paymentId,
        status,
        paymentType,
        merchantOrderId,
        preferenceId,
        siteId,
        processingMode,
    }) {
        const reserva = JSON.parse(localStorage.getItem("reserva"));
        const fechaTour = new Date(reserva?.fechaReserva);

        const tour = await this.db
            .collection("tours")
            .doc(reserva.idTour)
            .get()
            .then((doc) => doc.data());

        let tour_disponibilidad = tour?.tour_disponibilidad?.map((item) => {
            const dia = new Date(item.dia.seconds * 1000);

            if (dia.getTime() === fechaTour.getTime())
                return {
                    ...item,
                    capacidad: +item.capacidad - +reserva.personas,
                };

            return item;
        });

        return this.db
            .collection("tours")
            .doc(reserva.idTour)
            .update({
                tour_disponibilidad,
            })
            .then(() => {
                return this.db
                    .collection("compras")
                    .doc()
                    .set({
                        ...reserva,
                        estado: status === "approved" ? "pagado" : "pendiente",
                        mercadoPago: {
                            paymentId,
                            status,
                            paymentType,
                            merchantOrderId,
                            preferenceId,
                            siteId,
                            processingMode,
                        },
                    });
            })
            .then(() => {
                localStorage.removeItem("reserva");
            });
    }*/
}
