import React, { useState, useEffect } from 'react';
import MenuCliente from '../../components/Shared/MenuCliente';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import fire from '../../config/Firebase';
import Swal from 'sweetalert2';
import FotoPerfil from '../../components/Shared/FotoPerfil';
import useForm from '../../hooks/useForm';
import { functions } from '../../config/Firebase';
import './css/InfoEmpresa.css';

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name) {
            case 'nombre': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s]{3,}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'El nombre no puede tener menos de 3 caracteres'
                            : 'Sólo se permiten letras y espacios.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'descripcion': {
                validates.push({
                    name,
                    isValid: !formState[name] || (formState[name].length > 4 && formState[name].length < 351),
                    message: formState[name]?.length
                        ? formState[name]?.length < 5
                            ? 'La descripción no puede tener menos de 5 caracteres'
                            : formState[name]?.length > 350
                            ? 'La descripción no puede tener más de 350 caracteres'
                            : 'El campo es requerido'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'ruc': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^(10|20)[0-9]{9}$/i.test(formState[name]),
                    message: !formState[name]?.length
                        ? 'El documento es requerido'
                        : !/^[0-9]+$/i.test(formState[name])
                        ? 'Sólo se permiten números.'
                        : formState[name].length !== 11
                        ? 'El ruc debe tener 11 dígitos'
                        : 'No es un ruc válido',
                });
                break;
            }

            case 'razon': {
                validates.push({
                    name,
                    isValid: !formState[name] || formState[name]?.length > 2,
                    message: 'La razón social no puede tener menos de 3 caracteres',
                });
                break;
            }

            case 'direccion': {
                validates.push({
                    name,
                    isValid: !formState[name] || formState[name]?.length > 2,
                    message: 'La dirección no puede tener menos de 3 caracteres',
                });
                break;
            }

            case 'telefono': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[0-9]+$/i.test(formState[name]),
                    message: 'Sólo se permiten números.',
                });
                break;
            }

            case 'telefono_celular': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[0-9]+$/i.test(formState[name]),
                    message: 'Sólo se permiten números.',
                });
                break;
            }

            case 'telefono_referencia': {
                validates.push({
                    name,
                    isValid: !formState[name] || /^[0-9]+$/i.test(formState[name]),
                    message: 'Sólo se permiten números.',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

export default function InfoEmpresa(props) {
    const db = fire.firestore();
    const [state, setState] = useState({
        nombre: '',
        descripcion: '',
        tipo: [
            {
                nombre: 'ANFITRION',
                activo: false,
            },
            {
                nombre: 'GUIA',
                activo: false,
            },
            {
                nombre: 'TOUR OPERADOR',
                activo: false,
            },
        ],
        ruc: '',
        razon: '',
        direccion: '',
        telefono: '',
        telefono_celular: '',
        telefono_referencia: '',
    });
    const [edited, setEdited] = useState({
        nombre: false,
        descripcion: false,
        tipo: false,
        ruc: false,
        razon: false,
        direccion: false,
        telefono: false,
        telefono_celular: false,
        telefono_referencia: false,
    });
    const [disabled, setDisabled] = useState(true);
    const [usuario, setUsuario] = useState('');
    const { formState, setFormState, focus, setFocus, errors, handleChange, handleBlur, handleSubmit } = useForm(
        {
            nombre: '',
            descripcion: '',
            ruc: '',
            razon: '',
            direccion: '',
            telefono: '',
            telefono_celular: '',
            telefono_referencia: '',
        },
        validateForm,
    );
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (focus) {
            document.getElementsByName(focus)[0].focus();
            setFocus(null);
        }
    }, [focus]);

    const actualizarInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });

        setDisabled(false);
    };

    const actualizarTipo = (e) => {
        setState({
            ...state,
            tipo: state.tipo.map((tipo) => {
                if (tipo.nombre === e.target.value) {
                    tipo.activo = true;
                } else {
                    tipo.activo = false;
                }

                return tipo;
            }),
        });
    };

    const obtenerUsuario = () => {
        return new Promise((resolve, reject) => {
            fire.auth().onAuthStateChanged((user) => {
                if (user) {
                    setEmail(user.email);
                    resolve(user.uid);
                } else {
                    reject('Error al obtener usuario');
                }
            });
        });
    };

    const actualizarEmpresa = (e) => {
        e.preventDefault();

        const data = {
            cliente_dirempresa: state.direccion,
            cliente_ruc: state.ruc,
            cliente_razon: state.razon,
            cliente_telempresa: state.telefono,
            cliente_telefonocelular: state.telefono_celular || '',
            cliente_telefonoreferencia: state.telefono_referencia || '',
            cliente_nomempresa: state.nombre || '',
            cliente_tipo: state.tipo.filter((tipo) => tipo.activo === true)[0].nombre,
            cliente_descripcion: state.descripcion || '',
            no_editar_empresa: true,
        };

        if (edited.descripcion) {
            data.desc_aprob = false;
        }

        db.collection('cliente')
            .doc(usuario)
            .update(data)
            .then(() => {
                setDisabled(true);

                Swal.fire({
                    icon: 'success',
                    title: 'Perfil de Empresa Actualizado',
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .then(() => {
                setTimeout(() => window.location.replace('/'), 1000);
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Ocurrió un error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    const onSubmit = handleSubmit(actualizarEmpresa, false);

    const actualizarFoto = (foto, nombre) => {
        Swal.fire({
            title: 'Subiendo foto de perfil...',
            text: 'Por favor espere...',
            allowOutsideClick: false,
        });
        Swal.showLoading();
        return db
            .collection('cliente')
            .doc(usuario)
            .update({
                cliente_operadorfoto: foto,
                cliente_operadorfotonombre: nombre,
            })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Perfil Actualizado',
                    showConfirmButton: false,
                    timer: 1500,
                });

                setState({
                    ...state,
                    foto: foto,
                    nombre_foto: nombre,
                });
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Ocurrió un error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    useEffect(() => {
        obtenerUsuario()
            .then((userid) => {
                setUsuario(userid);

                return db.collection('cliente').doc(userid).get();
            })
            .then((usu) => {
                setState({
                    ...state,
                    direccion: usu.data().cliente_dirempresa,
                    ruc: usu.data().cliente_ruc,
                    razon: usu.data().cliente_razon,
                    telefono: usu.data().cliente_telempresa,
                    telefono_celular: usu.data().cliente_telefonocelular,
                    telefono_referencia: usu.data().cliente_telefonoreferencia,
                    nombre: usu.data().cliente_nomempresa,
                    //tipo: usu.data().cliente_tipo,
                    descripcion: usu.data().cliente_descripcion,
                    tipo: state.tipo.map((tipo) => {
                        if (tipo.nombre === usu.data().cliente_tipo) {
                            tipo.activo = true;
                        }
                        return tipo;
                    }),
                    no_editar: usu.data()?.no_editar_empresa ?? false,
                });

                setFormState({
                    nombre: usu.data()?.cliente_nomempresa || '',
                    descripcion: usu.data().cliente_descripcion || '',
                    ruc: usu.data()?.cliente_ruc || '',
                    razon: usu.data()?.cliente_razon || '',
                    direccion: usu.data()?.cliente_dirempresa || '',
                    telefono: usu.data()?.cliente_telempresa || '',
                    telefono_celular: usu.data()?.cliente_telefonocelular || '',
                    telefono_referencia: usu.data()?.cliente_telefonoreferencia || '',
                });
            });

        return () => {
            return null;
        };
    }, []);

    const enviarMensaje = (to, subject, body) => {
        const functionEmail = functions.httpsCallable('enviarEmail');

        return functionEmail({ to, subject, body, info: false });
    };

    const solicitarEditar = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Enviando solicitud',
            icon: 'info',
            text: 'Se está enviando la solicitud, por favor espere un momento...',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        Swal.showLoading();

        return db
            .collection('solicitudes')
            .add({
                clienteId: usuario,
                mensaje: 'Solicito habilitar la edición de mis datos empresariales',
                tipo: 'EDITAR_EMPRESA',
            })
            .then(() => {
                const body_usuario = `<p>Se envió la solicitud de cambio de los datos de operador/guía/anfitrión de su cuenta de Itoour Latam</p>
                    <p>En un lapso máximo de 48 horas soporte estaría validando su identidad para proceder a habilitar la modificación de sus datos.</p>
                    <br/>
                    <p>Si usted no realizó la solicitud por favor ignore este mensaje y comuníquese con soporte.`;

                return enviarMensaje(email, 'Solicitud de modificación de datos de Empresa', body_usuario);
            })
            .then(() => {
                return Swal.fire({
                    title: 'Solicitud enviada',
                    text: 'La solicitud fue enviada con éxito.',
                    icon: 'success',
                    allowOutsideClick: false,
                    timer: 3000,
                });
            })
            .catch((error) => {
                return Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error al enviar la solicitud. Por favor, vuelva a intentarlo.',
                    icon: 'error',
                    allowOutsideClick: false,
                    timer: 3000,
                });
            });
    };

    return (
        <Container className='mt-4'>
            <Row className='mobile mb-3'>
                <Col md={12}>
                    <MenuCliente empresa={true} anfitrion={true} operador={props.permisos === 'cliente-operador'} />
                </Col>
            </Row>
            <Row>
                <Col lg='2' className='desktop'>
                    <MenuCliente
                        empresa={true}
                        vertical={true}
                        anfitrion={true}
                        operador={props.permisos === 'cliente-operador'}
                    />
                </Col>
                <Col lg='10'>
                    <h2 className='text-itoour mb-3'>Información del Anfitrión</h2>
                    <FotoPerfil
                        actualizarFoto={actualizarFoto}
                        title='Subir foto de empresa'
                        permisos={props.permisos}
                        noUpload={props.permisos !== 'cliente-operador'}
                    />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (state.no_editar) solicitarEditar(e);
                            else onSubmit(e);
                        }}
                    >
                        <Form.Group className='mb-3'>
                            <Form.Label>Nombre del Anfitrión:</Form.Label>
                            <Form.Control
                                type='text'
                                name='nombre'
                                value={formState.nombre}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.nombre ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.nombre && <small className='text-danger'>{errors.nombre}</small>}
                        </Form.Group>
                        <Form.Group className='mb-3 text-descripcion-container'>
                            <Form.Label>
                                {formState.tipo === 'TOUR OPERADOR'
                                    ? 'Describe tu empresa'
                                    : 'Describete como Anfitrión o Guía'}
                            </Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={4}
                                name='descripcion'
                                value={formState.descripcion}
                                onChange={(e) => {
                                    actualizarInput({
                                        target: { name: 'descripcion', value: e.target.value.substring(0, 350) },
                                    });
                                    handleChange({
                                        target: { name: 'descripcion', value: e.target.value.substring(0, 350) },
                                    });
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={'text-descripcion-empresa' + (edited.descripcion ? 'bgColorEditInput' : '')}
                                disabled={state.no_editar}
                            />
                            <div
                                className={
                                    'contador-descripcion' +
                                    (formState.descripcion.length > 350 ? ' sobrepaso-texto' : '')
                                }
                            >
                                {formState.descripcion.length}/350
                            </div>
                            {errors.descripcion && <small className='text-danger'>{errors.descripcion}</small>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Tipo:</Form.Label>
                            <Form.Control
                                as='select'
                                name='tipo'
                                onChange={(e) => {
                                    actualizarTipo(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                className={edited.tipo ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            >
                                {state.tipo.map((tipo) => {
                                    if (tipo.activo) {
                                        return (
                                            <option key={tipo.nombre} value={tipo.nombre} selected>
                                                {tipo.nombre}
                                            </option>
                                        );
                                    }

                                    return (
                                        <option key={tipo.nombre} value={tipo.nombre}>
                                            {tipo.nombre}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>RUC:</Form.Label>
                            <Form.Control
                                type='text'
                                name='ruc'
                                value={formState.ruc}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.ruc ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.ruc && <small className='text-danger'>{errors.ruc}</small>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Razón Social:</Form.Label>
                            <Form.Control
                                type='text'
                                name='razon'
                                value={formState.razon}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.razon ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.razon && <small className='text-danger'>{errors.razon}</small>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Dirección:</Form.Label>
                            <Form.Control
                                type='text'
                                name='direccion'
                                value={formState.direccion}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.direccion ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.direccion && <small className='text-danger'>{errors.direccion}</small>}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Teléfono Fijo:</Form.Label>
                            <Form.Control
                                type='text'
                                name='telefono'
                                value={formState.telefono}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.telefono ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.telefono && <small className='text-danger'>{errors.telefono}</small>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Teléfono Celular:</Form.Label>
                            <Form.Control
                                type='text'
                                name='telefono_celular'
                                value={formState.telefono_celular}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.telefono_celular ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.telefono_celular && (
                                <small className='text-danger'>{errors.telefono_celular}</small>
                            )}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Teléfono de Referencia:</Form.Label>
                            <Form.Control
                                type='text'
                                name='telefono_referencia'
                                value={formState.telefono_referencia}
                                onChange={(e) => {
                                    actualizarInput(e);
                                    handleChange(e);
                                    setDisabled(false);
                                    setEdited({ ...edited, [e.target.name]: true });
                                }}
                                onBlur={handleBlur}
                                className={edited.telefono_referencia ? 'bgColorEditInput' : ''}
                                disabled={state.no_editar}
                            />
                            {errors.telefono_referencia && (
                                <small className='text-danger'>{errors.telefono_referencia}</small>
                            )}
                        </Form.Group>
                        {state.no_editar ? (
                            <Form.Control
                                type='submit'
                                value='Solicitar Modificación'
                                className='btn-itoour btn-save'
                            />
                        ) : disabled || Object.keys(errors).length ? (
                            <Form.Group className='mt-3'>
                                <Form.Control type='submit' value='Guardar Cambios' className='btn-itoour' disabled />
                            </Form.Group>
                        ) : (
                            <Form.Group className='mt-3'>
                                <Form.Control type='submit' value='Guardar Cambios' className='btn-itoour btn-save' />
                            </Form.Group>
                        )}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
