import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

export default function DatosTurista({
    state,
    setState,
    step,
    validForm,
    setValidForm,
    formState,
    errors,
    focus,
    handleChange,
    handleBlur,
}) {
    const { register, handleSubmit } = useForm();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const detectEmptyFields = (e) => {
        const fields = state.turistas[step] || {};
        const { nombres, apellidos, tipodoc, documento, pais, ciudad } = fields;

        if (!nombres || !apellidos || !tipodoc || !documento || !pais || !ciudad) return true;

        return false;
    };

    const actualizarInput = (e) => {
        const turistas = [...state.turistas];

        turistas[step] = turistas[step] || {};
        turistas[step][e.target.name] = e.target.value;

        setState({
            ...state,
            turistas,
        });

        validForm[step] = !detectEmptyFields();

        setValidForm([...validForm]);
    };

    return (
        <div className='form-row col-12 col-md-8 px-4'>
            <div className='form-group col-12'>
                <h5 style={{ 'text-align': 'center', 'font-weight': 'bold' }}>Turista N° {step + 1}</h5>
            </div>
            <div className='form-group col-6'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Nombres'
                    name={`turistas.${step}.nombres`}
                    //value={state.turistas[step].nombres}
                    value={formState[`turistas.${step}.nombres`]}
                    onChange={(e) => {
                        actualizarInput({ target: { ...e.target, name: 'nombres' } });
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                    //ref={register({ required: true })}
                />
                {errors[`turistas.${step}.nombres`] && (
                    <small className='text-danger'>{errors[`turistas.${step}.nombres`]}</small>
                )}
            </div>
            <div className='form-group col-6'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Apellidos'
                    name={`turistas.${step}.apellidos`}
                    value={formState[`turistas.${step}.apellidos`]}
                    onChange={(e) => {
                        actualizarInput({ target: { ...e.target, name: 'apellidos' } });
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors[`turistas.${step}.apellidos`] && (
                    <small className='text-danger'>{errors[`turistas.${step}.apellidos`]}</small>
                )}
            </div>
            <div className='form-group col-6'>
                <select
                    className='form-control'
                    value={formState[`turistas.${step}.tipodoc`]}
                    name={`turistas.${step}.tipodoc`}
                    onChange={(e) => {
                        actualizarInput({ target: { ...e.target, name: 'tipodoc' } });
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                >
                    <option value={'dni'}>DNI</option>
                    <option value={'pasaporte'}>Pasaporte</option>
                    <option value={'carnet_extranjeria'}>Carnet de Extranjería</option>
                </select>
            </div>
            <div className='form-group col-6'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Nro. de Documento'
                    name={`turistas.${step}.documento`}
                    value={formState[`turistas.${step}.documento`]}
                    onChange={(e) => {
                        actualizarInput({ target: { ...e.target, name: 'documento' } });
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors[`turistas.${step}.documento`] && (
                    <small className='text-danger'>{errors[`turistas.${step}.documento`]}</small>
                )}
            </div>
            <div className='form-group col-6'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='País'
                    name={`turistas.${step}.pais`}
                    value={formState[`turistas.${step}.pais`]}
                    onChange={(e) => {
                        actualizarInput({ target: { ...e.target, name: 'pais' } });
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors[`turistas.${step}.pais`] && (
                    <small className='text-danger'>{errors[`turistas.${step}.pais`]}</small>
                )}
            </div>
            <div className='form-group col-6'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Ciudad'
                    name={`turistas.${step}.ciudad`}
                    value={formState[`turistas.${step}.ciudad`]}
                    onChange={(e) => {
                        actualizarInput({ target: { ...e.target, name: 'ciudad' } });
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                />
                {errors[`turistas.${step}.ciudad`] && (
                    <small className='text-danger'>{errors[`turistas.${step}.ciudad`]}</small>
                )}
            </div>
        </div>
    );
}
