import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import fire from '../config/Firebase';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { AuthService } from '../services/Auth';
import Swal from 'sweetalert2';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ResetPassword = ({ permisos, uid, setOperador }) => {
    const query = useQuery();
    const mode = query.get('mode');
    const oobCode = query.get('oobCode');
    const apiKey = query.get('apiKey');
    const [errorEmptyPass, setErrorEmptyPass] = useState(false);
    const [errorEmptyRePass, setErrorEmptyRePass] = useState(false);
    const [errorDiff, setErrorDiff] = useState(false);
    const [values, setValues] = useState({ password: '', re_password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const sAuth = new AuthService();

    const resetErrors = () => {
        setErrorEmptyPass(false);
        setErrorEmptyRePass(false);
        setErrorDiff(false);
    };

    const onChangeInput = (e) => {
        resetErrors();
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (!values.password) return setErrorEmptyPass(true);
        if (!values.re_password) return setErrorEmptyRePass(true);
        if (values.password !== values.re_password) return setErrorDiff(true);

        resetErrors();

        Swal.fire({
            title: 'Actualizando Contraseña',
            text: 'Espere un momento...',
            icon: 'info',
            allowOutsideClick: false
        });
        Swal.showLoading();

        return sAuth
            .handleAction(mode, oobCode, values.password)
            .then(() => {
                return Swal.fire({
                    title: 'Contraseña actualizada con éxito',
                    text: 'La contraseña fue actualizada con éxito. Por favor, inicie sesión nuevamente con su nueva contraseña.',
                    allowOutsideClick: false,
                    icon: 'success',
                    timer: 3000
                }).then(() => setRedirect(true));
            })
            .catch((error) => {
                return Swal.fire({
                    title: 'Error',
                    text: 'Oops! Algo salió mal. Verifique si la contraseña fué cambiada sino vuelva a intentarlo.',
                    icon: 'icon',
                    allowOutsideClick: false,
                    timer: 3000
                });
            });
    };

    const EyeButton = ({ show, setShow }) => {
        return (
            <>
                {show ? (
                    <span className="input-group-text eye-password" onClick={(e) => setShow(false)}>
                        <i className="fas fa-eye-slash"></i>
                    </span>
                ) : (
                    <span className="input-group-text eye-password" onClick={(e) => setShow(true)}>
                        <i className="fas fa-eye"></i>
                    </span>
                )}
            </>
        );
    };

    if (redirect) return <Redirect to="/login" />;

    return (
        <div className="complete-page w-100 h-100">
            <Container className="complete-register h-100" style={{ maxWidth: '700px' }}>
                <Row className="d-flex flex-column justify-content-center align-items-center h-100">
                    <Col lg={8}>
                        <Card>
                            <Card.Body>
                                <h2>Restablecer Contraseña</h2>
                                <form onSubmit={onSubmit}>
                                    <div className="form-row">
                                        <div className="col-12 mb-3">
                                            <label>Contraseña:</label>
                                            <div className="input-group">
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    className={`form-control ${errorEmptyPass ? 'is-invalid' : ''}`}
                                                    name="password"
                                                    value={values.password}
                                                    onChange={onChangeInput}
                                                    required
                                                />
                                                <div className="input-group-prepend">
                                                    <EyeButton show={showPassword} setShow={setShowPassword} />
                                                </div>
                                                {errorEmptyPass ? (
                                                    <div className="invalid-feedback">
                                                        El campo contraseña no puede estar vacío.
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-12 mb-3">
                                            <label>Repetir Contraseña:</label>
                                            <div className="input-group">
                                                <input
                                                    type={showRePassword ? 'text' : 'password'}
                                                    className={`form-control ${
                                                        errorEmptyRePass || errorDiff ? 'is-invalid' : ''
                                                    }`}
                                                    name="re_password"
                                                    value={values.re_password}
                                                    onChange={onChangeInput}
                                                    required
                                                />
                                                <div className="input-group-prepend">
                                                    <EyeButton show={showRePassword} setShow={setShowRePassword} />
                                                </div>
                                                {errorEmptyRePass ? (
                                                    <div className="invalid-feedback">
                                                        El campo repetir contraseña no puede estar vacío.
                                                    </div>
                                                ) : errorDiff ? (
                                                    <div className="invalid-feedback">
                                                        No coinciden las contraseñas.
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-itoour" type="submit">
                                            Guardar Contraseña
                                        </button>
                                    </div>
                                </form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ResetPassword;
