import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Document, Page, Text, View, Image, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        width: '100%',
        height: window.innerHeight,
    },
    page: {
        position: 'relative',
        padding: '10mm 15mm',
    },
    titleHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logoTitle: {
        width: '27.5%',
    },
    ticket: {
        width: '70%',
    },
    titleInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '5mm',
        marginBottom: '7mm',
    },
    titleText: {
        padding: '3mm',
        backgroundColor: '#842D7D',
        fontFamily: 'Helvetica-Bold',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        marginRight: '3mm',
    },
    titleIcon: {
        width: '35px',
    },
    fieldInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5mm',
    },
    fieldTitle: {
        color: '#E97E23',
        borderTop: '1.5px dashed #E97E23',
        borderLeft: '1.5px dashed #E97E23',
        padding: '1mm 2mm',
        fontSize: '15px',
        fontFamily: 'Helvetica-Bold',
    },
    fieldText: {
        width: '120mm',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    // resume: {
    //     marginBottom: '5mm',
    //     marginTop: '5mm',
    // },
    footer: {
        display: 'flex',
        margin: '0 auto',
        marginTop: 'auto',
        width: '90%',
        fontSize: '14px',
        bottom: 0,
    },
    footerLine: {
        border: '1px solid #842D7D',
        backgroundColor: '#842D7D',
        marginBottom: '3mm',
    },
    footerInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    infoFooter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    footerIcon: {
        width: '15px',
        marginRight: '2mm',
    },
    turistTitle: {
        color: '#E97E23',
        fontSize: '18px',
        fontFamily: 'Helvetica-Bold',
        marginBottom: '5mm',
    },
    infoTuristContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    turistColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoTurist: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    turistFieldText: {
        fontSize: '14px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5mm',
    },
    iconHeader: {
        width: '20%',
    },
    turista: {
        marginBottom: '7mm',
    },
});

export default function ModalResumenPDF({ compra }) {
    const [show, setShow] = useState(false);

    const TitleInfo = ({ text, icon }) => {
        return (
            <View style={styles.titleInfo}>
                <View style={styles.titleText}>
                    <Text>{text}</Text>
                </View>
                <Image style={styles.titleIcon} src={icon} />
            </View>
        );
    };

    const TitleHeader = () => {
        return (
            <View style={styles.titleHeader}>
                <Image
                    style={styles.logoTitle}
                    src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Flogo_color.png?alt=media&token=93ee0fda-149c-4067-b0ac-b617673c555d'
                />
                <Image
                    style={styles.ticket}
                    src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2FTicketResumen.png?alt=media&token=f94100ae-93fc-4500-8516-2179e39356bf'
                />
            </View>
        );
    };

    const FieldInfo = ({ title, text }) => {
        return (
            <View style={styles.fieldInfo}>
                <View style={styles.fieldTitle}>
                    <Text>{title}:</Text>
                </View>
                <View style={styles.fieldText}>
                    <Text>{text}</Text>
                </View>
            </View>
        );
    };

    const Resume = ({ destino, fecha, moneda, adultos, precioAdulto, ninos = 0, precioNino = 0 }) => {
        return (
            <View style={styles.resume}>
                <TitleInfo
                    text='DETALLES DE COMPRA'
                    icon='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Ficono1.png?alt=media&token=aeb80a60-259f-41d2-b2c9-3562654971d1'
                />
                <FieldInfo title='Destino' text={destino} />
                <FieldInfo title='Fecha de compra' text={fecha.toLocaleDateString('en-GB')} />
                {precioNino > 0 ? (
                    <FieldInfo title='N° de adultos' text={adultos} />
                ) : (
                    <FieldInfo title='N° de asistentes' text={adultos + ninos} />
                )}
                {precioNino > 0 ? (
                    <FieldInfo title='Precio de adultos' text={`${moneda === 'PEN' ? 'S/. ' : '$ '} ${precioAdulto}`} />
                ) : (
                    <FieldInfo
                        title='Precio del servicio'
                        text={`${moneda === 'PEN' ? 'S/. ' : '$ '} ${precioAdulto}`}
                    />
                )}
                {precioNino > 0 ? (
                    <>
                        <FieldInfo title='N° de niños' text={ninos} />
                        <FieldInfo title='Precio de niños' text={`${moneda === 'PEN' ? 'S/. ' : '$ '} ${precioNino}`} />
                    </>
                ) : null}
                <FieldInfo
                    title='Precio Total'
                    text={`${moneda === 'PEN' ? 'S/. ' : '$ '} ${+adultos * +precioAdulto + +ninos * +precioNino}`}
                />
            </View>
        );
    };

    const Footer = ({ telefono, web, email }) => {
        return (
            <View style={styles.footer} fixed>
                <View style={styles.footerLine}></View>
                <View style={styles.footerInfo}>
                    <View>
                        <Text>{telefono}</Text>
                    </View>
                    <View style={styles.infoFooter}>
                        <Image
                            style={styles.footerIcon}
                            src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Fpiedepagina1.png?alt=media&token=94b7be2d-547f-480a-ab4d-9b505411b726'
                        />
                        <Text>{web}</Text>
                    </View>
                    <View style={styles.infoFooter}>
                        <Image
                            style={styles.footerIcon}
                            src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Fpiedepagina2.png?alt=media&token=d3c7e8c1-eb2b-48ed-a4a0-bfb3566fc6da'
                        />
                        <Text>{email}</Text>
                    </View>
                </View>
            </View>
        );
    };

    const TuristInfo = ({ orden, nombres, apellidos, tipoDoc, numeroDoc }) => {
        return (
            <View style={styles.turista}>
                <View style={styles.turistTitle}>
                    <Text>ASISTENTE {orden}</Text>
                </View>
                <View style={styles.infoTuristContainer}>
                    <View style={styles.turistColumn}>
                        <View style={styles.infoTurist}>
                            <View style={{ ...styles.fieldTitle, marginBottom: '3mm' }}>
                                <Text>Nombres:</Text>
                            </View>
                            <View style={styles.turistFieldText}>
                                <Text>{nombres}</Text>
                            </View>
                        </View>
                        <View style={{ ...styles.infoTurist, marginTop: '5mm' }}>
                            <View style={{ ...styles.fieldTitle, marginBottom: '3mm' }}>
                                <Text>Tipo de documento:</Text>
                            </View>
                            <View style={styles.turistFieldText}>
                                <Text>{tipoDoc}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.turistColumn}>
                        <View style={styles.infoTurist}>
                            <View style={{ ...styles.fieldTitle, marginBottom: '3mm' }}>
                                <Text>Apellidos:</Text>
                            </View>
                            <View style={styles.turistFieldText}>
                                <Text>{apellidos}</Text>
                            </View>
                        </View>
                        <View style={{ ...styles.infoTurist, marginTop: '5mm' }}>
                            <View style={{ ...styles.fieldTitle, marginBottom: '3mm' }}>
                                <Text>N° de documento:</Text>
                            </View>
                            <View style={styles.turistFieldText}>
                                <Text>{numeroDoc}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    const Header = () => {
        return (
            <View style={styles.header} fixed>
                <Image
                    style={styles.iconHeader}
                    src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Ficonocabecera.png?alt=media&token=43ded5fd-42e0-4140-b5e2-d3af5fd64fde'
                />
                <Image
                    style={styles.logoTitle}
                    src='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Flogo.png?alt=media&token=2d9f0563-57cf-4eed-9a2a-79f18e6d4163'
                />
            </View>
        );
    };

    return (
        <>
            <Button
                className='btn btn-itoour'
                onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                }}
            >
                Ver PDF
            </Button>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                className='modalReservas'
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>{compra.tour.tour_nom}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='accordion-body-modal'>
                    <PDFViewer style={styles.viewer}>
                        <Document title={compra.codigo}>
                            <Page size='A4' style={styles.page}>
                                <TitleHeader />
                                <Resume
                                    destino={compra.tour.tour_direccion}
                                    fecha={compra.fechaReserva}
                                    moneda={compra.moneda}
                                    adultos={+compra.adultos}
                                    precioAdulto={+compra.precioAdulto}
                                    ninos={+compra.ninos}
                                    precioNino={+compra.precioNino}
                                />
                                <TitleInfo
                                    text='DETALLES DEL TOUR O SERVICIO'
                                    icon='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Ficono2.png?alt=media&token=f24ccaae-34cb-4005-9b61-fa1ff18c0721'
                                />
                                <TuristInfo
                                    orden={1}
                                    nombres={compra.turistas[0].nombres}
                                    apellidos={compra.turistas[0].apellidos}
                                    tipoDoc={compra.turistas[0].tipodoc?.toUpperCase()}
                                    numeroDoc={compra.turistas[0].documento}
                                />
                                <Footer telefono='(+51) 1 743 9064' web='www.itoour.com' email='info@itoour.com' />
                            </Page>
                            {compra.turistas?.length > 1 ? (
                                <Page size='A4' style={styles.page} wrap>
                                    <Header />
                                    {compra.turistas.slice(1).map((t, i) => (
                                        <TuristInfo
                                            key={`Turista${i}`}
                                            orden={i + 2}
                                            nombres={t.nombres}
                                            apellidos={t.apellidos}
                                            tipoDoc={t.tipodoc?.toUpperCase()}
                                            numeroDoc={t.documento}
                                        />
                                    ))}
                                    <Footer telefono='(+51) 1 743 9064' web='www.itoour.com' email='info@itoour.com' />
                                </Page>
                            ) : null}
                        </Document>
                    </PDFViewer>
                </Modal.Body>
            </Modal>
        </>
    );
}
