import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavSinLogin() {
    return (
        <ul className='navbar-nav my-2 navbar-menu'>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/vertours'>
                    Tours
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/contacto'>
                    Contáctanos
                </NavLink>
            </li>
            <li className='nav-item align-self-center'>
                <NavLink className='nav-link' to='/login'>
                    Ingresar
                </NavLink>
            </li>

            <li className='nav-item align-self-center'>
                <NavLink
                    className='nav-link guia active no-line'
                    style={{ transform: 'translateY(-2px)' }}
                    to='/registrocliente'
                    onClick={() => {
                        window.fbq('track', 'Cliente potencial', {
                            medio: 'Link directo Registro Guía',
                        });
                    }}
                >
                    ¡Quiero ser guía!
                </NavLink>
            </li>
        </ul>
    );
}
