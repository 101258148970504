import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, Container } from 'react-bootstrap';
//import { useForm } from 'react-hook-form';
import useForm from '../../hooks/useForm';
import DatosTurista from './DatosTurista';
import TourService from '../../services/Tour';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import './css/ModalComprarTour.css';

const validateForm = (formState) => {
    const validates = [];

    for (let name in formState) {
        switch (name.split('.')[2]) {
            case 'nombres': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s]{3,}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'El nombre no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras y espacios.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'apellidos': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s]{3,}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'El apellido no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras y espacios.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'documento': {
                validates.push({
                    name,
                    isValid: /^[0-9]{8,9}$/i.test(formState[name]),
                    message: !formState[name]?.length
                        ? 'El documento es requerido'
                        : !/^[0-9]+$/i.test(formState[name])
                        ? 'Sólo se permiten números.'
                        : 'El dni debe tener 8 dígitos',
                });
                break;
            }

            case 'pais': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s]{3,}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'El nombre del país no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras y espacios.'
                        : 'El campo es requerido',
                });
                break;
            }

            case 'ciudad': {
                validates.push({
                    name,
                    isValid: /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s]{3,}$/i.test(formState[name]),
                    message: formState[name]?.length
                        ? formState[name]?.length < 3
                            ? 'El nombre de la ciudad no puede tener menos de 3 caracteres.'
                            : 'Sólo se permiten letras y espacios.'
                        : 'El campo es requerido',
                });
                break;
            }

            default:
                validates.push({
                    name,
                    isValid: true,
                    message: '',
                });
        }
    }

    return validates;
};

export default function ModalComprarTour({
    idCliente,
    tourId,
    tour,
    comprar,
    fechaCompra,
    personas,
    ninos,
    state,
    setState,
    fechaSeleccionada,
    email,
}) {
    const [url, setUrl] = useState(null);
    const [show, setShow] = useState(false);
    const [tos, setTos] = useState(false);
    const [validForm, setValidForm] = useState([]);
    const [step, setStep] = useState(0);
    const [totalPersonas, setTotalPersonas] = useState(0);
    const sTour = new TourService();

    const initialState = {};

    for (let i = 0; i < +personas + +ninos; i++) {
        initialState[`turistas.${i}.nombres`] = '';
        initialState[`turistas.${i}.apellidos`] = '';
        initialState[`turistas.${i}.documento`] = '';
        initialState[`turistas.${i}.pais`] = '';
        initialState[`turistas.${i}.ciudad`] = '';
    }

    const { formState, setFormState, errors, focus, setFocus, handleChange, handleBlur, resetForm, handleSubmit } =
        useForm(initialState, validateForm);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        const numPersonas = +personas + +ninos;

        setTotalPersonas(numPersonas);
        setState({
            ...state,
            turistas: Array.from({ length: numPersonas }).map((item) => {
                return {
                    nombres: '',
                    apellidos: '',
                    tipodoc: 'dni',
                    documento: '',
                    pais: '',
                    ciudad: '',
                };
            }),
            email: email,
            codigo: '',
        });
        setStep(0);
        setTos(false);
        setValidForm(Array.from({ length: numPersonas }).map((item) => false));
        setShow(true);
    };

    useEffect(() => {
        const initialState = {};

        for (let i = 0; i < +personas + +ninos; i++) {
            initialState[`turistas.${i}.nombres`] = '';
            initialState[`turistas.${i}.apellidos`] = '';
            initialState[`turistas.${i}.documento`] = '';
            initialState[`turistas.${i}.pais`] = '';
            initialState[`turistas.${i}.ciudad`] = '';
        }

        setFormState(initialState);
    }, [personas, ninos]);

    const handleComprar = (e) => {
        e.preventDefault();
        handleClose();

        window.fbq('track', 'Compra potencial', {
            medio: 'Comprar Tour',
            nombre_tour: tour.tour_nom,
            region: tour.tour_departamento,
            adultos: personas,
            ninos,
        });

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se procederá a realizar la compra del tour',
            icon: 'warning',
            showCancelButton: true,
        })
            .then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Registrando los datos',
                        text: 'Por favor espere...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    });
                    Swal.showLoading();

                    //const fechaTour = new Date(fechaCompra);
                    const fechaTour = new Date(fechaSeleccionada.seconds * 1000);
                    const dia = tour.tour_disponibilidad.find((item) => {
                        const curDia = new Date(item.dia.seconds * 1000);

                        return fechaTour.getTime() === curDia.getTime();
                    });

                    if (!dia) throw new Error('No se encontró turno');

                    const capacidad = +dia.capacidad || 0;

                    if (capacidad < totalPersonas) throw new Error('No hay cupos');

                    const datosReserva = {
                        titulo: tour.tour_nom,
                        descripcion: tour.tour_desc,
                        precioAdulto: tour.tour_precioAdulto,
                        precioNino: tour.tour_precioNino,
                        moneda: tour.tour_moneda,
                        cantidadAdultos: personas,
                        cantidadNinos: ninos,
                        imagen: tour.tour_imagenes[tour.tour_miniatura],
                    };

                    return sTour.reservarTour(tourId, datosReserva).then((res) => {
                        const d = new Date().toString().split(' ');

                        return sTour
                            .registrarReserva(
                                res.preferenceId,
                                {
                                    idCliente,
                                    idTour: tourId,
                                    idOperador: tour.uid,
                                    fechaCompra: new Date(),
                                    fechaReserva: fechaTour,
                                    adultos: +personas,
                                    ninos: +ninos,
                                    turistas: state.turistas,
                                    email: state.email,
                                    operador: tour.cli_email,
                                    precioAdulto: tour.tour_precioAdulto,
                                    precioNino: tour.tour_precioNino,
                                    moneda: tour.tour_moneda,
                                    estado: 'pendiente',
                                },
                                res.url
                            )
                            .then(() => setUrl(res.url));
                    });
                }

                return null;
            })
            .catch((err) => {
                console.log(err);

                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo realizar la compra',
                    timer: 3000,
                });
            });
    };

    useEffect(() => {
        if (url) {
            window.location.href = url;
        }
    }, [url]);

    useEffect(() => {
        if (focus) {
            const st = +focus.split('.')[1];
            setStep(st);
            if (st === step) document.getElementsByName(focus)[0].focus();
            setFocus(null);
        }
    }, [focus]);

    return (
        <>
            <button className='btn btn-itoour btn-block btn-lg mt-4' onClick={handleShow}>
                Comprar Tour
            </button>
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registre los asistentes al Tour</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <DatosTurista
                            state={state}
                            setState={setState}
                            step={step}
                            validForm={validForm}
                            setValidForm={setValidForm}
                            formState={formState}
                            errors={errors}
                            focus={focus}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                        <div className='col-12 col-md-4'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title font-weight-bold'>Resumen de Compra</h5>
                                    <p>
                                        <span className='font-weight-bold'>Fecha de partida: </span>
                                        {fechaCompra.toLocaleString().slice(0, 9)}
                                    </p>
                                    <p>
                                        <span className='font-weight-bold'>Duración:</span>{' '}
                                        {tour.fullDay
                                            ? `Full day`
                                            : +(tour.horasTour ?? 0) + +(tour.minTour ?? 0) > 0
                                            ? `${tour.horasTour ?? 0} hora${+(tour.horasTour ?? 0) > 1 ? 's' : ''} ${
                                                  +(tour.minTour ?? 0) !== 0 ? `y ${tour.minTour} minutos` : ''
                                              }`
                                            : `${tour.tour_dias} día${+tour.tour_dias > 1 ? 's' : ''} ${
                                                  +tour.tour_noches !== 0
                                                      ? `y ${tour.tour_noches} noche${+tour.tour_noches > 1 ? 's' : ''}`
                                                      : ''
                                              }`}
                                    </p>
                                    <p>
                                        <span className='font-weight-bold'>
                                            {!tour.tour_precioNino || +tour.tour_precioNino === 0
                                                ? 'Personas'
                                                : 'Adultos'}
                                        </span>{' '}
                                        {personas}
                                    </p>
                                    <p className='text-secondary'>
                                        <span className='font-weight-bold'>
                                            Precio por{' '}
                                            {!tour.tour_precioNino || +tour.tour_precioNino === 0
                                                ? 'persona'
                                                : 'adulto'}
                                        </span>{' '}
                                        {Math.ceil(+tour.tour_precioAdulto / 0.85)}
                                    </p>
                                    {!tour.tour_precioNino || +tour.tour_precioNino === 0 ? null : (
                                        <>
                                            <p>
                                                <span className='font-weight-bold'>Niños:</span> {ninos}
                                            </p>
                                            <p className='text-secondary'>
                                                <span className='font-weight-bold'>Precio por niño:</span>{' '}
                                                {Math.ceil(+tour.tour_precioNino / 0.85)}
                                            </p>
                                        </>
                                    )}
                                    <p className='font-weight-bold'>
                                        Precio Total:{' '}
                                        {Math.ceil(+tour.tour_precioAdulto / 0.85) * +personas +
                                            Math.ceil(+tour.tour_precioNino / 0.85) * +ninos}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {step < totalPersonas - 1 ? (
                    <Modal.Footer>
                        <Form.Group
                            style={{
                                display: 'flex',
                                'justify-content': step > 0 ? 'space-between' : 'flex-end',
                                width: '100%',
                            }}
                        >
                            {step > 0 ? (
                                <Button className='btn-itoour' onClick={() => setStep((prev) => prev - 1)}>
                                    Anterior
                                </Button>
                            ) : null}
                            <Button
                                className='btn-itoour'
                                onClick={() => setStep((prev) => prev + 1)}
                                disabled={Object.keys(errors).length}
                            >
                                Siguiente
                            </Button>
                        </Form.Group>
                    </Modal.Footer>
                ) : (
                    <Modal.Footer>
                        <div
                            style={{
                                display: 'flex',
                                'justify-content': step > 0 ? 'space-between' : 'flex-end',
                                'align-items': 'center',
                                width: '100%',
                            }}
                        >
                            {step > 0 ? (
                                <Button className='btn-itoour' onClick={() => setStep((prev) => prev - 1)}>
                                    Anterior
                                </Button>
                            ) : null}
                            <div
                                style={{
                                    display: 'flex',
                                    'justify-content': 'space-between',
                                    'align-items': 'center',
                                }}
                            >
                                <Form.Group controlId='formBasicCheckbox' style={{ margin: 'auto 10px' }}>
                                    <Form.Check type='checkbox'>
                                        <Form.Check.Input
                                            type='checkbox'
                                            checked={tos}
                                            onClick={(evt) => setTos(evt.target.checked)}
                                            label='Acepto los términos y condiciones'
                                            style={{
                                                visibility: 'visible',
                                                height: 'auto',
                                                width: 'auto',
                                            }}
                                        />
                                        <Form.Check.Label>
                                            <a
                                                href='https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FTERMINOS%20Y%20CONDICIONES%20usuarios.pdf?alt=media&token=2c25f0e9-cf7a-4ece-859e-9438a905b367'
                                                target='_blank'
                                            >
                                                Acepto los términos y condiciones
                                            </a>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <Button
                                    disabled={!tos || totalPersonas > validForm.filter((k) => k).length}
                                    variant='primary'
                                    className='btn-itoour'
                                    onClick={(e) => {
                                        if (!tos) return;
                                        //setShow(false);
                                        //handleComprar(e);
                                        handleSubmit(handleComprar)(e);
                                        //handleSubmit(comprar(state));
                                    }}
                                >
                                    Proceder Compra
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
}
