import fire, { googleProvider } from '../config/Firebase';
import { functions } from '../config/Firebase';

export class AuthService {
    constructor() {
        this.db = fire.firestore();
    }

    tokenid;
    closeSession() {
        localStorage.removeItem('iduser');
        this.tokenid = null;
    }
    saveToken(id) {
        this.tokenid = id;
        // //console.log("auth",id)
        localStorage.setItem('iduser', id);
    }

    register(email, password) {
        return new Promise((resolve, reject) => {
            fire.auth()
                .createUserWithEmailAndPassword(email, password)
                .then(async (u) => {
                    const enviarEmail = functions.httpsCallable('enviarEmail');
                    const fecha = new Date(u.user.metadata.creationTime);

                    const body = `<p>Sea ha registrado el e-mail: ${email} como Turista el día ${fecha.toLocaleDateString(
                        'us-GB',
                    )} y ${fecha.toLocaleTimeString('us-GB', { hour12: true })}`;

                    await enviarEmail({
                        subject: `${
                            process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''
                        }Nuevo registro en Itoour`,
                        to: 'soporte@itoour.com,ventas@itoour.com',
                        body,
                        info: true,
                    });

                    return this.db
                        .collection('usuario')
                        .doc(u.user.uid)
                        .set({
                            usuario_email: email,
                            usuario_nombres: '',
                            usuario_apellidos: '',
                            usuario_dni: '',
                            usuario_telefono: '',
                            fecha_creacion: new Date(u?.user?.metadata?.creationTime),
                        })
                        .then(() => {
                            u.user.sendEmailVerification();
                            resolve(u.user.uid);
                        });
                })
                .catch((error) => {
                    //console.log('errorregister', error);
                    reject(error);
                });
        });
    }

    registerWithGoogle(setRedirect) {
        return this.loginOrRegisterWithProvider(googleProvider).then(async (result) => {
            if (result.additionalUserInfo.isNewUser) {
                const db = fire.firestore();
                const enviarEmail = functions.httpsCallable('enviarEmail');
                const fecha = new Date(result.user.metadata.creationTime);

                const body = `<p>Sea ha registrado mediante Google el usuario ${
                    result.additionalUserInfo.profile.given_name
                } ${result.additionalUserInfo.profile.family_name} con email: ${
                    result.user.email
                } como Turista el día ${fecha.toLocaleDateString('us-GB')} y ${fecha.toLocaleTimeString('us-GB', {
                    hour12: true,
                })}`;

                await enviarEmail({
                    subject: `${process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''}Nuevo registro en Itoour`,
                    to: 'soporte@itoour.com,ventas@itoour.com',
                    body,
                    info: true,
                });

                return db
                    .collection('usuario')
                    .doc(result.user.uid)
                    .set({
                        usuario_email: result.user.email,
                        usuario_nombres: result.additionalUserInfo.profile.given_name,
                        usuario_apellidos: result.additionalUserInfo.profile.family_name,
                        usuario_dni: '',
                        usuario_telefono: result.user.phoneNumber || '',
                        usuario_foto: result.user.photoURL || '',
                        fecha_creacion: new Date(result.user.metadata.creationTime),
                    })
                    .then(() => setRedirect && setTimeout(() => setRedirect(true), 500));
            }

            return null;
        });
    }

    registerClient(email, password, data) {
        return new Promise((resolve, reject) => {
            let uid = '';
            fire.auth()
                .createUserWithEmailAndPassword(email, password)
                .then(async (u) => {
                    const enviarEmail = functions.httpsCallable('enviarEmail');
                    const fecha = new Date(u.user.metadata.creationTime);

                    const body = `<p>Sea ha registrado el e-mail: ${email} como Guía el día ${fecha.toLocaleDateString(
                        'us-GB',
                    )} y ${fecha.toLocaleTimeString('us-GB', { hour12: true })}`;

                    await enviarEmail({
                        subject: `${
                            process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''
                        }Nuevo registro en Itoour`,
                        to: 'soporte@itoour.com,ventas@itoour.com',
                        body,
                        info: true,
                    });

                    uid = u.user.uid;

                    return u.user;
                })
                .then((user) => {
                    let datos = {
                        cliente_email: data?.email || '',
                        cliente_nombres: data?.nombres || '',
                        cliente_apellidos: data?.apellidos || '',
                        cliente_tipodoc: data?.tipodoc || 'DNI',
                        cliente_documento: data?.documento || '',
                        cliente_pais: data?.pais || '',
                        cliente_departamento: data?.departamento || '',
                        cliente_ciudad: data?.ciudad || '',
                        cliente_sexo: data?.sexo || 'No especifica',
                        cliente_telefono: '',
                        cliente_celular: data?.celular || '',
                        cliente_razon: data?.razon || '',
                        cliente_ruc: data?.ruc || '',
                        cliente_nomempresa: data?.nomempresa || '',
                        cliente_dirempresa: data?.dirempresa || '',
                        cliente_telempresa: '',
                        cliente_tipo: data?.tipo || 'ANFITRION',
                        cliente_representante: data?.representante || '',
                        cliente_reprdni: '',
                        cliente_reprtel: '',
                        cliente_reprdir: '',
                        cliente_cuenta: '',
                        cliente_cci: '',
                        no_editar_comercial: false,
                        no_editar_empresa: false,
                        fecha_creacion: new Date(user?.metadata?.creationTime),
                    };

                    if (data?.tipo === 'TOUR OPERADOR') datos['cliente_operadorfoto'] = data.foto;
                    else if (data?.tipo) datos['cliente_foto'] = data.foto;

                    return this.db
                        .collection('cliente')
                        .doc(uid)
                        .set(datos)
                        .then(() => user.sendEmailVerification());
                })
                .then(() => {
                    const addClientRole = functions.httpsCallable('addClientRole');
                    return addClientRole(uid);
                })
                .then(() => {
                    resolve(uid);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    registerClientWithGoogle(setRedirect) {
        return this.loginOrRegisterWithProvider(googleProvider).then(async (result) => {
            if (result.additionalUserInfo.isNewUser) {
                const db = fire.firestore();
                const enviarEmail = functions.httpsCallable('enviarEmail');
                const fecha = new Date(result.user.metadata.creationTime);

                const body = `<p>Sea ha registrado mediante Google el usuario ${
                    result.additionalUserInfo.profile.given_name
                } ${result.additionalUserInfo.profile.family_name} con email: ${
                    result.user.email
                } como Guía el día ${fecha.toLocaleDateString('us-GB')} y ${fecha.toLocaleTimeString('us-GB', {
                    hour12: true,
                })}`;

                await enviarEmail({
                    subject: `${process.env.NODE_ENV !== 'production' ? '(Desarrollo) ' : ''}Nuevo registro en Itoour`,
                    to: 'soporte@itoour.com,ventas@itoour.com',
                    body,
                    info: true,
                });

                return db
                    .collection('cliente')
                    .doc(result.user.uid)
                    .set({
                        cliente_email: result.user.email,
                        cliente_nombres: result.additionalUserInfo.profile.given_name,
                        cliente_apellidos: result.additionalUserInfo.profile.family_name,
                        cliente_dni: '',
                        cliente_telefono: result.user.phoneNumber || '',
                        cliente_foto: result.user.photoURL || '',
                        cliente_razon: '',
                        cliente_ruc: '',
                        cliente_nomempresa: '',
                        cliente_dirempresa: '',
                        cliente_telempresa: '',
                        cliente_tipo: 'ANFITRION',
                        cliente_representante: '',
                        cliente_reprdni: '',
                        cliente_reprtel: '',
                        cliente_reprdir: '',
                        cliente_cuenta: '',
                        cliente_cci: '',
                        no_editar_comercial: false,
                        no_editar_empresa: false,
                        fecha_creacion: new Date(result.user.metadata.creationTime),
                    })
                    .then(() => setRedirect && setTimeout(() => setRedirect(true), 500));
            }

            return null;
        });
    }

    login(email, password) {
        fire.auth()
            .signInWithEmailAndPassword(email, password)
            .then((u) => {
                //console.log("sauth", u);
                this.saveToken(u.user.refreshToken);
                return u;
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    loginOrRegisterWithProvider(provider) {
        return fire.auth().signInWithPopup(provider);
    }

    logout() {
        fire.auth()
            .signOut()
            .then(() => {
                //console.log("El usuario se deslogueo");
            });
    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            //console.log(user);
            if (user) {
                return { user };
            } else {
                return null;
            }
        });
    }

    handleAction(mode, actionCode, payload) {
        switch (mode) {
            case 'resetPassword':
                return fire
                    .auth()
                    .verifyPasswordResetCode(actionCode)
                    .then(() => {
                        return fire.auth().confirmPasswordReset(actionCode, payload);
                    });
            case 'recoverEmail': {
                const restoredEmail = null;

                return fire
                    .auth()
                    .checkActionCode(actionCode)
                    .then((info) => {
                        restoredEmail = info['data']['email'];
                        return fire.auth().applyActionCode(actionCode);
                    })
                    .then(() => {
                        return fire.auth().sendPasswordResetEmail(restoredEmail);
                    });
            }
            case 'verifyEmail':
                return fire.auth().applyActionCode(actionCode);
            default:
            // Error: invalid mode.
        }
    }
}
