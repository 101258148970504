import React, { useEffect, useState, useMemo } from 'react';
import fire from '../../config/Firebase';
import './css/Header.css';
import 'moment/locale/es';
import { NavLink } from 'react-router-dom';
import Table from '../../Util/Table';

let db = fire.firestore();

export default function Header(props) {
    const [tours, setTours] = useState([]);
    const [state, setState] = useState('');

    const getTours = () => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);

        db.collection('tours')
            .where('status', '==', 'Publicado')
            .get()
            .then((snapshot) => {
                let ToursTmp = [];

                snapshot.forEach((tour) => {
                    let objTour = {
                        id: tour.id,
                        tour_nom: tour.data().tour_nom,
                        tour_cuidad: tour.data().tour_ciudad,
                        tour_provincia: tour.data().tour_provincia,
                        tour_departamento: tour.data().tour_departamento,
                        tour_precio: tour.data().tour_precio,
                        tour_ciudad: tour.data().tour_ciudad,
                        tour_moneda: tour.data().tour_moneda,
                        tour_miniatura: tour.data().tour_miniatura,
                        tour_img: tour.data().tour_imagenes,
                        tour_disponibilidad: tour.data().tour_disponibilidad || [],
                        tour_minimoanticipacion: tour.data().tour_minimoanticipacion,
                        tour_tipoanticipacion: tour.data().tour_tipoanticipacion,
                        tour_fullDay: tour.data().fullDay,
                        tour_duracion:
                            +(tour.data().horasTour ?? 0) + +(tour.data().minTour ?? 0) > 0
                                ? `${tour.data().horasTour ?? 0} hora${+(tour.data().horasTour ?? 0) > 1 ? 's' : ''} ${
                                      +(tour.data().minTour ?? 0) !== 0 ? `y ${tour.data().minTour} minutos` : ''
                                  }`
                                : `${tour.data().tour_dias}D / ${
                                      +tour.data().tour_noches !== 0 ? `${tour.data().tour_noches} N` : ''
                                  }`,
                    };
                    ToursTmp.push(objTour);
                });

                ToursTmp = ToursTmp.filter((tour) => {
                    const tiempo_anticipacion = new Date(
                        date.getTime() +
                            +tour.tour_minimoanticipacion *
                                (tour.tour_tipoanticipacion === 'dias' ? 1 : 30) *
                                24 *
                                60 *
                                60 *
                                1000
                    );

                    return !!tour?.tour_disponibilidad?.find((disponibilidad) => {
                        const diaDisponibilidad = new Date(disponibilidad?.dia.seconds * 1000);

                        diaDisponibilidad.setHours(0, 0, 0, 0);

                        return (
                            +disponibilidad.capacidad > 0 &&
                            diaDisponibilidad.getTime() >= tiempo_anticipacion.getTime()
                        );
                    });
                });

                setTours(ToursTmp);
            });
    };

    useEffect(() => {
        getTours();
    }, []);

    const filteredTours = useMemo(() => {
        if (state) {
            return tours.filter((tour) => {
                return (
                    tour.tour_nom.toLowerCase().includes(state.toLowerCase()) ||
                    tour.tour_ciudad.toLowerCase().includes(state.toLowerCase()) ||
                    tour.tour_provincia.toLowerCase().includes(state.toLowerCase()) ||
                    tour.tour_departamento.toLowerCase().includes(state.toLowerCase())
                );
            });
        }

        return tours;
    }, [tours, state]);

    return (
        <div>
            <div className='header'>
                <div className='container'>
                    <div className='texto-header-container'>
                        <p className='texto-header'>
                            Encuentra los mejores lugares y las mejores aventuras de la mano de expertos locales
                        </p>
                    </div>
                    <div className='itoour-search-bar'>
                        <form>
                            <div className='itoour-search-container'>
                                <input
                                    type='search'
                                    name='search'
                                    placeholder='¿Dónde será tu próxima aventura?'
                                    className='itoour-input-search'
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            window.location.href = `/vertours?s=${state}`;
                                        }
                                    }}
                                />
                                <NavLink
                                    to={`/vertours?s=${state}`}
                                    className='itoour-btn-search'
                                    onClick={() =>
                                        window.fbq('track', 'Buscar', {
                                            medio: 'Barra de búsqueda',
                                            busqueda: state,
                                        })
                                    }
                                >
                                    Buscar <i className='fas fa-arrow-right'></i>
                                </NavLink>
                            </div>
                            {state ? (
                                <div className='search-result-container'>
                                    {filteredTours.length ? (
                                        <>
                                            <div className='search-result'>
                                                {filteredTours.map((tour) => (
                                                    <NavLink
                                                        to={`/vertour/${tour.id}`}
                                                        className='result-container'
                                                        onClick={() =>
                                                            window.fbq('track', 'Buscar', {
                                                                medio: 'Barra de búsqueda',
                                                                busqueda: state,
                                                                tour_nombre: tour.tour_nom,
                                                                tour_codigo: tour.id,
                                                            })
                                                        }
                                                    >
                                                        <div className='result-pic'>
                                                            <img src={tour.tour_img[tour.tour_miniatura || 0]} />
                                                        </div>
                                                        <div className='result-info'>
                                                            <div className='result-info-region'>
                                                                <i class='fas fa-map-marker-alt'></i> {tour.tour_ciudad}{' '}
                                                                - {tour.tour_departamento}
                                                            </div>
                                                            <div className='result-info-duracion'>
                                                                <i class='fas fa-hourglass'></i>{' '}
                                                                {tour.tour_fullDay ? 'Full day' : tour.tour_duracion}
                                                            </div>
                                                            <div className='result-info-titulo'>{tour.tour_nom}</div>
                                                        </div>
                                                    </NavLink>
                                                ))}
                                            </div>
                                            <NavLink
                                                to={`/vertours?s=${state}`}
                                                className='itoour-btn-all-result'
                                                onClick={() =>
                                                    window.fbq('track', 'Buscar', {
                                                        medio: 'Barra de búsqueda',
                                                        busqueda: state,
                                                    })
                                                }
                                            >
                                                VER TODOS LOS RESULTADOS PARA "{state}"
                                            </NavLink>
                                        </>
                                    ) : (
                                        <div className='search-no-result'>No se encontraron tours disponibles.</div>
                                    )}
                                </div>
                            ) : null}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
