import React from "react";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { perfil, dashboard, iconos } from "../../helpers/accountMenuConfig";
import "./css/AccountMenu.css";
import ProfilePic from "./ProfilePic";

export default function AccountMenu({ signOut, permisos }) {
    return (
        <NavDropdown
            title={
                permisos === "admin" ? (
                    "Administrador"
                ) : (
                    <ProfilePic permisos={permisos} />
                )
            }
            id="account-menu"
        >
            {permisos !== "admin" ? (
                <NavDropdown.Item as={Link} to={perfil[permisos].link}>
                    <i className={iconos[permisos].perfil}></i>
                    {perfil[permisos].nombre}
                </NavDropdown.Item>
            ) : null}
            {permisos !== "cliente-usuario" &&
            permisos !== "usuario" &&
            permisos !== "admin" ? (
                <NavDropdown.Item as={Link} to="/perfilcomercial">
                    <i className={iconos[permisos].perfil}></i>
                    Comercial
                </NavDropdown.Item>
            ) : null}
            {permisos !== "cliente-usuario" &&
            permisos !== "usuario" &&
            permisos !== "admin" ? (
                <NavDropdown.Item as={Link} to="/reservaciones">
                    <i className={iconos["usuario"].dashboard}></i>
                    Reservaciones
                </NavDropdown.Item>
            ) : null}
            <NavDropdown.Item as={Link} to={dashboard[permisos].link}>
                <i className={iconos[permisos].dashboard}></i>
                {dashboard[permisos].nombre}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => signOut()}>
                <i className={iconos[permisos].salir}></i>Salir
            </NavDropdown.Item>
        </NavDropdown>
    );
}
