import React, { useState, useEffect } from "react";
import fire, { auth, functions } from "../config/Firebase";
import { NavLink } from "react-router-dom";
export default function ActivarUsuario(props) {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let id = props.match.params.id;
    const refActivateUser = functions.httpsCallable("ActivateUser");
    refActivateUser(id).then((rpta) => {
      // console.log("activate", rpta.data.success);
      if (rpta.data.success === true) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    });
  }, []);
  return (
    <div>
      {success === true ? (
        <div className="container mt-5">
          <h5>Tu cuenta esta activa!!!</h5>
          <NavLink
            className="btn btn-lg btn-itoour"
            to="/login
          "
          >
            Ingresa!
          </NavLink>
        </div>
      ) : (
        <div className="container mt-5">
          <h5>ID No encontrado</h5>
        </div>
      )}
    </div>
  );
}
