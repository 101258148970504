import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './css/CardEmpresa.css';

export default function CardEmpresa({ empresa, tours }) {
    const [stats, setStats] = useState({
        total: 0,
        pendientes: 0,
        observados: 0,
        pausados: 0,
        inhabilitados: 0,
        publicados: 0,
        cancelados: 0,
    });

    useEffect(() => {
        const cur_tours = tours?.filter((t) => t.cli_email === empresa.email);

        setStats({
            total: cur_tours.length,
            pendientes: cur_tours.filter((t) => t.status === 'Pendiente de aprobación').length,
            publicados: cur_tours.filter((t) => t.status === 'Publicado').length,
            observados: cur_tours.filter((t) => t.status === 'Observado').length,
            pausados: cur_tours.filter((t) => t.status === 'Pausado').length,
            inhabilitados: cur_tours.filter((t) => t.status === 'Inhabilitado').length,
            cancelados: cur_tours.filter((t) => t.status === 'Cancelado').length,
        });
    }, [empresa, tours]);

    return (
        <div className='card-empresa'>
            <div className='card-information'>
                <div className='card-foto-empresa'>
                    <NavLink className='card-info-nombre' to={`/listatoursadmin/${empresa.id}`}>
                        <img src={empresa.foto}></img>
                    </NavLink>
                </div>
                <div className='card-info-empresa'>
                    <NavLink className='card-info-nombre' to={`/listatoursadmin/${empresa.id}`}>
                        {empresa.nombre == '' ? 'Empresa sin configurar' : empresa.nombre}
                    </NavLink>
                    <small>{empresa.tipo}</small>
                    <div className='card-info-box'>
                        <div className='card-info-data'>
                            <span className='card-info-data-name'>RUC:</span>
                            <span className='card-info-data-value'>{empresa.ruc || '--'}</span>
                        </div>
                        <div className='card-info-data'>
                            <span className='card-info-data-name'>Correo:</span>
                            <span className='card-info-data-value'>{empresa.email}</span>
                        </div>
                        {empresa.tipo == 'TOUR OPERADOR' ? (
                            <div className='card-info-data'>
                                <span className='card-info-data-name'>Representante:</span>
                                <span className='card-info-data-value'>{empresa.representante || '--'}</span>
                            </div>
                        ) : null}
                        <div className='card-info-data'>
                            <span className='card-info-data-name'>Teléfono:</span>
                            <span className='card-info-data-value'>
                                {empresa.tipo == 'TOUR OPERADOR'
                                    ? empresa.reprtel || '--'
                                    : empresa.telefono || empresa.telempresa}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-tours'>
                <div className='card-tours-stat first-c tours-total'>
                    <p className='card-tours-stat-name'>TOTAL</p>
                    <p className='card-tours-stat-value'>{stats.total}</p>
                </div>
                <div className='card-tours-stat tours-publicados'>
                    <p className='card-tours-stat-name'>PUBLICADOS</p>
                    <p className='card-tours-stat-value'>{stats.publicados}</p>
                </div>
                <div className='card-tours-stat tours-pendientes'>
                    <p className='card-tours-stat-name'>PENDIENTES</p>
                    <p className='card-tours-stat-value'>{stats.pendientes}</p>
                </div>
                <div className='card-tours-stat tours-pausados'>
                    <p className='card-tours-stat-name'>PAUSADOS</p>
                    <p className='card-tours-stat-value'>{stats.pausados}</p>
                </div>
                <div className='card-tours-stat tours-observados'>
                    <p className='card-tours-stat-name'>OBSERVADOS</p>
                    <p className='card-tours-stat-value'>{stats.observados}</p>
                </div>
                <div className='card-tours-stat tours-inhabilitados'>
                    <p className='card-tours-stat-name'>INHABILITADOS</p>
                    <p className='card-tours-stat-value'>{stats.inhabilitados}</p>
                </div>
                <div className='card-tours-stat last-c tours-cancelados'>
                    <p className='card-tours-stat-name'>CANCELADOS</p>
                    <p className='card-tours-stat-value'>{stats.cancelados}</p>
                </div>
            </div>
        </div>
    );
}
