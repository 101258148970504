import React, { Fragment, useEffect, useState } from 'react';
import './css/Login.css';
import { useForm } from 'react-hook-form';
import { AuthService } from '../services/Auth';
import Navbar from '../components/Shared/Navbar';
import fire, { googleProvider } from '../config/Firebase';
import { signIn } from '../store/actions/authActions';
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';

let Login = (props) => {
    const [id, setId] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, errors } = useForm();

    let sAuth = new AuthService();

    let login = (data) => {
        fire.auth()
            .signInWithEmailAndPassword(data.email, data.password)
            .then((user) => {
                Swal.fire({
                    icon: 'success',
                    title: `Ha iniciado sesión exitosamente`,
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    // props.history.push("/");
                });
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: `Login Incorrecto, Verifique sus datos`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    const loginWithGoogle = (e) => {
        e.preventDefault();
        sAuth.registerWithGoogle().catch((error) => console.log({ error }));
    };

    const EyeButton = ({ show, setShow }) => {
        return (
            <>
                {show ? (
                    <span className='input-group-text eye-password' onClick={(e) => setShow(false)}>
                        <i className='fas fa-eye-slash'></i>
                    </span>
                ) : (
                    <span className='input-group-text eye-password' onClick={(e) => setShow(true)}>
                        <i className='fas fa-eye'></i>
                    </span>
                )}
            </>
        );
    };

    return (
        <Fragment>
            <div className='contenedor'>
                <div className='imagenLogin'>
                    <span>itoour.com</span>
                </div>
                <div className='formulario'>
                    <div className='card'>
                        <div className='card-header'>itoour.com</div>

                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='clearfix'>
                                    <h3 className='float-left'>Iniciar Sesión</h3>
                                    <Link
                                        to={'/registro'}
                                        className='font-weight-bold float-right'
                                        id='registro'
                                        onClick={() => {
                                            window.fbq('track', 'Cliente potencial', {
                                                medio: 'Link directo Registro Turista',
                                            });
                                        }}
                                    >
                                        <span>¿Eres nuevo?</span> <span>Regístrate</span>
                                    </Link>
                                </div>
                                <form>
                                    <label htmlFor='email' className='font-weight-bold'>
                                        Correo Electrónico
                                    </label>
                                    <br />
                                    <input
                                        type='email'
                                        className='form-control auth-email-input'
                                        id='email'
                                        name='email'
                                        placeholder='Ejemplo: matias23@ejemplo.com'
                                        ref={register({ required: true })}
                                    />
                                    {errors.email && <small className='text-danger'>El email es obligatorio</small>}
                                    <br />
                                    <label htmlFor='pass' className='font-weight-bold'>
                                        Contraseña
                                    </label>
                                    <br />
                                    <div className='input-group'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className='form-control'
                                            id='password'
                                            name='password'
                                            placeholder='Ingresar contraseña'
                                            ref={register({ required: true })}
                                        />
                                        <div className='input-group-prepend'>
                                            <EyeButton show={showPassword} setShow={setShowPassword} />
                                        </div>
                                        {errors.password && (
                                            <small className='text-danger'>El password es obligatorio</small>
                                        )}
                                    </div>
                                    <br />
                                    <div className='custom-control custom-checkbox'>
                                        <input type='checkbox' className='custom-control-input' id='remember' />
                                        <label className='custom-control-label font-weight-bold' htmlFor='remember'>
                                            Recuérdame
                                        </label>
                                        <Link
                                            className='font-weight-bold float-right'
                                            to='/restaurar'
                                            style={{ color: '#D6872B' }}
                                        >
                                            Olvidé mi contraseña
                                        </Link>
                                    </div>
                                    <br />
                                    <button
                                        className='btn btn-block btn-login text-light'
                                        onClick={handleSubmit(login)}
                                    >
                                        Iniciar Sesión
                                    </button>
                                    <div className='separador-login'>
                                        <div className='linea-separador'></div>o<div className='linea-separador'></div>
                                    </div>
                                    <button
                                        className='btn btn-block btn-login btn-login-google text-light'
                                        onClick={loginWithGoogle}
                                    >
                                        <i class='fab fa-google'></i> Continuar con Google
                                    </button>
                                    <br />
                                </form>

                                {/* <button className="btn btn-outline-danger btn-sm">
                  <i className="fab fa-google"></i> Iniciar con Google
                </button>
                <button className="btn btn-outline-primary btn-sm float-right">
                  <i className="fab fa-facebook-f"></i> Iniciar con Facebook
                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

// const mapStateToProps = (state) => {
//   return {
//     authError: state.auth.authError,
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     signIn: (creds) => dispatch(signIn(creds))
//   }
// }

export default Login;
